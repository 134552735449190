import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'

export const getSafeWorst = async (args, reduxEvent) => {
	try {
		const res = await axiosInstance.request({
			url: 'v1/analysis/safe-worst-triggers',
			source: AxiosAbortController.signal,
			method: 'get',
		})
		return res.data
	} catch (err) {
		throw err
	}
}

export const getStoolLabels = async (args, reduxEvent) => {
	try {
		const res = await axiosInstance.request({
			url: 'v1/analysis/stool_labels',
			source: AxiosAbortController.signal,
			method: 'get',
		})
		return res.data
	} catch (err) {
		throw err
	}
}

export const getInterventionRecs = async (args, reduxEvent) => {
	try {
		const res = await axiosInstance.request({
			url: 'v1/analysis/interventions',
			source: AxiosAbortController.signal,
			method: 'get',
		})
		return res.data
	} catch (err) {
		return {
			// Not Available
			data: {
				top: null,
			},
		}
	}
}

export const downloadExport = async () => {
	try {
		const res = await axiosInstance.request({
			url: 'v1/export',
			source: AxiosAbortController.signal,
			method: 'get',
		})
		return res.data
	} catch (err) {
		console.log('No good')
	}
}
