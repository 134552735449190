import React, { useEffect } from 'react'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { IntercomProvider, useIntercom } from 'react-use-intercom'

const IntercomAuthResponder = ({ auth, children }) => {
	const { boot, update, shutdown } = useIntercom()
	const bootWithProps = React.useCallback(() => {
		if (auth.email && auth.sub) {
			boot({
				userId: auth.sub,
				email: auth.email,
				hideDefaultLauncher: true,
			})
		}
	}, [boot])
	useEffect(() => {
		if (auth.email && auth.sub) {
			bootWithProps()
		} else {
			shutdown()
		}
	}, [auth.sub])

	return <>{children}</>
}

const IntercomBootMasterComponent = (props) => {
	const onHide = React.useCallback(() => console.log('Intercom did hide the Messenger'), [])
	const onShow = React.useCallback(() => console.log('Intercom did show the Messenger'), [])
	const onUnreadCountChange = React.useCallback((number) => {
		console.log('Intercom has a new unread message', number)
		// setUnreadMessagesCount(amount);
	})
	return (
		<IntercomProvider
			appId={process.env.INTERCOM_APP_ID_DIETA}
			onUnreadCountChange={onUnreadCountChange}
			onHide={onHide}
			onShow={onShow}
		>
			<IntercomAuthResponder auth={props.auth}>{props.children}</IntercomAuthResponder>
		</IntercomProvider>
	)
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}

export default compose(connect(mapStateToProps, actions))(IntercomBootMasterComponent)
