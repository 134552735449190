import {
	fetchOthers,
	addOther as postOther,
	updateOther as putOther,
	removeOther,
	fetchOtherTypes,
	addOtherType as postOtherType,
	updateOtherType as putOtherType,
	removeOtherType,
} from 'api/other'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { getDefaultOccurredAt } from 'utils/helpers'

const getDefaultOther = (attentionDate) => ({
	notes: null,
	occurred_at: getDefaultOccurredAt(attentionDate),
	types: [],
	_app: {
		uuid: null,
		type: 'other',
		synced: false,
	},
})

export const resetOtherLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_OTHER_LOADERS })
	}
}

export const addOther = (other) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_OTHER, payload: other })
		try {
			const res = await postOther(other)
			const contextResponse = {
				...res.data,
				_app: {
					...other._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.ADD_OTHER_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...other,
				_app: {
					err,
					...other._app,
				},
			}
			handleFail(dispatch, types.ADD_OTHER_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateOther = (other, previousOther) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.UPDATE_OTHER,
			payload: { new: other, old: previousOther },
		})
		try {
			const res = other.id ? await putOther(other, previousOther) : await postOther(other)
			const contextResponse = {
				...res.data,
				_app: {
					...other._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_OTHER_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...other,
				_app: {
					err,
					...other._app,
				},
			}
			handleFail(dispatch, types.UPDATE_OTHER_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteOther = (other) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.DELETE_OTHER, payload: other })
		try {
			const res = await removeOther(other)
			const contextResponse = {
				...res.data,
				_app: {
					...other._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.DELETE_OTHER_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...other,
				_app: {
					err,
					...other._app,
				},
			}
			handleFail(dispatch, types.DELETE_OTHER_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getOthers = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_OTHERS })
		try {
			const res = await fetchOthers(args, {
				dispatch,
				event: types.GET_OTHERS_SUCCESS,
			})
			await handleSuccess(dispatch, types.GET_OTHERS_SUCCESS_COMPLETE)
			return res
		} catch (err) {
			handleFail(dispatch, types.GET_OTHERS_FAIL, err)
		}
	}
	return thunk
}

export const addOtherType = (otherType) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_OTHER_TYPE, payload: otherType })
		try {
			const res = await postOtherType(otherType)
			const contextResponse = {
				...res.data,
				_app: {
					...otherType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_OTHER_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...otherType,
				_app: {
					err,
					...otherType._app,
				},
			}
			handleFail(dispatch, types.ADD_OTHER_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateOtherType = (otherType) => {
	async function thunk(dispatch) {
		dispatch({ type: types.UPDATE_OTHER_TYPE, payload: otherType })
		try {
			const res = otherType.id ? await putOtherType(otherType) : await postOtherType(otherType)
			const contextResponse = {
				...res.data,
				_app: {
					...otherType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.UPDATE_OTHER_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...otherType,
				_app: {
					err,
					...otherType._app,
				},
			}
			handleFail(dispatch, types.UPDATE_OTHER_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteOtherType = (otherType) => {
	async function thunk(dispatch) {
		dispatch({ type: types.DELETE_OTHER_TYPE, payload: otherType })
		try {
			const res = await removeOtherType(otherType)
			const contextResponse = {
				...res.data,
				_app: {
					...otherType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_OTHER_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...otherType,
				_app: {
					err,
					...otherType._app,
				},
			}
			handleFail(dispatch, types.DELETE_OTHER_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getOtherTypes = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_OTHER_TYPES })
		try {
			const res = await fetchOtherTypes(args)
			handleSuccess(dispatch, types.GET_OTHER_TYPES_SUCCESS, res.data, {
				...args,
				invoked_at: new Date().toISOString(),
			})
			return res.data
		} catch (err) {
			handleFail(dispatch, types.GET_OTHER_TYPES_FAIL, err)
		}
	}
	return thunk
}

export const setCurrentOther = (other, attentionDate) => {
	if (!other) {
		other = getDefaultOther(attentionDate)
	}
	return (dispatch) => {
		dispatch({ type: types.CURRENT_OTHER_SET, payload: other })
	}
}

export const clearCurrentOther = (other = getDefaultOther()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_OTHER_CLEAR, payload: other })
	}
}

export const addCurrentOtherType = (otherType) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({ type: types.CURRENT_OTHER_TYPE_ADD, payload: otherType })
			res(otherType)
		})
}

export const removeCurrentOtherType = (otherType) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({ type: types.CURRENT_OTHER_TYPE_REMOVE, payload: otherType })
			res(otherType)
		})
}
