/**
 * CRUD Actions for BOWEL
 */
// ADD
export const ADD_BOWEL_SUCCESS = 'ADD_BOWEL_SUCCESS'
export const ADD_BOWEL_MEDIA_SUCCESS = 'ADD_BOWEL_MEDIA_SUCCESS'
export const ADD_BOWEL_FAIL = 'ADD_BOWEL_FAIL'
export const ADD_BOWEL_MEDIA_FAIL = 'ADD_BOWEL_MEDIA_FAIL'
export const ADD_BOWEL = 'ADD_BOWEL'
// DELETE
export const DELETE_BOWEL_SUCCESS = 'DELETE_BOWEL_SUCCESS'
export const DELETE_BOWEL_MEDIA_SUCCESS = 'DELETE_BOWEL_MEDIA_SUCCESS'
export const DELETE_BOWEL_FAIL = 'DELETE_BOWEL_FAIL'
export const DELETE_BOWEL_MEDIA_FAIL = 'DELETE_BOWEL_MEDIA_FAIL'
export const DELETE_BOWEL = 'DELETE_BOWEL'
// UPDATE
export const UPDATE_BOWEL_SUCCESS = 'UPDATE_BOWEL_SUCCESS'
export const UPDATE_BOWEL_MEDIA_SUCCESS = 'UPDATE_BOWEL_MEDIA_SUCCESS'
export const UPDATE_BOWEL_FAIL = 'UPDATE_BOWEL_FAIL'
export const UPDATE_BOWEL_MEDIA_FAIL = 'UPDATE_BOWEL_MEDIA_FAIL'
export const UPDATE_BOWEL = 'UPDATE_BOWEL'
// GET ALL
export const GET_BOWELS_SUCCESS = 'GET_BOWELS_SUCCESS'
export const GET_BOWELS_SUCCESS_COMPLETE = 'GET_BOWELS_SUCCESS_COMPLETE'
export const GET_BOWELS_FAIL = 'GET_BOWELS_FAIL'
export const GET_BOWELS = 'GET_BOWELS'

export const GET_BOWEL_SUCCESS = 'GET_BOWEL_SUCCESS'
export const GET_BOWEL_FAIL = 'GET_BOWEL_FAIL'
export const GET_BOWEL = 'GET_BOWEL'

// ADD - BOWEL_TYPE
export const ADD_BOWEL_TYPE_SUCCESS = 'ADD_BOWEL_TYPE_SUCCESS'
export const ADD_BOWEL_TYPE_FAIL = 'ADD_BOWEL_TYPE_FAIL'
export const ADD_BOWEL_TYPE = 'ADD_BOWEL_TYPE'
// DELETE - BOWEL_TYPE
export const DELETE_BOWEL_TYPE_SUCCESS = 'DELETE_BOWEL_TYPE_SUCCESS'
export const DELETE_BOWEL_TYPE_FAIL = 'DELETE_BOWEL_TYPE_FAIL'
export const DELETE_BOWEL_TYPE = 'DELETE_BOWEL_TYPE'
// UPDATE - BOWEL_TYPE
export const UPDATE_BOWEL_TYPE_SUCCESS = 'UPDATE_BOWEL_TYPE_SUCCESS'
export const UPDATE_BOWEL_TYPE_FAIL = 'UPDATE_BOWEL_TYPE_FAIL'
export const UPDATE_BOWEL_TYPE = 'UPDATE_BOWEL_TYPE'
// GET ALL - BOWEL_TYPE
export const GET_BOWEL_TYPES_SUCCESS = 'GET_BOWEL_TYPES_SUCCESS'
export const GET_BOWEL_TYPES_FAIL = 'GET_BOWEL_TYPES_FAIL'
export const GET_BOWEL_TYPES = 'GET_BOWEL_TYPES'

/**
 * Current Types
 */
// CURRENT BOWEL
export const CURRENT_BOWEL_SET = 'CURRENT_BOWEL_SET'
export const CURRENT_BOWEL_UPDATE = 'CURRENT_BOWEL_UPDATE'
export const CURRENT_BOWEL_CLEAR = 'CURRENT_BOWEL_CLEAR'

// CURRENT OTHER_TYPE
export const CURRENT_BOWEL_TYPE_ADD = 'CURRENT_BOWEL_TYPE_ADD'
export const CURRENT_BOWEL_TYPE_REMOVE = 'CURRENT_BOWEL_TYPE_REMOVE'

export const MEDIA_BOWEL_SET = 'MEDIA_BOWEL_SET'
export const MEDIA_BOWEL_ITEM_SET = 'MEDIA_BOWEL_ITEM_SET'
export const MEDIA_BOWEL_ITEM_DELETE = 'MEDIA_BOWEL_ITEM_DELETE'

export const RESET_BOWEL_LOADERS = 'RESET_BOWEL_LOADERS'

export const BOWEL_CONTEXT_RESET = 'BOWEL_CONTEXT_RESET'
