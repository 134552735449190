import * as types from 'types'
import { deepMerge } from 'reducers/z_redux_helpers'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	safeWorst: {
		lastFetched: null,
		lastModified: null,
		best: [],
		worst: [],
		loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
	},
	stool_labels_timeseries: {
		lastFetched: null,
		data: [],
		loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
	},
	interventionRecs: {
		lastFetched: null,
		top: [],
		loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
	},
	lastFetched: null,
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case types.PURGE: {
			return JSON.parse(JSON.stringify(INITIAL_STATE))
		}

		case types.FETCH_INTERVENTION_RECS: {
			return {
				...state,
				interventionRecs: {
					...state.interventionRecs,
					loading: {
						...state.interventionRecs.loading,
						read: true,
					},
				},
				loading: {
					...state.loading,
					read: true,
				},
			}
		}

		case types.FETCH_INTERVENTION_RECS_SUCCESS: {
			return {
				...state,
				interventionRecs: {
					...state.interventionRecs,
					lastFetched: new Date().getTime(),
					top:
						action.payload.top &&
						action.payload.top
							.sort((a, b) => {
								return +a.rank - +b.rank
							})
							.map((rec) => ({
								...rec,
								rank: parseInt(rec.rank),
							})),
					loading: {
						...state.interventionRecs.loading,
						read: false,
					},
				},
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.FETCH_SAFE_WORST_TRIGGERS: {
			return {
				...state,
				safeWorst: {
					...state.safeWorst,
					loading: {
						...state.safeWorst.loading,
						read: true,
					},
				},
				loading: {
					...state.loading,
					read: true,
				},
			}
		}

		case types.FETCH_SAFE_WORST_TRIGGERS_SUCCESS: {
			return {
				...state,
				safeWorst: {
					...state.safeWorst,
					lastFetched: new Date().getTime(),
					lastModified: action.payload.lastModified ? new Date(action.payload.lastModified).getTime() : null,
					best: action.payload.best,
					worst: action.payload.worst,
					loading: {
						...state.safeWorst.loading,
						read: false,
					},
				},
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.FETCH_SAFE_WORST_TRIGGERS_FAIL: {
			return {
				...state,
				safeWorst: {
					...state.safeWorst,
					lastFetched: new Date().getTime(),
					loading: {
						...state.safeWorst.loading,
						read: false,
					},
				},
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.FETCH_STOOL_LABELS_TIMESERIES: {
			return {
				...state,
				stool_labels_timeseries: {
					...state.stool_labels_timeseries,
					loading: {
						...state.stool_labels_timeseries.loading,
						read: true,
					},
				},
				loading: {
					...state.loading,
					read: true,
				},
			}
		}

		case types.FETCH_STOOL_LABELS_TIMESERIES_SUCCESS: {
			return {
				...state,
				stool_labels_timeseries: {
					...state.stool_labels_timeseries,
					lastFetched: new Date().getTime(),
					data: action.payload,
					loading: {
						...state.stool_labels_timeseries.loading,
						read: false,
					},
				},
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.FETCH_STOOL_LABELS_TIMESERIES_FAIL: {
			return {
				...state,
				stool_labels_timeseries: {
					...state.stool_labels_timeseries,
					lastFetched: new Date().getTime(),
					loading: {
						...state.stool_labels_timeseries.loading,
						read: false,
					},
				},
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case 'FAST_FORWARD_STORES': {
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)
		}

		case types.SCENE: {
			return {
				...state,
				interventionRecs: {
					...state.interventionRecs,
					loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
				},
				safeWorst: {
					...state.safeWorst,
					loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
				},
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}
		}

		case types.ANALYTICS_CONTEXT_RESET: {
			return JSON.parse(JSON.stringify(INITIAL_STATE))
		}

		default: {
			return state
		}
	}
}
