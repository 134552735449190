/**
 * CRUD Actions for SYMPTOM
 */
// ADD - SYMPTOM
export const ADD_SYMPTOM_SUCCESS = 'ADD_SYMPTOM_SUCCESS'
export const ADD_SYMPTOM_FAIL = 'ADD_SYMPTOM_FAIL'
export const ADD_SYMPTOM = 'ADD_SYMPTOM'
// DELETE - SYMPTOM
export const DELETE_SYMPTOM_SUCCESS = 'DELETE_SYMPTOM_SUCCESS'
export const DELETE_SYMPTOM_FAIL = 'DELETE_SYMPTOM_FAIL'
export const DELETE_SYMPTOM = 'DELETE_SYMPTOM'
// UPDATE - SYMPTOM
export const UPDATE_SYMPTOM_SUCCESS = 'UPDATE_SYMPTOM_SUCCESS'
export const UPDATE_SYMPTOM_FAIL = 'UPDATE_SYMPTOM_FAIL'
export const UPDATE_SYMPTOM = 'UPDATE_SYMPTOM'
// GET ALL - SYMPTOM
export const GET_SYMPTOMS_SUCCESS = 'GET_SYMPTOMS_SUCCESS'
export const GET_SYMPTOMS_SUCCESS_COMPLETE = 'GET_SYMPTOMS_SUCCESS_COMPLETE'
export const GET_SYMPTOMS_FAIL = 'GET_SYMPTOMS_FAIL'
export const GET_SYMPTOMS = 'GET_SYMPTOMS'

export const GET_SYMPTOM_SUCCESS = 'GET_SYMPTOM_SUCCESS'
export const GET_SYMPTOM_FAIL = 'GET_SYMPTOM_FAIL'
export const GET_SYMPTOM = 'GET_SYMPTOM'

// ADD - SYMPTOM_TYPE
export const ADD_SYMPTOM_TYPE_SUCCESS = 'ADD_SYMPTOM_TYPE_SUCCESS'
export const ADD_SYMPTOM_TYPE_FAIL = 'ADD_SYMPTOM_TYPE_FAIL'
export const ADD_SYMPTOM_TYPE = 'ADD_SYMPTOM_TYPE'
// DELETE - SYMPTOM_TYPE
export const DELETE_SYMPTOM_TYPE_SUCCESS = 'DELETE_SYMPTOM_TYPE_SUCCESS'
export const DELETE_SYMPTOM_TYPE_FAIL = 'DELETE_SYMPTOM_TYPE_FAIL'
export const DELETE_SYMPTOM_TYPE = 'DELETE_SYMPTOM_TYPE'
// UPDATE - SYMPTOM_TYPE
export const UPDATE_SYMPTOM_TYPE_SUCCESS = 'UPDATE_SYMPTOM_TYPE_SUCCESS'
export const UPDATE_SYMPTOM_TYPE_FAIL = 'UPDATE_SYMPTOM_TYPE_FAIL'
export const UPDATE_SYMPTOM_TYPE = 'UPDATE_SYMPTOM_TYPE'
// GET ALL - SYMPTOM_TYPE
export const GET_SYMPTOM_TYPES_SUCCESS = 'GET_SYMPTOM_TYPES_SUCCESS'
export const GET_SYMPTOM_TYPES_FAIL = 'GET_SYMPTOM_TYPES_FAIL'
export const GET_SYMPTOM_TYPES = 'GET_SYMPTOM_TYPES'

/**
 * Current Types
 */
// CURRENT OTHER
export const CURRENT_SYMPTOM_SET = 'CURRENT_SYMPTOM_SET'
export const CURRENT_SYMPTOM_CLEAR = 'CURRENT_SYMPTOM_CLEAR'
// CURRENT OTHER_TYPE
export const CURRENT_SYMPTOM_TYPE_ADD = 'CURRENT_SYMPTOM_TYPE_ADD'
export const CURRENT_SYMPTOM_TYPE_REMOVE = 'CURRENT_SYMPTOM_TYPE_REMOVE'

export const MEDIA_SYMPTOM_SET = 'MEDIA_SYMPTOM_SET'

export const RESET_SYMPTOM_LOADERS = 'RESET_SYMPTOM_LOADERS'

export const SYMPTOM_CONTEXT_RESET = 'SYMPTOM_CONTEXT_RESET'
