import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import HelpIcon from '@mui/icons-material/Help'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsIcon from '@mui/icons-material/Notifications'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import StopIcon from '@mui/icons-material/Stop'
import ForumIcon from '@mui/icons-material/Forum'
import Typography from '@mui/material/Typography'
import MailIcon from '@mui/icons-material/Mail'

import { withTranslation } from 'react-i18next'
import { paths } from 'routes'
import { navigate } from 'gatsby'
import { useSendbirdStateContext, sendBirdSelectors } from 'sendbird-uikit'
import Badge from '@mui/material/Badge'
import { PROGRAM_IDS } from '../../../constants.js'
import { ChatBubble } from '@mui/icons-material'

const PREFIX = 'index'

const classes = {
	menuButton: `${PREFIX}-menuButton`,
	button: `${PREFIX}-button`,
	appBar: `${PREFIX}-appBar`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.menuButton}`]: {
		marginLeft: theme.spacing(-1),
	},

	[`& .${classes.button}`]: {
		borderColor: lightColor,
	},

	[`& .${classes.appBar}`]: {
		zIndex: theme.zIndex.drawer + 1,
	},
}))

const lightColor = 'rgba(255, 255, 255, 0.7)'

function Header({ auth, patient, onDrawerToggle, onPanelToggle, clearCurrentPatient, setCurrentPatient, title }) {
	const [unreadMessages, setUnreadMessages] = useState(0)
	const roles = auth?.app_metadata?.roles || []
	const hasRoles = roles.length

	const context = useSendbirdStateContext()
	const sdkInstance = sendBirdSelectors.getSdk(context)

	const checkUnread = async () => {
		if (typeof sdkInstance?.getTotalUnreadChannelCount === 'function') {
			const count = await sdkInstance?.getTotalUnreadChannelCount()
			setUnreadMessages(count)
		}
	}

	useEffect(() => {
		if (sdkInstance) checkUnread()
	}, [sdkInstance])

	const isProvider = ['Admin', 'Dietitian', 'Gastroenterologist', 'Nutritionist'].filter((n) => {
		return roles.indexOf(n) > -1
	}).length
	const isResearcher = ['Admin', 'Researcher'].filter((n) => {
		return roles.indexOf(n) > -1
	}).length

	const isMonitoring = patient.current?.key

	const isReasearching = patient.current?.isStudy
	const isProgrammed = patient.current?.isProgram

	const hasStudies = patient.studies.length
	const hasPrograms = patient.programs.length

	const metamucilPatient = patient.programs.some((e) => e.id === PROGRAM_IDS.METAMUCIL)
	const intercomId = metamucilPatient ? process.env.INTERCOM_APP_ID_METAMUCIL : process.env.INTERCOM_APP_ID_DIETA

	return (
		<Root>
			<AppBar color="primary" position="sticky" className={classes.appBar} id={'#header'}>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item sx={{ display: { xs: 'block', md: 'none' } }}>
							<IconButton
								color="inherit"
								aria-label="Open drawer"
								onClick={onDrawerToggle}
								className={classes.menuButton}
								size="large"
							>
								<MenuIcon />
							</IconButton>
						</Grid>

						{/* {isProvider ? (
							<Grid item>
								<Button
									className={classes.button}
									onClick={() => {
										navigate(paths.patient)
									}}
									variant="outlined"
									color="inherit"
									size="small"
								>
									Patients
								</Button>
							</Grid>
						) : (
							<></>
						)} */}
						{hasStudies ? (
							<Grid item>
								<Button
									className={classes.button}
									onClick={() => {
										navigate(paths.study)
									}}
									variant="outlined"
									color="inherit"
									size="small"
								>
									Studies
								</Button>
							</Grid>
						) : (
							<></>
						)}

						{hasPrograms ? (
							<Grid item>
								<Button
									className={classes.button}
									onClick={() => {
										navigate('/program')
									}}
									variant="outlined"
									color="inherit"
									size="small"
								>
									Patients
								</Button>
							</Grid>
						) : (
							<></>
						)}

						{isMonitoring ? (
							<Grid item>
								<Button
									className={classes.button}
									onClick={async () => {
										// finish monitoring
										await clearCurrentPatient()
										if (isReasearching) {
											navigate(paths.study)
										} else if (isProgrammed) {
											navigate(paths.program)
										} else {
											navigate(paths.patient)
										}
									}}
									variant="outlined"
									color="inherit"
									size="small"
									startIcon={<StopIcon />}
								>
									Finish Monitoring
								</Button>
							</Grid>
						) : (
							<></>
						)}

						<Grid item xs />

						{/* {isMonitoring ? (
							<Grid item>
								<Tooltip arrow title="Chats" aria-label="chats">
									<IconButton
										color="inherit"
										aria-label="Open chats"
										onClick={() => {
											window.open(
												`https://app.intercom.com/apps/${intercomId}/users/show?user_id=${patient.current.key}`,
												'',
												'menubar=1,resizable=1,width=1250,height=850,top=0,left=1000'
											)
										}}
										size="large"
									>
										<Badge badgeContent={unreadMessages} color="error">
											<MailIcon />
										</Badge>
									</IconButton>
								</Tooltip>
							</Grid>
						) : (
							<></>
						)} */}

						{/* <Grid item>
							<Button className={classes.button} variant="outlined" color="inherit" size="small">
								Support
							</Button>
						</Grid> */}

						{/* {!isMonitoring && ( */}
						<Grid item>
							<IconButton
								color="inherit"
								aria-label="Open chat"
								onClick={() => {
									navigate(paths.chat)
								}}
								size="large"
							>
								<Badge badgeContent={unreadMessages} color="error">
									<ChatBubble />
								</Badge>
							</IconButton>
						</Grid>
						{/* )} */}

						<Grid item>
							<Tooltip arrow title="Alerts • No alerts" aria-label="alerts • no alerts">
								<IconButton
									color="inherit"
									aria-label="Open panel"
									onClick={onPanelToggle}
									size="large"
								>
									<NotificationsIcon />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		</Root>
	)
}

Header.propTypes = {
	onDrawerToggle: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	auth: PropTypes.shape({
		app_metadata: PropTypes.shape({
			roles: PropTypes.array,
		}),
	}),
	patient: PropTypes.shape({
		current: PropTypes.shape({
			id: PropTypes.number,
		}),
		data: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	setCurrentPatient: PropTypes.func.isRequired,
	clearCurrentPatient: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, patient }) => {
	return {
		auth,
		patient,
	}
}
export default compose(connect(mapStateToProps, actions), withTranslation())(Header)
