import React from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { lighten, darken } from '@mui/system'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TablePagination from '@mui/material/TablePagination'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import RefreshIcon from '@mui/icons-material/Refresh'

import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'
import Tooltip from '@mui/material/Tooltip'

import moment from 'moment'
import { TimeSeconds } from 'utils/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCodeBranch } from '@fortawesome/free-solid-svg-icons'

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

const headCells = [
	{ id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Name', format: (value) => value },
	{
		id: 'symptom',
		align: 'center',
		numeric: false,
		disablePadding: false,
		label: 'Symptom',
		format: (value) => value,
	},
	{
		id: 'duration',
		align: 'center',
		numeric: true,
		disablePadding: false,
		label: 'Duration',
		format: (value) => value,
	},
	{ id: 'assign', align: 'center', numeric: false, disablePadding: false, label: 'Assign', format: (value) => value },
]

function EnhancedTableHead(props) {
	const { order, orderBy, rowCount, onRequestSort } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead className={classes.root}>
			<TableRow>
				<TableCell />
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.align}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

// const useRowStyles = makeStyles((theme) => ({
// 	root: {
// 		'& > *': {
// 			borderBottom: 'unset',
// 		},
// 		'&:nth-of-type(even)': {
// 			backgroundColor: theme.palette.action.hover,
// 		},
// 	},
// }))

const effectIcon = (effect) => {
	if (effect === null) {
		return <></>
	} else if (effect === 50) {
		return <TrendingFlatIcon color={'disabled'} />
	} else if (effect < 50) {
		return <TrendingDownIcon color={'error'} />
	} else {
		return <TrendingUpIcon color={'primary'} />
	}
}

const ROW_PREFIX = 'InterventionsOptionsTableRow'

const row_classes = {
	root: `${ROW_PREFIX}-root`,
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	[`&.${row_classes.root}`]: {
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))

function Row(props) {
	const { row, setCurrentIntervention, handleClickOpenInterventionCreate } = props
	const [open, setOpen] = React.useState(false)

	return (
		<React.Fragment>
			<StyledTableRow className={row_classes.root}>
				<TableCell>
					<IconButton color="inherit" aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row" align="left">
					{row.meta?.friendlyName || row.name}
				</TableCell>
				<TableCell align="center">{row.symptom}</TableCell>
				<TableCell align="center">{row.duration ? TimeSeconds(row.duration, 1) : 'Self Bounded'}</TableCell>

				<TableCell align="center">
					<Grid container direction="row" alignItems="center" justifyContent="center">
						<Tooltip title="Create custom experiment based on this one">
							<label htmlFor="fork-intervention-button">
								<IconButton
									color="primary"
									aria-label="fork intervention"
									component="span"
									onClick={() => {
										setCurrentIntervention(row)
										handleClickOpenInterventionCreate(true)
									}}
									size="large"
								>
									<FontAwesomeIcon icon={faCodeBranch} />
								</IconButton>
							</label>
						</Tooltip>
						<Tooltip title="Assign this experiment now">
							<Button
								variant="contained"
								color="primary"
								onClick={() => {
									setCurrentIntervention(row)
									handleClickOpenInterventionCreate(false)
								}}
							>
								Assign
							</Button>
						</Tooltip>
					</Grid>
				</TableCell>
			</StyledTableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell
											style={{
												borderStyle: 'none',
											}}
										>
											Details
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableCell
										style={{
											borderStyle: 'none',
										}}
										component="th"
										scope="row"
										align="left"
									>
										{row.recommendation}
									</TableCell>
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	)
}

Row.propTypes = {
	row: PropTypes.shape({
		name: PropTypes.string.isRequired,
		symptom: PropTypes.string.isRequired,
		duration: PropTypes.number,
		meta: PropTypes.shape({
			theme: PropTypes.string.isRequired,
			friendlyName: PropTypes.string.isRequired,
		}),
	}).isRequired,
}

const PREFIX = 'InterventionsOptionsTable'

const classes = {
	root: `${PREFIX}-root`,
	wrapper: `${PREFIX}-wrapper`,
	container: `${PREFIX}-container`,
	titleBar: `${PREFIX}-titleBar`,
	interventionGroupHeader: `${PREFIX}-interventionGroupHeader`,
	compactSearch: `${PREFIX}-compactSearch`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		width: '100%',
	},
	[`& .${classes.wrapper}`]: {
		width: '100%',
		// margin: theme.spacing(2),
	},
	[`& .${classes.container}`]: {
		maxHeight: 440,
	},
	[`& .${classes.titleBar}`]: {
		padding: theme.spacing(1),
	},
	[`& .${classes.interventionGroupHeader}`]: {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		background: `linear-gradient(90deg, 
    ${
		theme.palette.mode === 'dark'
			? lighten(theme.palette.background.paper, 0.7)
			: darken(theme.palette.background.paper, 0.2)
	} 3%,
    ${theme.palette.background.paper} 90%)`,
	},
	[`& .${classes.compactSearch}`]: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
}))

function InterventionsOptionsTable(props) {
	const { interventions, title, setCurrentIntervention, handleClickOpenInterventionCreate } = props
	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('symptom')
	const [searchVal, setSearchVal] = React.useState('')
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	const filteredInterventions = searchVal
		? interventions.reduce((acc, item) => {
				if (
					item.name.toLowerCase().includes(searchVal.toLowerCase()) ||
					item.recommendation.toLowerCase().includes(searchVal.toLowerCase()) ||
					item.symptom.toLowerCase().includes(searchVal.toLowerCase())
				) {
					acc.push(item)
				}
				return acc
		  }, [])
		: interventions || []

	return (
		<Root>
			<AppBar className={classes.titleBar} position="static" color="default" elevation={0}>
				<Toolbar>
					<Typography variant="h5" color="textSecondary" component="h5">
						{title}
					</Typography>
				</Toolbar>
			</AppBar>
			<AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<SearchIcon className={classes.block} color="inherit" />
						</Grid>
						<Grid item xs>
							<TextField
								fullWidth
								value={searchVal}
								onChange={(event) => {
									setSearchVal(event.target.value)
								}}
								placeholder="Search by keyword"
								InputProps={{
									disableunderline: 'true',
									className: classes.searchInput,
									inputProps: {
										className: classes.compactSearch,
									},
								}}
							/>
						</Grid>

						<Grid item>
							<Tooltip arrow title="Reload" aria-label="reload">
								<IconButton color="inherit" size="large">
									<RefreshIcon className={classes.block} color="inherit" />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<div className={classes.wrapper}>
				<TableContainer className={classes.container}>
					<Table size={'small'} stickyHeader aria-label="collapsible table">
						<EnhancedTableHead
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={filteredInterventions.length}
						/>
						{/* <TableHead>
							<TableRow>
								<TableCell />
								<TableCell>Name</TableCell>
								<TableCell align="right">Symptom</TableCell>
								<TableCell align="right">Duration</TableCell>
								<TableCell align="right">Assign</TableCell>
							</TableRow>
						</TableHead> */}
						<TableBody>
							{stableSort(filteredInterventions, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, i) => (
									<Row key={i} row={row} {...props} />
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={filteredInterventions.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</div>
		</Root>
	)
}

export { InterventionsOptionsTable }
