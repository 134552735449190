import React from 'react'
import { navigate } from 'gatsby'
import MuiButton from '@mui/material/Button'

const Button = (props) => {
	return (
		<MuiButton
			{...props}
			onClick={() => {
				if (props.onClick) {
					props.onClick()
				}
				if (props.to) {
					navigate(props.to)
				}
			}}
		/>
	)
}
export { Button }
