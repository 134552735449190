import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateExhaust, paginateDefaultArgs, checkDeepEqual } from 'api/api_utils'

const formatMedicationData = (medicationData) => {
	return {
		occurred_at: medicationData.occurred_at,
		notes: medicationData.notes,
		types: medicationData.types,
	}
}

const formatMedicationTypeData = (medicationTypeData) => {
	return {
		name: medicationTypeData.name,
		quantity_num: medicationTypeData.quantity_num,
		quantity_unit: medicationTypeData.quantity_unit,
	}
}

export const addMedication = (medicationData) => {
	return axiosInstance
		.request({
			url: 'v1/medications',
			source: AxiosAbortController.signal,
			method: 'post',
			data: formatMedicationData(medicationData),
		})
		.then(async (res) => {
			if (res.data && medicationData.types.length > 0) {
				for (const type of medicationData.types) {
					await addMedicationTypeToMedication(res.data.id, type)
				}
				return await getMedication(res.data.id)
			} else {
				return res
			}
		})
}

export const updateMedication = (nextMedicationData, previousMedicationData) => {
	return axiosInstance
		.request({
			url: `v1/medications/${nextMedicationData.id}`,
			source: AxiosAbortController.signal,
			method: 'put',
			data: formatMedicationData(nextMedicationData),
		})
		.then(async (res) => {
			// see if types array has changed
			if (!checkDeepEqual(nextMedicationData.types, previousMedicationData.types)) {
				for (const type of nextMedicationData.types) {
					if (!previousMedicationData.types.some((previousType) => previousType.id === type.id)) {
						await addMedicationTypeToMedication(nextMedicationData.id, type)
					}
				}

				for (const type of previousMedicationData.types) {
					if (!nextMedicationData.types.some((nextType) => nextType.id === type.id)) {
						await removeMedicationTypeFromMedication(nextMedicationData.id, type)
					}
				}

				for (const type of nextMedicationData.types) {
					const previousVal = previousMedicationData.types.find((previousType) => previousType.id === type.id)
					if (previousVal && !checkDeepEqual(previousVal, type)) {
						await changeMedicationType(nextMedicationData.id, type)
					}
				}

				return await getMedication(nextMedicationData.id)
			} else {
				return res
			}
		})
}

export const removeMedication = (medicationData) => {
	return axiosInstance.request({
		url: `v1/medications/${medicationData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const getMedication = (medicationId) => {
	return axiosInstance.request({
		url: `v1/medications/${medicationId}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const fetchMedications = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/medications',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'medications',
		1,
		[],
		reduxEvent
	)
}

export const addMedicationType = (medicationTypeData) => {
	return axiosInstance.request({
		url: 'v1/medicationTypes',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatMedicationTypeData(medicationTypeData),
	})
}

export const updateMedicationType = (medicationTypeData) => {
	return axiosInstance.request({
		url: `v1/medicationTypes/${medicationTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatMedicationTypeData(medicationTypeData),
	})
}

export const removeMedicationType = (medicationTypeData) => {
	return axiosInstance.request({
		url: `v1/medicationTypes/${medicationTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const fetchMedicationTypes = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/medicationTypes?${queryString}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

const changeMedicationType = (medicationId, medicationTypeData) => {
	return axiosInstance
		.request({
			url: `v1/medications/${medicationId}/types/${medicationTypeData.id}`,
			source: AxiosAbortController.signal,
			method: 'put',
			data: {
				type_id: medicationTypeData.id,
				name: medicationTypeData.name,
				quantity_num: medicationTypeData.quantity_num,
				quantity_unit: medicationTypeData.quantity_unit,
			},
		})
		.then(() => {
			return getMedication(medicationId)
		})
}

const addMedicationTypeToMedication = (medicationId, medicationTypeData) => {
	return axiosInstance.request({
		url: `v1/medications/${medicationId}/types`,
		source: AxiosAbortController.signal,
		method: 'post',
		data: {
			type_id: medicationTypeData.id,
			quantity_num: medicationTypeData.quantity_num,
			quantity_unit: medicationTypeData.quantity_unit,
		},
	})
}

const removeMedicationTypeFromMedication = (medicationId, medicationTypeData) => {
	return axiosInstance.request({
		url: `v1/medications/${medicationId}/types/${medicationTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}
