/**
 * CRUD Actions for MENSTRUATION
 */
// ADD
export const ADD_MENSTRUATION_SUCCESS = 'ADD_MENSTRUATION_SUCCESS'
export const ADD_MENSTRUATION_FAIL = 'ADD_MENSTRUATION_FAIL'
export const ADD_MENSTRUATION = 'ADD_MENSTRUATION'
// UPDATE
export const UPDATE_MENSTRUATION_SUCCESS = 'UPDATE_MENSTRUATION_SUCCESS'
export const UPDATE_MENSTRUATION_FAIL = 'UPDATE_MENSTRUATION_FAIL'
export const UPDATE_MENSTRUATION = 'UPDATE_MENSTRUATION'
// DELETE
export const DELETE_MENSTRUATION_SUCCESS = 'DELETE_MENSTRUATION_SUCCESS'
export const DELETE_MENSTRUATION_FAIL = 'DELETE_MENSTRUATION_FAIL'
export const DELETE_MENSTRUATION = 'DELETE_MENSTRUATION'
// GET ALL
export const GET_MENSTRUATIONS_SUCCESS = 'GET_MENSTRUATIONS_SUCCESS'
export const GET_MENSTRUATIONS_SUCCESS_COMPLETE = 'GET_MENSTRUATIONS_SUCCESS_COMPLETE'
export const GET_MENSTRUATIONS_FAIL = 'GET_MENSTRUATIONS_FAIL'
export const GET_MENSTRUATIONS = 'GET_MENSTRUATIONS'

export const GET_MEDICATION_SUCCESS = 'GET_MEDICATION_SUCCESS'
export const GET_MEDICATION_FAIL = 'GET_MEDICATION_FAIL'
export const GET_MEDICATION = 'GET_MEDICATION'
/**
 * Current Types
 */
// CURRENT MENSTRUATION
export const CURRENT_MENSTRUATION_SET = 'CURRENT_MENSTRUATION_SET'
export const CURRENT_MENSTRUATION_CLEAR = 'CURRENT_MENSTRUATION_CLEAR'

export const MEDIA_MENSTRUATION_SET = 'MEDIA_MENSTRUATION_SET'

export const RESET_MENSTRUATION_LOADERS = 'RESET_MENSTRUATION_LOADERS'

export const MENSTRUATION_CONTEXT_RESET = 'MENSTRUATION_CONTEXT_RESET'
