import 'intersection-observer'
import React from 'react'
import ReactDOM from 'react-dom'
import * as actions from 'actions'
import { Helmet } from 'react-helmet'
import * as types from 'action_types'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import { I18nextProvider } from 'react-i18next'
import { PersistGate } from 'redux-persist/integration/react'
import ModalDOM from '/src/utils/ModalDOM'
import i18next from '/src/locales/i18next'
import { configuredStore } from '/src/configureStore'
import ThemeProvider from '/src/styles/theme-provider'
import ChatService from '/src/utils/ChatService'
import { EphemeralWrapper } from '/src/utils/EphemeralWrapper'
import { SnackbarUtilsConfigurator } from '/src/utils/SnackbarUtils'
import IntercomBootMasterComponent from '/src/utils/IntercomBootMaster'

import '/src/styles' // global styles

const loadableReady = require('@loadable/component').loadableReady

export const wrapRootElement = ({ element }) => {
	return (
		<>
			<Helmet>
				{/* Any head tags go here */}
				<meta http-equiv="Strict-Transport-Security" content="max-age=31536000" />
			</Helmet>
			<Provider store={configuredStore.store}>
				<PersistGate
					loading={null}
					persistor={configuredStore.persistor}
					onBeforeLift={async () => {
						await configuredStore.store.dispatch({ type: types.WAIT_LOADING_STOP })
						await configuredStore.store.dispatch({ type: 'FAST_FORWARD_STORES' })
						await configuredStore.store.dispatch(actions.checkToken())
					}}
				>
					<ThemeProvider>
						<EphemeralWrapper>
							<IntercomBootMasterComponent>
								<I18nextProvider i18n={i18next}>
									<ChatService>
										<SnackbarProvider maxSnack={3}>
											<SnackbarUtilsConfigurator />
											{element}
										</SnackbarProvider>
									</ChatService>
								</I18nextProvider>
							</IntercomBootMasterComponent>
						</EphemeralWrapper>
						<ModalDOM />
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</>
	)
}

export const replaceHydrateFunction = () => {
	return (element, container, callback) => {
		loadableReady(() => {
			ReactDOM.render(element, container, callback)
		})
	}
}

export const onRouteUpdate = ({ location, prevLocation }) => {
	configuredStore.store.dispatch({ type: types.SCENE, payload: location.pathname })
}

export const onServiceWorkerUpdateReady = () => window.location.reload(true)
