import * as types from 'types'
import {
	storeItemsTransactor,
	storeItemsMerger,
	storeChildTypeRanker,
	deepMerge,
	ArrayMerger,
} from 'reducers/z_redux_helpers'
import { v4 as uuidv4 } from 'uuid'
import _groupBy from 'lodash.groupBy'
import _merge from 'lodash.merge'
import moment from 'moment'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	data: [],
	exerciseTypes: {
		data: [],
		used: [],
		loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
	},
	daysEvents: {},
	current: {},
	searchCache: {
		exerciseTypes: {},
	},
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

const exerciseDecorator = (entity, exerciseEntitiesMap) => {
	return exerciseEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			types: (entity.types || []).map((type) => {
				if (!type._app) {
					type._app = {}
					type._app.uuid = uuidv4()
				}
				return type
			}),
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

const exerciseUnsyncedDecorator = (entity, exerciseEntitiesMap) => {
	return exerciseEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			types: (entity.types || []).map((type) => {
				if (!type._app) {
					type._app = {}
					type._app.uuid = uuidv4()
				}
				return type
			}),
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		return entityMap
	})
}

const exerciseTypeDecorator = (entity, exerciseTypeEntitiesMap) => {
	return exerciseTypeEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

const exerciseTypeUnsyncedDecorator = (entity, exerciseTypeEntitiesMap) => {
	return exerciseTypeEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE: {
			return JSON.parse(JSON.stringify(INITIAL_STATE))
		}

		// GET EXERCISES
		case types.GET_EXERCISES_SUCCESS: {
			let newPayload = ArrayMerger(state.data, [...action.payload.exercises, ...state.data])
			return {
				...state,
				data: newPayload,
				exerciseTypes: {
					...state.exerciseTypes,
					data: storeChildTypeRanker(state.exerciseTypes.data, 'types', state.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: newPayload.reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				daysEvents: _merge(
					{},
					state.daysEvents,
					_groupBy(action.payload.exercises, (item) => {
						return moment(item.occurred_at).format('YYYY-MM-DD')
					})
				),
			}
		}

		case types.GET_EXERCISES_SUCCESS_COMPLETE: {
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.GET_EXERCISES_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.GET_EXERCISES: {
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}
		}

		// UPDATE EXERCISE
		case types.UPDATE_EXERCISE_SUCCESS: {
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						exerciseDecorator
					)
				),
				exerciseTypes: {
					...state.exerciseTypes,
					data: storeChildTypeRanker(state.exerciseTypes.data, 'types', state.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					update: false,
				},
			}
		}

		case types.UPDATE_EXERCISE_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					update: false,
				},
			}
		}

		case types.UPDATE_EXERCISE: {
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),
				loading: {
					...state.loading,
					update: true,
				},
			}
		}

		// DELETE EXERCISE
		case types.DELETE_EXERCISE_SUCCESS: {
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				execisesTypes: {
					...state.exerciseTypes,
					used: state.data.reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					delete: false,
				},
			}
		}

		case types.DELETE_EXERCISE_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					delete: false,
				},
			}
		}

		case types.DELETE_EXERCISE: {
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: true,
				},
			}
		}

		// ADD EXERCISE
		case types.ADD_EXERCISE_SUCCESS: {
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						exerciseDecorator
					)
				),
				exerciseTypes: {
					...state.exerciseTypes,
					data: storeChildTypeRanker(state.exerciseTypes.data, 'types', state.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					create: false,
				},
			}
		}

		case types.ADD_EXERCISE_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					create: false,
				},
			}
		}

		case types.ADD_EXERCISE: {
			return {
				...state,
				data: state.data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					create: true,
				},
			}
		}

		// GET EXERCISE TYPES
		case types.GET_EXERCISE_TYPES_SUCCESS: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.exerciseTypes.data,
							[
								...action.payload.local.map((local) => {
									local._app = {
										...local._app,
										type: 'local',
									}
									return local
								}),
								...action.payload.community.map((community) => {
									community._app = {
										...community._app,
										type: 'community',
									}
									return community
								}),
							]
								.map((entity) => {
									return storeItemsTransactor(state, 'exerciseTypes.data', entity, {
										id: entity.id,
									})
								})
								.reduce((acc, current) => acc.concat(current), [])
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'migration_id', '_app']
					),
					loading: {
						...state.exerciseTypes.loading,
						read: false,
					},
				},
				searchCache: {
					...state.searchCache,
					exerciseTypes: {
						...state.searchCache.exerciseTypes,
						[action.meta.like || '']: {
							invoked_at: action.meta.invoked_at,
						},
					},
				},
			}
		}

		case types.GET_EXERCISE_TYPES_FAIL: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					loading: {
						...state.exerciseTypes.loading,
						read: false,
					},
				},
			}
		}

		case types.GET_EXERCISE_TYPES: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					loading: {
						...state.exerciseTypes.loading,
						read: true,
					},
				},
			}
		}

		// UPDATE EXERCISE TYPE
		case types.UPDATE_EXERCISE_TYPE_SUCCESS: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.exerciseTypes.data,
							storeItemsTransactor(
								state,
								'exerciseTypes.data',
								action.payload,
								{
									id: action.payload.id,
								},
								exerciseTypeDecorator
							)
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'migration_id', '_app']
					),
					loading: {
						...state.exerciseTypes.loading,
						update: false,
					},
				},
			}
		}

		case types.UPDATE_EXERCISE_TYPE_FAIL: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					loading: {
						...state.exerciseTypes.loading,
						update: false,
					},
				},
			}
		}

		case types.UPDATE_EXERCISE_TYPE: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					data: state.exerciseTypes.data.filter((item) => item.id !== action.payload.id),
					loading: {
						...state.exerciseTypes.loading,
						update: true,
					},
				},
			}
		}

		// DELETE EXERCISE TYPE
		case types.DELETE_EXERCISE_TYPE_SUCCESS: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					data: state.exerciseTypes.data.filter((type) => type.id !== action.payload.id),
					loading: {
						...state.exerciseTypes.loading,
						delete: false,
					},
				},
			}
		}

		case types.DELETE_EXERCISE_TYPE_FAIL: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					loading: {
						...state.exerciseTypes.loading,
						delete: false,
					},
				},
			}
		}

		case types.DELETE_EXERCISE_TYPE: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					data: state.exerciseTypes.data.filter((item) => item.id !== action.payload.id),
					loading: {
						...state.exerciseTypes.loading,
						delete: true,
					},
				},
			}
		}

		// ADD EXERCISE TYPE
		case types.ADD_EXERCISE_TYPE_SUCCESS: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.exerciseTypes.data,
							storeItemsTransactor(state, 'exerciseTypes.data', action.payload, {
								id: action.payload.id,
							})
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'migration_id', '_app']
					),
					loading: {
						...state.exerciseTypes.loading,
						create: false,
					},
				},
			}
		}

		case types.ADD_EXERCISE_TYPE_FAIL: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					loading: {
						...state.exerciseTypes.loading,
						create: false,
					},
				},
			}
		}

		case types.ADD_EXERCISE_TYPE: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					data: state.exerciseTypes.data.filter((item) => {
						if (action.payload.id) {
							return item.id !== action.payload.id
						} else if (action.payload._app) {
							if (item._app) {
								return item._app.uuid !== action.payload._app.uuid
							}
						}
						return true
					}),
					loading: {
						...state.exerciseTypes.loading,
						create: true,
					},
				},
			}
		}

		// CURRENT EXERCISE
		case types.CURRENT_EXERCISE_SET: {
			return { ...state, current: action.payload }
		}

		case types.CURRENT_EXERCISE_CLEAR: {
			return { ...state, current: action.payload }
		}

		case types.CURRENT_EXERCISE_TYPE_ADD: {
			return {
				...state,
				current: {
					...state.current,
					types: [...state.current.types, action.payload],
				},
			}
		}

		case types.CURRENT_EXERCISE_TYPE_REMOVE: {
			return {
				...state,
				current: {
					...state.current,
					types: state.current.types.filter((type) => {
						if (type.id) {
							return type.id !== action.payload.id
						}
						if (type._app && action.payload._app) {
							return type._app.uuid !== action.payload._app.uuid
						}
					}),
				},
			}
		}

		case types.RESET_EXERCISE_LOADERS: {
			return {
				...state,
				exerciseTypes: {
					...state.exerciseTypes,
					loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
				},
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}
		}

		// case 'persist/REHYDRATE': {
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.exercise,
		//       exerciseTypes: {
		//         ...action.payload.exercise.exerciseTypes,
		//         loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//       },
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		case 'FAST_FORWARD_STORES': {
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)
		}

		case types.SCENE: {
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}
		}

		case types.EXERCISE_CONTEXT_RESET: {
			return JSON.parse(JSON.stringify(INITIAL_STATE))
		}

		// DEFAULT
		default: {
			return state
		}
	}
}
