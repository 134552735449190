export const FETCH_SAFE_WORST_TRIGGERS_SUCCESS = 'FETCH_SAFE_WORST_TRIGGERS_SUCCESS'
export const FETCH_SAFE_WORST_TRIGGERS_FAIL = 'FETCH_SAFE_WORST_TRIGGERS_FAIL'
export const FETCH_SAFE_WORST_TRIGGERS = 'FETCH_SAFE_WORST_TRIGGERS'

export const FETCH_INTERVENTION_RECS_SUCCESS = 'FETCH_INTERVENTION_RECS_SUCCESS'
export const FETCH_INTERVENTION_RECS_FAIL = 'FETCH_INTERVENTION_RECS_FAIL'
export const FETCH_INTERVENTION_RECS = 'FETCH_INTERVENTION_RECS'

export const ANALYTICS_CONTEXT_RESET = 'ANALYTICS_CONTEXT_RESET'

export const FETCH_STOOL_LABELS_TIMESERIES_SUCCESS = 'FETCH_STOOL_LABELS_TIMESERIES_SUCCESS'
export const FETCH_STOOL_LABELS_TIMESERIES_FAIL = 'FETCH_STOOL_LABELS_TIMESERIES_FAIL'
export const FETCH_STOOL_LABELS_TIMESERIES = 'FETCH_STOOL_LABELS_TIMESERIES'
