import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateExhaust } from 'api/api_utils'

const formatMenstruationData = (scoreData) => ({
	score: scoreData.score || 0,
	range_start: scoreData.range_start,
	range_end: scoreData.range_end,
})

export const getMenstruations = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/menstruations',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'menstruations',
		1,
		[],
		reduxEvent
	)
}

export const addMenstruation = (scoreData) => {
	return axiosInstance.request({
		url: 'v1/menstruations',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatMenstruationData(scoreData),
	})
}

export const updateMenstruation = (scoreData) => {
	return axiosInstance.request({
		url: `v1/menstruations/${scoreData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatMenstruationData(scoreData),
	})
}
