import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateExhaust } from 'api/api_utils'

const formatNoteData = (noteData) => ({
	title: noteData.title,
	document: noteData.document || '',
	uuid: noteData.uuid,
	occurred_at: noteData.occurred_at,
})

export const addNote = (noteData) => {
	return axiosInstance.request({
		url: 'v1/notes',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatNoteData(noteData),
	})
}

export const updateNote = (noteData) => {
	return axiosInstance.request({
		url: `v1/notes/${noteData.uuid}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatNoteData(noteData),
	})
}

export const removeNote = (noteData) => {
	return axiosInstance.request({
		url: `v1/notes/${noteData.uuid}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const fetchNote = (noteData) => {
	return axiosInstance.request({
		url: `v1/notes/${noteData.uuid}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const fetchNotes = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/notes',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'notes',
		1,
		[],
		reduxEvent
	)
}
