import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateExhaust, paginateDefaultArgs, checkDeepEqual } from 'api/api_utils'

const formatBowelData = (bowelData) => {
	return {
		notes: bowelData.notes,
		occurred_at: bowelData.occurred_at,
		duration: bowelData.duration || 0,
		types: bowelData.types,
		uuid: bowelData.uuid,
	}
}

const formatBowelTypeData = (bowelTypeData) => ({
	name: bowelTypeData.name,
	intensity: bowelTypeData.intensity,
	meta: bowelTypeData.meta,
})

export const addBowel = (bowelData) => {
	return axiosInstance
		.request({
			url: 'v2/bowels',
			source: AxiosAbortController.signal,
			method: 'post',
			data: formatBowelData(bowelData),
		})
		.then(async (res) => {
			if (res.data && bowelData.types.length > 0) {
				for (const type of bowelData.types) {
					await addBowelTypeToBowel(res.data.id, type)
				}
				return await getBowel(res.data.id)
			} else {
				return res
			}
		})
}

export const updateBowel = (nextBowelData, previousBowelData) => {
	return axiosInstance
		.request({
			url: `v2/bowels/${nextBowelData.id}`,
			source: AxiosAbortController.signal,
			method: 'put',
			data: formatBowelData(nextBowelData),
		})
		.then(async (res) => {
			// see if types array has changed
			if (!checkDeepEqual(nextBowelData.types, previousBowelData.types)) {
				for (const type of nextBowelData.types) {
					if (!previousBowelData.types.some((previousType) => previousType.id === type.id)) {
						await addBowelTypeToBowel(nextBowelData.id, type)
					}
				}

				for (const type of previousBowelData.types) {
					if (!nextBowelData.types.some((nextType) => nextType.id === type.id)) {
						await removeBowelTypeFromBowel(nextBowelData.id, type)
					}
				}

				for (const type of nextBowelData.types) {
					const previousVal = previousBowelData.types.find((previousType) => previousType.id === type.id)
					if (previousVal && previousVal.intensity !== type.intensity) {
						// val changed, update it
						await changeBowelTypeIntensity(nextBowelData.id, type)
					}
				}

				return await getBowel(nextBowelData.id)
			} else {
				return res
			}
		})
}

export const removeBowel = (bowelData) => {
	return axiosInstance.request({
		url: `v2/bowels/${bowelData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const getBowel = (bowelId) => {
	return axiosInstance.request({
		url: `v2/bowels/${bowelId}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const getBowelAnalysis = () => {
	return axiosInstance.request({
		url: `v3/analysis/bowel`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const getBowelMedia = (bowelData) => {
	return axiosInstance.request({
		url: `v2/bowels/${bowelData.id}/media`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const fetchBowels = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v2/bowels',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'bowels',
		1,
		[],
		reduxEvent
	)
}

export const addBowelType = (bowelTypeData) => {
	return axiosInstance.request({
		url: 'v2/bowelTypes',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatBowelTypeData(bowelTypeData),
	})
}

export const updateBowelType = (bowelTypeData) => {
	return axiosInstance.request({
		url: `v2/bowelTypes/${bowelTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatBowelTypeData(bowelTypeData),
	})
}

export const removeBowelType = (bowelTypeData) => {
	return axiosInstance.request({
		url: `v2/bowelTypes/${bowelTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const fetchBowelTypes = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v2/bowelTypes?${queryString}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

const changeBowelTypeIntensity = (bowelId, bowelTypeData) => {
	return axiosInstance.request({
		url: `v2/bowels/${bowelId}/types/${bowelTypeData.id}`,
		method: 'put',
		source: AxiosAbortController.signal,
		data: {
			id: bowelTypeData.id,
			name: bowelTypeData.name,
			intensity: bowelTypeData.intensity,
		},
	})
}

const addBowelTypeToBowel = (bowelId, bowelTypeData) => {
	const payloadData = {
		intensity: bowelTypeData.intensity,
	}
	if (bowelTypeData.id) {
		payloadData.type_id = bowelTypeData.id
	} else {
		payloadData.name = bowelTypeData.name
	}
	if (Number.isInteger(payloadData.intensity)) {
		return axiosInstance.request({
			url: `v2/bowels/${bowelId}/types`,
			method: 'post',
			data: payloadData,
		})
	}
}

const removeBowelTypeFromBowel = (bowelId, bowelTypeData) => {
	return axiosInstance.request({
		url: `v2/bowels/${bowelId}/types/${bowelTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}
