import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import Echarts from 'echarts'
import { useTheme } from '@mui/material'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'

const getOption = (daysEvents, theme) => {
	let max = 0
	const dayCounts = Object.keys(daysEvents).map((day) => {
		if (daysEvents[day].length > max) {
			max = daysEvents[day].length
		}
		return [day, daysEvents[day].length]
	})
	const today = moment().format('YYYY-MM-DD')
	const lastMonth = moment().subtract(1, 'months').format('YYYY-MM-DD')
	const lastTerm = moment().subtract(8, 'months').format('YYYY-MM-DD')
	const firstDay = Object.keys(daysEvents).length
		? moment(Object.keys(daysEvents).reverse()[0]).format('YYYY-MM-DD')
		: null
	const option = {
		title: {
			top: 30,
			left: 'center',
			text: 'Logging Activity',
			textStyle: {
				color: theme.palette.mode === 'light' ? '#444' : '#fff',
			},
		},

		tooltip: {
			trigger: 'item',
		},
		legend: {
			top: '30',
			left: '100',
			textStyle: {
				color: theme.palette.mode === 'light' ? '#444' : '#fff',
			},
		},
		visualMap: {
			seriesIndex: 0,
			min: 0,
			max: max,
			type: 'piecewise',
			orient: 'vertical',
			calculable: true,
			top: 65,
			right: 60,
			align: 'right',
			textStyle: {
				color: theme.palette.mode === 'light' ? '#444' : 'FFF',
			},
			inRange: {
				color: ['#ccc', theme.palette.primary[500]],
			},
		},
		calendar: [
			{
				top: 85,
				left: 80,
				right: 180,
				cellSize: ['auto', 13],
				range: [lastTerm, today],
				itemStyle: {
					borderWidth: 1,
				},
				splitLine: {
					show: true,
					lineStyle: {
						color: '#000',
						width: 2,
						type: 'solid',
					},
				},
				dayLabel: {
					color: theme.palette.mode === 'light' ? '#444' : '#fff',
				},
				monthLabel: {
					color: theme.palette.mode === 'light' ? '#444' : '#fff',
				},
				yearLabel: { color: theme.palette.mode === 'light' ? '#444' : '#fff', show: false },
			},
		],
		series: [
			{
				type: 'heatmap',
				symbolSize: function (val) {
					return (val[1] / max) * 13
				},
				calendarIndex: 0,
				coordinateSystem: 'calendar',
				data: dayCounts,
			},
		],
	}

	if (firstDay && moment(firstDay).isAfter(lastTerm)) {
		option.series.push({
			name: 'First Day',
			type: 'effectScatter',
			coordinateSystem: 'calendar',
			data: [[firstDay, daysEvents[firstDay] ? daysEvents[firstDay].length : 0]]
				.sort(function (a, b) {
					return b[1] - a[1]
				})
				.slice(0, 12),
			symbolSize: function (val) {
				return 13
			},
			showEffectOn: 'render',
			rippleEffect: {
				color: theme.palette.secondary[400],
				brushType: 'fill',
			},
			hoverAnimation: true,
			itemStyle: {
				color: theme.palette.secondary[400],
				shadowBlur: 10,
				shadowColor: '#333',
			},
			zlevel: 1,
		})
	}
	return option
}

function CalendarHeatMap({ daysEvents, loading, onClick }) {
	const theme = useTheme()
	return loading ? (
		<div
			style={{ height: '250px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
		>
			<CircularProgress disableShrink={true} />
		</div>
	) : (
		<ReactEcharts
			option={getOption(daysEvents, theme)}
			onEvents={{
				click: onClick,
			}}
			style={{
				height: '250px',
				width: '100%',
			}}
		/>
	)
}

export { CalendarHeatMap }
