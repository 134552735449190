import * as types from 'types'
import jwtDecode from 'jwt-decode'

const INITIAL_STATE = {
	accessToken: '',
	idToken: '',
	refreshToken: '',
	tokenType: '',
	expires_at: 0,
	email: '',
	email_verified: '',
	name: '',
	nickname: '',
	givenName: '',
	familyName: '',
	sub: '',
	picture: '',
	user_metadata: {},
	app_metadata: {},
	userMetaError: '',
	userMetaLoading: false,
	logoutUserError: '',
	stats: {
		lastFetched: null,
		activity: {
			active_days: '-1',
		},
	},
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE: {
			return JSON.parse(JSON.stringify(INITIAL_STATE))
		}

		case types.REFRESH_SESSION_SUCCESS: {
			const decodedRefreshIdentity = jwtDecode(action.payload.id_token)
			const decodedRefreshAccessToken = jwtDecode(action.payload.access_token)
			return {
				...state,
				accessToken: action.payload.access_token,
				idToken: action.payload.id_token,
				expires_at: decodedRefreshAccessToken.exp * 1000,
				user_metadata: decodedRefreshIdentity['https://bigdieta.com/user_metadata'],
				app_metadata: decodedRefreshIdentity['https://bigdieta.com/app_metadata'],
				email: decodedRefreshIdentity.email,
				email_verified: decodedRefreshIdentity.email_verified,
				familyName: decodedRefreshIdentity.family_name,
				givenName: decodedRefreshIdentity.given_name,
				name: decodedRefreshIdentity.name,
				nickname: decodedRefreshIdentity.nickname,
				sub: decodedRefreshIdentity.sub,
			}
		}

		case types.IMPERSONATE_USER: {
			return {
				...state,
				accessToken: action.payload.accessToken,
				refreshToken: action.payload.refreshToken,
				tokenType: action.payload.tokenType,
			}
		}

		case types.IMPERSONATE_USER_FAIL: {
			return state
		}

		case types.IMPERSONATE_USER_SUCCESS: {
			const decodedImpersonateIdentity = jwtDecode(action.payload.idToken)
			return {
				...state,
				idToken: action.payload.idToken,
				user_metadata: decodedImpersonateIdentity['https://bigdieta.com/user_metadata'],
				app_metadata: decodedImpersonateIdentity['https://bigdieta.com/app_metadata'],
				email: decodedImpersonateIdentity.email,
				email_verified: decodedImpersonateIdentity.email_verified,
				familyName: decodedImpersonateIdentity.family_name,
				givenName: decodedImpersonateIdentity.given_name,
				name: decodedImpersonateIdentity.name,
				nickname: decodedImpersonateIdentity.nickname,
				sub: decodedImpersonateIdentity.sub,
			}
		}

		// LOG IN
		case types.LOGIN_USER_SUCCESS: {
			const decodedLoginIdentity = jwtDecode(action.payload.idToken)
			const decodedLoginAccessToken = jwtDecode(action.payload.accessToken)
			return {
				...state,
				accessToken: action.payload.accessToken,
				idToken: action.payload.idToken,
				refreshToken: action.payload.refreshToken,
				expires_at: decodedLoginAccessToken.exp * 1000,
				tokenType: action.payload.tokenType,
				user_metadata: decodedLoginIdentity['https://bigdieta.com/user_metadata'],
				app_metadata: decodedLoginIdentity['https://bigdieta.com/app_metadata'],
				email: decodedLoginIdentity.email,
				email_verified: decodedLoginIdentity.email_verified,
				familyName: decodedLoginIdentity.family_name,
				givenName: decodedLoginIdentity.given_name,
				name: decodedLoginIdentity.name,
				nickname: decodedLoginIdentity.nickname,
				sub: decodedLoginIdentity.sub,
			}
		}

		case types.GET_USER_PICTURE_SUCCESS: {
			if (action.payload) {
				return {
					...state,
					picture: action.payload.uri,
				}
			}
			return state
		}

		case types.LOGIN_USER_FAIL: {
			return state
		}

		case types.LOGIN_USER: {
			return state
		}

		case types.LOGOUT_USER_SUCCESS: {
			if (action.payload && action.payload.message) {
			}

			return JSON.parse(JSON.stringify(INITIAL_STATE))
		}

		case types.LOGOUT_USER_FAIL: {
			return state
		}

		case types.LOGOUT_USER: {
			return { ...state, logoutUserError: '' }
		}

		default: {
			return state
		}
	}
}
