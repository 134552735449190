import React, { Component, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { EnumerateDaysBetweenDates } from 'utils/helpers'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'

import CubeScan from 'svgs/icons/cube-scan.svg'
import { std } from 'mathjs'
import groupBy from 'lodash.groupby'

const COLUMNS = ['bristol', 'consistency', 'fragmentation', 'edge_fuzziness', 'volume'].sort()
const REMAP = {
	edge_fuzziness: 'fuzziness',
}
const NORMALIZE = {
	bristol: (val) => (val / 7) * 100,
}
const REV_NORMALIZE = {
	bristol: (val) => parseInt(Math.ceil((val / 100) * 7)),
}
const NAMED_COLUMNS = COLUMNS.map((c) => (REMAP[c] ? REMAP[c] : c))

const PREFIX = 'InterventionStoolDetailsCard'

const classes = {
	root: `${PREFIX}-root`,
	cubeIcon: `${PREFIX}-cubeIcon`,
	tableTitle: `${PREFIX}-tableTitle`,
}

const Root = styled(Paper)(({ theme }) => ({
	[`&.${classes.root}`]: {},
	[`& .${classes.cubeIcon}`]: {
		width: 30,
		height: 30,
		verticalAlign: 'middle',
	},
	[`& .${classes.tableTitle}`]: {
		fontWeight: 'bold',
	},
}))

class InterventionStoolDetailsCard extends Component {
	constructor(props) {
		super(props)
	}

	static getDerivedStateFromProps(props, state) {
		const endDate = props.currentIntervention.completed_at ? props.currentIntervention.completed_at : moment()
		const filteredData = props.bowel.data.filter((event) => {
			return moment(event.occurred_at).isBetween(props.currentIntervention.occurred_at, endDate, '', '[]')
		})

		const direct = filteredData.reduce((ac, event) => {
			if (event._labels) {
				ac.push(
					COLUMNS.reduce((acc, property) => {
						acc[REMAP[property] ? REMAP[property] : property] = event._labels[property]
						return acc
					}, {})
				)
			}
			return ac
		}, [])

		const totals = direct.reduce(
			(acc, item) => {
				Object.keys(item).forEach((p) => {
					acc[p].sum += item[p]
					acc[p].data = [...acc[p].data, item[p]]
				})
				return acc
			},
			NAMED_COLUMNS.reduce((a, i) => {
				a[i] = {
					sum: 0,
					data: [],
				}
				return a
			}, {})
		)

		const daysDataBowels = groupBy(filteredData, (item) => {
			return moment(item.occurred_at).format('YYYY-MM-DD')
		})
		const daysBowels = Object.keys(daysDataBowels).sort()
		const enumeratedDays = EnumerateDaysBetweenDates(daysBowels[0], daysBowels[daysBowels.length - 1])

		const bowelsFrequencies = enumeratedDays.reduce(
			(acc, day) => {
				const res = daysDataBowels[day] ? daysDataBowels[day].length : 0
				acc.sum += res
				acc.data.push(res)
				return acc
			},
			{
				sum: 0,
				data: [],
			}
		)

		// changed totals to object
		Object.keys(totals).forEach((total) => {
			const numbers = totals[total]
			totals[total] = {
				name: total,
				average: numbers.data.length ? (numbers.sum / numbers.data.length).toFixed(2) : 0,
				total: numbers.data.length,
				std: numbers.data.length ? std(numbers.data).toFixed(2) : 0,
			}
			if (NORMALIZE[total]) {
				totals[total] = {
					name: total,
					average: REV_NORMALIZE[total](NORMALIZE[total](totals[total].average)),
					total: numbers.data.length || 0,
					std: numbers.data.length ? std(numbers.data).toFixed(2) : 0,
				}
			}
		})
		totals.frequency = {
			name: 'Frequency',
			average: bowelsFrequencies.data.length
				? (bowelsFrequencies.sum / bowelsFrequencies.data.length).toFixed(2)
				: 0,
			total: enumeratedDays.length,
			std: bowelsFrequencies.data.length ? std(bowelsFrequencies.data).toFixed(2) : 0,
		}

		return {
			...state,
			stoolsSamples: filteredData.length,
			stoolData: totals,
		}
	}

	state = {
		stoolData: {},
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		return true
	}

	render() {
		return (
			<Root className={classes.root}>
				<AppBar className={classes.titleBar} position="static" color="default" elevation={1}>
					<Toolbar>
						<Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
							<Grid item>
								<CubeScan className={classes.cubeIcon} />
							</Grid>
							<Grid item>
								<Typography variant="body1" color="textSecondary" className={classes.tableTitle}>
									Stool Properties{' '}
									{Number.isInteger(this.state.stoolsSamples) ? `(${this.state.stoolsSamples})` : ''}
								</Typography>
							</Grid>
							<Grid item xs>
								{!!this.props.bowel.loading.read &&
									// <CircularProgress
									// 	color="inherit"
									// 	disableShrink={true}
									// 	style={{ width: 20, height: 20 }}
									// />
									(this.props.bowel.loading.progress.total ? (
										<LinearProgress
											color="inherit"
											disableShrink={true}
											variant="determinate"
											value={
												(this.props.bowel.loading.progress.page /
													this.props.bowel.loading.progress.total) *
												100
											}
										/>
									) : (
										<LinearProgress color="inherit" disableShrink={true} />
									))}
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>

				<Grid container>
					<TableContainer component={'div'}>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>Measurement</TableCell>
									<TableCell align="right">Average</TableCell>
									<TableCell align="right">Std.</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Object.keys(this.state.stoolData).map((key) => {
									const characteristic = this.state.stoolData[key]
									return (
										<TableRow
											key={characteristic.name}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell
												component="th"
												scope="row"
												style={{
													textTransform: 'capitalize',
												}}
											>
												{characteristic.name}
											</TableCell>
											<TableCell align="right">{characteristic.average}</TableCell>
											<TableCell align="right">{characteristic.std}</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Root>
		)
	}
}

InterventionStoolDetailsCard.propTypes = {
	bowel: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
			progress: PropTypes.shape({
				page: PropTypes.number,
				total: PropTypes.number,
			}),
		}),
	}),
	intervention: PropTypes.shape({
		data: PropTypes.array,
		interventionCompliance: PropTypes.shape({
			interventionMap: PropTypes.object.isRequired,
			daysCompliance: PropTypes.object.isRequired,
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	fetchStoolLabels: PropTypes.func.isRequired,
	getBowels: PropTypes.func.isRequired,
}

const mapStateToProps = ({ bowel, symptomScore, intervention }) => {
	return {
		bowel,
		symptomScore,
		intervention,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(InterventionStoolDetailsCard)
