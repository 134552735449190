import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateExhaust, paginateDefaultArgs, checkDeepEqual } from 'api/api_utils'

const formatMoodData = (moodData) => {
	return {
		occurred_at: moodData.occurred_at,
		notes: moodData.notes,
		duration: moodData.duration || 0,
		intensity: moodData.intensity,
		types: moodData.types,
	}
}

const formatMoodTypeData = (moodTypeData) => ({
	name: moodTypeData.name,
})

export const addMood = (moodData) => {
	return axiosInstance
		.request({
			url: 'v1/moods',
			source: AxiosAbortController.signal,
			method: 'post',
			data: formatMoodData(moodData),
		})
		.then(async (res) => {
			if (res.data && moodData.types.length > 0) {
				await addMoodTypesToMood(res.data.id, moodData.types)
				return await getMood(res.data.id)
			} else {
				return res
			}
		})
}

export const updateMood = (nextMoodData, previousMoodData) => {
	return axiosInstance
		.request({
			url: `v1/moods/${nextMoodData.id}`,
			source: AxiosAbortController.signal,
			method: 'put',
			data: formatMoodData(nextMoodData),
		})
		.then(async (res) => {
			// see if types array has changed
			if (!checkDeepEqual(nextMoodData.types, previousMoodData.types)) {
				for (const type of nextMoodData.types) {
					if (!previousMoodData.types.some((previousType) => previousType.id === type.id)) {
						// new one, add it
						await addMoodTypesToMood(nextMoodData.id, type)
					}
				}

				for (const type of previousMoodData.types) {
					if (!nextMoodData.types.some((nextType) => nextType.id === type.id)) {
						await removeMoodTypeFromMood(nextMoodData.id, type)
					}
				}

				return await getMood(nextMoodData.id)
			} else {
				return res
			}
		})
}
export const removeMood = (moodData) => {
	return axiosInstance.request({
		url: `v1/moods/${moodData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const getMood = (moodId) => {
	return axiosInstance.request({
		url: `v1/moods/${moodId}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const fetchMoods = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/moods',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'moods',
		1,
		[],
		reduxEvent
	)
}

export const addMoodType = (moodTypeData) => {
	return axiosInstance.request({
		url: 'v1/moodTypes',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatMoodTypeData(moodTypeData),
	})
}

export const updateMoodType = (moodTypeData) => {
	return axiosInstance.request({
		url: `v1/moodTypes/${moodTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatMoodTypeData(moodTypeData),
	})
}

export const removeMoodType = (moodTypeData) => {
	return axiosInstance.request({
		url: `v1/moodTypes/${moodTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const fetchMoodTypes = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/moodTypes?${queryString}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

const addMoodTypesToMood = (moodId, moodTypesData) => {
	return axiosInstance.request({
		url: `v1/moods/${moodId}/types`,
		source: AxiosAbortController.signal,
		method: 'post',
		data: {
			type_id: Array.isArray(moodTypesData)
				? moodTypesData.map((moodType) => moodType.id) // add multiple at once
				: moodTypesData.id, // add one
		},
	})
}

const removeMoodTypeFromMood = (moodId, moodTypeData) => {
	return axiosInstance.request({
		url: `v1/moods/${moodId}/types/${moodTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}
