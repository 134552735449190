import * as types from 'types'
import {
	storeItemsTransactor,
	storeItemsMerger,
	storeChildTypeRanker,
	deepMerge,
	ArrayMerger,
} from 'reducers/z_redux_helpers'
import { v4 as uuidv4 } from 'uuid'
import _groupBy from 'lodash.groupBy'
import _merge from 'lodash.merge'
import moment from 'moment'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	data: [],
	mealItems: {
		data: [],
		used: [],
		loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
	},
	mealIngredients: {
		data: [],
		used: [],
		loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
	},
	daysEvents: {},
	current: {},
	searchCache: {
		mealItems: {},
		mealIngredients: {},
	},
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

const mealDecorator = (entity, mealEntitiesMap) => {
	return mealEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			items: (entity.items || []).map((item) => {
				if (!item._app) {
					item._app = {}
					item._app.uuid = uuidv4()
				}
				return item
			}),
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}

		return entityMap
	})
}

const mealUnsyncedDecorator = (entity, mealEntitiesMap) => {
	return mealEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			items: (entity.items || []).map((item) => {
				if (!item._app) {
					item._app = {}
					item._app.uuid = uuidv4()
				}
				return item
			}),
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		return entityMap
	})
}

const mealItemDecorator = (entity, mealItemEntitiesMap) => {
	return mealItemEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			ingredients: entity.ingredients || [],
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

const mealItemUnsyncedDecorator = (entity, mealItemEntitiesMap) => {
	return mealItemEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			ingredients: entity.ingredients || [],
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

const mealIngredientDecorator = (entity, mealIngredientEntitiesMap) => {
	return mealIngredientEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

const mealIngredientUnsyncedDecorator = (entity, mealIngredientEntitiesMap) => {
	return mealIngredientEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	const wasOffline = action && action.meta && action.meta.offline
	const wasOnline = !wasOffline

	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// GET MEALS
		case types.GET_MEALS_SUCCESS:
			let newPayload = ArrayMerger(state.data, [...action.payload.meals, ...state.data])
			return {
				...state,
				data: newPayload,
				mealItems: {
					...state.mealItems,
					data: storeChildTypeRanker(state.mealItems.data, 'items', state.data, [
						'id',
						'name',
						'creator_id',
						'ingredients',
						'migration_id',
						'_app',
					]),
					used: newPayload.reduce((acc, entity) => {
						acc = entity.items ? [...acc, ...entity.items.map((item) => item.id)] : acc
						return acc
					}, []),
				},
				mealIngredients: {
					...state.mealIngredients,
					data: storeChildTypeRanker(state.mealIngredients.data, 'ingredients', state.mealItems.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: newPayload.reduce((acc, entity) => {
						if (entity.items) {
							const ingredients = entity.items.reduce((ac, item) => {
								ac = item.ingredients ? [...ac, ...item.ingredients] : ac
								return ac
							}, [])
							acc = ingredients ? [...acc, ...ingredients.map((ingredient) => ingredient.id)] : acc
						}
						return acc
					}, []),
				},
				daysEvents: _merge(
					{},
					state.daysEvents,
					_groupBy(action.payload.meals, (item) => {
						return moment(item.occurred_at).format('YYYY-MM-DD')
					})
				),
			}

		case types.GET_MEALS_SUCCESS_COMPLETE:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_MEALS_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_MEALS:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		// UPDATE MEAL
		case types.UPDATE_MEAL_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						mealDecorator
					)
				),
				mealItems: {
					...state.mealItems,
					data: storeChildTypeRanker(state.mealItems.data, 'items', state.data, [
						'id',
						'name',
						'creator_id',
						'ingredients',
						'migration_id',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						acc = entity.items ? [...acc, ...entity.items.map((item) => item.id)] : acc
						return acc
					}, []),
				},
				mealIngredients: {
					...state.mealIngredients,
					data: storeChildTypeRanker(state.mealIngredients.data, 'ingredients', state.mealItems.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						if (entity.items) {
							const ingredients = entity.items.reduce((ac, item) => {
								ac = item.ingredients ? [...ac, ...item.ingredients] : ac
								return ac
							}, [])
							acc = ingredients ? [...acc, ...ingredients.map((ingredient) => ingredient.id)] : acc
						}
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					update: false,
				},
			}

		case types.UPDATE_MEAL_MEDIA_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						mealDecorator
					)
				),
				mealItems: {
					...state.mealItems,
					data: storeChildTypeRanker(state.mealItems.data, 'items', state.data, [
						'id',
						'name',
						'creator_id',
						'ingredients',
						'migration_id',
						'_app',
					]),
				},
				mealIngredients: {
					...state.mealIngredients,
					data: storeChildTypeRanker(state.mealIngredients.data, 'ingredients', state.mealItems.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
				},
				loading: {
					...state.loading,
					update: false,
				},
			}

		case types.UPDATE_MEAL_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					update: false,
				},
			}

		case types.UPDATE_MEAL_MEDIA_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					update: false,
				},
			}

		case types.UPDATE_MEAL:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),
				loading: {
					...state.loading,
					update: true,
				},
			}

		// DELETE MEAL
		case types.DELETE_MEAL_SUCCESS:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				mealItems: {
					...state.mealItems,
					used: state.data.reduce((acc, entity) => {
						acc = entity.items ? [...acc, ...entity.items.map((item) => item.id)] : acc
						return acc
					}, []),
				},
				mealIngredients: {
					...state.mealIngredients,
					used: state.data.reduce((acc, entity) => {
						if (entity.items) {
							const ingredients = entity.items.reduce((ac, item) => {
								ac = item.ingredients ? [...ac, ...item.ingredients] : ac
								return ac
							}, [])
							acc = ingredients ? [...acc, ...ingredients.map((ingredient) => ingredient.id)] : acc
						}
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					delete: false,
				},
			}

		case types.DELETE_MEAL_MEDIA_SUCCESS:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: false,
				},
			}

		case types.DELETE_MEAL_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					delete: false,
				},
			}

		case types.DELETE_MEAL_MEDIA_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					delete: false,
				},
			}

		case types.DELETE_MEAL:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: true,
				},
			}

		// ADD MEAL
		case types.ADD_MEAL_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						mealDecorator
					)
				),
				mealItems: {
					...state.mealItems,
					data: storeChildTypeRanker(state.mealItems.data, 'items', state.data, [
						'id',
						'name',
						'creator_id',
						'ingredients',
						'migration_id',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						acc = entity.items ? [...acc, ...entity.items.map((item) => item.id)] : acc
						return acc
					}, []),
				},
				mealIngredients: {
					...state.mealIngredients,
					data: storeChildTypeRanker(state.mealIngredients.data, 'ingredients', state.mealItems.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						if (entity.items) {
							const ingredients = entity.items.reduce((ac, item) => {
								ac = item.ingredients ? [...ac, ...item.ingredients] : ac
								return ac
							}, [])
							acc = ingredients ? [...acc, ...ingredients.map((ingredient) => ingredient.id)] : acc
						}
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_MEAL_MEDIA_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						mealDecorator
					)
				),
				mealItems: {
					...state.mealItems,
					data: storeChildTypeRanker(state.mealItems.data, 'items', state.data, [
						'id',
						'name',
						'creator_id',
						'ingredients',
						'migration_id',
						'_app',
					]),
				},
				mealIngredients: {
					...state.mealIngredients,
					data: storeChildTypeRanker(state.mealIngredients.data, 'ingredients', state.mealItems.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
				},
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_MEAL_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_MEAL_MEDIA_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_MEAL:
			return {
				...state,
				data: state.data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					create: true,
				},
			}

		// GET MEAL TYPES
		case types.GET_MEAL_ITEMS_SUCCESS:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.mealItems.data,
							[
								...action.payload.local.map((local) => {
									local._app = {
										...local._app,
										type: 'local',
									}
									return local
								}),
								...action.payload.community.map((community) => {
									community._app = {
										...community._app,
										type: 'community',
									}
									return community
								}),
							]
								.map((entity) => {
									return storeItemsTransactor(
										state,
										'mealItems.data',
										entity,
										{
											id: entity.id,
										},
										mealItemDecorator
									)
								})
								.reduce((acc, current) => acc.concat(current), [])
						),
						'items',
						state.data,
						['id', 'name', 'creator_id', 'ingredients', 'migration_id', '_app']
					),
					loading: {
						...state.mealItems.loading,
						read: false,
					},
				},
				searchCache: {
					...state.searchCache,
					mealItems: {
						...state.searchCache.mealItems,
						[action.meta.like || '']: {
							invoked_at: action.meta.invoked_at,
						},
					},
				},
			}

		case types.GET_MEAL_ITEMS_FAIL:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					loading: {
						...state.mealItems.loading,
						read: false,
					},
				},
			}

		case types.GET_MEAL_ITEMS:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					loading: {
						...state.mealItems.loading,
						read: true,
					},
				},
			}

		// UPDATE MEAL ITEM
		case types.UPDATE_MEAL_ITEM_SUCCESS:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.mealItems.data,
							storeItemsTransactor(
								state,
								'mealItems.data',
								action.payload,
								{
									id: action.payload.id,
								},
								mealItemDecorator
							)
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'ingredients', 'migration_id', '_app']
					),
					loading: {
						...state.mealItems.loading,
						update: false,
					},
				},
			}

		case types.UPDATE_MEAL_ITEM_FAIL:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					loading: {
						...state.mealItems.loading,
						update: false,
					},
				},
			}

		case types.UPDATE_MEAL_ITEM:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					data: state.mealItems.data.filter((item) => item.id !== action.payload.id),
					loading: {
						...state.mealItems.loading,
						update: true,
					},
				},
			}

		// DELETE MEAL ITEM
		case types.DELETE_MEAL_ITEM_SUCCESS:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					data: state.mealItems.data.filter((type) => type.id !== action.payload.id),
					loading: {
						...state.mealItems.loading,
						delete: false,
					},
				},
			}

		case types.DELETE_MEAL_ITEM_FAIL:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					loading: {
						...state.mealItems.loading,
						delete: false,
					},
				},
			}

		case types.DELETE_MEAL_ITEM:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					data: state.mealItems.data.filter((item) => item.id !== action.payload.id),
					loading: {
						...state.mealItems.loading,
						delete: true,
					},
				},
			}

		// ADD MEAL ITEM
		case types.ADD_MEAL_ITEM_SUCCESS:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					data: storeItemsMerger(
						state.mealItems.data,
						storeItemsTransactor(state, 'mealItems.data', action.payload, {
							id: action.payload.id,
						})
					),
					loading: {
						...state.mealItems.loading,
						create: false,
					},
				},
			}

		case types.ADD_MEAL_ITEM_FAIL:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					loading: {
						...state.mealItems.loading,
						create: false,
					},
				},
			}

		case types.ADD_MEAL_ITEM:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					data: state.mealItems.data.filter((item) => {
						if (action.payload.id) {
							return item.id !== action.payload.id
						} else if (action.payload._app) {
							if (item._app) {
								return item._app.uuid !== action.payload._app.uuid
							}
						}
						return true
					}),
					loading: {
						...state.mealItems.loading,
						create: true,
					},
				},
			}

		//  GET MEAL INGREDIENTS
		case types.GET_MEAL_INGREDIENTS_SUCCESS:
			return {
				...state,
				mealIngredients: {
					...state.mealIngredients,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.mealIngredients.data,
							[
								...action.payload.local.map((local) => {
									local._app = {
										...local._app,
										type: 'local',
									}
									return local
								}),
								...action.payload.community.map((community) => {
									community._app = {
										...community._app,
										type: 'community',
									}
									return community
								}),
							]
								.map((entity) => {
									return storeItemsTransactor(state, 'mealIngredients.data', entity, {
										id: entity.id,
									})
								})
								.reduce((acc, current) => acc.concat(current), [])
						),
						'ingredients',
						state.mealItems.data,
						['id', 'name', 'creator_id', 'migration_id', '_app']
					),
					loading: {
						...state.mealIngredients.loading,
						read: false,
					},
				},
				searchCache: {
					...state.searchCache,
					mealIngredients: {
						...state.searchCache.mealIngredients,
						[action.meta.like || '']: {
							invoked_at: action.meta.invoked_at,
						},
					},
				},
			}

		case types.GET_MEAL_INGREDIENTS_FAIL:
			return {
				...state,
				mealIngredients: {
					...state.mealIngredients,
					loading: {
						...state.mealIngredients.loading,
						read: false,
					},
				},
			}

		case types.GET_MEAL_INGREDIENTS:
			return {
				...state,
				mealIngredients: {
					...state.mealIngredients,
					loading: {
						...state.mealIngredients.loading,
						read: true,
					},
				},
			}

		// UPDATE MEAL INGREDIENT
		case types.UPDATE_MEAL_INGREDIENT_SUCCESS:
			return {
				...state,
				mealIngredients: {
					...state.mealIngredients,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.mealIngredients.data,
							storeItemsTransactor(
								state,
								'mealIngredients.data',
								action.payload,
								{
									id: action.payload.id,
								},
								mealIngredientDecorator
							)
						),
						'types',
						state.mealItems.data,
						['id', 'name', 'creator_id', 'migration_id', '_app']
					),
					loading: {
						...state.mealIngredients.loading,
						update: false,
					},
				},
			}

		case types.UPDATE_MEAL_INGREDIENT_FAIL:
			return {
				...state,
				mealIngredients: {
					...state.mealIngredients,
					loading: {
						...state.mealIngredients.loading,
						update: false,
					},
				},
			}

		case types.UPDATE_MEAL_INGREDIENT:
			return {
				...state,
				mealIngredients: {
					...state.mealIngredients,
					data: state.mealIngredients.data.filter((item) => item.id !== action.payload.id),
					loading: {
						...state.mealIngredients.loading,
						update: true,
					},
				},
			}

		// DELETE MEAL INGREDIENT
		case types.DELETE_MEAL_INGREDIENT_SUCCESS:
			return {
				...state,
				mealIngredients: {
					...state.mealIngredients,
					data: state.mealIngredients.data.filter((type) => type.id !== action.payload.id),
					loading: {
						...state.mealIngredients.loading,
						delete: false,
					},
				},
			}

		case types.DELETE_MEAL_INGREDIENT_FAIL:
			return {
				...state,
				mealIngredients: {
					...state.mealIngredients,
					loading: {
						...state.mealIngredients.loading,
						delete: false,
					},
				},
			}

		case types.DELETE_MEAL_INGREDIENT:
			return {
				...state,
				mealIngredients: {
					...state.mealIngredients,
					data: state.mealIngredients.data.filter((item) => item.id !== action.payload.id),
					loading: {
						...state.mealIngredients.loading,
						delete: true,
					},
				},
			}

		// ADD MEAL INGREDIENT
		case types.ADD_MEAL_INGREDIENT_SUCCESS:
			return {
				...state,
				mealIngredients: {
					...state.mealIngredients,
					data: storeItemsMerger(
						state.mealIngredients.data,
						storeItemsTransactor(state, 'mealIngredients.data', action.payload, {
							id: action.payload.id,
						})
					),
					loading: {
						...state.mealIngredients.loading,
						create: false,
					},
				},
			}

		case types.ADD_MEAL_INGREDIENT_FAIL:
			return {
				...state,
				mealIngredients: {
					...state.mealIngredients,
					loading: {
						...state.mealIngredients.loading,
						create: false,
					},
				},
			}

		case types.ADD_MEAL_INGREDIENT:
			return {
				...state,
				mealIngredients: {
					...state.mealIngredients,
					data: state.mealIngredients.data.filter((item) => {
						if (action.payload.id) {
							return item.id !== action.payload.id
						} else if (action.payload._app) {
							if (item._app) {
								return item._app.uuid !== action.payload._app.uuid
							}
						}
						return true
					}),
					loading: {
						...state.mealIngredients.loading,
						create: true,
					},
				},
			}

		case types.MEDIA_MEAL_SET:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						mealDecorator
					)
				),
				current:
					state.current.id === action.payload.id
						? {
								...state.current,
								media: action.payload.media,
						  }
						: state.current,
			}

		case types.MEDIA_MEAL_ITEM_SET:
			return {
				...state,
				data: state.data.map((entity) => {
					if (entity.id === action.payload.id) {
						entity.media = entity.media.length
							? entity.media.map((mediaItem) => {
									if (mediaItem.eTag) {
										if (mediaItem.eTag === action.payload.mediaItem.eTag) {
											return action.payload.mediaItem
										}
									} else if (mediaItem.name && action.payload.mediaItem.key) {
										if (action.payload.mediaItem.key.endsWith(mediaItem.name)) {
											return action.payload.mediaItem
										}
									} else if (mediaItem.name && action.payload.mediaItem.name) {
										if (action.payload.mediaItem.name === mediaItem.name) {
											return action.payload.mediaItem
										}
									}
									return mediaItem
							  })
							: [action.payload.mediaItem]
					}
					return entity
				}),
				current:
					state.current.id === action.payload.id
						? {
								...state.current,
								media: state.current.media.length
									? state.current.media.map((mediaItem) => {
											if (mediaItem.eTag) {
												if (mediaItem.eTag === action.payload.mediaItem.eTag) {
													return action.payload.mediaItem
												}
											} else if (mediaItem.name && action.payload.mediaItem.key) {
												if (action.payload.mediaItem.key.endsWith(mediaItem.name)) {
													return action.payload.mediaItem
												}
											} else if (mediaItem.name && action.payload.mediaItem.name) {
												if (action.payload.mediaItem.name === mediaItem.name) {
													return action.payload.mediaItem
												}
											}
											return mediaItem
									  })
									: [action.payload.mediaItem],
						  }
						: state.current,
			}

		case types.MEDIA_MEAL_ITEM_DELETE:
			return {
				...state,
				data: state.data.map((entity) => {
					if (entity.id === action.payload.id) {
						entity.media = entity.media.filter((mediaItem) => {
							let isTarget = false
							if (mediaItem.eTag) {
								if (mediaItem.eTag === action.payload.mediaItem.eTag) {
									isTarget = true
								}
							} else if (mediaItem.name && action.payload.mediaItem.key) {
								if (action.payload.mediaItem.key.endsWith(mediaItem.name)) {
									isTarget = true
								}
							} else if (mediaItem.name && action.payload.mediaItem.name) {
								if (action.payload.mediaItem.name === mediaItem.name) {
									isTarget = true
								}
							}
							return !isTarget
						})
					}
					return entity
				}),
				current:
					state.current.id === action.payload.id
						? {
								...state.current,
								media: state.current.media.filter((mediaItem) => {
									const isTarget =
										(mediaItem.eTag && mediaItem.eTag === action.payload.mediaItem.eTag) ||
										mediaItem.key === action.payload.mediaItem.key
									return !isTarget
								}),
						  }
						: state.current,
			}

		// GET MEAL TYPES
		case types.FETCH_MEAL_ITEMS_DETECTIONS_SUCCESS:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.mealItems.data,
							[
								...action.payload.observations.map((obs) => {
									obs._app = {
										...obs._app,
										type: obs.creator_id ? 'local' : 'community',
									}
									delete obs._app.probability
									return obs
								}),
								...action.payload.detections.map((det) => {
									det._app = {
										...det._app,
										type: det.creator_id ? 'local' : 'community',
									}
									delete det._app.probability
									return det
								}),
							]
								.map((entity) => {
									return storeItemsTransactor(
										state,
										'mealItems.data',
										entity,
										{
											id: entity.id,
										},
										mealItemDecorator
									)
								})
								.reduce((acc, current) => acc.concat(current), [])
						),
						'items',
						state.data,
						['id', 'name', 'creator_id', 'ingredients', 'migration_id', '_app']
					),
					loading: {
						...state.mealItems.loading,
						read: false,
					},
				},
			}

		case types.FETCH_MEAL_ITEMS_DETECTIONS_FAIL:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					loading: {
						...state.mealItems.loading,
						read: false,
					},
				},
			}

		case types.FETCH_MEAL_ITEMS_DETECTIONS:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					loading: {
						...state.mealItems.loading,
						read: true,
					},
				},
			}

		// CURRENT MEAL
		case types.CURRENT_MEAL_UPDATE:
			return {
				...state,
				current: {
					...state.current,
					...action.payload,
				},
			}

		case types.CURRENT_MEAL_SET:
			return { ...state, current: action.payload }

		case types.CURRENT_MEAL_CLEAR:
			return { ...state, current: action.payload }

		// CURRENT MEAL ITEM
		case types.CURRENT_MEAL_ITEM_ADD:
			return {
				...state,
				current: {
					...state.current,
					items: [...state.current.items, action.payload],
				},
			}

		case types.CURRENT_MEAL_ITEM_UPDATE:
			return {
				...state,
				current: {
					...state.current,
					items: state.current.items.map((item) => {
						if (item.id && action.payload.id) {
							return action.payload.id === item.id
								? {
										...item,
										...action.payload,
								  }
								: item
						}
						if (item._app && action.payload && action.payload._app) {
							return action.payload._app.uuid === item._app.uuid
								? {
										...item,
										...action.payload,
								  }
								: item
						}
					}),
				},
			}

		case types.CURRENT_MEAL_ITEM_REMOVE:
			return {
				...state,
				current: {
					...state.current,
					items: state.current.items.filter((item) => {
						if (item.id) {
							return item.id !== action.payload.id
						}
						if (item._app && action.payload && action.payload._app) {
							return item._app.uuid !== action.payload._app.uuid
						}
					}),
				},
			}

		case types.RESET_MEAL_LOADERS:
			return {
				...state,
				mealItems: {
					...state.mealItems,
					loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
				},
				mealIngredients: {
					...state.mealIngredients,
					loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
				},
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		// case 'persist/REHYDRATE':
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.meal,
		//       mealItems: {
		//         ...action.payload.meal.mealItems,
		//         loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//       },
		//       mealIngredients: {
		//         ...action.payload.meal.mealIngredients,
		//         loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//       },
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		case types.SCENE:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		case types.MEAL_CONTEXT_RESET:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// DEFAULT
		default:
			return state
	}
}
