export const GET_INTERVENTIONS_SUCCESS = 'GET_INTERVENTIONS_SUCCESS'
export const GET_INTERVENTIONS_FAIL = 'GET_INTERVENTIONS_FAIL'
export const GET_INTERVENTIONS = 'GET_INTERVENTIONS'

export const GET_INTERVENTIONS_SUPERSET_SUCCESS = 'GET_INTERVENTIONS_SUPERSET_SUCCESS'
export const GET_INTERVENTIONS_SUPERSET_FAIL = 'GET_INTERVENTIONS_SUPERSET_FAIL'
export const GET_INTERVENTIONS_SUPERSET = 'GET_INTERVENTIONS_SUPERSET'

export const UPDATE_INTERVENTION_SUCCESS = 'UPDATE_INTERVENTION_SUCCESS'
export const UPDATE_INTERVENTION_FAIL = 'UPDATE_INTERVENTION_FAIL'
export const UPDATE_INTERVENTION = 'UPDATE_INTERVENTION'

export const ADD_INTERVENTION_SUCCESS = 'ADD_INTERVENTION_SUCCESS'
export const ADD_INTERVENTION_FAIL = 'ADD_INTERVENTION_FAIL'
export const ADD_INTERVENTION = 'ADD_INTERVENTION'

export const DELETE_INTERVENTION_SUCCESS = 'DELETE_INTERVENTION_SUCCESS'
export const DELETE_INTERVENTION_FAIL = 'DELETE_INTERVENTION_FAIL'
export const DELETE_INTERVENTION = 'DELETE_INTERVENTION'

export const RESET_INTERVENTION_LOADERS = 'RESET_INTERVENTION_LOADERS'

// CURRENT INTERVENTION
export const CURRENT_INTERVENTION_SET = 'CURRENT_INTERVENTION_SET'
export const CURRENT_INTERVENTION_CLEAR = 'CURRENT_INTERVENTION_CLEAR'

export const INTERVENTION_CONTEXT_RESET = 'INTERVENTION_CONTEXT_RESET'

// INTERVENTION COMPLIANCE
export const GET_INTERVENTION_COMPLIANCE_SUCCESS_COMPLETE = 'GET_INTERVENTION_COMPLIANCE_SUCCESS_COMPLETE'
export const GET_INTERVENTION_COMPLIANCE_SUCCESS = 'GET_INTERVENTION_COMPLIANCE_SUCCESS'
export const GET_INTERVENTION_COMPLIANCE_FAIL = 'GET_INTERVENTION_COMPLIANCE_FAIL'
export const GET_INTERVENTION_COMPLIANCE = 'GET_INTERVENTION_COMPLIANCE'

export const UPDATE_INTERVENTION_COMPLIANCE_SUCCESS = 'UPDATE_INTERVENTION_COMPLIANCE_SUCCESS'
export const UPDATE_INTERVENTION_COMPLIANCE_FAIL = 'UPDATE_INTERVENTION_COMPLIANCE_FAIL'
export const UPDATE_INTERVENTION_COMPLIANCE = 'UPDATE_INTERVENTION_COMPLIANCE'

export const ADD_INTERVENTION_COMPLIANCE_SUCCESS = 'ADD_INTERVENTION_COMPLIANCE_SUCCESS'
export const ADD_INTERVENTION_COMPLIANCE_FAIL = 'ADD_INTERVENTION_COMPLIANCE_FAIL'
export const ADD_INTERVENTION_COMPLIANCE = 'ADD_INTERVENTION_COMPLIANCE'

export const DELETE_INTERVENTION_COMPLIANCE_SUCCESS = 'DELETE_INTERVENTION_COMPLIANCE_SUCCESS'
export const DELETE_INTERVENTION_COMPLIANCE_FAIL = 'DELETE_INTERVENTION_COMPLIANCE_FAIL'
export const DELETE_INTERVENTION_COMPLIANCE = 'DELETE_INTERVENTION_COMPLIANCE'

export const RESET_INTERVENTION_COMPLIANCE_LOADERS = 'RESET_INTERVENTION_COMPLIANCE_LOADERS'
