import * as types from 'types'

import { storeItemsTransactor, storeItemsMerger, deepMerge, ArrayMerger } from 'reducers/z_redux_helpers'
import _groupBy from 'lodash.groupBy'
import _merge from 'lodash.merge'
import moment from 'moment'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	data: [],
	daysEvents: {},
	current: {},
	lastFetched: null,
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

const noteDecorator = (entity, noteEntitiesMap) => {
	return noteEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

const noteUnsyncedDecorator = (entity, noteEntitiesMap) => {
	return noteEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// GET NOTES
		case types.GET_NOTES_SUCCESS:
			let newPayload = ArrayMerger(state.data, [...action.payload.notes, ...state.data])
			return {
				...state,
				data: newPayload,
				daysEvents: _merge(
					{},
					state.daysEvents,
					_groupBy(action.payload.notes, (item) => {
						return moment(item.occurred_at).format('YYYY-MM-DD')
					})
				),
				lastFetched: new Date().getTime(),
			}

		case types.GET_NOTES_SUCCESS_COMPLETE:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_NOTES_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		case types.GET_NOTES:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		// GET NOTE
		case types.GET_NOTE_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						noteDecorator
					)
				),
				loading: {
					...state.loading,
					read: false,
				},
			}
		case types.GET_NOTE_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		case types.GET_NOTE:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),
				loading: {
					...state.loading,
					read: true,
				},
			}

		// UPDATE NOTE
		case types.UPDATE_NOTE_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						noteDecorator
					)
				),
				loading: {
					...state.loading,
					update: false,
				},
			}
		case types.UPDATE_NOTE_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					update: false,
				},
			}
		case types.UPDATE_NOTE:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),

				loading: {
					...state.loading,
					update: true,
				},
			}

		// DELETE NOTE
		case types.DELETE_NOTE_SUCCESS:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: false,
				},
			}
		case types.DELETE_NOTE_FAIL:
			return {
				...state,

				loading: {
					...state.loading,
					delete: false,
				},
			}
		case types.DELETE_NOTE:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: true,
				},
			}

		// ADD NOTE
		case types.ADD_NOTE_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						noteDecorator
					)
				),
				loading: {
					...state.loading,
					create: false,
				},
			}
		case types.ADD_NOTE_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					create: false,
				},
			}
		case types.ADD_NOTE:
			return {
				...state,
				data: state.data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					create: true,
				},
			}

		// CURRENT NOTE
		case types.CURRENT_NOTE_SET:
		case types.CURRENT_NOTE_CLEAR:
			return { ...state, current: action.payload }

		case types.RESET_NOTE_LOADERS:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		// case 'persist/REHYDRATE':
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.note,
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		case types.SCENE:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		case types.NOTE_CONTEXT_RESET:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// DEFAULT
		default:
			return state
	}
}
