import {
	getInterventionCompliance,
	addInterventionCompliance as postInterventionCompliance,
	updateInterventionCompliance as putInterventionCompliance,
	removeInterventionCompliance,
} from 'api/intervention'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'

export const fetchInterventionCompliance = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_INTERVENTION_COMPLIANCE })
		try {
			const res = await getInterventionCompliance(
				args,
				{
					dispatch,
					event: types.GET_INTERVENTION_COMPLIANCE_SUCCESS,
				},
				{
					user_intervention_id: args.user_intervention_id,
				}
			)
			handleSuccess(dispatch, types.GET_INTERVENTION_COMPLIANCE_SUCCESS_COMPLETE)
			return res
		} catch (err) {
			handleFail(dispatch, types.GET_INTERVENTION_COMPLIANCE_FAIL, err)
		}
	}
	return thunk
}

export const addInterventionCompliance = (interventionCompliance) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.ADD_INTERVENTION_COMPLIANCE,
			payload: interventionCompliance,
		})
		try {
			const res = await postInterventionCompliance(interventionCompliance)
			const contextResponse = {
				...res.data,
				_app: {
					...interventionCompliance._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_INTERVENTION_COMPLIANCE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			console.log(err)
			const contextResponse = {
				...interventionCompliance,
				_app: {
					err,
					...interventionCompliance._app,
				},
			}
			handleFail(dispatch, types.ADD_INTERVENTION_COMPLIANCE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateInterventionCompliance = (interventionCompliance, previousInterventionCompliance) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.UPDATE_INTERVENTION_COMPLIANCE,
			payload: {
				new: interventionCompliance,
				old: previousInterventionCompliance,
			},
		})
		try {
			const res = interventionCompliance.id
				? await putInterventionCompliance(interventionCompliance)
				: await postInterventionCompliance(interventionCompliance)
			const contextResponse = {
				...res.data,
				_app: {
					...interventionCompliance._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_INTERVENTION_COMPLIANCE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...interventionCompliance,
				_app: {
					err,
					...interventionCompliance._app,
				},
			}
			handleFail(dispatch, types.UPDATE_INTERVENTION_COMPLIANCE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteInterventionCompliance = (interventionCompliance) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.DELETE_INTERVENTION_COMPLIANCE,
			payload: interventionCompliance,
		})
		try {
			const res = await removeInterventionCompliance(interventionCompliance)
			const contextResponse = {
				...res.data,
				_app: {
					...interventionCompliance._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.DELETE_INTERVENTION_COMPLIANCE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...interventionCompliance,
				_app: {
					err,
					...interventionCompliance._app,
				},
			}
			handleFail(dispatch, types.DELETE_INTERVENTION_COMPLIANCE_FAIL, contextResponse)
		}
	}
	return thunk
}
