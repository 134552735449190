import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateDefaultArgs } from 'api/api_utils'

export const fetchQuestions = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/questions?${queryString}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}
