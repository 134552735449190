import { getSummary } from 'api/summary'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'

export const resetSummaryLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_SUMMARY_LOADERS })
	}
}
export const setAttentionDate = (args) => {
	return (dispatch) => {
		dispatch({ type: 'SET_ATTENTION_DATE', payload: args })
	}
}

export const fetchSummary = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_SUMMARY })
		try {
			const res = await getSummary(args)
			handleSuccess(dispatch, types.GET_SUMMARY_SUCCESS, res.data)
			return res.data
		} catch (err) {
			return handleFail(dispatch, types.GET_SUMMARY_FAIL, err)
		}
	}
	return thunk
}
