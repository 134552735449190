/**
 * CRUD Actions for EXERCISE
 */
// ADD - EXERCISE
export const ADD_EXERCISE_SUCCESS = 'ADD_EXERCISE_SUCCESS'
export const ADD_EXERCISE_FAIL = 'ADD_EXERCISE_FAIL'
export const ADD_EXERCISE = 'ADD_EXERCISE'
// DELETE - EXERCISE
export const DELETE_EXERCISE_SUCCESS = 'DELETE_EXERCISE_SUCCESS'
export const DELETE_EXERCISE_FAIL = 'DELETE_EXERCISE_FAIL'
export const DELETE_EXERCISE = 'DELETE_EXERCISE'
// UPDATE - EXERCISE
export const UPDATE_EXERCISE_SUCCESS = 'UPDATE_EXERCISE_SUCCESS'
export const UPDATE_EXERCISE_FAIL = 'UPDATE_EXERCISE_FAIL'
export const UPDATE_EXERCISE = 'UPDATE_EXERCISE'
// GET ALL - EXERCISE
export const GET_EXERCISES_SUCCESS = 'GET_EXERCISES_SUCCESS'
export const GET_EXERCISES_SUCCESS_COMPLETE = 'GET_EXERCISES_SUCCESS_COMPLETE'
export const GET_EXERCISES_FAIL = 'GET_EXERCISES_FAIL'
export const GET_EXERCISES = 'GET_EXERCISES'

export const GET_EXERCISE_SUCCESS = 'GET_EXERCISE_SUCCESS'
export const GET_EXERCISE_FAIL = 'GET_EXERCISE_FAIL'
export const GET_EXERCISE = 'GET_EXERCISE'

// ADD - EXERCISE_TYPE
export const ADD_EXERCISE_TYPE_SUCCESS = 'ADD_EXERCISE_TYPE_SUCCESS'
export const ADD_EXERCISE_TYPE_FAIL = 'ADD_EXERCISE_TYPE_FAIL'
export const ADD_EXERCISE_TYPE = 'ADD_EXERCISE_TYPE'
// DELETE - EXERCISE_TYPE
export const DELETE_EXERCISE_TYPE_SUCCESS = 'DELETE_EXERCISE_TYPE_SUCCESS'
export const DELETE_EXERCISE_TYPE_FAIL = 'DELETE_EXERCISE_TYPE_FAIL'
export const DELETE_EXERCISE_TYPE = 'DELETE_EXERCISE_TYPE'
// UPDATE - EXERCISE_TYPE
export const UPDATE_EXERCISE_TYPE_SUCCESS = 'UPDATE_EXERCISE_TYPE_SUCCESS'
export const UPDATE_EXERCISE_TYPE_FAIL = 'UPDATE_EXERCISE_TYPE_FAIL'
export const UPDATE_EXERCISE_TYPE = 'UPDATE_EXERCISE_TYPE'
// GET ALL - EXERCISE_TYPE
export const GET_EXERCISE_TYPES_SUCCESS = 'GET_EXERCISE_TYPES_SUCCESS'
export const GET_EXERCISE_TYPES_FAIL = 'GET_EXERCISE_TYPES_FAIL'
export const GET_EXERCISE_TYPES = 'GET_EXERCISE_TYPES'

/**
 * Current Types
 */
// CURRENT EXERCISE
export const CURRENT_EXERCISE_SET = 'CURRENT_EXERCISE_SET'
export const CURRENT_EXERCISE_CLEAR = 'CURRENT_EXERCISE_CLEAR'
// CURRENT EXERCISE_TYPE
export const CURRENT_EXERCISE_TYPE_ADD = 'CURRENT_EXERCISE_TYPE_ADD'
export const CURRENT_EXERCISE_TYPE_REMOVE = 'CURRENT_EXERCISE_TYPE_REMOVE'

export const MEDIA_EXERCISE_SET = 'MEDIA_EXERCISE_SET'

export const RESET_EXERCISE_LOADERS = 'RESET_EXERCISE_LOADERS'

export const EXERCISE_CONTEXT_RESET = 'EXERCISE_CONTEXT_RESET'
