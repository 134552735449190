import * as types from 'types'
import { requestSession } from 'api/chat'
import moment from 'moment'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { getSortedChannelsApi } from '../apis/chat'

export const connectChat = () => {
	async function thunk(dispatch, getState) {
		const { auth, chat } = getState()
		dispatch({ type: types.SENDBIRD_CONNECT })
		try {
			const sb_user_req = await requestSession()
			const sb_user = sb_user_req.data
			handleSuccess(dispatch, types.SENDBIRD_CONNECT_SUCCESS, {
				user: sb_user,
				session_token: sb_user.sessionToken,
			})
			// dispatch(fetchChannels());
		} catch (err) {
			console.log(err)
			dispatch({
				type: types.SENDBIRD_CONNECT_FAIL,
				payload: 'Unable to connect to chat service at this time',
			})
		}
	}
	thunk.interceptInOffline = false
	thunk.entity = false
	thunk.retry = false
	return thunk
}

export const reconnectChat = () => {
	async function thunk(dispatch, getState) {
		const { auth, chat } = getState()
		try {
			if (chat.session_token && moment(chat.session_token.expires_at).isAfter()) {
				dispatch({ type: types.SENDBIRD_RECONNECT })
				handleSuccess(dispatch, types.SENDBIRD_RECONNECT_SUCCESS, {
					user: chat.user,
					session_token: chat.session_token,
				})
				// dispatch(fetchChannels());
			} else {
				dispatch(connectChat())
			}
		} catch (err) {
			console.log(err)
			dispatch({ type: types.SENDBIRD_RECONNECT_FAIL })
			dispatch(connectChat())
		}
	}
	thunk.interceptInOffline = false
	thunk.entity = false
	thunk.retry = false
	return thunk
}

export const onChatPatient = (patient, sbContext) => {
	async function thunk(dispatch, getState) {
		const { auth, chat } = getState()
		const SB = sbContext.stores.sdkStore.sdk
		const params = new SB.GroupChannelParams()
		params.isPublic = false
		params.isEphemeral = false
		params.isDistinct = true
		params.isSuper = false
		params.addUserIds([auth.sub, patient.key])
		params.name = 'Direct Chat'
		return await SB.GroupChannel.createChannel(params)
	}
	thunk.interceptInOffline = false
	thunk.entity = false
	thunk.retry = false
	return thunk
}

export const getSortedChannelsAction = (user_id, token = '') => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.GET_SORTED_CHANNELS })
		try {
			const channels = await getSortedChannelsApi(user_id, token)
			dispatch({ type: types.GET_SORTED_CHANNELS_SUCCESS, payload: channels.data })
		} catch (err) {
			dispatch({ type: types.GET_SORTED_CHANNELS_FAIL })
		}
	}
	thunk.interceptInOffline = false
	thunk.entity = false
	thunk.retry = false
	return thunk
}

export const clearChannels = (user_id, token = '') => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.CLEAR_SORTED_CHANNELS })
	}
	thunk.interceptInOffline = false
	thunk.entity = false
	thunk.retry = false
	return thunk
}
