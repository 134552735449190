/**
 * CRUD Actions for CLINICIAN
 */
// FINISH
export const FINISH_SURVEY_SUCCESS = 'FINISH_SURVEY_SUCCESS'
export const FINISH_SURVEY_FAIL = 'FINISH_SURVEY_FAIL'
export const FINISH_SURVEY = 'FINISH_SURVEY'
// GET ALL
export const GET_SURVEYS_SUCCESS = 'GET_SURVEYS_SUCCESS'
export const GET_SURVEYS_FAIL = 'GET_SURVEYS_FAIL'
export const GET_SURVEYS = 'GET_SURVEYS'

// ENRICH
export const ENRICH_SURVEYS_SUCCESS = 'ENRICH_SURVEYS_SUCCESS'
export const ENRICH_SURVEYS_FAIL = 'ENRICH_SURVEYS_FAIL'
export const ENRICH_SURVEYS = 'ENRICH_SURVEYS'
/**
 * Current Types
 */
// CURRENT CLINICIAN
export const CURRENT_SURVEY_SET = 'CURRENT_SURVEY_SET'
export const CURRENT_SURVEY_CLEAR = 'CURRENT_SURVEY_CLEAR'

export const RESET_SURVEY_LOADERS = 'RESET_SURVEY_LOADERS'

export const SURVEY_CONTEXT_RESET = 'SURVEY_CONTEXT_RESET'
