import * as types from 'types'

import {
	storeItemsTransactor,
	storeItemsMerger,
	storeChildTypeRanker,
	deepMerge,
	ArrayMerger,
} from 'reducers/z_redux_helpers'

import { v4 as uuidv4 } from 'uuid'
import _groupBy from 'lodash.groupBy'
import _merge from 'lodash.merge'
import moment from 'moment'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	data: [],
	moodTypes: {
		data: [],
		used: [],
		loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
	},
	daysEvents: {},
	current: {},
	searchCache: {
		moodTypes: {},
	},
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

const moodDecorator = (entity, moodEntitiesMap) => {
	return moodEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			types: (entity.types || []).map((type) => {
				if (!type._app) {
					type._app = {}
					type._app.uuid = uuidv4()
				}
				return type
			}),
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

const moodUnsyncedDecorator = (entity, moodEntitiesMap) => {
	return moodEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			types: (entity.types || []).map((type) => {
				if (!type._app) {
					type._app = {}
					type._app.uuid = uuidv4()
				}
				return type
			}),
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		return entityMap
	})
}

const moodTypeDecorator = (entity, moodTypeEntitiesMap) => {
	return moodTypeEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

const moodTypeUnsyncedDecorator = (entity, moodTypeEntitiesMap) => {
	return moodTypeEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// GET MOODS
		case types.GET_MOODS_SUCCESS:
			let newPayload = ArrayMerger(state.data, [...action.payload.moods, ...state.data])
			return {
				...state,
				data: newPayload,
				moodTypes: {
					...state.moodTypes,
					data: storeChildTypeRanker(state.moodTypes.data, 'types', state.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: newPayload.reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				daysEvents: _merge(
					{},
					state.daysEvents,
					_groupBy(action.payload.moods, (item) => {
						return moment(item.occurred_at).format('YYYY-MM-DD')
					})
				),
			}

		case types.GET_MOODS_SUCCESS_COMPLETE:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_MOODS_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_MOODS:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		// UPDATE MOOD
		case types.UPDATE_MOOD_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						moodDecorator
					)
				),
				moodTypes: {
					...state.moodTypes,
					data: storeChildTypeRanker(state.moodTypes.data, 'types', state.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},

				loading: {
					...state.loading,
					update: false,
				},
			}

		case types.UPDATE_MOOD_FAIL:
			// if (wasOnline) {
			//   ToastHolder.showToast("error").alertWithContext(
			//     "Error",
			//     "Could Not Update Entry!"
			//   );
			// }
			return {
				...state,
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload.error,
						{
							'_app.uuid': action.payload.error._app.uuid,
						},
						moodUnsyncedDecorator
					)
				),
				loading: {
					...state.loading,
					update: false,
				},
			}

		case types.UPDATE_MOOD:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload.new,
						{
							'_app.uuid': action.payload.new._app.uuid,
						},
						moodUnsyncedDecorator
					)
				),
				loading: {
					...state.loading,
					update: true,
				},
			}

		// DELETE MOOD
		case types.DELETE_MOOD_SUCCESS:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				moodTypes: {
					...state.moodTypes,
					used: state.data.reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},

				loading: {
					...state.loading,
					delete: false,
				},
			}

		case types.DELETE_MOOD_FAIL:
			// if (wasOnline) {
			//   ToastHolder.showToast("error").alertWithContext(
			//     "Error",
			//     "Could Not Delete Entry!"
			//   );
			// }
			return {
				...state,
				loading: {
					...state.loading,
					delete: false,
				},
			}

		case types.DELETE_MOOD:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: true,
				},
			}

		// ADD MOOD
		case types.ADD_MOOD_SUCCESS:
			// if (wasOnline) {
			//   ToastHolder.showToast("success").alertWithContext(
			//     "Mood Logged!",
			//     ""
			//   );
			// }
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						moodDecorator
					)
				),

				moodTypes: {
					...state.moodTypes,
					data: storeChildTypeRanker(state.moodTypes.data, 'types', state.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_MOOD_FAIL:
			// if (wasOnline) {
			//   ToastHolder.showToast("error").alertWithContext(
			//     "Error",
			//     "Could Not Add Entry!"
			//   );
			// }
			return {
				...state,
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_MOOD:
			return {
				...state,
				data: state.data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					create: true,
				},
			}

		// GET MOOD TYPES
		case types.GET_MOOD_TYPES_SUCCESS:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.moodTypes.data,
							[
								...action.payload.local.map((local) => {
									local._app = {
										...local._app,
										type: 'local',
									}
									return local
								}),
								...action.payload.community.map((community) => {
									community._app = {
										...community._app,
										type: 'community',
									}
									return community
								}),
							]
								.map((entity) => {
									return storeItemsTransactor(state, 'moodTypes.data', entity, {
										id: entity.id,
									})
								})
								.reduce((acc, current) => acc.concat(current), [])
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'migration_id', '_app']
					),
					loading: {
						...state.moodTypes.loading,
						read: false,
					},
				},
				searchCache: {
					...state.searchCache,
					moodTypes: {
						...state.searchCache.moodTypes,
						[action.meta.like || '']: {
							invoked_at: action.meta.invoked_at,
						},
					},
				},
			}

		case types.GET_MOOD_TYPES_FAIL:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					loading: {
						...state.moodTypes.loading,
						read: false,
					},
				},
			}

		case types.GET_MOOD_TYPES:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					loading: {
						...state.moodTypes.loading,
						read: true,
					},
				},
			}

		// UPDATE MOOD TYPE
		case types.UPDATE_MOOD_TYPE_SUCCESS:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.moodTypes.data,
							storeItemsTransactor(
								state,
								'moodTypes.data',
								action.payload,
								{
									id: action.payload.id,
								},
								moodTypeDecorator
							)
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'migration_id', '_app']
					),
					loading: {
						...state.moodTypes.loading,
						update: false,
					},
				},
			}

		case types.UPDATE_MOOD_TYPE_FAIL:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					loading: {
						...state.moodTypes.loading,
						update: false,
					},
				},
			}

		case types.UPDATE_MOOD_TYPE:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					data: state.moodTypes.data.filter((item) => item.id !== action.payload.id),
					loading: {
						...state.moodTypes.loading,
						update: true,
					},
				},
			}

		// DELETE MOOD TYPE
		case types.DELETE_MOOD_TYPE_SUCCESS:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					data: state.moodTypes.data.filter((type) => type.id !== action.payload.id),
					loading: {
						...state.moodTypes.loading,
						delete: false,
					},
				},
			}

		case types.DELETE_MOOD_TYPE_FAIL:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					loading: {
						...state.moodTypes.loading,
						delete: false,
					},
				},
			}

		case types.DELETE_MOOD_TYPE:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					data: state.moodTypes.data.filter((item) => item.id !== action.payload.id),
					loading: {
						...state.moodTypes.loading,
						delete: true,
					},
				},
			}

		// ADD MOOD TYPE
		case types.ADD_MOOD_TYPE_SUCCESS:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.moodTypes.data,
							storeItemsTransactor(state, 'moodTypes.data', action.payload, {
								id: action.payload.id,
							})
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'migration_id', '_app']
					),
					_data: state.moodTypes._data.filter((item) => item._app.uuid !== action.payload._app.uuid),
					loading: {
						...state.moodTypes.loading,
						create: false,
					},
				},
			}

		case types.ADD_MOOD_TYPE_FAIL:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					loading: {
						...state.moodTypes.loading,
						create: false,
					},
				},
			}

		case types.ADD_MOOD_TYPE:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					data: state.moodTypes.data.filter((item) => {
						if (action.payload.id) {
							return item.id !== action.payload.id
						} else if (action.payload._app) {
							if (item._app) {
								return item._app.uuid !== action.payload._app.uuid
							}
						}
						return true
					}),
					loading: {
						...state.moodTypes.loading,
						create: true,
					},
				},
			}

		// CURRENT MOOD
		case types.CURRENT_MOOD_SET:
			return { ...state, current: action.payload }

		case types.CURRENT_MOOD_CLEAR:
			return { ...state, current: action.payload }

		case types.CURRENT_MOOD_TYPE_ADD:
			return {
				...state,
				current: {
					...state.current,
					types: [...state.current.types, action.payload],
				},
			}

		case types.CURRENT_MOOD_TYPE_REMOVE:
			return {
				...state,
				current: {
					...state.current,
					types: state.current.types.filter((type) => {
						if (type.id) {
							return type.id !== action.payload.id
						}
						if (type._app && action.payload._app) {
							return type._app.uuid !== action.payload._app.uuid
						}
					}),
				},
			}

		case types.RESET_MOOD_LOADERS:
			return {
				...state,
				moodTypes: {
					...state.moodTypes,
					loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
				},
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		// case 'persist/REHYDRATE':
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.mood,
		//       moodTypes: {
		//         ...action.payload.mood.moodTypes,
		//         loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//       },
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		case types.SCENE:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		case types.MOOD_CONTEXT_RESET:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// DEFAULT
		default:
			return state
	}
}
