import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateDefaultArgs } from 'api/api_utils'

// export const removeSurvey = surveyData => {
//   return axiosInstance.request({
//     url: `v1/surveys/${surveyData.id}`,
//     method: 'delete',
//   });
// };

export const fetchSurveys = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/surveys?${queryString}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const enrichSurvey = (id) => {
	return axiosInstance.request({
		url: `v1/surveys/${id}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}
