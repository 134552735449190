import React from 'react'

export const EphemeralContext = React.createContext(null)

export class EphemeralWrapper extends React.Component {
	constructor() {
		super()
		this.state = {
			store: {},
			actions: {},
		}
	}
	render() {
		return <EphemeralContext.Provider value={this.state}>{this.props.children}</EphemeralContext.Provider>
	}
}
