import { getAllPrograms, getProgram, addProgram as assignProgram } from 'api/program'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'

export const fetchRawPrograms = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_PROGRAMS })
		try {
			const res = await getAllPrograms(args, {
				dispatch,
				event: types.GET_PROGRAMS_SUCCESS,
			})
			handleSuccess(dispatch, types.GET_PROGRAMS_SUCCESS, res.data)
			return res
		} catch (err) {
			handleFail(dispatch, types.GET_PROGRAMS_FAIL, err)
		}
	}
	return thunk
}

export const fetchProgram = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_PROGRAM })
		try {
			const res = await getProgram(args, {
				dispatch,
				event: types.GET_PROGRAM_SUCCESS,
			})
			handleSuccess(dispatch, types.GET_PROGRAM_SUCCESS, res.data)
			return res
		} catch (err) {
			handleFail(dispatch, types.GET_PROGRAM_FAIL, err)
		}
	}
	return thunk
}

export const addProgram = (program) => {
	async function thunk(dispatch) {
		dispatch({ type: types.ADD_PROGRAM, payload: program })
		try {
			const res = await assignProgram(program)
			const contextResponse = {
				...res.data,
				_app: {
					...program._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_PROGRAM_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...program,
				_app: {
					err,
					...program._app,
				},
			}
			handleFail(dispatch, types.ADD_PROGRAM_FAIL, contextResponse)
		}
	}
	return thunk
}

export const setCurrentProgram = (program) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_PROGRAM_SET, payload: program })
	}
}

export const clearCurrentProgram = () => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_PROGRAM_CLEAR })
	}
}

export const setCurrentProgramTabName = (programName) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_PROGRAM_TAB_NAME_SET, payload: programName })
	}
}

export const setProgramTableSearchValue = (searchInput) => {
	return (dispatch) => {
		dispatch({ type: types.PROGRAM_TABLE_SEARCH_VALUE_SET, payload: searchInput })
	}
}

export const setProgramTableSort = ({ column, direction }) => {
	return (dispatch) => {
		dispatch({
			type: types.PROGRAM_TABLE_SORT_SET,
			payload: {
				column,
				direction,
			},
		})
	}
}
