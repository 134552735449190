/**
 * CRUD Actions for NOTE
 */
// ADD
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS'
export const ADD_NOTE_FAIL = 'ADD_NOTE_FAIL'
export const ADD_NOTE = 'ADD_NOTE'
// UPDATE
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS'
export const UPDATE_NOTE_FAIL = 'UPDATE_NOTE_FAIL'
export const UPDATE_NOTE = 'UPDATE_NOTE'
// DELETE
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS'
export const DELETE_NOTE_FAIL = 'DELETE_NOTE_FAIL'
export const DELETE_NOTE = 'DELETE_NOTE'

// GET
export const GET_NOTE_SUCCESS = 'GET_NOTE_SUCCESS'
export const GET_NOTE_SUCCESS_COMPLETE = 'GET_NOTE_SUCCESS_COMPLETE'
export const GET_NOTE_FAIL = 'GET_NOTE_FAIL'
export const GET_NOTE = 'GET_NOTE'

// GET ALL
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS'
export const GET_NOTES_SUCCESS_COMPLETE = 'GET_NOTES_SUCCESS_COMPLETE'
export const GET_NOTES_FAIL = 'GET_NOTES_FAIL'
export const GET_NOTES = 'GET_NOTES'

/**
 * Current Types
 */
// CURRENT NOTE
export const CURRENT_NOTE_SET = 'CURRENT_NOTE_SET'
export const CURRENT_NOTE_CLEAR = 'CURRENT_NOTE_CLEAR'

export const MEDIA_NOTE_SET = 'MEDIA_NOTE_SET'

export const RESET_NOTE_LOADERS = 'RESET_NOTE_LOADERS'

export const NOTE_CONTEXT_RESET = 'NOTE_CONTEXT_RESET'
