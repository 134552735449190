import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateExhaust } from 'api/api_utils'

const formatSleepData = (sleepData) => ({
	quality: sleepData.quality || 0,
	duration: sleepData.duration || 0,
	occurred_at: sleepData.occurred_at,
	notes: sleepData.notes,
})

export const addSleep = (sleepData) => {
	return axiosInstance.request({
		url: 'v1/sleeps',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatSleepData(sleepData),
	})
}

export const updateSleep = (sleepData) => {
	return axiosInstance.request({
		url: `v1/sleeps/${sleepData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatSleepData(sleepData),
	})
}

export const removeSleep = (sleepData) => {
	return axiosInstance.request({
		url: `v1/sleeps/${sleepData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const fetchSleeps = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/sleeps',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'sleeps',
		1,
		[],
		reduxEvent
	)
}
