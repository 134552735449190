import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import ContentGraphic from './ContentGraphic'

const PREFIX = 'Content'

const classes = {
	paper: `${PREFIX}-paper`,
	searchBar: `${PREFIX}-searchBar`,
	block: `${PREFIX}-block`,
	addUser: `${PREFIX}-addUser`,
	contentWrapper: `${PREFIX}-contentWrapper`,
	container: `${PREFIX}-container`,
}

const Root = styled('div')(({ theme }) => {
	return {
		[`& .${classes.paper}`]: {
			margin: 'auto',
			overflow: 'hidden',

			// [theme.breakpoints.up('sm')]: {
			// 	minWidth: 600,
			// },
			// [theme.breakpoints.up('lg')]: {
			// 	minWidth: 936,
			// },
		},
		[`& .${classes.searchBar}`]: {
			borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
		},
		[`& .${classes.block}`]: {
			display: 'block',
		},
		[`& .${classes.addUser}`]: {
			marginRight: theme.spacing(1),
		},
		[`& .${classes.contentWrapper}`]: {
			height: 368,
		},
		[`&.${classes.container}`]: {
			padding: '48px 36px 48px',
		},
	}
})

function Content({ title, subtitle, body }) {
	return (
		<Root className={classes.container}>
			<Paper className={classes.paper}>
				<Grid
					container
					spacing={2}
					className={classes.contentWrapper}
					wrap={'wrap'}
					alignItems="center"
					justifyContent="center"
				>
					<Grid lg={4} xs={12} item align="center">
						<ContentGraphic />
					</Grid>
					<Grid lg={8} xs={12} item>
						<Typography component="h2" variant="h5" color="textSecondary" align="center">
							{title}
						</Typography>
						<Typography component="h5" variant="body1" color="textSecondary" align="center">
							{subtitle}
						</Typography>
						{body ? <>{body}</> : <></>}
					</Grid>
				</Grid>
			</Paper>
		</Root>
	)
}

Content.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
}

export default Content
