import * as types from 'types'
import { handleSuccess } from 'actions/z_action_helpers'

export const updateDeviceSetting = (args) => {
	async function thunk(dispatch) {
		try {
			handleSuccess(dispatch, types.UPDATE_DEVICE_SETTING, args)
		} catch (err) {}
	}

	return thunk
}

export const updateDeviceSettingDiaryInterestEntities = (args) => {
	async function thunk(dispatch) {
		try {
			handleSuccess(dispatch, types.UPDATE_DEVICE_SETTING_DIARY_INTERESTED_ENTITIES, args)
		} catch (err) {}
	}

	return thunk
}

export const updateDeviceSettingDiaryImageRenderPreference = (args) => {
	async function thunk(dispatch) {
		try {
			handleSuccess(dispatch, types.UPDATE_DEVICE_SETTING_DIARY_VIEW_IMAGES, args)
		} catch (err) {}
	}
	return thunk
}
