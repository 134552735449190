import {
	fetchBowels,
	addBowel as postBowel,
	updateBowel as putBowel,
	removeBowel,
	fetchBowelTypes,
	addBowelType as postBowelType,
	updateBowelType as putBowelType,
	removeBowelType,
} from 'api/bowel'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { getDefaultOccurredAt } from 'utils/helpers'
import { mediaEntityDecorator } from 'api/api_utils'

export const getDefaultBowel = (attentionDate) => ({
	notes: null,
	media: [],
	duration: 0,
	occurred_at: getDefaultOccurredAt(attentionDate),
	types: [],
	uuid: null,
	_app: {
		uuid: null,
		type: 'bowel',
		synced: false,
	},
})

export const resetBowelLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_BOWEL_LOADERS })
	}
}

export const addBowel = (rawBowel) => {
	async function thunk(dispatch, getState) {
		const bowel = mediaEntityDecorator(rawBowel)
		dispatch({ type: types.ADD_BOWEL, payload: bowel })
		try {
			const res = await postBowel(bowel)
			const contextResponse = {
				...res.data,
				media: bowel.media,
				_app: {
					...bowel._app,
					...res.data._app,
				},
			}
			const hyperBowel = await handleSuccess(dispatch, types.ADD_BOWEL_SUCCESS, contextResponse)
			if (hyperBowel.uuid) {
				// const mediaObject = await dispatch(
				//   actions.writeMediaBackground(hyperBowel, 'bowels'),
				// );
				// if (mediaObject._app.err) {
				// 	dispatch({
				// 		type: types.ADD_BOWEL_MEDIA_FAIL,
				// 		payload: { error: mediaObject },
				// 	})
				// }
			}

			return hyperBowel
		} catch (err) {
			const contextResponse = {
				...bowel,
				_app: {
					err,
					...bowel._app,
				},
			}
			handleFail(dispatch, types.ADD_BOWEL_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateBowel = (rawBowel, previousBowel) => {
	async function thunk(dispatch, getState) {
		const bowel = mediaEntityDecorator(rawBowel)
		dispatch({
			type: types.UPDATE_BOWEL,
			payload: {
				new: bowel,
				old: previousBowel,
			},
		})
		try {
			const res = bowel.id ? await putBowel(bowel) : await postBowel(bowel)
			const contextResponse = {
				...res.data,
				media: bowel.media,
				_app: {
					...bowel._app,
					...res.data._app,
				},
			}
			const hyperBowel = await handleSuccess(dispatch, types.UPDATE_BOWEL_SUCCESS, contextResponse)
			if (hyperBowel.uuid) {
				// const mediaObject = await dispatch(
				//   actions.writeMediaBackground(hyperBowel, 'bowels'),
				// );
				// if (mediaObject._app.err) {
				// 	dispatch({
				// 		type: types.ADD_BOWEL_MEDIA_FAIL,
				// 		payload: { error: mediaObject },
				// 	})
				// }
			}

			return hyperBowel
		} catch (err) {
			const contextResponse = {
				...bowel,
				_app: {
					err,
					...bowel._app,
				},
			}
			handleFail(dispatch, types.UPDATE_BOWEL_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteBowel = (bowel) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.DELETE_BOWEL, payload: bowel })
		try {
			const res = await removeBowel(bowel)
			const contextResponse = {
				...res.data,
				_app: {
					...bowel._app,
					...res.data._app,
				},
			}
			const hyperBowel = await handleSuccess(dispatch, types.DELETE_BOWEL_SUCCESS, contextResponse)
			if (hyperBowel.uuid) {
				// const mediaObject = await dispatch(
				//   actions.removeMedia(hyperBowel, 'bowels'),
				// );
				// if (mediaObject._app.err) {
				// 	dispatch({
				// 		type: types.ADD_BOWEL_MEDIA_FAIL,
				// 		payload: { error: mediaObject },
				// 	})
				// }
			}

			return hyperBowel
		} catch (err) {
			const contextResponse = {
				...bowel,
				_app: {
					err,
					...bowel._app,
				},
			}
			handleFail(dispatch, types.DELETE_BOWEL_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getBowels = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_BOWELS })
		try {
			await fetchBowels(args, {
				dispatch,
				event: types.GET_BOWELS_SUCCESS,
			})
			handleSuccess(dispatch, types.GET_BOWELS_SUCCESS_COMPLETE)
		} catch (err) {
			handleFail(dispatch, types.GET_BOWELS_FAIL, err)
		}
	}
	return thunk
}

export const addBowelType = (bowelType) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_BOWEL_TYPE, payload: bowelType })
		try {
			const res = await postBowelType(bowelType)
			const contextResponse = {
				...res.data,
				_app: {
					...bowelType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_BOWEL_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...bowelType,
				_app: {
					err,
					...bowelType._app,
				},
			}
			handleFail(dispatch, types.ADD_BOWEL_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateBowelType = (bowelType) => {
	async function thunk(dispatch) {
		dispatch({ type: types.UPDATE_BOWEL_TYPE, payload: bowelType })
		try {
			const res = bowelType.id ? await putBowelType(bowelType) : await postBowelType(bowelType)
			const contextResponse = {
				...res.data,
				_app: {
					...bowelType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.UPDATE_BOWEL_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...bowelType,
				_app: {
					err,
					...bowelType._app,
				},
			}
			handleFail(dispatch, types.UPDATE_BOWEL_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteBowelType = (bowelType) => {
	async function thunk(dispatch) {
		dispatch({ type: types.DELETE_BOWEL_TYPE, payload: bowelType })
		try {
			const res = await removeBowelType(bowelType)
			const contextResponse = {
				...res.data,
				_app: {
					...bowelType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_BOWEL_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...bowelType,
				_app: {
					err,
					...bowelType._app,
				},
			}
			handleFail(dispatch, types.DELETE_BOWEL_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getBowelTypes = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_BOWEL_TYPES })
		try {
			const res = await fetchBowelTypes(args)
			handleSuccess(dispatch, types.GET_BOWEL_TYPES_SUCCESS, res.data, {
				...args,
				invoked_at: new Date().toISOString(),
			})
			return res.data
		} catch (err) {
			handleFail(dispatch, types.GET_BOWEL_TYPES_FAIL, err)
		}
	}
	return thunk
}

export const setCurrentBowel = (bowel, attentionDate) => {
	if (!bowel) {
		bowel = getDefaultBowel(attentionDate)
	}
	return (dispatch) => {
		dispatch({ type: types.CURRENT_BOWEL_SET, payload: bowel })
	}
}

export const updateCurrentBowel = (payload) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_BOWEL_UPDATE, payload })
	}
}

export const clearCurrentBowel = (bowel = getDefaultBowel()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_BOWEL_CLEAR, payload: getDefaultBowel() })
	}
}

export const addCurrentBowelType = (bowelType) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({ type: types.CURRENT_BOWEL_TYPE_ADD, payload: bowelType })
			res(bowelType)
		})
}

export const removeCurrentBowelType = (bowelType) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: types.CURRENT_BOWEL_TYPE_REMOVE,
				payload: bowelType,
			})
			res(bowelType)
		})
}
