import React from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import { StaticDateRangePicker } from '@components'
import Sticky from 'react-stickynode'
import useMediaQuery from '@mui/material/useMediaQuery'

const PREFIX = 'StickyDiaryCalendar'

const classes = {
	root: `${PREFIX}-root`,
	paper: `${PREFIX}-paper`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		justifyContent: 'center',
	},
	[`& .${classes.paper}`]: {
		margin: 'auto',
		overflow: 'hidden',
	},
}))

const StickyDiaryCalendar = (props) => {
	const theme = useTheme()
	const shouldDock = useMediaQuery(theme.breakpoints.down('sm'))
	return (
		<Root>
			<Sticky top="#header" top={10} enabled={!shouldDock}>
				<Paper className={classes.paper}>
					<StaticDateRangePicker range={props.range} onChangedDate={props.onChangedDate} />
				</Paper>
			</Sticky>
		</Root>
	)
}

export { StickyDiaryCalendar }
