import {
	fetchMedications,
	addMedication as postMedication,
	updateMedication as putMedication,
	removeMedication,
	fetchMedicationTypes,
	addMedicationType as postMedicationType,
	updateMedicationType as putMedicationType,
	removeMedicationType,
} from 'api/medication'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { getDefaultOccurredAt } from 'utils/helpers'

const getDefaultMedication = (attentionDate) => ({
	notes: null,
	occurred_at: getDefaultOccurredAt(attentionDate),
	types: [],
	_app: {
		uuid: null,
		type: 'medication',
		synced: false,
	},
})

export const resetMedicationLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_MEDICATION_LOADERS })
	}
}

export const addMedication = (medication) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_MEDICATION, payload: medication })
		try {
			const res = await postMedication(medication)
			const contextResponse = {
				...res.data,
				_app: {
					...medication._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.ADD_MEDICATION_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...medication,
				_app: {
					err,
					...medication._app,
				},
			}
			handleFail(dispatch, types.ADD_MEDICATION_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateMedication = (medication, previousMedication) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.UPDATE_MEDICATION,
			payload: { new: medication, old: previousMedication },
		})
		try {
			const res = medication.id
				? await putMedication(medication, previousMedication)
				: await postMedication(medication)
			const contextResponse = {
				...res.data,
				_app: {
					...medication._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_MEDICATION_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...medication,
				_app: {
					err,
					...medication._app,
				},
			}
			handleFail(dispatch, types.UPDATE_MEDICATION_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteMedication = (medication) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.DELETE_MEDICATION, payload: medication })
		try {
			const res = await removeMedication(medication)
			const contextResponse = {
				...res.data,
				_app: {
					...medication._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.DELETE_MEDICATION_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...medication,
				_app: {
					err,
					...medication._app,
				},
			}
			handleFail(dispatch, types.DELETE_MEDICATION_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getMedications = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_MEDICATIONS })
		try {
			const res = await fetchMedications(args, {
				dispatch,
				event: types.GET_MEDICATIONS_SUCCESS,
			})
			await handleSuccess(dispatch, types.GET_MEDICATIONS_SUCCESS_COMPLETE)
			return res
		} catch (err) {
			handleFail(dispatch, types.GET_MEDICATIONS_FAIL, err)
		}
	}
	return thunk
}

export const addMedicationType = (medicationType) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_MEDICATION_TYPE, payload: medicationType })
		try {
			const res = await postMedicationType(medicationType)
			const contextResponse = {
				...res.data,
				_app: {
					...medicationType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_MEDICATION_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...medicationType,
				_app: {
					err,
					...medicationType._app,
				},
			}
			handleFail(dispatch, types.ADD_MEDICATION_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateMedicationType = (medicationType) => {
	async function thunk(dispatch) {
		dispatch({ type: types.UPDATE_MEDICATION_TYPE, payload: medicationType })
		try {
			const res = medicationType.id
				? await putMedicationType(medicationType)
				: await postMedicationType(medicationType)
			const contextResponse = {
				...res.data,
				_app: {
					...medicationType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.UPDATE_MEDICATION_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...medicationType,
				_app: {
					err,
					...medicationType._app,
				},
			}
			handleFail(dispatch, types.UPDATE_MEDICATION_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteMedicationType = (medicationType) => {
	async function thunk(dispatch) {
		dispatch({ type: types.DELETE_MEDICATION_TYPE, payload: medicationType })
		try {
			const res = await removeMedicationType(medicationType)
			const contextResponse = {
				...res.data,
				_app: {
					...medicationType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_MEDICATION_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...medicationType,
				_app: {
					err,
					...medicationType._app,
				},
			}
			handleFail(dispatch, types.DELETE_MEDICATION_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getMedicationTypes = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_MEDICATION_TYPES })
		try {
			const res = await fetchMedicationTypes(args)
			handleSuccess(dispatch, types.GET_MEDICATION_TYPES_SUCCESS, res.data, {
				...args,
				invoked_at: new Date().toISOString(),
			})
			return res.data
		} catch (err) {
			handleFail(dispatch, types.GET_MEDICATION_TYPES_FAIL, err)
		}
	}
	return thunk
}

export const setCurrentMedication = (medication, attentionDate) => {
	return (dispatch) => {
		const payload = { ...getDefaultMedication(attentionDate), ...medication }
		return new Promise((res, rej) => {
			dispatch({
				type: types.CURRENT_MEDICATION_SET,
				payload,
			})
			res(payload)
		})
	}
}

export const clearCurrentMedication = (medication = getDefaultMedication()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_MEDICATION_CLEAR, payload: medication })
	}
}

export const addCurrentMedicationType = (medicationType = getDefaultMedication()) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: types.CURRENT_MEDICATION_TYPE_ADD,
				payload: medicationType,
			})
			res(medicationType)
		})
}

export const updateCurrentMedicationType = (medicationType) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: types.CURRENT_MEDICATION_TYPE_UPDATE,
				payload: medicationType,
			})
		})
}

export const removeCurrentMedicationType = (medicationType) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: types.CURRENT_MEDICATION_TYPE_REMOVE,
				payload: medicationType,
			})
			res(medicationType)
		})
}
