import React from 'react'
import { styled } from '@mui/material/styles'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Dialog from '@mui/material/Dialog'
import CircularProgress from '@mui/material/CircularProgress'

const PREFIX = 'ModalDOM'
const classes = {}

const StyledDialog = styled(Dialog)(({ theme }) => ({}))

const ModalDOM = ({ device, children }) => {
	return (
		<StyledDialog
			PaperProps={{
				style: {
					backgroundColor: 'transparent',
					boxShadow: 'none',
					overflow: 'hidden',
					backgroundImage: 'none',
				},
			}}
			open={device?.modals?.state_block || false}
		>
			<CircularProgress size={200} disableShrink />
		</StyledDialog>
	)
}

const mapStateToProps = ({ device }) => {
	return {
		device,
	}
}

export default compose(connect(mapStateToProps, actions))(ModalDOM)
