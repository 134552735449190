import React, { useEffect, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { lighten, darken } from '@mui/system'
import classNames from 'classnames'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Card from '@mui/material/Card'

import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'
import RefreshIcon from '@mui/icons-material/Refresh'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import moment from 'moment'
import { PieComplianceChart } from '@components'
import SymptomScoresChart from '@components/SymptomScoresChart'
import InterventionStoolDetailsCard from '@components/Interventions/InterventionStoolDetailsCard'
import InterventionDSSDetailsCard from '@components/Interventions/InterventionDSSDetailsCard'
import InterventionComplianceDetailsCard from '@components/Interventions/InterventionComplianceDetailsCard'

import { getRange } from 'utils/helpers'
const PREFIX = 'ActiveInterventionsTable'

const classes = {
	cardRoot: `${PREFIX}-cardRoot`,
	loadingContainer: `${PREFIX}-loadingContainer`,
	media: `${PREFIX}-media`,
	expand: `${PREFIX}-expand`,
	stack: `${PREFIX}-stack`,
	expandOpen: `${PREFIX}-expandOpen`,
	avatar: `${PREFIX}-avatar`,
	cover: `${PREFIX}-cover`,
	titleBar: `${PREFIX}-titleBar`,
	interventionGroupHeader: `${PREFIX}-interventionGroupHeader`,
	complianceHeader: `${PREFIX}-complianceHeader`,
	emptyGraph: `${PREFIX}-emptyGraph`,
	emptyGraphContents: `${PREFIX}-emptyGraphContents`,
	chartsHolder: `${PREFIX}-chartsHolder`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.loadingContainer}`]: {
		padding: theme.spacing(3),
	},
	[`& .${classes.cardRoot}`]: {
		margin: theme.spacing(2),
		paddingBottom: theme.spacing(3),
	},

	[`& .${classes.media}`]: {
		height: 0,
		paddingTop: '36.25%', // 16:9
	},
	[`& .${classes.stack}`]: {
		padding: theme.spacing(2),
	},

	[`& .${classes.expand}`]: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	[`& .${classes.expandOpen}`]: {
		transform: 'rotate(180deg)',
	},
	[`& .${classes.avatar}`]: {
		'& > img': {
			objectFit: 'contain',
		},

		width: theme.spacing(7),
		height: theme.spacing(7),
	},
	[`& .${classes.cover}`]: {
		width: 151,
	},
	[`& .${classes.titleBar}`]: {
		padding: theme.spacing(1),
	},
	[`& .${classes.interventionGroupHeader}`]: {
		padding: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		background: `linear-gradient(90deg, 
      ${
			theme.palette.mode === 'dark'
				? lighten(theme.palette.background.paper, 0.7)
				: darken(theme.palette.background.paper, 0.2)
		} 3%,
      ${theme.palette.background.paper} 90%)`,
	},
	[`& .${classes.complianceHeader}`]: {
		marginTop: 10,
		justifyContent: 'center',
		alignItems: 'center',
	},

	[`& .${classes.emptyGraph}`]: {
		margin: theme.spacing(2),
		borderWidth: 1,
		borderStyle: 'solid',
		padding: theme.spacing(2),
	},
	[`& .${classes.emptyGraphContents}`]: {
		padding: 10,
		textAlign: 'center',
	},
	[`& .${classes.chartsHolder}`]: {
		margin: 20,
	},
}))

const getComplianceSummary = (total, complied) => {
	const ratio = complied / total
	if (total < 1) {
		return 'N/A'
	}
	if (ratio > 0.8) {
		return 'Good'
	} else if (ratio > 0.6) {
		return 'Fair'
	} else if (ratio > 0.45) {
		return 'Half Compliant'
	} else if (ratio > 0.3) {
		return 'Poor'
	} else if (ratio > 0.15) {
		return 'Terrible'
	} else if (ratio > 0.05) {
		return 'Horrible'
	}
	return 'Did not comply'
}

const filterActiveOnDate = (interv) => {
	const today = moment().format('YYYY-MM-DD')
	if (interv.completed_at && interv.occurred_at) {
		return moment(today).isBetween([
			moment(interv.occurred_at).startOf('day'),
			moment(interv.completed_at).endOf('day'),
		])
	} else {
		return moment(today).isSameOrAfter(moment(interv.occurred_at).startOf('day'))
	}
}

function ActiveInterventionsTable(props) {
	const { interventions, symptomScore, loading, onRefresh, hydrateChildren, interventionCompliance, clinicians } =
		props

	const totalInterventions = [...interventions].filter(filterActiveOnDate)

	const compliances = totalInterventions.reduce((acc, activeIntervention) => {
		const interventionStart = moment(activeIntervention.occurred_at).startOf('day').format('YYYY-MM-DD')
		const interventionEnd = activeIntervention.completed_at
			? moment(activeIntervention.completed_at).startOf('day').format('YYYY-MM-DD')
			: false

		const now = new Date()
		const endStart = moment(interventionEnd || now)
			.startOf('day')
			.format('YYYY-MM-DD')

		let compliedCount = 0
		let notCompliedCount = 0
		const complianceRecord = (interventionCompliance.interventionMap[activeIntervention.id] || []).reduce(
			(acc, item) => {
				const day = moment(item.range_start).format('YYYY-MM-DD')
				acc[day] = item.compliance
				if (item.compliance === 'true') {
					compliedCount++
				} else if (item.compliance === 'false') {
					notCompliedCount++
				}
				return acc
			},
			getRange(interventionStart, endStart, 'days').reduce((acc, item) => {
				acc[item.format('YYYY-MM-DD')] = 'unknown'
				return acc
			}, {})
		)

		acc[activeIntervention.id] = {
			total: Object.keys(complianceRecord).length,
			complied: compliedCount,
			notComplied: notCompliedCount,
			unknown: Object.keys(complianceRecord).length - compliedCount - notCompliedCount,
			summary: getComplianceSummary(Object.keys(complianceRecord).length, compliedCount),
		}
		return acc
	}, {})

	useEffect(() => {
		totalInterventions.forEach((interv) => {
			hydrateChildren(interv)
		})
	}, [totalInterventions.length])

	return (
		<Root>
			<AppBar className={classes.titleBar} position="static" color="default" elevation={0}>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs>
							<Typography variant="h5" color="textSecondary" component="h5">
								Active Interventions ({interventions.length})
							</Typography>
						</Grid>
						<Grid item>
							<IconButton color="inherit" onClick={onRefresh} size="large">
								<RefreshIcon className={classes.block} color="inherit" />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			{!loading ? (
				<Grid container spacing={2} direction="row">
					<Grid item xs>
						{interventions.map((intervention, i) => {
							const startDate = moment(intervention.occurred_at).format('YYYY-MM-DD')
							const endDate =
								intervention.completed_at && moment(intervention.completed_at).format('YYYY-MM-DD')
							const progressMade = startDate !== moment().format('YYYY-MM-DD')
							const chartData = []
							if (compliances[intervention.id]) {
								if (compliances[intervention.id].complied) {
									chartData.push({
										color: '#7db586',
										value: (
											compliances[intervention.id].complied / compliances[intervention.id].total
										).toFixed(2),
										name: 'Complied',
									})
								}
								if (compliances[intervention.id].unknown) {
									chartData.push({
										color: '#CACED8',
										value: (
											compliances[intervention.id].unknown / compliances[intervention.id].total
										).toFixed(2),
										name: 'Unknown',
									})
								}
								if (compliances[intervention.id].complied) {
									chartData.push({
										color: '#bf565f',
										value: (
											compliances[intervention.id].notComplied /
											compliances[intervention.id].total
										).toFixed(2),
										name: "Didn't Comply",
									})
								}
							}

							const clinician = clinicians?.find((c) => c.id === intervention?.assigned_by_user_id)

							const Subheader = () => {
								const { assignedBy, assigned_by_user_id, accepted_at, occurred_at, created_at } =
									intervention

								return (
									<span>
										{assigned_by_user_id && !accepted_at ? (
											<>
												<span style={{ fontWeight: 'bold' }}>Status: </span> Pending patient
												acceptance
											</>
										) : (
											<>
												{occurred_at ? (
													<>
														<span style={{ fontWeight: 'bold' }}>Started</span>{' '}
														{moment(occurred_at).format('YYYY-MM-DD')}
													</>
												) : (
													<span style={{ fontWeight: 'bold' }}>Not started</span>
												)}
											</>
										)}
										{assignedBy && (
											<>
												<span style={{ fontWeight: 'bold', marginLeft: 20 }}>Assigned </span>
												{assignedBy?.name && (
													<>
														<span style={{ fontWeight: 'bold' }}>by</span> {assignedBy.name}{' '}
													</>
												)}
												<span>on {moment(created_at).format('YYYY-MM-DD')}</span>
											</>
										)}
									</span>
								)
							}

							const Header = () => {
								return (
									<span style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>
										{intervention.meta.friendlyName}
									</span>
								)
							}

							return (
								<Card className={classes.cardRoot} variant="outlined" key={intervention.id}>
									<CardHeader
										avatar={
											<Avatar
												variant="square"
												className={classes.avatar}
												src={`https://dieta-tracker-media-production.s3-us-west-2.amazonaws.com/public/assets/interventions/${intervention.meta.type}.jpg`}
											/>
										}
										// action={
										// 	compliances[intervention.id] ? (
										// 		<div className={classes.complianceHeader}>
										// 			<Grid container direction="row">
										// 				<Grid
										// 					item
										// 					style={{
										// 						paddingRight: 20,
										// 						height: 40,
										// 						margin: 'auto',
										// 					}}
										// 				>
										// 					<Typography variant="body2" component="p">
										// 						Compliance: {compliances[intervention.id].summary}
										// 					</Typography>
										// 				</Grid>
										// 				<Grid item>
										// 					<PieComplianceChart values={chartData} />
										// 				</Grid>
										// 			</Grid>
										// 		</div>
										// 	) : (
										// 		<></>
										// 	)
										// }
										title={Header()}
										subheader={Subheader()}
									/>

									<CardContent>
										{intervention.creator_id ? (
											<Typography variant="h6" color="textSecondary" component="h6">
												Self Created Experiment:
											</Typography>
										) : (
											<></>
										)}
										<Grid container direction="row">
											<Grid item xs>
												<Typography
													variant="body2"
													color="textSecondary"
													component="p"
													style={{
														display: 'flex',
														justifyContent: 'flex-start',
														alignItems: 'center',
													}}
												>
													{intervention.recommendation}
												</Typography>
											</Grid>
										</Grid>
									</CardContent>
									{progressMade ? (
										<CardContent>
											<Container>
												<Grid container spacing={2} alignItems="center">
													<Grid item xs={12} style={{ marginBottom: 30 }}>
														<SymptomScoresChart
															scores={symptomScore.daysScores}
															range={{
																start: startDate,
																end: endDate || moment().format('YYYY-MM-DD'),
															}}
															regions={(
																interventionCompliance.interventionMap[
																	intervention.id
																] || []
															).reduce((acc, interv) => {
																if (interv.compliance === 'true') {
																	acc[
																		moment(interv.range_start).format('YYYY-MM-DD')
																	] = {
																		range_start: interv.range_start,
																		Compliance: 100,
																	}
																} else if (interv.compliance === 'false') {
																	acc[
																		moment(interv.range_start).format('YYYY-MM-DD')
																	] = {
																		range_start: interv.range_start,
																		'Non-Compliance': 100,
																	}
																}

																return acc
															}, {})}
														/>
													</Grid>
												</Grid>
											</Container>
											<Container>
												<Grid container spacing={2} alignItems="flex-start">
													<Grid item xs={12} sm={6}>
														<Stack spacing={2}>
															<InterventionDSSDetailsCard
																currentIntervention={intervention}
															/>
															<InterventionComplianceDetailsCard
																currentIntervention={intervention}
															/>
														</Stack>
													</Grid>
													<Grid item xs={12} sm={6}>
														<InterventionStoolDetailsCard
															currentIntervention={intervention}
														/>
													</Grid>
												</Grid>
											</Container>
										</CardContent>
									) : (
										<></>
									)}
								</Card>
							)
						})}
					</Grid>
				</Grid>
			) : (
				<Grid container alignItems={'center'} justifyContent="center" className={classes.loadingContainer}>
					<Grid item>
						<CircularProgress disableShrink={true} />
					</Grid>
				</Grid>
			)}
		</Root>
	)
}

export { ActiveInterventionsTable }
