/**
 * CRUD Actions for MEDICATION
 */
// ADD - MEDICATION
export const ADD_MEDICATION_SUCCESS = 'ADD_MEDICATION_SUCCESS'
export const ADD_MEDICATION_FAIL = 'ADD_MEDICATION_FAIL'
export const ADD_MEDICATION = 'ADD_MEDICATION'
// DELETE - MEDICATION
export const DELETE_MEDICATION_SUCCESS = 'DELETE_MEDICATION_SUCCESS'
export const DELETE_MEDICATION_FAIL = 'DELETE_MEDICATION_FAIL'
export const DELETE_MEDICATION = 'DELETE_MEDICATION'
// UPDATE - MEDICATION
export const UPDATE_MEDICATION_SUCCESS = 'UPDATE_MEDICATION_SUCCESS'
export const UPDATE_MEDICATION_FAIL = 'UPDATE_MEDICATION_FAIL'
export const UPDATE_MEDICATION = 'UPDATE_MEDICATION'
// GET ALL - MEDICATION
export const GET_MEDICATIONS_SUCCESS = 'GET_MEDICATIONS_SUCCESS'
export const GET_MEDICATIONS_SUCCESS_COMPLETE = 'GET_MEDICATIONS_SUCCESS_COMPLETE'
export const GET_MEDICATIONS_FAIL = 'GET_MEDICATIONS_FAIL'
export const GET_MEDICATIONS = 'GET_MEDICATIONS'
// ADD - MEDICATION_TYPE
export const ADD_MEDICATION_TYPE_SUCCESS = 'ADD_MEDICATION_TYPE_SUCCESS'
export const ADD_MEDICATION_TYPE_FAIL = 'ADD_MEDICATION_TYPE_FAIL'
export const ADD_MEDICATION_TYPE = 'ADD_MEDICATION_TYPE'
// DELETE - MEDICATION_TYPE
export const DELETE_MEDICATION_TYPE_SUCCESS = 'DELETE_MEDICATION_TYPE_SUCCESS'
export const DELETE_MEDICATION_TYPE_FAIL = 'DELETE_MEDICATION_TYPE_FAIL'
export const DELETE_MEDICATION_TYPE = 'DELETE_MEDICATION_TYPE'
// UPDATE - MEDICATION_TYPE
export const UPDATE_MEDICATION_TYPE_SUCCESS = 'UPDATE_MEDICATION_TYPE_SUCCESS'
export const UPDATE_MEDICATION_TYPE_FAIL = 'UPDATE_MEDICATION_TYPE_FAIL'
export const UPDATE_MEDICATION_TYPE = 'UPDATE_MEDICATION_TYPE'
// GET ALL - MEDICATION_TYPE
export const GET_MEDICATION_TYPES_SUCCESS = 'GET_MEDICATION_TYPES_SUCCESS'
export const GET_MEDICATION_TYPES_FAIL = 'GET_MEDICATION_TYPES_FAIL'
export const GET_MEDICATION_TYPES = 'GET_MEDICATION_TYPES'

/**
 * Current Types
 */
// CURRENT MEDICATION
export const CURRENT_MEDICATION_SET = 'CURRENT_MEDICATION_SET'
export const CURRENT_MEDICATION_CLEAR = 'CURRENT_MEDICATION_CLEAR'
// CURRENT MEDICATION_TYPE
export const CURRENT_MEDICATION_TYPE_ADD = 'CURRENT_MEDICATION_TYPE_ADD'
export const CURRENT_MEDICATION_TYPE_UPDATE = 'CURRENT_MEDICATION_TYPE_UPDATE'
export const CURRENT_MEDICATION_TYPE_REMOVE = 'CURRENT_MEDICATION_TYPE_REMOVE'

export const MEDIA_MEDICATION_SET = 'MEDIA_MEDICATION_SET'

export const RESET_MEDICATION_LOADERS = 'RESET_MEDICATION_LOADERS'

export const MEDICATION_CONTEXT_RESET = 'MEDICATION_CONTEXT_RESET'
