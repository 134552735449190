import {
	getInterventions,
	addIntervention as postIntervention,
	updateIntervention as putIntervention,
	removeIntervention,
	getInterventionsSuperset,
} from 'api/intervention'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { getDefaultOccurredAt } from 'utils/helpers'

const getDefaultIntervention = (attentionDate) => ({
	id: null,
	effect: null,
	duration: null,
	name: '',
	symptom: '',
	recommendation: '',
	occurred_at: getDefaultOccurredAt(attentionDate),
	completed_at: null,
	creator_id: null,
	meta: {
		type: 'lifestyle',
		theme: '#8F82F4',
		pallet: 'dark',
		imgType: 'generic.jpg',
		friendlyName: '',
	},
	_app: {
		uuid: null,
		type: 'intervention',
		synced: false,
	},
})

export const setCurrentIntervention = (intervention, attentionDate) => {
	if (!intervention) {
		intervention = getDefaultIntervention()
	}
	return (dispatch) => {
		dispatch({ type: types.CURRENT_INTERVENTION_SET, payload: intervention })
	}
}

export const clearCurrentIntervention = (intervention = getDefaultIntervention()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_INTERVENTION_SET, payload: intervention })
	}
}

export const fetchInterventions = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_INTERVENTIONS })
		try {
			const res = await getInterventions(args, {
				dispatch,
				event: types.GET_INTERVENTIONS_SUCCESS,
			})
			handleSuccess(dispatch, types.GET_INTERVENTIONS_SUCCESS, res.data)
			return res
		} catch (err) {
			console.log(err)
			handleFail(dispatch, types.GET_INTERVENTIONS_FAIL, err)
		}
	}
	return thunk
}

export const fetchInterventionsSuperset = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_INTERVENTIONS_SUPERSET })
		try {
			const res = await getInterventionsSuperset(args, {
				dispatch,
				event: types.GET_INTERVENTIONS_SUPERSET_SUCCESS,
			})
			handleSuccess(dispatch, types.GET_INTERVENTIONS_SUPERSET_SUCCESS, res.data)
			return res
		} catch (err) {
			console.log(err)
			handleFail(dispatch, types.GET_INTERVENTIONS_SUPERSET_FAIL, err)
		}
	}
	return thunk
}

export const addIntervention = (intervention) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_INTERVENTION, payload: intervention })
		try {
			const res = await postIntervention(intervention)
			const contextResponse = {
				...res.data,
				_app: {
					...intervention._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_INTERVENTION_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...intervention,
				_app: {
					err,
					...intervention._app,
				},
			}
			handleFail(dispatch, types.ADD_INTERVENTION_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateIntervention = (intervention, previousIntervention) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.UPDATE_INTERVENTION,
			payload: { new: intervention, old: previousIntervention },
		})
		try {
			const res = intervention.id ? await putIntervention(intervention) : await postIntervention(intervention)
			const contextResponse = {
				...res.data,
				_app: {
					...intervention._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_INTERVENTION_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...intervention,
				_app: {
					err,
					...intervention._app,
				},
			}
			handleFail(dispatch, types.UPDATE_INTERVENTION_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteIntervention = (intervention) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.DELETE_INTERVENTION, payload: intervention })
		try {
			const res = await removeIntervention(intervention)
			const contextResponse = {
				...res.data,
				_app: {
					...intervention._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_INTERVENTION_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...intervention,
				_app: {
					err,
					...intervention._app,
				},
			}
			handleFail(dispatch, types.DELETE_INTERVENTION_FAIL, contextResponse)
		}
	}
	return thunk
}
