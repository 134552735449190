import * as storage from 'localforage' // defaults to localStorage for web
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import AnalyticsReducer from 'reducers/AnalyticsReducer'
import AuthReducer from 'reducers/AuthReducer'
import NetworkReducer from 'reducers/NetworkReducer'
import SummaryReducer from 'reducers/SummaryReducer'
import SymptomScoreReducer from 'reducers/SymptomScoreReducer'
import ChatReducer from 'reducers/ChatReducer'
import ClinicianReducer from 'reducers/ClinicianReducer'
import PatientReducer from 'reducers/PatientReducer'
import SurveyReducer from 'reducers/SurveyReducer'
import ProgramSurveyReducer from 'reducers/ProgramSurveyReducer'
import SleepReducer from 'reducers/SleepReducer'
import BowelReducer from 'reducers/BowelReducer'
import OtherReducer from 'reducers/OtherReducer'
import ExerciseReducer from 'reducers/ExerciseReducer'
import MoodReducer from 'reducers/MoodReducer'
import NoteReducer from 'reducers/NoteReducer'
import MedicationReducer from 'reducers/MedicationReducer'
import MenstruationReducer from 'reducers/MenstruationReducer'
import SymptomReducer from 'reducers/SymptomReducer'
import InterventionReducer from 'reducers/InterventionReducer'
import MealReducer from 'reducers/MealReducer'
import QuestionReducer from 'reducers/QuestionReducer'
import DeviceReducer from 'reducers/DeviceReducer'
import ProgramReducer from './ProgramReducer'
import AnnotationsReducer from './AnnotationsReducer'

storage.config({
	driver: [storage.INDEXEDDB, storage.WEBSQL, storage.LOCALSTORAGE],
	name: 'dieta',
	storeName: 'portal',
	version: 3,
})

const persistedDeviceReducers = persistReducer(
	{
		key: 'device',
		storage,
		timeout: 0,
	},
	DeviceReducer
)

const combinedReducers = combineReducers({
	device: persistedDeviceReducers,
	analytics: AnalyticsReducer,
	auth: AuthReducer,
	network: NetworkReducer,
	clinician: ClinicianReducer,
	chat: ChatReducer,
	bowel: BowelReducer,
	exercise: ExerciseReducer,
	meal: MealReducer,
	medication: MedicationReducer,
	menstruation: MenstruationReducer,
	mood: MoodReducer,
	note: NoteReducer,
	other: OtherReducer,
	patient: PatientReducer,
	sleep: SleepReducer,
	intervention: InterventionReducer,
	summary: SummaryReducer,
	survey: SurveyReducer,
	program: ProgramReducer,
	programSurvey: ProgramSurveyReducer,
	symptom: SymptomReducer,
	symptomScore: SymptomScoreReducer,
	question: QuestionReducer,
	annotations: AnnotationsReducer,
})

const persistConfig = {
	key: 'primary',
	storage,
	blacklist: ['program'],
	transforms: [],
	timeout: 0,
}

const persistedCombinedReducer = persistReducer(persistConfig, combinedReducers)

const rootReducer = (state, action) => {
	return persistedCombinedReducer(state, action)
}

export { rootReducer, persistConfig, combinedReducers, storage }
