import React from 'react'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'

const PREFIX = 'BadgeAvatars'

const classes = {
	root: `${PREFIX}-root`,
	badgeIndicator: `${PREFIX}-badgeIndicator`,
}

const Root = styled('div')(({ theme, props }) => ({
	[`&.${classes.root}`]: {
		display: 'flex',
		transform: `scale(${props.scale || 1})`,
		'& > *': {
			margin: `calc(${theme.spacing(1)} * ${props.scale})`,
		},
	},
}))

const StyledBadge = styled(Badge)(({ theme, props }) => ({
	['& .MuiBadge-badge']: {
		backgroundColor: '#44b700',
		color: '#44b700',
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		'&::after': {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			borderRadius: '100%',
			animation: `ripple 1.2s infinite ease-in-out`,
			border: '1px solid currentColor',
			content: '""',
		},
	},
	'@keyframes ripple': {
		'0%': {
			transform: 'scale(.8)',
			opacity: 1,
		},
		'100%': {
			transform: 'scale(2.4)',
			opacity: 0,
		},
	},
}))

const SmallAvatar = styled(Avatar)(({ theme }) => ({
	[`&.${classes.root}`]: {
		border: `2px solid ${theme.palette.background.paper}`,
	},
}))

const ActiveBadgeAvatar = (props) => {
	return (
		<Root className={classes.root} props={props}>
			<StyledBadge
				overlap="circular"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				variant="dot"
			>
				<Avatar
					src={props.src}
					imgProps={{
						onError: props.onError,
					}}
				/>
			</StyledBadge>
		</Root>
	)
}

const PictureInPictureAvatar = (props) => {
	return (
		<Root className={classes.root} props={props}>
			<Badge
				overlap="circular"
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				badgeContent={
					<SmallAvatar
						src={props.childSrc}
						style={{
							transform: `scale(.55)`,
						}}
						imgProps={{
							onError: props.onChildError,
						}}
					/>
				}
			>
				<Avatar
					src={props.src}
					imgProps={{
						onError: (e) => {
							props.onError()
						},
					}}
				/>
			</Badge>
		</Root>
	)
}

export { ActiveBadgeAvatar, PictureInPictureAvatar }
