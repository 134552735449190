import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateExhaust, paginateDefaultArgs, checkDeepEqual } from 'api/api_utils'

const formatOtherData = (otherData) => {
	return {
		occurred_at: otherData.occurred_at,
		notes: otherData.notes,
	}
}

const formatOtherTypeData = (otherTypeData) => ({
	name: otherTypeData.name,
})

export const addOther = (otherData) => {
	return axiosInstance
		.request({
			url: 'v1/others',
			source: AxiosAbortController.signal,
			method: 'post',
			data: formatOtherData(otherData),
		})
		.then(async (res) => {
			if (res.data && otherData.types.length > 0) {
				await addOtherTypesToOther(res.data.id, otherData.types)
				return await getOther(res.data.id)
			} else {
				return res
			}
		})
}

export const updateOther = (nextOtherData, previousOtherData) => {
	return axiosInstance
		.request({
			url: `v1/others/${nextOtherData.id}`,
			source: AxiosAbortController.signal,
			method: 'put',
			data: formatOtherData(nextOtherData),
		})
		.then(async (res) => {
			// see if types array has changed
			if (!checkDeepEqual(nextOtherData.types, previousOtherData.types)) {
				for (const type of nextOtherData.types) {
					if (!previousOtherData.types.some((previousType) => previousType.id === type.id)) {
						// new one, add it
						await addOtherTypesToOther(nextOtherData.id, type)
					}
				}

				for (const type of previousOtherData.types) {
					if (!nextOtherData.types.some((nextType) => nextType.id === type.id)) {
						await removeOtherTypeFromOther(nextOtherData.id, type)
					}
				}

				return await getOther(nextOtherData.id)
			} else {
				return res
			}
		})
}

export const removeOther = (otherData) => {
	return axiosInstance.request({
		url: `v1/others/${otherData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const getOther = (otherId) => {
	return axiosInstance.request({
		url: `v1/others/${otherId}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const fetchOthers = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/others',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'others',
		1,
		[],
		reduxEvent
	)
}

export const addOtherType = (otherTypeData) => {
	return axiosInstance.request({
		url: 'v1/otherTypes',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatOtherTypeData(otherTypeData),
	})
}

export const updateOtherType = (otherTypeData) => {
	return axiosInstance.request({
		url: `v1/otherTypes/${otherTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatOtherTypeData(otherTypeData),
	})
}

export const removeOtherType = (otherTypeData) => {
	return axiosInstance.request({
		url: `v1/otherTypes/${otherTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const fetchOtherTypes = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/otherTypes?${queryString}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

const addOtherTypesToOther = (otherId, otherTypesData) => {
	return axiosInstance.request({
		url: `v1/others/${otherId}/types`,
		source: AxiosAbortController.signal,
		method: 'post',
		data: {
			type_id: Array.isArray(otherTypesData)
				? otherTypesData.map((otherType) => otherType.id) // add multiple at once
				: otherTypesData.id, // add one
		},
	})
}

const removeOtherTypeFromOther = (otherId, otherTypeData) => {
	return axiosInstance.request({
		url: `v1/others/${otherId}/types/${otherTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}
