import { logout, getUserProfilePicture, adminLogin, loginWithSocial, loginWithUserPass, resetPassword } from 'api/auth'
import * as actions from 'actions'
import * as types from 'types'
import { configuredStore, storage } from '/src/configureStore'
import jwtDecode from 'jwt-decode'
import { navigate } from 'gatsby'
import { handleSuccess, handleFail } from './z_action_helpers'
import { paths } from 'routes'
import { snackActions } from 'utils/SnackbarUtils'
import moment from 'moment'
import { fetchPrograms } from '../apis/patient'

function timeout(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

export const hasRole = async (roles) => {
	const {
		app_metadata: { roles: userRoles },
	} = configuredStore.store.getState().auth

	return userRoles.some((role) => roles.includes(role))
}

export const hydrateApplication = () => {
	async function thunk(dispatch, getState) {
		await dispatch(actions.getUserPicture())
		const now = new Date().toISOString()
		// const from = moment().subtract(1, 'months').toDate().toISOString()
		const { auth } = getState()
		if (auth?.app_metadata?.roles?.length) {
			dispatch(actions.getPatients())
			dispatch(actions.getUserPrograms())
			dispatch(actions.getClinicalSettings(auth))
		}
		// Fetches all summary data
		await dispatch(
			actions.fetchSummary({
				to: now,
			})
		)

		dispatch(
			actions.fetchSymptomScores({
				to: now,
			})
		)

		dispatch(
			actions.fetchMenstruations({
				to: now,
			})
		)

		// dispatch(
		// 	actions.getBowels({
		// 		from,
		// 		to: now,
		// 	})
		// )

		// dispatch(
		// 	actions.getExercises({
		// 		from,
		// 		to: now,
		// 	})
		// )

		// dispatch(
		// 	actions.getMeals({
		// 		from,
		// 		to: now,
		// 	})
		// )

		// dispatch(
		// 	actions.getMedications({
		// 		from,
		// 		to: now,
		// 	})
		// )

		// dispatch(
		// 	actions.getMoods({
		// 		from,
		// 		to: now,
		// 	})
		// )

		// dispatch(
		// 	actions.getOthers({
		// 		from,
		// 		to: now,
		// 	})
		// )

		// dispatch(
		// 	actions.getSleeps({
		// 		from,
		// 		to: now,
		// 	})
		// )

		// dispatch(
		// 	actions.getSymptoms({
		// 		from,
		// 		to: now,
		// 	})
		// )

		// dispatch(actions.getQuestions())
		// dispatch(actions.getSurveys({ historical: true }))
	}
	return thunk
}

export const callbackLoginUser = (res) => {
	async function thunk(dispatch) {
		await storage.setItem('dieta_i', res.idToken)
		const end = await handleSuccess(dispatch, types.IMPERSONATE_USER_SUCCESS, {
			idToken: res.idToken,
		})
		navigate(paths.diary)
		dispatch(hydrateApplication())
		return end
	}
	return thunk
}

export const loginUser = (social, creds = {}) => {
	async function thunk(dispatch) {
		try {
			await storage.removeItem('persist:primary')
			const request = !social ? loginWithUserPass : loginWithSocial
			let res
			try {
				res = !social ? await request(creds.email.trim(), creds.password) : await request(creds.identifier)
			} catch (err) {
				return handleFail(dispatch, types.LOGIN_USER_FAIL, err)
			}
			await storage.setItem('dieta_r', res.refreshToken)
			await storage.setItem('dieta_a', res.accessToken)
			const end = await handleSuccess(dispatch, types.LOGIN_USER_SUCCESS, res)
			const exists = await hasRole([
				'Coach',
				'Dietician',
				'Gastroenterologist',
				'Nutritionist',
				'Researcher',
				'Admin',
			])
			navigate(exists ? paths.patient : paths.diary)
			dispatch(hydrateApplication())
			return end
		} catch (err) {
			await dispatch(logoutUser(err.message))
			return handleFail(dispatch, types.LOGIN_USER_FAIL, err)
		}
	}
	return thunk
}

export const loginAdminUser = (social, creds) => {
	async function thunk(dispatch) {
		try {
			await storage.removeItem('persist:primary')
			const request = !social ? loginWithUserPass : loginWithSocial
			let res
			try {
				res = !social ? await request(creds.email.trim(), creds.password) : await request(creds.identifier)
			} catch (err) {
				return handleFail(dispatch, types.IMPERSONATE_USER_FAIL, err)
			}
			await storage.setItem('dieta_r', res.refreshToken)
			await storage.setItem('dieta_a', res.accessToken)
			const end = await handleSuccess(dispatch, types.IMPERSONATE_USER, res)
			const uri = await adminLogin(res, creds)
			navigate(uri)
			return end
		} catch (err) {
			console.log(err)
			await dispatch(logoutUser(err.message))
			return handleFail(dispatch, types.LOGIN_USER_FAIL, err)
		}
	}
	return thunk
}

export const resetForgotPassword = (username) => {
	async function thunk(dispatch) {
		try {
			return await resetPassword(username)
		} catch (err) {
			return { err }
		}
	}
	return thunk
}

export const checkToken = () => {
	async function thunk(dispatch, getState) {
		const auth = getState().auth
		if (auth.accessToken && auth.sub) {
			if (moment(auth.expires_at).isBefore()) {
				dispatch({ type: types.WAIT_LOADING_START })
				await dispatch(refreshToken())
				dispatch({ type: types.WAIT_LOADING_STOP })
			}
		}
	}
	thunk.interceptInOffline = true
	thunk.retry = true
	return thunk
}

export const refreshToken = (attempts = 0) => {
	async function thunk(dispatch, getState) {
		const auth = getState().auth
		if (isNaN(attempts)) {
			attempts = 0
		}
		try {
			let storedRefreshToken = await storage.getItem('dieta_r')
			if (!storedRefreshToken) {
				storedRefreshToken = auth.refreshToken
			}

			const requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					client_id: process.env.AUTH0_CLIENT_ID,
					refresh_token: storedRefreshToken,
					grant_type: 'refresh_token',
				}),
			}

			const req = await fetch(`https://${process.env.AUTH0_DOMAIN}/oauth/token`, requestOptions)
			const data = await req.json()
			const identity = jwtDecode(data.id_token)
			if (identity.sub !== auth.sub) {
				await dispatch(logoutUser('Session expired'))
			} else {
				await storage.setItem('dieta_a', data.access_token)
				await handleSuccess(dispatch, 'REFRESH_SESSION_SUCCESS', data)
				return {
					token: data.access_token,
					expires_at: jwtDecode(data.access_token).exp * 1000,
				}
			}
		} catch (err) {
			if (attempts >= 3) {
				await dispatch(logoutUser(err.message))
			} else {
				await timeout(1000 * attempts * 2)
				return await dispatch(actions.refreshToken(++attempts))
			}
		}
	}
	thunk.interceptInOffline = true
	thunk.retry = true
	return thunk
}

export const logoutUser = (message) => {
	async function thunk(dispatch, getState) {
		navigate(paths.login)
		await logout()
		dispatch({ type: types.PURGE })
		dispatch({ type: 'FAST_FORWARD_STORES' })
		// dump persisted redux store
		try {
			if (message) {
				snackActions.error(message)
			}
			await storage.removeItem('persist:primary')
			await storage.removeItem('dieta_a')
			await storage.removeItem('dieta_r')
			await storage.removeItem('dieta_i')
			const end = await handleSuccess(dispatch, types.LOGOUT_USER_SUCCESS, {
				message,
			})
			return end
		} catch (err) {
			console.log(err)
			return handleFail(dispatch, types.LOGOUT_USER_FAIL, err)
		}
	}
	return thunk
}
export const getUserPicture = () => {
	async function thunk(dispatch) {
		const userData = await getUserProfilePicture()
		return await handleSuccess(dispatch, types.GET_USER_PICTURE_SUCCESS, userData)
	}
	return thunk
}

// export const getUser = () => {
// 	async function thunk(dispatch) {
// 		const userData = await getUserData()
// 		return await handleSuccess(dispatch, types.GET_USER_SUCCESS, userData)
// 	}
// 	return thunk
// }
