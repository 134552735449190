import * as types from 'types'

import { storeItemsTransactor, storeItemsMerger, deepMerge } from 'reducers/z_redux_helpers'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	lastFetched: null,
	data: [],
	current: {},
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

const surveyDecorator = (entity, surveyEntitiesMap) => {
	return surveyEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

const surveyUnsyncedDecorator = (entity, surveyEntitiesMap) => {
	return surveyEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// GET SURVEYS
		case types.GET_SURVEYS_SUCCESS:
			const data = action.payload.surveys.map((survey) => {
				return storeItemsTransactor(
					state,
					'data',
					survey,
					{
						id: survey.id,
					},
					surveyDecorator
				)[0].object
			})
			return {
				...state,
				data,
				loading: {
					...state.loading,
					read: false,
				},
				lastFetched: new Date().getTime(),
			}

		case types.GET_SURVEYS_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_SURVEYS:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		case types.ENRICH_SURVEYS_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						surveyDecorator
					)
				),
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.ENRICH_SURVEYS_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.ENRICH_SURVEYS:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		// FINISH SURVEY
		case types.FINISH_SURVEY_SUCCESS:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: false,
				},
			}
		case types.FINISH_SURVEY_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					delete: false,
				},
			}

		case types.FINISH_SURVEY:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: true,
				},
			}

		// CURRENT SURVEY
		case types.CURRENT_SURVEY_SET:
		case types.CURRENT_SURVEY_CLEAR:
			return { ...state, current: action.payload }

		case types.RESET_SURVEY_LOADERS:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		// case 'persist/REHYDRATE':
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.survey,
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		case types.SCENE:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		case types.SURVEY_CONTEXT_RESET:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// DEFAULT
		default:
			return state
	}
}
