export const API = {
	PAGESIZE: 500,
	PAGESLIMIT: 100,
	PAGESLIMIT_MINOR: 2,
}

export const ENTITY_THEME = {
	MEAL: '#b8e9c0',
	MEDICATION: '#9adddd',
	SYMPTOM: '#f3b786',
	BOWEL: '#dd99ab',
	EXERCISE: '#e8f5fb',
	SLEEP: '#ccdbfe',
	MOOD: '#f8ec9f',
	OTHER: '#e5e5ea',
	PROGRAM_SURVEY: '#d5caff',
}

export const DIARY_ENTITY_TYPES = [
	'bowel',
	'exercise',
	'meal',
	'medication',
	'symptom',
	'sleep',
	'other',
	'mood',
	'programSurvey',
]

export const PROGRAM_IDS = {
	METAMUCIL: 2,
	MAYO: 12,
	UMDIET001: 19,
	YAKULT2023: 24,
	CS2586: 17,
}
