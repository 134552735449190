import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import { useTheme } from '@mui/material/styles'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Badge from '@mui/material/Badge'
import moment from 'moment'
import InfiniteCalendar, { Calendar, EVENT_TYPE, withRange } from '@appannie/react-infinite-calendar'

const CalendarWithRange = withRange(Calendar)

const PREFIX = 'StaticDateRangePicker'

const classes = {
	root: `${PREFIX}-root`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		justifyContent: 'center',
	},
}))

const StaticDateRangePicker = (props) => {
	const theme = useTheme()
	return (
		<Root>
			<InfiniteCalendar
				Component={CalendarWithRange}
				className={classes.root}
				width={'100%'}
				height={300}
				theme={{
					accentColor: theme.palette.primary[800],
					floatingNav: {
						background: theme.palette.primary[500],
						chevron: '#FFF',
						color: '#FFF',
					},
					headerColor: theme.palette.primary[500],
					selectionColor: theme.palette.primary[200],
					textColor: {
						active: '#FFF',
						default: '#333',
					},
					weekdayColor: theme.palette.mode === 'dark' ? '#FFF' : 'rgb(146, 118, 255)',
					todayColor: theme.palette.primary[200],
				}}
				selected={{
					start: props.range[0],
					end: props.range[1],
				}}
				locale={{
					headerFormat: 'MMM Do',
				}}
				maxDate={new Date()}
				onSelect={(event) => {
					if (event.eventType === EVENT_TYPE.END) {
						const start = moment(event.start)
						const end = moment(event.end)
						props.onChangedDate(start, event.end ? end : start)
					}
				}}
			/>
		</Root>
	)
}

export { StaticDateRangePicker }
