import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateExhaust } from 'api/api_utils'

export const getAllPrograms = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/programsRaw',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'programs',
		1,
		[],
		reduxEvent
	)
}

export const getProgram = () => {
	return axiosInstance.request({
		url: 'v1/programs',
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const addProgram = (program) => {
	return axiosInstance.request({
		url: 'v1/programs',
		source: AxiosAbortController.signal,
		method: 'post',
		data: program,
	})
}
