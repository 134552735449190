import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react'
import { useTheme } from '@mui/material'

const clockIcon =
	'path://M256,8C119,8,8,119,8,256s111,248,248,248,248-111,248-248S393,8,256,8zm0,448c-110.5,0-200-89.5-200-200S145.5,56,256,56s200,89.5,200,200-89.5,200-200,200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6,5.4-12,12-12h32c6.6,0,12,5.4,12,12v141.7l66.8,48.6c5.4,3.9,6.5,11.4,2.6,16.8L334.6,349c-3.9,5.3-11.4,6.5-16.8,2.6z'
const clockInverted =
	'path://M256,8C393,8,504,119,504,256C504,393,393,504,256,504C119,504,8,393,8,256C8,119,119,8,256,8ZM256,456C366.5,456,456,366.5,456,256C456,145.5,366.5,56,256,56C145.5,56,56,145.5,56,256C56,366.5,145.5,456,256,456ZM194.2,351.6L279.1,289.9C282.2,287.6,284,284,284,280.2V116C284,109.4,278.6,104,272,104H240C233.4,104,228,109.4,228,116V257.7L161.2,306.3C155.8,310.2,154.7,317.7,158.6,323.1L177.4,349C181.3,354.3,188.8,355.5,194.2,351.6Z'
const getOption = (triggers, title, theme, defaultOrder = 'desc', colorRange = ['#D7DA8B', '#E15457']) => {
	let min = 0
	let max = 0
	const triggerData = triggers.reduce((acc, item, index) => {
		if (item.impact < min) {
			min = item.impact
		}
		if (item.impact > max) {
			max = item.impact
		}
		const size = item.lag === '6 Hours' ? 8 : 16
		acc.push({
			index,
			value: item.impact,
			symbolPosition: 'center',
			symbolSize: [size, size],
			name: item.trigger,
			lag: item.lag, //=== '6 Hours' ? 'Short' : 'Long',
			se: item.se,
			pv: item.pv,
		})
		return acc
	}, [])

	triggerData.sort((a, b) => {
		if (defaultOrder === 'desc') {
			return b.value - a.value
		}
		return a.value - b.value
	})

	const triggerNames = triggerData.reduce((acc, item) => {
		acc.push(item.name)
		return acc
	}, [])

	// [min,  Q1,  median (or Q2),  Q3,  max]
	const boxPlotData = triggerData.map((item) => {
		return [item.value - 2 * item.se, item.value, item.value, item.value, item.value + 2 * item.se]
	})

	const option = {
		title: {
			show: false,
			text: title,
			x: 'center',
			top: 20,
			textStyle: {
				color: theme.palette.mode === 'light' ? '#444' : '#fff',
			},
		},
		color: [theme.palette.primary[500]],
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow',
			},
		},
		visualMap: {
			show: false,
			orient: 'horizontal',
			left: 'center',
			min,
			max,

			seriesIndex: 0,
			inRange: {
				color: colorRange,
			},
		},
		tooltip: {
			formatter: (value) => {
				if (value.seriesName === 'Confidence') {
					return null
				}
				return [
					`Trigger: ${value.data.name}`,
					`Time Lag: ${value.data.lag}`,
					`Impact Estimate: ${Math.round(value.data.value)}`,
					`P-value: ${value.data.pv ? value.data.pv.toFixed(4) : 0}`,
				].join('<br />')
			},
		},
		grid: {
			top: '2%',
			left: '3%',
			right: '4%',
			bottom: '3%',
			containLabel: true,
		},
		xAxis: [
			{
				type: 'category',
				data: triggerNames,
				axisLabel: {
					rotate: 45,
					textStyle: {
						color: theme.palette.mode === 'light' ? '#444' : '#fff',
					},
				},

				axisTick: {
					alignWithLabel: true,
				},
			},
		],
		yAxis: [
			{
				type: 'value',
				axisLabel: {
					textStyle: {
						color: theme.palette.mode === 'light' ? '#444' : '#fff',
					},
				},
			},
		],

		series: [
			{
				name: 'Trigger',
				type: 'bar',
				barWidth: '60%',
				data: triggerData,
			},
			// {
			// 	type: 'pictorialBar',
			// 	name: 'pictorial element',
			//   symbol: defaultOrder === 'desc' ? clockIcon : clockInverted,
			// 	z: 10,
			// 	data: triggerData,
			// 	symbolRotate: defaultOrder === 'desc' ? 0 : 180,
			// },
			{
				name: 'Confidence',
				type: 'boxplot',
				boxWidth: ['6', '35%'],
				itemStyle: {
					borderColor: theme.palette.mode === 'light' ? '#444' : '#fff',
					color: theme.palette.mode === 'light' ? '#444' : '#fff',
				},
				tooltop: {
					show: false,
					showContent: false,
				},

				data: boxPlotData,
			},
		],
	}
	return option
}

function SafeWorstBarChart({ triggers, title, defaultOrder, colorRange }) {
	const theme = useTheme()
	return (
		<ReactEcharts
			option={getOption(triggers, title, theme, defaultOrder, colorRange)}
			style={{
				height: '350px',
				width: '100%',
			}}
		/>
	)
}

export { SafeWorstBarChart }
