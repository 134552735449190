import * as types from 'types'

import {
	storeItemsTransactor,
	storeItemsMerger,
	storeChildTypeRanker,
	deepMerge,
	ArrayMerger,
} from 'reducers/z_redux_helpers'

import { v4 as uuidv4 } from 'uuid'
import _groupBy from 'lodash.groupBy'
import _merge from 'lodash.merge'
import moment from 'moment'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	data: [],
	medicationTypes: {
		data: [],
		used: [],
		loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
	},
	daysEvents: {},
	current: {},
	searchCache: {
		medicationTypes: {},
	},
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

// flattens the medication types join model through the medication types model
// entity = new object
// medicationEntitiesMap = stored object (if exists)
const medicationDecorator = (entity, medicationEntitiesMap) => {
	return medicationEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			types: (entity.types && entity.types.length
				? entity.types.map((type) => ({
						id: type.id,
						medication_id: type.MedicationTypesLists ? type.MedicationTypesLists.medication_id : null,
						medication_type_id: type.MedicationTypesLists
							? type.MedicationTypesLists.medication_type_id
							: null,
						name: type.name,
						quantity_num: type.MedicationTypesLists ? type.MedicationTypesLists.quantity_num : null,
						quantity_unit: type.MedicationTypesLists ? type.MedicationTypesLists.quantity_unit : null,
				  }))
				: []
			).map((type) => {
				if (!type._app) {
					type._app = {}
					type._app.uuid = uuidv4()
				}
				return type
			}),
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

const medicationUnsyncedDecorator = (entity, medicationEntitiesMap) => {
	return medicationEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			types: (entity.types || []).map((type) => {
				if (!type._app) {
					type._app = {}
					type._app.uuid = uuidv4()
				}
				return type
			}),
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		return entityMap
	})
}

const medicationTypeDecorator = (entity, medicationTypeEntitiesMap) => {
	return medicationTypeEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

const medicationTypeUnsyncedDecorator = (entity, medicationTypeEntitiesMap) => {
	return medicationTypeEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// GET MEDICATIONS
		case types.GET_MEDICATIONS_SUCCESS:
			let newPayload = ArrayMerger(state.data, [...action.payload.medications, ...state.data])
			return {
				...state,
				data: newPayload,
				medicationTypes: {
					...state.medicationTypes,
					data: storeChildTypeRanker(state.medicationTypes.data, 'types', state.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'quantity_num',
						'quantity_unit',
						'_app',
					]),
					used: newPayload.reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				daysEvents: _merge(
					{},
					state.daysEvents,
					_groupBy(action.payload.medications, (item) => {
						return moment(item.occurred_at).format('YYYY-MM-DD')
					})
				),
			}

		case types.GET_MEDICATIONS_SUCCESS_COMPLETE:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_MEDICATIONS_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		case types.GET_MEDICATIONS:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		// UPDATE MEDICATION
		case types.UPDATE_MEDICATION_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						medicationDecorator
					)
				),

				medicationTypes: {
					...state.medicationTypes,
					data: storeChildTypeRanker(state.medicationTypes.data, 'types', state.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'quantity_num',
						'quantity_unit',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					update: false,
				},
			}
		case types.UPDATE_MEDICATION_FAIL:
			// if (wasOnline) {
			//   ToastHolder.showToast("error").alertWithContext(
			//     "Error",
			//     "Could Not Update Entry!"
			//   );
			// }
			return {
				...state,
				loading: {
					...state.loading,
					update: false,
				},
			}
		case types.UPDATE_MEDICATION:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),
				loading: {
					...state.loading,
					update: true,
				},
			}

		// DELETE MEDICATION
		case types.DELETE_MEDICATION_SUCCESS:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				medicationTypes: {
					...state.medicationTypes,
					used: state.data.reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},

				loading: {
					...state.loading,
					delete: false,
				},
			}

		case types.DELETE_MEDICATION_FAIL:
			// if (wasOnline) {
			//   ToastHolder.showToast("error").alertWithContext(
			//     "Error",
			//     "Could Not Delete Entry!"
			//   );
			// }
			return {
				...state,

				loading: {
					...state.loading,
					delete: false,
				},
			}
		case types.DELETE_MEDICATION:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: true,
				},
			}

		// ADD MEDICATION
		case types.ADD_MEDICATION_SUCCESS:
			// if (wasOnline) {
			//   ToastHolder.showToast("success").alertWithContext(
			//     "Medication Logged!",
			//     ""
			//   );
			// }
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						medicationDecorator
					)
				),
				medicationTypes: {
					...state.medicationTypes,
					data: storeChildTypeRanker(state.medicationTypes.data, 'types', state.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'quantity_num',
						'quantity_unit',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_MEDICATION_FAIL:
			// if (wasOnline) {
			//   ToastHolder.showToast("error").alertWithContext(
			//     "Error",
			//     "Could Not Add Entry!"
			//   );
			// }
			return {
				...state,
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_MEDICATION:
			return {
				...state,
				data: state.data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					create: true,
				},
			}

		// GET MEDICATION TYPES
		case types.GET_MEDICATION_TYPES_SUCCESS:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.medicationTypes.data,
							[
								...action.payload.local.map((local) => {
									local._app = {
										...local._app,
										type: 'local',
									}
									return local
								}),
								...action.payload.community.map((community) => {
									community._app = {
										...community._app,
										type: 'community',
									}
									return community
								}),
							]
								.map((entity) => {
									return storeItemsTransactor(state, 'medicationTypes.data', entity, {
										id: entity.id,
									})
								})
								.reduce((acc, current) => acc.concat(current), [])
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'migration_id', 'quantity_num', 'quantity_unit', '_app']
					),
					loading: {
						...state.medicationTypes.loading,
						read: false,
					},
				},
				searchCache: {
					...state.searchCache,
					medicationTypes: {
						...state.searchCache.medicationTypes,
						[action.meta.like || '']: {
							invoked_at: action.meta.invoked_at,
						},
					},
				},
			}

		case types.GET_MEDICATION_TYPES_FAIL:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,
					loading: {
						...state.medicationTypes.loading,
						read: false,
					},
				},
			}

		case types.GET_MEDICATION_TYPES:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,
					loading: {
						...state.medicationTypes.loading,
						read: true,
					},
				},
			}

		// UPDATE MEDICATION TYPE
		case types.UPDATE_MEDICATION_TYPE_SUCCESS:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.medicationTypes.data,
							storeItemsTransactor(
								state,
								'medicationTypes.data',
								action.payload,
								{
									id: action.payload.id,
								},
								medicationTypeDecorator
							)
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'migration_id', 'quantity_num', 'quantity_unit', '_app']
					),
					loading: {
						...state.medicationTypes.loading,
						update: false,
					},
				},
			}

		case types.UPDATE_MEDICATION_TYPE_FAIL:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,
					loading: {
						...state.medicationTypes.loading,
						update: false,
					},
				},
			}

		case types.UPDATE_MEDICATION_TYPE:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,
					data: state.medicationTypes.data.filter((item) => item.id !== action.payload.id),
					loading: {
						...state.medicationTypes.loading,
						update: true,
					},
				},
			}

		// DELETE MEDICATION TYPE
		case types.DELETE_MEDICATION_TYPE_SUCCESS:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,
					data: state.medicationTypes.data.filter((type) => type.id !== action.payload.id),
					loading: {
						...state.medicationTypes.loading,
						delete: false,
					},
				},
			}

		case types.DELETE_MEDICATION_TYPE_FAIL:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,
					loading: {
						...state.medicationTypes.loading,
						delete: false,
					},
				},
			}

		case types.DELETE_MEDICATION_TYPE:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,
					data: state.medicationTypes.data.filter((item) => item.id !== action.payload.id),
					loading: {
						...state.medicationTypes.loading,
						delete: true,
					},
				},
			}

		// ADD MEDICATION TYPE
		case types.ADD_MEDICATION_TYPE_SUCCESS:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.medicationTypes.data,
							storeItemsTransactor(state, 'medicationTypes.data', action.payload, {
								id: action.payload.id,
							})
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'migration_id', 'quantity_num', 'quantity_unit', '_app']
					),

					loading: {
						...state.medicationTypes.loading,
						create: false,
					},
				},
			}

		case types.ADD_MEDICATION_TYPE_FAIL:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,

					loading: {
						...state.medicationTypes.loading,
						create: false,
					},
				},
			}

		case types.ADD_MEDICATION_TYPE:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,
					data: state.medicationTypes.data.filter((item) => {
						if (action.payload.id) {
							return item.id !== action.payload.id
						} else if (action.payload._app) {
							if (item._app) {
								return item._app.uuid !== action.payload._app.uuid
							}
						}
						return true
					}),

					loading: {
						...state.medicationTypes.loading,
						create: true,
					},
				},
			}

		// CURRENT MEDICATION
		case types.CURRENT_MEDICATION_SET:
			return { ...state, current: action.payload }

		case types.CURRENT_MEDICATION_CLEAR:
			return { ...state, current: action.payload }

		case types.CURRENT_MEDICATION_TYPE_ADD:
			return {
				...state,
				current: {
					...state.current,
					types: [...state.current.types, action.payload],
				},
			}

		case types.CURRENT_MEDICATION_TYPE_UPDATE:
			return {
				...state,
				current: {
					...state.current,
					types: state.current.types.map((type) => {
						if (type.id && action.payload.id) {
							return action.payload.id === type.id
								? {
										...type,
										...action.payload,
								  }
								: type
						}
						if (type._app && action.payload._app) {
							return action.payload._app.uuid === type._app.uuid
								? {
										...type,
										...action.payload,
								  }
								: type
						}
					}),
				},
			}

		case types.CURRENT_MEDICATION_TYPE_REMOVE:
			return {
				...state,
				current: {
					...state.current,
					types: state.current.types.filter((type) => {
						if (type.id && action.payload.id) {
							return type.id !== action.payload.id
						}
						if (type._app && action.payload._app) {
							return type._app.uuid !== action.payload._app.uuid
						}
					}),
				},
			}

		case types.RESET_MEDICATION_LOADERS:
			return {
				...state,
				medicationTypes: {
					...state.medicationTypes,
					loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
				},
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		// case 'persist/REHYDRATE':
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.medication,
		//       medicationTypes: {
		//         ...action.payload.medication.medicationTypes,
		//         loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//       },
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		case types.SCENE:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		case types.MEDICATION_CONTEXT_RESET:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// DEFAULT
		default:
			return state
	}
}
