import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateExhaust, paginateDefaultArgs, checkDeepEqual } from 'api/api_utils'

const formatExerciseData = (exerciseData) => {
	return {
		occurred_at: exerciseData.occurred_at,
		notes: exerciseData.notes,
		duration: exerciseData.duration || 0,
		intensity: exerciseData.intensity,
		types: exerciseData.types,
	}
}

const formatExerciseTypeData = (exerciseTypeData) => ({
	name: exerciseTypeData.name,
})

export const addExercise = (exerciseData) => {
	return axiosInstance
		.request({
			url: 'v1/exercises',
			source: AxiosAbortController.signal,
			method: 'post',
			data: formatExerciseData(exerciseData),
		})
		.then(async (res) => {
			if (res.data && exerciseData.types.length > 0) {
				await addExerciseTypesToExercise(res.data.id, exerciseData.types)
				return await getExercise(res.data.id)
			} else {
				return res
			}
		})
}

export const updateExercise = (nextExerciseData, previousExerciseData) => {
	return axiosInstance
		.request({
			url: `v1/exercises/${nextExerciseData.id}`,
			source: AxiosAbortController.signal,
			method: 'put',
			data: formatExerciseData(nextExerciseData),
		})
		.then(async (res) => {
			// see if types array has changed
			if (!checkDeepEqual(nextExerciseData.types, previousExerciseData.types)) {
				for (const type of nextExerciseData.types) {
					if (!previousExerciseData.types.some((previousType) => previousType.id === type.id)) {
						// new one, add it
						await addExerciseTypesToExercise(nextExerciseData.id, type)
					}
				}

				for (const type of previousExerciseData.types) {
					if (!nextExerciseData.types.some((nextType) => nextType.id === type.id)) {
						await removeExerciseTypeFromExercise(nextExerciseData.id, type)
					}
				}

				return await getExercise(nextExerciseData.id)
			} else {
				return res
			}
		})
}

export const removeExercise = (exerciseData) => {
	return axiosInstance.request({
		url: `v1/exercises/${exerciseData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const getExercise = (exerciseId) => {
	return axiosInstance.request({
		url: `v1/exercises/${exerciseId}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const fetchExercises = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/exercises',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'exercises',
		1,
		[],
		reduxEvent
	)
}

export const addExerciseType = (exerciseTypeData) => {
	return axiosInstance.request({
		url: 'v1/exerciseTypes',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatExerciseTypeData(exerciseTypeData),
	})
}

export const updateExerciseType = (exerciseTypeData) => {
	return axiosInstance.request({
		url: `v1/exerciseTypes/${exerciseTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatExerciseTypeData(exerciseTypeData),
	})
}

export const removeExerciseType = (exerciseTypeData) => {
	return axiosInstance.request({
		url: `v1/exerciseTypes/${exerciseTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const fetchExerciseTypes = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/exerciseTypes?${queryString}`,
		method: 'get',
	})
}

const addExerciseTypesToExercise = (exerciseId, exerciseTypesData) => {
	return axiosInstance.request({
		url: `v1/exercises/${exerciseId}/types`,
		source: AxiosAbortController.signal,
		method: 'post',
		data: {
			type_id: Array.isArray(exerciseTypesData)
				? exerciseTypesData.map((exerciseType) => exerciseType.id) // add multiple at once
				: exerciseTypesData.id, // add one
		},
	})
}

const removeExerciseTypeFromExercise = (exerciseId, exerciseTypeData) => {
	return axiosInstance.request({
		url: `v1/exercises/${exerciseId}/types/${exerciseTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}
