import {
	fetchClinicians,
	addClinician as postClinician,
	updateClinician as putClinician,
	removeClinician,
	getClinicianSettings,
	updateClinicianSettings,
} from 'api/clinician'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'

const getDefaultClinician = () => ({
	_app: {
		uuid: null,
		type: 'clinician',
		synced: false,
	},
})

export const resetClinicianLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_CLINICIAN_LOADERS })
	}
}

export const addClinician = (clinician) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_CLINICIAN, payload: clinician })
		try {
			const res = await postClinician(clinician)
			const contextResponse = {
				...res.data,
				_app: {
					...clinician._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_CLINICIAN_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...clinician,
				_app: {
					err,
					...clinician._app,
				},
			}
			handleFail(dispatch, types.ADD_CLINICIAN_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateClinician = (clinician) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.UPDATE_CLINICIAN, payload: clinician })
		try {
			const res = clinician.id ? await putClinician(clinician) : await postClinician(clinician)
			const contextResponse = {
				...res.data,
				_app: {
					...clinician._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_CLINICIAN_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...clinician,
				_app: {
					err,
					...clinician._app,
				},
			}
			handleFail(dispatch, types.UPDATE_CLINICIAN_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteClinician = (clinician) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.DELETE_CLINICIAN, payload: clinician })
		try {
			const res = await removeClinician(clinician)
			const contextResponse = {
				...res.data,
				_app: {
					...clinician._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_CLINICIAN_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...clinician,
				_app: {
					err,
					...clinician._app,
				},
			}
			handleFail(dispatch, types.DELETE_CLINICIAN_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getClinicians = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_CLINICIANS })
		try {
			if (args && args.enrolling) {
				const res = await fetchClinicians(args)
				handleSuccess(dispatch, types.GET_CLINICIANS_ENROLLING_SUCCESS, res.data)
			} else {
				const res = await fetchClinicians(args)
				handleSuccess(dispatch, types.GET_CLINICIANS_SUCCESS, res.data)
			}
		} catch (err) {
			if (args && args.enrolling) {
				handleFail(dispatch, types.GET_CLINICIANS_ENROLLING_FAIL, err)
			} else {
				handleFail(dispatch, types.GET_CLINICIANS_FAIL, err)
			}
		}
	}
	return thunk
}

export const getClinicalSettings = (auth) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_CLINICIAN_ADMINISTRATION_SETTINGS })
		try {
			const res = await getClinicianSettings({ key: auth.sub })
			handleSuccess(dispatch, types.GET_CLINICIAN_ADMINISTRATION_SETTINGS_SUCCESS, res.data)
		} catch (err) {
			handleFail(dispatch, types.GET_CLINICIAN_ADMINISTRATION_SETTINGS_FAIL, err)
		}
	}
	return thunk
}

export const updateClinicalSettings = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.UPDATE_CLINICIAN_ADMINISTRATION_SETTINGS })
		try {
			const res = await updateClinicianSettings(args)
			handleSuccess(dispatch, types.UPDATE_CLINICIAN_ADMINISTRATION_SETTINGS_SUCCESS, res.data)
		} catch (err) {
			console.log(err)
			handleFail(dispatch, types.UPDATE_CLINICIAN_ADMINISTRATION_SETTINGS_FAIL, err)
		}
	}
	return thunk
}

export const setCurrentClinician = (clinician = getDefaultClinician()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_CLINICIAN_SET, payload: clinician })
	}
}

export const clearCurrentClinician = (clinician = getDefaultClinician()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_CLINICIAN_CLEAR, payload: clinician })
	}
}
