import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateExhaust, paginateDefaultArgs, checkDeepEqual } from 'api/api_utils'

const formatSymptomData = (symptomData) => {
	return {
		notes: symptomData.notes,
		occurred_at: symptomData.occurred_at,
		duration: symptomData.duration || 0,
		types: symptomData.types,
	}
}

const formatSymptomTypeData = (symptomTypeData) => ({
	name: symptomTypeData.name,
	intensity: symptomTypeData.intensity,
})

export const addSymptom = (symptomData) => {
	return axiosInstance
		.request({
			url: 'v1/symptoms',
			source: AxiosAbortController.signal,
			method: 'post',
			data: formatSymptomData(symptomData),
		})
		.then(async (res) => {
			if (res.data && symptomData.types.length > 0) {
				for (const type of symptomData.types) {
					await addSymptomTypeToSymptom(res.data.id, type)
				}
				return await getSymptom(res.data.id)
			} else {
				return res
			}
		})
}

export const updateSymptom = (nextSymptomData, previousSymptomData) => {
	return axiosInstance
		.request({
			url: `v1/symptoms/${nextSymptomData.id}`,
			source: AxiosAbortController.signal,
			method: 'put',
			data: formatSymptomData(nextSymptomData),
		})
		.then(async (res) => {
			// see if types array has changed
			if (!checkDeepEqual(nextSymptomData.types, previousSymptomData.types)) {
				for (const type of nextSymptomData.types) {
					if (!previousSymptomData.types.some((previousType) => previousType.id === type.id)) {
						await addSymptomTypeToSymptom(nextSymptomData.id, type)
					}
				}

				for (const type of previousSymptomData.types) {
					if (!nextSymptomData.types.some((nextType) => nextType.id === type.id)) {
						await removeSymptomTypeFromSymptom(nextSymptomData.id, type)
					}
				}

				for (const type of nextSymptomData.types) {
					const previousVal = previousSymptomData.types.find((previousType) => previousType.id === type.id)
					if (previousVal && previousVal.intensity !== type.intensity) {
						// val changed, update it
						await changeSymptomTypeIntensity(nextSymptomData.id, type)
					}
				}

				return await getSymptom(nextSymptomData.id)
			} else {
				return res
			}
		})
}

export const removeSymptom = (symptomData) => {
	return axiosInstance.request({
		url: `v1/symptoms/${symptomData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const getSymptom = (symptomId) => {
	return axiosInstance.request({
		url: `v1/symptoms/${symptomId}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const fetchSymptoms = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/symptoms',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'symptoms',
		1,
		[],
		reduxEvent
	)
}

export const addSymptomType = (symptomTypeData) => {
	return axiosInstance.request({
		url: 'v1/symptomTypes',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatSymptomTypeData(symptomTypeData),
	})
}

export const updateSymptomType = (symptomTypeData) => {
	return axiosInstance.request({
		url: `v1/symptomTypes/${symptomTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatSymptomTypeData(symptomTypeData),
	})
}

export const removeSymptomType = (symptomTypeData) => {
	return axiosInstance.request({
		url: `v1/symptomTypes/${symptomTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const fetchSymptomTypes = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/symptomTypes?${queryString}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

const changeSymptomTypeIntensity = (symptomId, symptomTypeData) => {
	return axiosInstance.request({
		url: `v1/symptoms/${symptomId}/types/${symptomTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: {
			id: symptomTypeData.id,
			name: symptomTypeData.name,
			intensity: symptomTypeData.intensity,
		},
	})
}

const addSymptomTypeToSymptom = (symptomId, symptomTypeData) => {
	const payloadData = {
		intensity: symptomTypeData.intensity,
	}
	if (symptomTypeData.id) {
		payloadData.type_id = symptomTypeData.id
	} else {
		payloadData.name = symptomTypeData.name
	}
	if (Number.isInteger(payloadData.intensity)) {
		return axiosInstance.request({
			url: `v1/symptoms/${symptomId}/types`,
			source: AxiosAbortController.signal,
			method: 'post',
			data: payloadData,
		})
	}
}

const removeSymptomTypeFromSymptom = (symptomId, symptomTypeData) => {
	return axiosInstance.request({
		url: `v1/symptoms/${symptomId}/types/${symptomTypeData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}
