import * as types from 'types'
import {
	storeItemsTransactor,
	storeItemsMerger,
	storeChildTypeRanker,
	deepMerge,
	ArrayMerger,
} from 'reducers/z_redux_helpers'
import { v4 as uuidv4 } from 'uuid'
import _groupBy from 'lodash.groupBy'
import _merge from 'lodash.merge'
import moment from 'moment'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
	progress: {
		page: 0,
		total: 0,
	},
}

const INITIAL_STATE = {
	data: [],
	_data: [],
	bowelTypes: {
		data: [],
		_data: [],
		used: [],
		loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
	},
	daysEvents: {},
	current: {},
	searchCache: {
		bowelTypes: {},
	},
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}
const bowelDecorator = (entity, bowelEntitiesMap) => {
	return bowelEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			types: (entity.types && entity.types.length
				? entity.types.map((type) => ({
						id: type.id,
						name: type.name,
						meta: type.meta,
						intensity: type.intensity || (type.BowelTypesLists ? type.BowelTypesLists?.intensity : null),
						bowel_id: type.bowel_id || (type.BowelTypesLists ? type.BowelTypesLists?.bowel_id : null),
						bowel_type_id:
							type.bowel_type_id || (type.BowelTypesLists ? type.BowelTypesLists?.bowel_type_id : null),
				  }))
				: []
			).map((type) => {
				if (!type._app) {
					type._app = {}
					type._app.uuid = uuidv4()
				}
				return type
			}),
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

const bowelUnsyncedDecorator = (entity, bowelEntitiesMap) => {
	return bowelEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			types: (entity.types || []).map((type) => {
				if (!type._app) {
					type._app = {}
					type._app.uuid = uuidv4()
				}
				return type
			}),
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		return entityMap
	})
}

const bowelTypeDecorator = (entity, bowelTypeEntitiesMap) => {
	return bowelTypeEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

const bowelTypeUnsyncedDecorator = (entity, bowelTypeEntitiesMap) => {
	return bowelTypeEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE: {
			return JSON.parse(JSON.stringify(INITIAL_STATE))
		}

		// GET BOWELS
		case types.GET_BOWELS_SUCCESS: {
			let newPayload = ArrayMerger(state.data, [...action.payload.bowels, ...state.data])
			return {
				...state,
				data: newPayload,
				bowelTypes: {
					...state.bowelTypes,
					data: storeChildTypeRanker(state.bowelTypes.data, 'types', state.data, [
						'id',
						'name',
						'meta',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: newPayload.reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},

				daysEvents: _merge(
					{},
					state.daysEvents,
					_groupBy(action.payload.bowels, (item) => {
						return moment(item.occurred_at).format('YYYY-MM-DD')
					})
				),
				loading: {
					...state.loading,
					progress: {
						...state.loading.progress,
						page: action.payload.pageNum,
						total: action.payload.pages,
					},
				},
			}
		}

		case types.GET_BOWELS_SUCCESS_COMPLETE: {
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
					progress: JSON.parse(JSON.stringify(FALSY_LOADERS.progress)),
				},
			}
		}

		case types.GET_BOWELS_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.GET_BOWELS: {
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}
		}

		// UPDATE BOWEL
		case types.UPDATE_BOWEL_SUCCESS: {
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						bowelDecorator
					)
				),
				bowelTypes: {
					...state.bowelTypes,
					data: storeChildTypeRanker(state.bowelTypes.data, 'types', state.data, [
						'id',
						'name',
						'meta',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				_data: state._data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					update: false,
				},
			}
		}

		case types.UPDATE_BOWEL_MEDIA_SUCCESS: {
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						bowelDecorator
					)
				),
				_data: state._data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					update: false,
				},
			}
		}

		case types.UPDATE_BOWEL_FAIL: {
			// if (wasOnline) {
			//   ToastHolder.showToast("error").alertWithContext(
			//     "Error",
			//     "Could Not Update Entry!"
			//   );
			// }
			return {
				...state,
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload.error,
						{
							'_app.uuid': action.payload.error._app.uuid,
						},
						bowelUnsyncedDecorator
					)
				),
				loading: {
					...state.loading,
					update: false,
				},
			}
		}

		case types.UPDATE_BOWEL_MEDIA_FAIL: {
			return {
				...state,
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload.error,
						{
							'_app.uuid': action.payload.error._app.uuid,
						},
						bowelUnsyncedDecorator
					)
				),
				loading: {
					...state.loading,
					update: false,
				},
			}
		}

		case types.UPDATE_BOWEL: {
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload.new,
						{
							'_app.uuid': action.payload.new._app.uuid,
						},
						bowelUnsyncedDecorator
					)
				),
				loading: {
					...state.loading,
					update: true,
				},
			}
		}

		// DELETE BOWEL
		case types.DELETE_BOWEL_SUCCESS: {
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				bowelTypes: {
					...state.bowelTypes,
					used: state.data.reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				_data: state._data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					delete: false,
				},
			}
		}

		case types.DELETE_BOWEL_MEDIA_SUCCESS: {
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				_data: state._data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					delete: false,
				},
			}
		}

		case types.DELETE_BOWEL_FAIL: {
			// if (wasOnline) {
			//   ToastHolder.showToast("error").alertWithContext(
			//     "Error",
			//     "Could Not Delete Entry!"
			//   );
			// }
			return {
				...state,
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload.error,
						{
							'_app.uuid': action.payload.error._app.uuid,
						},
						bowelUnsyncedDecorator
					)
				),
				loading: {
					...state.loading,
					delete: false,
				},
			}
		}

		case types.DELETE_BOWEL_MEDIA_FAIL: {
			return {
				...state,
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload.error,
						{
							'_app.uuid': action.payload.error._app.uuid,
						},
						bowelUnsyncedDecorator
					)
				),
				loading: {
					...state.loading,
					delete: false,
				},
			}
		}

		case types.DELETE_BOWEL: {
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload,
						{
							'_app.uuid': action.payload._app.uuid,
						},
						bowelUnsyncedDecorator
					)
				),
				loading: {
					...state.loading,
					delete: true,
				},
			}
		}

		// ADD BOWEL
		case types.ADD_BOWEL_SUCCESS: {
			// if (wasOnline) {
			//   ToastHolder.showToast("success").alertWithContext(
			//     "Bowel Movement Logged!",
			//     ""
			//   );
			// }
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						bowelDecorator
					)
				),
				_data: state._data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				bowelTypes: {
					...state.bowelTypes,
					data: storeChildTypeRanker(state.bowelTypes.data, 'types', state.data, [
						'id',
						'name',
						'meta',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					create: false,
				},
			}
		}

		case types.ADD_BOWEL_MEDIA_SUCCESS: {
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						bowelDecorator
					)
				),
				_data: state._data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					create: false,
				},
			}
		}

		case types.ADD_BOWEL_FAIL: {
			// if (wasOnline) {
			//   ToastHolder.showToast("error").alertWithContext(
			//     "Error",
			//     "Could Not Add Entry!" + action.payload.error?._app?.err
			//   );
			// }
			return {
				...state,
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload.error,
						{
							'_app.uuid': action.payload.error._app.uuid,
						},
						bowelUnsyncedDecorator
					)
				),
				loading: {
					...state.loading,
					create: false,
				},
			}
		}

		case types.ADD_BOWEL_MEDIA_FAIL: {
			return {
				...state,
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload.error,
						{
							'_app.uuid': action.payload.error._app.uuid,
						},
						bowelUnsyncedDecorator
					)
				),
				loading: {
					...state.loading,
					create: false,
				},
			}
		}

		case types.ADD_BOWEL: {
			return {
				...state,
				data: state.data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload,
						{
							'_app.uuid': action.payload._app.uuid,
						},
						bowelUnsyncedDecorator
					)
				),
				loading: {
					...state.loading,
					create: true,
				},
			}
		}

		case types.MEDIA_BOWEL_SET: {
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						bowelDecorator
					)
				),
				current:
					state.current.id === action.payload.id
						? {
								...state.current,
								media: action.payload.media,
						  }
						: state.current,
			}
		}

		case types.MEDIA_BOWEL_ITEM_SET: {
			return {
				...state,
				data: state.data.map((entity) => {
					if (entity.id === action.payload.id) {
						entity.media = entity.media.length
							? entity.media.map((mediaItem) => {
									if (mediaItem.eTag) {
										if (mediaItem.eTag === action.payload.mediaItem.eTag) {
											return action.payload.mediaItem
										}
									} else if (mediaItem.name && action.payload.mediaItem.key) {
										if (action.payload.mediaItem.key.endsWith(mediaItem.name)) {
											return action.payload.mediaItem
										}
									} else if (mediaItem.name && action.payload.mediaItem.name) {
										if (action.payload.mediaItem.name == mediaItem.name) {
											return action.payload.mediaItem
										}
									}
									return mediaItem
							  })
							: [action.payload.mediaItem]
					}
					return entity
				}),
				current:
					state.current.id === action.payload.id
						? {
								...state.current,
								media: state.current.media.length
									? state.current.media.map((mediaItem) => {
											if (mediaItem.eTag) {
												if (mediaItem.eTag === action.payload.mediaItem.eTag) {
													return action.payload.mediaItem
												}
											} else if (mediaItem.name && action.payload.mediaItem.key) {
												if (action.payload.mediaItem.key.endsWith(mediaItem.name)) {
													return action.payload.mediaItem
												}
											} else if (mediaItem.name && action.payload.mediaItem.name) {
												if (action.payload.mediaItem.name == mediaItem.name) {
													return action.payload.mediaItem
												}
											}
											return mediaItem
									  })
									: [action.payload.mediaItem],
						  }
						: state.current,
			}
		}

		case types.MEDIA_BOWEL_ITEM_DELETE: {
			return {
				...state,
				data: state.data.map((entity) => {
					if (entity.id === action.payload.id) {
						entity.media = entity.media.filter((mediaItem) => {
							let isTarget = false
							if (mediaItem.eTag) {
								if (mediaItem.eTag === action.payload.mediaItem.eTag) {
									isTarget = true
								}
							} else if (mediaItem.name && action.payload.mediaItem.key) {
								if (action.payload.mediaItem.key.endsWith(mediaItem.name)) {
									isTarget = true
								}
							} else if (mediaItem.name && action.payload.mediaItem.name) {
								if (action.payload.mediaItem.name == mediaItem.name) {
									isTarget = true
								}
							}
							return !isTarget
						})
					}
					return entity
				}),
				current:
					state.current.id === action.payload.id
						? {
								...state.current,
								media: state.current.media.filter((mediaItem) => {
									const isTarget =
										(mediaItem.eTag && mediaItem.eTag === action.payload.mediaItem.eTag) ||
										mediaItem.key === action.payload.mediaItem.key

									return !isTarget
								}),
						  }
						: state.current,
			}
		}

		// GET BOWEL TYPES
		case types.GET_BOWEL_TYPES_SUCCESS: {
			return {
				...state,
				bowelTypes: {
					...state.bowelTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.bowelTypes.data,
							[
								...action.payload.local.map((local) => {
									local._app = {
										...local._app,
										type: 'local',
									}
									return local
								}),
								...action.payload.community.map((community) => {
									community._app = {
										...community._app,
										type: 'community',
									}
									return community
								}),
							]
								.map((entity) => {
									return storeItemsTransactor(state, 'bowelTypes.data', entity, {
										id: entity.id,
									})
								})
								.reduce((acc, current) => acc.concat(current), [])
						),
						'types',
						state.data,
						['id', 'name', 'meta', 'creator_id', 'migration_id', '_app']
					),
					loading: {
						...state.bowelTypes.loading,
						read: false,
					},
				},
				searchCache: {
					...state.searchCache,
					bowelTypes: {
						...state.searchCache.bowelTypes,
						[action.meta.like || '']: {
							invoked_at: action.meta.invoked_at,
						},
					},
				},
			}
		}

		case types.GET_BOWEL_TYPES_FAIL: {
			return {
				...state,
				bowelTypes: {
					...state.bowelTypes,
					loading: {
						...state.bowelTypes.loading,
						read: false,
					},
				},
			}
		}

		case types.GET_BOWEL_TYPES: {
			return {
				...state,
				bowelTypes: {
					...state.bowelTypes,
					loading: {
						...state.bowelTypes.loading,
						read: true,
					},
				},
			}
		}

		// UPDATE BOWEL TYPE
		case types.UPDATE_BOWEL_TYPE_SUCCESS: {
			return {
				...state,
				bowelTypes: {
					...state.bowelTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.bowelTypes.data,
							storeItemsTransactor(
								state,
								'bowelTypes.data',
								action.payload,
								{
									id: action.payload.id,
								},
								bowelTypeDecorator
							)
						),
						'types',
						state.data,
						['id', 'name', 'meta', 'creator_id', 'migration_id', '_app']
					),
					_data: state.bowelTypes._data.filter((item) => item._app.uuid !== action.payload._app.uuid),
					loading: {
						...state.bowelTypes.loading,
						update: false,
					},
				},
			}
		}

		case types.UPDATE_BOWEL_TYPE_FAIL: {
			return {
				...state,
				bowelTypes: {
					...state.bowelTypes,
					_data: storeItemsMerger(
						state.bowelTypes.data,
						storeItemsTransactor(
							state,
							'bowelTypes.data',
							action.payload,
							{
								id: action.payload.id,
							},
							bowelTypeDecorator
						)
					),
					loading: {
						...state.bowelTypes.loading,
						update: false,
					},
				},
			}
		}

		case types.UPDATE_BOWEL_TYPE: {
			return {
				...state,
				bowelTypes: {
					...state.bowelTypes,
					data: state.bowelTypes.data.filter((item) => item.id !== action.payload.id),
					_data: storeItemsMerger(
						state.bowelTypes._data,
						storeItemsTransactor(
							state,
							'bowelTypes._data',
							action.payload,
							{
								'_app.uuid': action.payload._app.uuid,
							},
							bowelTypeUnsyncedDecorator
						)
					),
					loading: {
						...state.bowelTypes.loading,
						update: true,
					},
				},
			}
		}

		// DELETE BOWEL TYPE
		case types.DELETE_BOWEL_TYPE_SUCCESS: {
			return {
				...state,
				bowelTypes: {
					...state.bowelTypes,
					data: state.bowelTypes.data.filter((type) => type.id !== action.payload.id),
					_data: state.bowelTypes._data.filter((item) => item._app.uuid !== action.payload._app.uuid),
					loading: {
						...state.bowelTypes.loading,
						delete: false,
					},
				},
			}
		}

		case types.DELETE_BOWEL_TYPE_FAIL: {
			return {
				...state,
				bowelTypes: {
					...state.bowelTypes,
					_data: storeItemsMerger(
						state.bowelTypes._data,
						storeItemsTransactor(
							state,
							'bowelTypes._data',
							action.payload.error,
							{
								'_app.uuid': action.payload.error._app.uuid,
							},
							bowelTypeUnsyncedDecorator
						)
					),
					loading: {
						...state.bowelTypes.loading,
						delete: false,
					},
				},
			}
		}

		case types.DELETE_BOWEL_TYPE: {
			return {
				...state,
				bowelTypes: {
					...state.bowelTypes,
					data: state.bowelTypes.data.filter((item) => item.id !== action.payload.id),
					_data: storeItemsMerger(
						state.bowelTypes._data,
						storeItemsTransactor(
							state,
							'bowelTypes._data',
							action.payload,
							{
								'_app.uuid': action.payload._app.uuid,
							},
							bowelTypeUnsyncedDecorator
						)
					),
					loading: {
						...state.bowelTypes.loading,
						delete: true,
					},
				},
			}
		}

		// ADD BOWEL TYPE
		case types.ADD_BOWEL_TYPE_SUCCESS: {
			return {
				...state,
				bowelTypes: {
					...state.bowelTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.bowelTypes.data,
							storeItemsTransactor(state, 'bowelTypes.data', action.payload, {
								id: action.payload.id,
							})
						),
						'types',
						state.data,
						['id', 'name', 'meta', 'creator_id', 'migration_id', '_app']
					),
					_data: state.bowelTypes._data.filter((item) => item._app.uuid !== action.payload._app.uuid),
					loading: {
						...state.bowelTypes.loading,
						create: false,
					},
				},
			}
		}

		case types.ADD_BOWEL_TYPE_FAIL: {
			return {
				...state,
				bowelTypes: {
					...state.bowelTypes,
					_data: storeItemsMerger(
						state.bowelTypes._data,
						storeItemsTransactor(
							state,
							'bowelTypes._data',
							action.payload.error,
							{
								'_app.uuid': action.payload.error._app.uuid,
							},
							bowelTypeUnsyncedDecorator
						)
					),
					loading: {
						...state.bowelTypes.loading,
						create: false,
					},
				},
			}
		}

		case types.ADD_BOWEL_TYPE: {
			return {
				...state,
				bowelTypes: {
					...state.bowelTypes,
					data: state.bowelTypes.data.filter((item) => {
						if (action.payload.id) {
							return item.id !== action.payload.id
						} else if (action.payload._app) {
							if (item._app) {
								return item._app.uuid !== action.payload._app.uuid
							}
						}
						return true
					}),
					_data: storeItemsMerger(
						state.bowelTypes._data,
						storeItemsTransactor(
							state,
							'bowelTypes._data',
							action.payload,
							{
								'_app.uuid': action.payload._app.uuid,
							},
							bowelTypeUnsyncedDecorator
						)
					),
					loading: {
						...state.bowelTypes.loading,
						create: true,
					},
				},
			}
		}

		// CURRENT BOWEL
		case types.CURRENT_BOWEL_UPDATE: {
			return {
				...state,
				current: {
					...state.current,
					...action.payload,
				},
			}
		}

		case types.CURRENT_BOWEL_SET: {
		}
		case types.CURRENT_BOWEL_CLEAR: {
			return { ...state, current: action.payload }
		}

		case types.CURRENT_BOWEL_TYPE_ADD: {
			return {
				...state,
				current: {
					...state.current,
					types: [...state.current.types, action.payload],
				},
			}
		}

		case types.CURRENT_BOWEL_TYPE_REMOVE: {
			return {
				...state,
				current: {
					...state.current,
					types: state.current.types.filter((type) => {
						if (type.id) {
							return type.id !== action.payload.id
						}
						if (type._app && action.payload._app) {
							return type._app.uuid !== action.payload._app.uuid
						}
					}),
				},
			}
		}

		case types.RESET_BOWEL_LOADERS: {
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}
		}
		// case 'persist/REHYDRATE': {
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.bowel,
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		case 'FAST_FORWARD_STORES': {
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)
		}

		case types.SCENE: {
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}
		}

		case types.BOWEL_CONTEXT_RESET: {
			return JSON.parse(JSON.stringify(INITIAL_STATE))
		}

		// DEFAULT
		default: {
			return state
		}
	}
}
