import * as types from 'types'
import {
	storeItemsTransactor,
	storeItemsMerger,
	entityInterceptEnqueue,
	deepMerge,
} from '/src/reducers/z_redux_helpers'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	available: [],
	data: [],
	current: {},
	admin: {},
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

const clinicianDecorator = (entity, clinicianEntitiesMap) => {
	return clinicianEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

const clinicianUnsyncedDecorator = (entity, clinicianEntitiesMap) => {
	return clinicianEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// GET CLINICIANS
		case types.GET_CLINICIANS_SUCCESS:
			return {
				...state,
				data: action.payload.clinicians
					.map((clinician) => {
						return storeItemsTransactor(
							state,
							'data',
							clinician,
							{
								id: clinician.id,
							},
							clinicianDecorator
						)[0].object
					})
					.filter((clinician) => {
						return clinician.name
					}),
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_CLINICIANS_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_CLINICIANS:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		case types.GET_CLINICIANS_ENROLLING_SUCCESS:
			return {
				...state,
				available: action.payload.clinicians
					.map((clinician) => {
						return storeItemsTransactor(
							state,
							'available',
							clinician,
							{
								id: clinician.id,
							},
							clinicianDecorator
						)[0].object
					})
					.filter((clinician) => {
						return clinician.name
					}),
				loading: {
					...state.loading,
					read: false,
				},
			}
		case types.GET_CLINICIANS_ENROLLING_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		case types.GET_CLINICIANS_ENROLLING:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		// UPDATE CLINICIAN
		case types.UPDATE_CLINICIAN_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						clinicianDecorator
					)
				),
				loading: {
					...state.loading,
					update: false,
				},
			}
		case types.UPDATE_CLINICIAN_FAIL:
			return {
				...state,

				loading: {
					...state.loading,
					update: false,
				},
			}
		case types.UPDATE_CLINICIAN:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					update: true,
				},
			}

		// DELETE CLINICIAN
		case types.DELETE_CLINICIAN_SUCCESS:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: false,
				},
			}
		case types.DELETE_CLINICIAN_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					delete: false,
				},
			}

		case types.DELETE_CLINICIAN:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: true,
				},
			}

		// ADD CLINICIAN
		case types.ADD_CLINICIAN_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						clinicianDecorator
					)
				),
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_CLINICIAN_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_CLINICIAN:
			return {
				...state,
				loading: {
					...state.loading,
					create: true,
				},
			}

		// CURRENT CLINICIAN
		case types.CURRENT_CLINICIAN_SET:
		case types.CURRENT_CLINICIAN_CLEAR:
			return { ...state, current: action.payload }

		case types.RESET_CLINICIAN_LOADERS:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		// case 'persist/REHYDRATE':
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.clinician,
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		// UPDATE CLINICIAN ADMIN
		case types.UPDATE_CLINICIAN_ADMINISTRATION_SETTINGS_SUCCESS:
			return {
				...state,
				admin: action.payload,
			}

		case types.GET_CLINICIAN_ADMINISTRATION_SETTINGS_SUCCESS:
			return {
				...state,
				admin: action.payload,
			}

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		case types.SCENE:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		case types.CLINICIAN_CONTEXT_RESET:
			return { ...JSON.parse(JSON.stringify(INITIAL_STATE)), admin: state.admin }

		// DEFAULT
		default:
			return state
	}
}
