import {
	getSymptomScores,
	addSymptomScore as postSymptomScore,
	updateSymptomScore as putSymptomScore,
} from 'api/symptomScore'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'

export const fetchSymptomScores = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_SYMPTOM_SCORES })
		try {
			const res = await getSymptomScores(args, {
				dispatch,
				event: types.GET_SYMPTOM_SCORES_SUCCESS,
			})
			handleSuccess(dispatch, types.GET_SYMPTOM_SCORES_SUCCESS, res.data)
			return res
		} catch (err) {
			handleFail(dispatch, types.GET_SYMPTOM_SCORES_FAIL, err)
		}
	}
	return thunk
}

export const addSymptomScore = (symptomScore) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_SYMPTOM_SCORE, payload: symptomScore })
		try {
			const res = await postSymptomScore(symptomScore)
			const contextResponse = {
				...res.data,
				_app: {
					...symptomScore._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_SYMPTOM_SCORE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...symptomScore,
				_app: {
					err,
					...symptomScore._app,
				},
			}
			handleFail(dispatch, types.ADD_SYMPTOM_SCORE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateSymptomScore = (symptomScore, previousSymptomScore) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.UPDATE_SYMPTOM_SCORE,
			payload: { new: symptomScore, old: previousSymptomScore },
		})
		try {
			const res = symptomScore.id ? await putSymptomScore(symptomScore) : await postSymptomScore(symptomScore)
			const contextResponse = {
				...res.data,
				_app: {
					...symptomScore._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_SYMPTOM_SCORE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...symptomScore,
				_app: {
					err,
					...symptomScore._app,
				},
			}
			handleFail(dispatch, types.UPDATE_SYMPTOM_SCORE_FAIL, contextResponse)
		}
	}
	return thunk
}
