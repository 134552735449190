import {
	getMenstruations,
	addMenstruation as postMenstruation,
	updateMenstruation as putMenstruation,
} from 'api/menstruation'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'

export const fetchMenstruations = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_MENSTRUATIONS })
		try {
			const res = await getMenstruations(args, {
				dispatch,
				event: types.GET_MENSTRUATIONS_SUCCESS,
			})
			handleSuccess(dispatch, types.GET_MENSTRUATIONS_SUCCESS, res.data)
			return res
		} catch (err) {
			console.log(err)
			handleFail(dispatch, types.GET_MENSTRUATIONS_FAIL, err)
		}
	}
	return thunk
}

export const addMenstruation = (menstruation) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_MENSTRUATION, payload: menstruation })
		try {
			const res = await postMenstruation(menstruation)
			const contextResponse = {
				...res.data,
				_app: {
					...menstruation._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_MENSTRUATION_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...menstruation,
				_app: {
					err,
					...menstruation._app,
				},
			}
			handleFail(dispatch, types.ADD_MENSTRUATION_FAIL, contextResponse)
		}
	}

	return thunk
}

export const updateMenstruation = (menstruation, previousMenstruation) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.UPDATE_MENSTRUATION,
			payload: { new: menstruation, old: previousMenstruation },
		})
		try {
			const res = menstruation.id ? await putMenstruation(menstruation) : await postMenstruation(menstruation)
			const contextResponse = {
				...res.data,
				_app: {
					...menstruation._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_MENSTRUATION_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...menstruation,
				_app: {
					err,
					...menstruation._app,
				},
			}
			handleFail(dispatch, types.UPDATE_MENSTRUATION_FAIL, contextResponse)
		}
	}

	return thunk
}
