import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useTranslation } from 'react-i18next'

const PREFIX = 'RoleCard'

const classes = {
	card: `${PREFIX}-card`,
	drawer: `${PREFIX}-drawer`,
	panel: `${PREFIX}-panel`,
	appContent: `${PREFIX}-appContent`,
	mainContent: `${PREFIX}-mainContent`,
}

const Root = styled(Card)(({ theme }) => ({
	[`&.${classes.card}`]: {
		width: '100%',
		// maxWidth: 500,
		borderRadius: theme.spacing(2), // 16px
		transition: '0.3s',
		boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
		position: 'relative',
		overflow: 'initial',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		paddingLeft: 8,
		paddingRight: 8,
		background: 'linear-gradient(34deg, rgba(55,16,83,1) 0%, rgba(162,73,190,1) 29%, rgba(33,16,83,1) 92%)',
		[theme.breakpoints.up('sm')]: {
			textAlign: 'left',
			flexDirection: 'row-reverse',
		},
	},
	[`& .${classes.mediaContainer}`]: {
		padding: 10,
	},
	[`& .${classes.media}`]: {
		flexShrink: 0,
		transform: `scale(.7)`,
		width: '25%',
		paddingTop: '25%',
		marginLeft: 'auto',
		marginRight: 'auto',
		[theme.breakpoints.up('sm')]: {
			marginRight: 'initial',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(4),
		},
	},
	[`& .${classes.overline}`]: {
		lineHeight: 2,
		color: '#ffffff',
		fontWeight: 'bold',
		fontSize: '0.625rem',
		opacity: 0.7,
	},
	[`& .${classes.heading}`]: {
		fontWeight: '900',
		color: '#ffffff',
		letterSpacing: 0.5,
	},
	[`& .${classes.button}`]: {
		display: 'block',
		marginTop: 10,
		color: theme.palette.common.white,
	},
}))
const roleMedias = {
	Admin: '/svgs/card/admin_logo.svg',
	Dietitian: '/svgs/card/dietitian_logo.svg',
	Gastroenterologist: '/svgs/card/gastroenterologist_logo.svg',
}

const RoleCard = ({ clinician, title, bgSrc = 'https://jkkm.info/ui/images/awards/victory.png', toggleVisibility }) => {
	const { t } = useTranslation()
	if (clinician.title) {
		bgSrc = roleMedias[clinician.title]
	}

	const handleChange = (event) => {
		toggleVisibility({ key: clinician.key, is_enrolling: event.target.checked })
	}

	return (
		<Root className={classes.card}>
			{/* <div className={classes.mediaContainer}>
				
			</div> */}
			<CardMedia className={classes.media} image={bgSrc} />
			<CardContent className={classes.content}>
				<Typography className={classes.heading} variant={'h6'}>
					{title}
				</Typography>
				<Typography className={classes.overline} variant={'overline'} gutterBottom>
					{clinician.title}
				</Typography>
				{/* <Button className={classes.button}>View Winners</Button> */}
				<FormControlLabel
					className={classes.button}
					control={
						<Switch
							checked={!!clinician.is_enrolling}
							color="primary"
							onChange={handleChange}
							name="enrolling"
						/>
					}
					label={
						clinician.is_enrolling
							? `${t('components.role_card.enrolling')}`
							: `${t('components.role_card.not_enrolling')}`
					}
				/>
			</CardContent>
		</Root>
	)
}

export { RoleCard }
