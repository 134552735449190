import React, { Component, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { EnumerateDaysBetweenDates } from 'utils/helpers'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import { std } from 'mathjs'

import Great from 'images/emotions/grin.png'

const PREFIX = 'InterventionDSSDetailsCard'

const classes = {
	root: `${PREFIX}-root`,
	emoji: `${PREFIX}-emoji`,
	tableTitle: `${PREFIX}-tableTitle`,
}

const Root = styled(Paper)(({ theme }) => ({
	[`&.${classes.root}`]: {},
	[`& .${classes.emoji}`]: {
		height: 30,
		width: 30,
		verticalAlign: 'middle',
	},
	[`& .${classes.tableTitle}`]: {
		fontWeight: 'bold',
	},
}))

class InterventionDSSDetailsCard extends Component {
	constructor(props) {
		super(props)
	}

	static getDerivedStateFromProps(props, state) {
		const scopedDays = EnumerateDaysBetweenDates(
			props.currentIntervention.occurred_at,
			props.currentIntervention.completed_at
		)

		const dss = scopedDays.reduce(
			(acc, day) => {
				if (props.symptomScore.daysScores[day]) {
					acc.total += props.symptomScore.daysScores[day][0].score
					acc.data.push(props.symptomScore.daysScores[day][0].score)
				}
				return acc
			},
			{
				total: 0,
				data: [],
			}
		)

		const results = [
			{
				name: 'Symptom Score',
				average: dss.data.length ? (dss.total / dss.data.length).toFixed(2) : 0,
				total: dss.data.length,
				std: dss.data.length ? std(dss.data).toFixed(2) : 0,
			},
		]

		return {
			...state,
			conclusions: results,
		}
	}

	state = {
		conclusions: [],
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		return true
	}
	componentDidMount = () => {}

	render() {
		return (
			<Root className={classes.root}>
				<AppBar className={classes.titleBar} position="static" color="default" elevation={1}>
					<Toolbar>
						<Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
							<Grid item>
								<img src={Great} className={classes.emoji} />
							</Grid>
							<Grid item>
								<Typography variant="body1" color="textSecondary" className={classes.tableTitle}>
									Well-being
								</Typography>
							</Grid>
							<Grid item xs>
								{!!this.props.symptomScore.loading.read && (
									// <CircularProgress
									// 	color="inherit"
									// 	disableShrink={true}
									// 	style={{ width: 20, height: 20 }}
									// />
									<LinearProgress color="inherit" disableShrink={true} />
								)}
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>

				<Grid container>
					<TableContainer component={'div'}>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>Characteristic</TableCell>
									<TableCell align="right">Average</TableCell>
									<TableCell align="right">Samples</TableCell>
									<TableCell align="right">Std.</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.state.conclusions.map((characteristic) => {
									return (
										<TableRow
											key={characteristic.name}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell
												component="th"
												scope="row"
												style={{
													textTransform: 'capitalize',
												}}
											>
												{characteristic.name}
											</TableCell>
											<TableCell align="right">{characteristic.average}</TableCell>
											<TableCell align="right">{characteristic.total}</TableCell>
											<TableCell align="right">{characteristic.std}</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Root>
		)
	}
}

InterventionDSSDetailsCard.propTypes = {
	symptomScore: PropTypes.shape({
		data: PropTypes.array,
		daysScores: PropTypes.object,
	}),
	intervention: PropTypes.shape({
		data: PropTypes.array,
		interventionCompliance: PropTypes.shape({
			interventionMap: PropTypes.object.isRequired,
			daysCompliance: PropTypes.object.isRequired,
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
	fetchStoolLabels: PropTypes.func.isRequired,
	getBowels: PropTypes.func.isRequired,
}

const mapStateToProps = ({ symptomScore, intervention }) => {
	return {
		symptomScore,
		intervention,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(InterventionDSSDetailsCard)
