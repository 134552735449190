import * as types from 'types'
import {
	storeItemsTransactor,
	storeItemsMerger,
	entityInterceptEnqueue,
	deepMerge,
	ArrayMerger,
} from 'reducers/z_redux_helpers'
import { v4 as uuidv4 } from 'uuid'
import _groupBy from 'lodash.groupBy'
import { daysScoresMerger } from 'reducers/z_summary_helpers'
import moment from 'moment'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	data: [],
	_data: [],
	daysMenstruations: {},
	current: {},
	lastFetched: null,
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

// flattens the symptom scores join model through the symptom scores model
// entity = new object
// symtomScoreEntitiesMap = stored object (if exists)
const menstruationDecorator = (entity, symtomEntitiesMap) => {
	return symtomEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
			draft: false,
		}
		return entityMap
	})
}

const menstruationUnsyncedDecorator = (entity, menstruationEntitiesMap) => {
	return menstruationEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
			draft: false,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	const wasOffline = action && action.meta && action.meta.offline
	const wasOnline = !wasOffline

	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// GET MENSTRUATIONS
		case types.GET_MENSTRUATIONS_SUCCESS:
			let newPayload = ArrayMerger(state.data, [...action.payload.menstruations, ...state.data])
			return {
				...state,
				data: newPayload,
				daysMenstruations: _groupBy(action.payload.menstruations, (item) => {
					return moment(item.range_start).format('YYYY-MM-DD')
				}),
				lastFetched: new Date().getTime(),
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_MENSTRUATIONS_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_MENSTRUATIONS:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		// UPDATE MENSTRUATION
		case types.UPDATE_MENSTRUATION_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						menstruationDecorator
					)
				),
				_data: state._data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				lastFetched: new Date().getTime(),
				daysMenstruations: daysScoresMerger(state.daysMenstruations, action.payload, true),
				loading: {
					...state.loading,
					update: false,
				},
			}

		case types.UPDATE_MENSTRUATION_FAIL:
			return {
				...state,
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload.error,
						{
							'_app.uuid': action.payload.error._app.uuid,
						},
						menstruationUnsyncedDecorator
					)
				),
				daysMenstruations: daysScoresMerger(state.daysMenstruations, action.payload, true),
				loading: {
					...state.loading,
					update: false,
				},
			}

		case types.UPDATE_MENSTRUATION:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload.new,
						{
							'_app.uuid': action.payload.new._app.uuid,
						},
						menstruationUnsyncedDecorator
					)
				),
				daysMenstruations: daysScoresMerger(state.daysMenstruations, action.payload, false),
				loading: {
					...state.loading,
					update: true,
				},
			}

		// ADD MENSTRUATION
		case types.ADD_MENSTRUATION_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						menstruationDecorator
					)
				),
				_data: state._data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				lastFetched: new Date().getTime(),
				daysMenstruations: daysScoresMerger(state.daysMenstruations, action.payload, true),
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_MENSTRUATION_FAIL:
			return {
				...state,
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload.error,
						{
							'_app.uuid': action.payload.error._app.uuid,
						},
						menstruationUnsyncedDecorator
					)
				),
				daysMenstruations: daysScoresMerger(state.daysMenstruations, action.payload, true),
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_MENSTRUATION:
			return {
				...state,
				data: state.data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				_data: storeItemsMerger(
					state._data,
					storeItemsTransactor(
						state,
						'_data',
						action.payload,
						{
							'_app.uuid': action.payload._app.uuid,
						},
						menstruationUnsyncedDecorator
					)
				),
				daysMenstruations: daysScoresMerger(state.daysMenstruations, action.payload, false),
				loading: {
					...state.loading,
					create: true,
				},
			}

		case types.RESET_MENSTRUATION_LOADERS:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		// case 'persist/REHYDRATE':
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.menstruation,
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		case types.MENSTRUATION_CONTEXT_RESET:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// DEFAULT
		default:
			return state
	}
}
