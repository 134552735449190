import {
	fetchMeals,
	addMeal as postMeal,
	updateMeal as putMeal,
	removeMeal,
	fetchMealItems,
	addMealItem as postMealItem,
	updateMealItem as putMealItem,
	removeMealItem,
	fetchMealIngredients,
	addMealIngredient as postMealIngredient,
	updateMealIngredient as putMealIngredient,
	removeMealIngredient,
} from 'api/meal'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { getDefaultOccurredAt } from 'utils/helpers'
import { mediaEntityDecorator } from 'api/api_utils'

export const getDefaultMeal = (attentionDate) => ({
	notes: null,
	name: null,
	occurred_at: getDefaultOccurredAt(attentionDate),
	items: [],
	media: [],
	uuid: null,
	_app: {
		uuid: null,
		type: 'meal',
		synced: false,
	},
})

export const resetMealLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_MEAL_LOADERS })
	}
}

export const addMeal = (rawMeal) => {
	async function thunk(dispatch, getState) {
		const meal = mediaEntityDecorator(rawMeal)
		dispatch({ type: types.ADD_MEAL, payload: meal })
		try {
			const res = await postMeal(meal)
			const contextResponse = {
				...res.data,
				media: meal.media,
				_app: {
					...meal._app,
					...res.data._app,
				},
			}
			const hyperMeal = await handleSuccess(dispatch, types.ADD_MEAL_SUCCESS, contextResponse)
			if (hyperMeal.uuid) {
				// const mediaObject = await dispatch(
				//   actions.writeMediaBackground(hyperMeal, 'meals'),
				// );
				// if (mediaObject._app.err) {
				// 	dispatch({
				// 		type: types.ADD_MEAL_MEDIA_FAIL,
				// 		payload: { error: mediaObject },
				// 	})
				// }
			}

			return hyperMeal
		} catch (err) {
			const contextResponse = {
				...meal,
				_app: {
					err,
					...meal._app,
				},
			}
			handleFail(dispatch, types.ADD_MEAL_FAIL, contextResponse)
		}
	}

	return thunk
}

export const updateMeal = (rawMeal, previousMeal) => {
	async function thunk(dispatch, getState) {
		const meal = mediaEntityDecorator(rawMeal)
		dispatch({
			type: types.UPDATE_MEAL,
			payload: {
				new: meal,
				old: previousMeal,
			},
		})
		try {
			const res = meal.id ? await putMeal(meal, previousMeal) : await postMeal(meal)
			const contextResponse = {
				...res.data,
				media: meal.media,
				_app: {
					...meal._app,
					...res.data._app,
				},
			}
			const hyperMeal = await handleSuccess(dispatch, types.UPDATE_MEAL_SUCCESS, contextResponse)
			if (hyperMeal.uuid) {
				// const mediaObject = await dispatch(
				//   actions.writeMediaBackground(hyperMeal, 'meals'),
				// );
				// if (mediaObject._app.err) {
				// 	dispatch({
				// 		type: types.ADD_MEAL_MEDIA_FAIL,
				// 		payload: { error: mediaObject },
				// 	})
				// }
			}

			return hyperMeal
		} catch (err) {
			const contextResponse = {
				...meal,
				_app: {
					err,
					...meal._app,
				},
			}
			handleFail(dispatch, types.UPDATE_MEAL_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteMeal = (meal) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.DELETE_MEAL, payload: meal })
		try {
			const res = await removeMeal(meal)
			const contextResponse = {
				...res.data,
				_app: {
					...meal._app,
					...res.data._app,
				},
			}
			const hyperMeal = await handleSuccess(dispatch, types.DELETE_MEAL_SUCCESS, contextResponse)
			if (hyperMeal.uuid) {
				// const mediaObject = await dispatch(actions.removeMedia(hyperMeal, 'meals'))
				// if (mediaObject._app.err) {
				// 	dispatch({
				// 		type: types.ADD_MEAL_MEDIA_FAIL,
				// 		payload: { error: mediaObject },
				// 	})
				// }
			}

			return hyperMeal
		} catch (err) {
			const contextResponse = {
				...meal,
				_app: {
					err,
					...meal._app,
				},
			}
			handleFail(dispatch, types.DELETE_MEAL_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getMeals = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_MEALS })
		try {
			const res = await fetchMeals(args, {
				dispatch,
				event: types.GET_MEALS_SUCCESS,
			})
			await handleSuccess(dispatch, types.GET_MEALS_SUCCESS_COMPLETE)
			return res
		} catch (err) {
			handleFail(dispatch, types.GET_MEALS_FAIL, err)
		}
	}
	return thunk
}

export const addMealItem = (mealItem) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_MEAL_ITEM, payload: mealItem })
		try {
			const res = await postMealItem(mealItem)
			const contextResponse = {
				...res.data,
				_app: {
					...mealItem._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_MEAL_ITEM_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...mealItem,
				_app: {
					err,
					...mealItem._app,
				},
			}
			handleFail(dispatch, types.ADD_MEAL_ITEM_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateMealItem = (mealItem) => {
	async function thunk(dispatch) {
		dispatch({ type: types.UPDATE_MEAL_ITEM, payload: mealItem })
		try {
			const res = mealItem.id ? await putMealItem(mealItem) : await postMealItem(mealItem)
			const contextResponse = {
				...res.data,
				_app: {
					...mealItem._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.UPDATE_MEAL_ITEM_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...mealItem,
				_app: {
					err,
					...mealItem._app,
				},
			}
			handleFail(dispatch, types.UPDATE_MEAL_ITEM_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteMealItem = (mealItem) => {
	async function thunk(dispatch) {
		dispatch({ type: types.DELETE_MEAL_ITEM, payload: mealItem })
		try {
			const res = await removeMealItem(mealItem)
			const contextResponse = {
				...res.data,
				_app: {
					...mealItem._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_MEAL_ITEM_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...mealItem,
				_app: {
					err,
					...mealItem._app,
				},
			}
			handleFail(dispatch, types.DELETE_MEAL_ITEM_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getMealItems = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_MEAL_ITEMS })
		try {
			const res = await fetchMealItems(args)
			handleSuccess(dispatch, types.GET_MEAL_ITEMS_SUCCESS, res.data, {
				...args,
				invoked_at: new Date().toISOString(),
			})
			return res.data
		} catch (err) {
			handleFail(dispatch, types.GET_MEAL_ITEMS_FAIL, err)
		}
	}
	return thunk
}

export const addMealIngredient = (mealIngredient) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_MEAL_INGREDIENT, payload: mealIngredient })
		try {
			const res = await postMealIngredient(mealIngredient)
			const contextResponse = {
				...res.data,
				_app: {
					...mealIngredient._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_MEAL_INGREDIENT_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...mealIngredient,
				_app: {
					err,
					...mealIngredient._app,
				},
			}
			handleFail(dispatch, types.ADD_MEAL_INGREDIENT_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateMealIngredient = (mealIngredient) => {
	async function thunk(dispatch) {
		dispatch({ type: types.UPDATE_MEAL_INGREDIENT, payload: mealIngredient })
		try {
			const res = mealIngredient.id
				? await putMealIngredient(mealIngredient)
				: await postMealIngredient(mealIngredient)
			const contextResponse = {
				...res.data,
				_app: {
					...mealIngredient._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.UPDATE_MEAL_INGREDIENT_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...mealIngredient,
				_app: {
					err,
					...mealIngredient._app,
				},
			}
			handleFail(dispatch, types.UPDATE_MEAL_INGREDIENT_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteMealIngredient = (mealIngredient) => {
	async function thunk(dispatch) {
		dispatch({ type: types.DELETE_MEAL_INGREDIENT, payload: mealIngredient })
		try {
			const res = await removeMealIngredient(mealIngredient)
			const contextResponse = {
				...res.data,
				_app: {
					...mealIngredient._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_MEAL_INGREDIENT_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...mealIngredient,
				_app: {
					err,
					...mealIngredient._app,
				},
			}
			handleFail(dispatch, types.DELETE_MEAL_INGREDIENT_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getMealIngredients = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_MEAL_INGREDIENTS })
		try {
			const res = await fetchMealIngredients(args)
			handleSuccess(dispatch, types.GET_MEAL_INGREDIENTS_SUCCESS, res.data, {
				...args,
				invoked_at: new Date().toISOString(),
			})
			return res.data
		} catch (err) {
			handleFail(dispatch, types.GET_MEAL_INGREDIENTS_FAIL, err)
		}
	}

	return thunk
}

export const setCurrentMeal = (meal, attentionDate) => (dispatch) => {
	const payload = { ...getDefaultMeal(attentionDate), ...meal }
	return new Promise((res, rej) => {
		dispatch({
			type: types.CURRENT_MEAL_SET,
			payload,
		})
		res(payload)
	})
}

export const updateCurrentMeal = (payload) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_MEAL_UPDATE, payload })
	}
}

export const clearCurrentMeal =
	(meal = getDefaultMeal()) =>
	(dispatch) => {
		dispatch({ type: types.CURRENT_MEAL_CLEAR, payload: meal })
	}

export const addCurrentMealItem =
	(mealItem = getDefaultMeal()) =>
	(dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: types.CURRENT_MEAL_ITEM_ADD,
				payload: mealItem,
			})
			res(mealItem)
		})

export const updateCurrentMealItem = (mealItem) => (dispatch) =>
	new Promise((res, rej) => {
		dispatch({
			type: types.CURRENT_MEAL_ITEM_UPDATE,
			payload: mealItem,
		})
	})

export const removeCurrentMealItem = (mealItem) => (dispatch) =>
	new Promise((res, rej) => {
		dispatch({
			type: types.CURRENT_MEAL_ITEM_REMOVE,
			payload: mealItem,
		})
		res(mealItem)
	})
