import moment from 'moment'
import '@gouch/to-title-case'

export const summaryRenderers = (type) => {
	switch (type) {
		case 'sleep':
			return {
				title: (entity) => entity._app.type.charAt(0).toUpperCase() + entity._app.type.slice(1),
				subTitle: (entity) => {
					if (entity.duration < 60) {
						if (entity.duration === 1) {
							return `${Math.round(entity.duration * 10) / 10} minute`
						}
						return `${Math.round(entity.duration * 10) / 10} minutes`
					}
					if (entity.duration / 60 === 1) {
						return `${Math.round((entity.duration / 60) * 10) / 10} hour`
					}
					return `${Math.round((entity.duration / 60) * 10) / 10} hours`
				},
				isLabled: (entity) => {
					return true
				},
			}
		case 'bowel':
			return {
				title: (entity) => 'Bowel Movement',
				subTitle: (entity) => {
					let subTitle = ''
					const safeTypes =
						entity.types && entity.types.length
							? entity.types
									.sort((a, b) => (a.name > b.name ? 1 : -1))
									.map((entityType) => ({
										id: entityType.id,
										name: entityType.name,
										intensity: entityType.intensity
											? entityType.intensity
											: entityType.BowelTypesLists
											? entityType.BowelTypesLists.intensity
											: null,
										bowel_id: entityType.bowel_id
											? entityType.bowel_id
											: entityType.BowelTypesLists
											? entityType.BowelTypesLists.bowel_id
											: null,
										bowel_type_id: entityType.bowel_type_id
											? entityType.bowel_type_id
											: entityType.BowelTypesLists
											? entityType.BowelTypesLists.bowel_type_id
											: null,
									}))
							: []
					safeTypes.forEach((symptomType) => {
						subTitle = subTitle
							? `${subTitle.trim()}, ${symptomType.name && symptomType.name.trim()} (${
									symptomType.intensity
							  })`
							: symptomType.name && `${symptomType.name.trim()} (${symptomType.intensity})`
					})
					if (!subTitle) {
						// subTitle = '❗To-Do: Label Bowel Movement❗'
						subTitle = ' '
					}
					return subTitle.toTitleCase()
				},
				isLabled: (entity) => {
					// if (entity._app && entity._app.subTitle.includes('Label Bowel Movement❗')) {
					// 	return false
					// }
					return true
				},
			}
		case 'mood':
			return {
				title: (entity) => entity._app.type.charAt(0).toUpperCase() + entity._app.type.slice(1),
				subTitle: (entity) => {
					let subTitle = ''
					entity.types &&
						entity.types
							.sort((a, b) => (a.name > b.name ? 1 : -1))
							.forEach((moodType) => {
								subTitle = subTitle
									? `${subTitle.trim()}, ${moodType.name && moodType.name.trim()}`
									: moodType.name && moodType.name.trim()
							})
					return subTitle.toTitleCase()
				},
				isLabled: (entity) => {
					return true
				},
			}
		case 'symptom':
			return {
				title: (entity) => `${entity._app.type.charAt(0).toUpperCase()}${entity._app.type.slice(1)}s`,
				subTitle: (entity) => {
					let subTitle = ''
					const safeTypes =
						entity.types && entity.types.length
							? entity.types
									.sort((a, b) => (a.name > b.name ? 1 : -1))
									.map((entityType) => ({
										id: entityType.id,
										name: entityType.name,
										intensity: entityType.intensity
											? entityType.intensity
											: entityType.SymptomTypesLists
											? entityType.SymptomTypesLists.intensity
											: null,
										symptom_id: entityType.symptom_id
											? entityType.symptom_id
											: entityType.SymptomTypesLists
											? entityType.SymptomTypesLists.symptom_id
											: null,
										symptom_type_id: entityType.symptom_type_id
											? entityType.symptom_type_id
											: entityType.SymptomTypesLists
											? entityType.SymptomTypesLists.symptom_type_id
											: null,
									}))
							: []

					safeTypes.forEach((symptomType) => {
						subTitle = subTitle
							? `${subTitle.trim()}, ${symptomType.name && symptomType.name.trim()}`
							: symptomType.name && symptomType.name.trim()
					})
					if (!subTitle) {
						// subTitle = '❗To-Do: Label Symptom❗'
						subTitle = ' '
					}
					return subTitle.toTitleCase()
				},
				isLabled: (entity) => {
					return true
				},
			}
		case 'meal':
			return {
				title: (entity) =>
					entity.name ? entity.name : entity._app.type.charAt(0).toUpperCase() + entity._app.type.slice(1),
				subTitle: (entity) => {
					let subTitle = ''
					entity.items &&
						entity.items
							.sort((a, b) => (a.name > b.name ? 1 : -1))
							.forEach((mealItem) => {
								subTitle = subTitle
									? `${subTitle.trim()}, ${mealItem.name && mealItem.name.trim()}`
									: mealItem.name && mealItem.name.trim()
							})
					if (!subTitle) {
						// subTitle = '❗To-Do: Label Meal❗'
						subTitle = ' '
					}
					return subTitle.toTitleCase()
				},
				isLabled: (entity) => {
					// if (entity._app && entity._app.subTitle.includes('Label Meal❗')) {
					// 	return false
					// }
					return true
				},
			}
		case 'other':
			return {
				title: (entity) => entity._app.type.charAt(0).toUpperCase() + entity._app.type.slice(1),
				subTitle: (entity) => {
					let subTitle = ''
					entity.types &&
						entity.types
							.sort((a, b) => (a.name > b.name ? 1 : -1))
							.forEach((otherType) => {
								subTitle = subTitle
									? `${subTitle.trim()}, ${otherType.name && otherType.name.trim()}`
									: otherType.name && otherType.name.trim()
							})
					return subTitle.toTitleCase()
				},
				isLabled: (entity) => {
					return true
				},
			}
		case 'medication':
			return {
				title: (entity) => entity._app.type.charAt(0).toUpperCase() + entity._app.type.slice(1),
				subTitle: (entity) => {
					let subTitle = ''
					entity.types &&
						entity.types
							.sort((a, b) => (a.name > b.name ? 1 : -1))
							.forEach((medType) => {
								subTitle = subTitle
									? `${subTitle.trim()}, ${medType.name && medType.name.trim()}`
									: medType.name && medType.name.trim()
							})
					return subTitle.toTitleCase()
				},
				isLabled: (entity) => {
					return true
				},
			}
		case 'exercise':
			return {
				title: (entity) => entity._app.type.charAt(0).toUpperCase() + entity._app.type.slice(1),
				subTitle: (entity) => {
					let subTitle = ''
					entity.types &&
						entity.types
							.sort((a, b) => (a.name > b.name ? 1 : -1))
							.forEach((exerciseType) => {
								subTitle = subTitle
									? `${subTitle.trim()}, ${exerciseType.name && exerciseType.name.trim()}`
									: exerciseType.name && exerciseType.name.trim()
							})
					return subTitle.toTitleCase()
				},
				isLabled: (entity) => {
					return true
				},
			}
		case 'programSurvey':
		case 'program_survey':
		case 'user_program_survey':
			return {
				title: (entity) => entity?.ProgramSurvey?.name || 'Program Survey',
				subTitle: (entity) => entity?.ProgramSurvey?.description || '',
				isLabled: (entity) => {
					return true
				},
			}
		default:
			return {
				title: (entity) => 'Unknown',
				subTitle: (entity) => '',
				isLabled: (entity) => {
					return true
				},
			}
	}
}

export const daysEventsMerger = (daysEvents, payload, synced, summary = true) => {
	let renderers
	let oldDate
	let targetDate
	let oldEntity
	let newEntity
	if (payload.new && payload.old) {
		renderers = summary && summaryRenderers(payload.new._app.type)
		oldDate = moment(payload.old.occurred_at).format('YYYY-MM-DD')
		targetDate = moment(payload.new.occurred_at).format('YYYY-MM-DD')
		oldEntity = payload.old
		newEntity = payload.new
	} else {
		if (payload.error) {
			payload = payload.error
		}
		renderers = summary && summaryRenderers(payload._app.type)
		oldDate = moment(payload.occurred_at).format('YYYY-MM-DD')
		targetDate = moment(payload.occurred_at).format('YYYY-MM-DD')
		oldEntity = payload
		newEntity = payload
	}
	daysEvents[oldDate] = (daysEvents[oldDate] || []).filter((item) => {
		if (Number.isInteger(oldEntity.id) && Number.isInteger(item.id)) {
			return item.id !== oldEntity.id
		} else if (oldEntity._app && oldEntity._app.uuid && item._app && item._app.uuid) {
			return oldEntity._app.uuid !== item._app.uuid
		}
		return true
	})
	daysEvents[targetDate] = [
		...(daysEvents[targetDate] || []),
		{
			...newEntity,
			_app: {
				...newEntity._app,
				...(summary
					? {
							title: renderers.title(newEntity),
							subTitle: renderers.subTitle(newEntity),
					  }
					: {}),
				synced,
			},
		},
	]
	// daysEvents[targetDate] = daysEvents[targetDate]
	//   .reverse()
	//   .reduce((acc, item) => {
	//     if (acc.find(entry => entry._app && entry._app.uuid === item.uuid)) {
	//     } else {
	//       acc.push(item);
	//     }
	//     return acc;
	//   }, []);
	return daysEvents
}

export const daysScoresMerger = (daysScores, payload, synced) => {
	let oldDate
	let targetDate
	let oldEntity
	let newEntity
	if (payload.new && payload.old) {
		oldDate = moment(payload.old.range_start).format('YYYY-MM-DD')
		targetDate = moment(payload.new.range_start).format('YYYY-MM-DD')
		oldEntity = payload.old
		newEntity = payload.new
	} else {
		oldDate = moment(payload.range_start).format('YYYY-MM-DD')
		targetDate = moment(payload.range_start).format('YYYY-MM-DD')
		oldEntity = payload
		newEntity = payload
	}
	daysScores[oldDate] = (daysScores[oldDate] || []).filter((item) => {
		if (Number.isInteger(oldEntity.id) && Number.isInteger(item.id)) {
			return item.id !== oldEntity.id
		} else if (oldEntity._app && oldEntity._app.uuid && item._app && item._app.uuid) {
			return oldEntity._app.uuid !== item._app.uuid
		}
		return true
	})
	daysScores[targetDate] = [
		...(daysScores[targetDate] || []),
		{
			...newEntity,
			_app: {
				...newEntity._app,
				synced,
			},
		},
	].sort((a, b) => {
		if (a.updated_at && b.updated_at) {
			return new Date(b.updated_at) - new Date(a.updated_at)
		}
		return b.id - a.id
	})

	return daysScores
}
