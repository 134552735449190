import * as types from 'types'
import * as actions from 'actions'
import { mergeDeep, deepMerge } from 'reducers/z_redux_helpers'

const INITIAL_STATE = {
	locale: 'en',
	modals: {
		state_block: false,
	},
	settings: {
		diary: {
			interestedDiaryEntities: [
				'bowel',
				'exercise',
				'meal',
				'medication',
				'symptom',
				'sleep',
				'other',
				'mood',
				'programSurvey',
				'user_program_survey',
			],
			showImages: true,
		},
	},
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.UPDATE_DEVICE_SETTING_DIARY_INTERESTED_ENTITIES:
			return {
				...state,
				settings: {
					...state.settings,
					diary: { ...state.settings.diary, interestedDiaryEntities: action.payload },
				},
			}

		case types.UPDATE_DEVICE_SETTING_DIARY_VIEW_IMAGES:
			return {
				...state,
				settings: {
					...state.settings,
					diary: { ...state.settings.diary, showImages: action.payload },
				},
			}

		case types.WAIT_LOADING_START: {
			return {
				...state,
				modals: {
					...state.modals,
					state_block: true,
				},
			}
		}

		case types.WAIT_LOADING_STOP: {
			return {
				...state,
				modals: {
					...state.modals,
					state_block: false,
				},
			}
		}

		case types.APP_LOADED: {
			return {
				...state,
				modals: {
					...state.modals,
					state_block: false,
				},
			}
		}
		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		default:
			return state
	}
}
