/**
 * CRUD Actions for MEAL
 */
// DETECTIONS - MEAL
export const FETCH_MEAL_ITEMS_DETECTIONS = 'FETCH_MEAL_ITEMS_DETECTIONS'
export const FETCH_MEAL_ITEMS_DETECTIONS_SUCCESS = 'FETCH_MEAL_ITEMS_DETECTIONS_SUCCESS'
export const FETCH_MEAL_ITEMS_DETECTIONS_FAIL = 'FETCH_MEAL_ITEMS_DETECTIONS_FAIL'

// ADD - MEAL
export const ADD_MEAL_SUCCESS = 'ADD_MEAL_SUCCESS'
export const ADD_MEAL_MEDIA_SUCCESS = 'ADD_MEAL_MEDIA_SUCCESS'
export const ADD_MEAL_FAIL = 'ADD_MEAL_FAIL'
export const ADD_MEAL_MEDIA_FAIL = 'ADD_MEAL_MEDIA_FAIL'
export const ADD_MEAL = 'ADD_MEAL'
// DELETE - MEAL
export const DELETE_MEAL_SUCCESS = 'DELETE_MEAL_SUCCESS'
export const DELETE_MEAL_MEDIA_SUCCESS = 'DELETE_MEAL_MEDIA_SUCCESS'
export const DELETE_MEAL_FAIL = 'DELETE_MEAL_FAIL'
export const DELETE_MEAL_MEDIA_FAIL = 'DELETE_MEAL_MEDIA_FAIL'
export const DELETE_MEAL = 'DELETE_MEAL'
// UPDATE - MEAL
export const UPDATE_MEAL_SUCCESS = 'UPDATE_MEAL_SUCCESS'
export const UPDATE_MEAL_MEDIA_SUCCESS = 'UPDATE_MEAL_MEDIA_SUCCESS'
export const UPDATE_MEAL_FAIL = 'UPDATE_MEAL_FAIL'
export const UPDATE_MEAL_MEDIA_FAIL = 'UPDATE_MEAL_MEDIA_FAIL'
export const UPDATE_MEAL = 'UPDATE_MEAL'
// GET ALL - MEAL
export const GET_MEALS_SUCCESS = 'GET_MEALS_SUCCESS'
export const GET_MEALS_SUCCESS_COMPLETE = 'GET_MEALS_SUCCESS_COMPLETE'
export const GET_MEALS_FAIL = 'GET_MEALS_FAIL'
export const GET_MEALS = 'GET_MEALS'

export const GET_MEAL_SUCCESS = 'GET_MEAL_SUCCESS'
export const GET_MEAL_FAIL = 'GET_MEAL_FAIL'
export const GET_MEAL = 'GET_MEAL'

// ADD - MEAL_ITEM
export const ADD_MEAL_ITEM_SUCCESS = 'ADD_MEAL_ITEM_SUCCESS'
export const ADD_MEAL_ITEM_FAIL = 'ADD_MEAL_ITEM_FAIL'
export const ADD_MEAL_ITEM = 'ADD_MEAL_ITEM'
// DELETE - MEAL_ITEM
export const DELETE_MEAL_ITEM_SUCCESS = 'DELETE_MEAL_ITEM_SUCCESS'
export const DELETE_MEAL_ITEM_FAIL = 'DELETE_MEAL_ITEM_FAIL'
export const DELETE_MEAL_ITEM = 'DELETE_MEAL_ITEM'
// UPDATE - MEAL_ITEM
export const UPDATE_MEAL_ITEM_SUCCESS = 'UPDATE_MEAL_ITEM_SUCCESS'
export const UPDATE_MEAL_ITEM_FAIL = 'UPDATE_MEAL_ITEM_FAIL'
export const UPDATE_MEAL_ITEM = 'UPDATE_MEAL_ITEM'
// GET ALL - MEAL_ITEM
export const GET_MEAL_ITEMS_SUCCESS = 'GET_MEAL_ITEMS_SUCCESS'
export const GET_MEAL_ITEMS_FAIL = 'GET_MEAL_ITEMS_FAIL'
export const GET_MEAL_ITEMS = 'GET_MEAL_ITEMS'

// ADD - MEAL_ITEM
export const ADD_MEAL_INGREDIENT_SUCCESS = 'ADD_MEAL_INGREDIENT_SUCCESS'
export const ADD_MEAL_INGREDIENT_FAIL = 'ADD_MEAL_INGREDIENT_FAIL'
export const ADD_MEAL_INGREDIENT = 'ADD_MEAL_INGREDIENT'
// DELETE - MEAL_INGREDIENT
export const DELETE_MEAL_INGREDIENT_SUCCESS = 'DELETE_MEAL_INGREDIENT_SUCCESS'
export const DELETE_MEAL_INGREDIENT_FAIL = 'DELETE_MEAL_INGREDIENT_FAIL'
export const DELETE_MEAL_INGREDIENT = 'DELETE_MEAL_INGREDIENT'
// UPDATE - MEAL_INGREDIENT
export const UPDATE_MEAL_INGREDIENT_SUCCESS = 'UPDATE_MEAL_INGREDIENT_SUCCESS'
export const UPDATE_MEAL_INGREDIENT_FAIL = 'UPDATE_MEAL_INGREDIENT_FAIL'
export const UPDATE_MEAL_INGREDIENT = 'UPDATE_MEAL_INGREDIENT'
// GET ALL - MEAL_INGREDIENT
export const GET_MEAL_INGREDIENTS_SUCCESS = 'GET_MEAL_INGREDIENTS_SUCCESS'
export const GET_MEAL_INGREDIENTS_FAIL = 'GET_MEAL_INGREDIENTS_FAIL'
export const GET_MEAL_INGREDIENTS = 'GET_MEAL_INGREDIENTS'

/**
 * Current Types
 */
// CURRENT MEAL
export const CURRENT_MEAL_SET = 'CURRENT_MEAL_SET'
export const CURRENT_MEAL_CLEAR = 'CURRENT_MEAL_CLEAR'
export const CURRENT_MEAL_UPDATE = 'CURRENT_MEAL_UPDATE'
// CURRENT MEAL_ITEM
export const CURRENT_MEAL_ITEM_ADD = 'CURRENT_MEAL_ITEM_ADD'
export const CURRENT_MEAL_ITEM_UPDATE = 'CURRENT_MEAL_ITEM_UPDATE'
export const CURRENT_MEAL_ITEM_REMOVE = 'CURRENT_MEAL_ITEM_REMOVE'

export const MEDIA_MEAL_SET = 'MEDIA_MEAL_SET'
export const MEDIA_MEAL_ITEM_SET = 'MEDIA_MEAL_ITEM_SET'
export const MEDIA_MEAL_ITEM_DELETE = 'MEDIA_MEAL_ITEM_DELETE'

export const RESET_MEAL_LOADERS = 'RESET_MEAL_LOADERS'

export const MEAL_CONTEXT_RESET = 'MEAL_CONTEXT_RESET'
