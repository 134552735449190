import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateExhaust } from 'api/api_utils'

const formatSymptomScoreData = (scoreData) => ({
	score: scoreData.score || 0,
	range_start: scoreData.range_start,
	range_end: scoreData.range_end,
})

export const getSymptomScores = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/symptomScores',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'symptomScores',
		1,
		[],
		reduxEvent
	)
}

export const addSymptomScore = (scoreData) => {
	return axiosInstance.request({
		url: 'v1/symptomScores',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatSymptomScoreData(scoreData),
	})
}

export const updateSymptomScore = (scoreData) => {
	return axiosInstance.request({
		url: `v1/symptomScores/${scoreData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatSymptomScoreData(scoreData),
	})
}
