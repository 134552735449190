import {
	fetchPatients,
	fetchStudies,
	getPatientPicture,
	addPatient as postPatient,
	updatePatient as putPatient,
	removePatient,
	requestPatientProfile,
} from 'api/patient'
import * as types from 'types'
import * as actions from 'actions'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { AxiosAbortController } from 'src/axiosConfig'
import { fetchPrograms, updatePatientMeta } from '../apis/patient'

const getDefaultPatient = () => ({
	id: null,
	picture: null,
	_app: {
		uuid: null,
		type: 'patient',
		synced: false,
	},
})

export const resetPatientLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_PATIENT_LOADERS })
	}
}

export const fetchPatientPicture = (patient) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.UPDATE_PATIENT, payload: patient })
		try {
			const res = await getPatientPicture(patient)
			const contextResponse = {
				...patient,
				picture: res.data.uri,
			}
			handleSuccess(dispatch, types.UPDATE_PATIENT_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...patient,
				_app: {
					err,
					...patient._app,
				},
			}
			handleFail(dispatch, types.UPDATE_PATIENT_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getPatientData = (patient) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.UPDATE_PATIENT, payload: patient })
		try {
			const res = await requestPatientProfile(patient)
			handleSuccess(dispatch, types.UPDATE_PATIENT_SUCCESS, res.data)
			return res.data
		} catch (err) {
			const contextResponse = {
				...patient,
				_app: {
					err,
					...patient._app,
				},
			}
			handleFail(dispatch, types.UPDATE_PATIENT_FAIL, contextResponse)
		}
	}
	return thunk
}

export const addPatient = (patient) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_PATIENT, payload: patient })
		try {
			const res = await postPatient(patient)
			const contextResponse = {
				...res.data,
				_app: {
					...patient._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_PATIENT_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...patient,
				_app: {
					err,
					...patient._app,
				},
			}
			handleFail(dispatch, types.ADD_PATIENT_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updatePatient = (patient) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.UPDATE_PATIENT, payload: patient })
		try {
			const res = patient.id ? await putPatient(patient) : await postPatient(patient)
			const contextResponse = {
				...res.data,
				_app: {
					...patient._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_PATIENT_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...patient,
				_app: {
					err,
					...patient._app,
				},
			}
			handleFail(dispatch, types.UPDATE_PATIENT_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deletePatient = (patient) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.DELETE_PATIENT, payload: patient })
		try {
			const res = await removePatient(patient)
			const contextResponse = {
				...res.data,
				_app: {
					...patient._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_PATIENT_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...patient,
				_app: {
					err,
					...patient._app,
				},
			}
			handleFail(dispatch, types.DELETE_PATIENT_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getPatients = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_PATIENTS })
		try {
			await fetchPatients(args, {
				dispatch,
				event: types.GET_PATIENTS_SUCCESS,
			})
			handleSuccess(dispatch, types.GET_PATIENTS_SUCCESS_COMPLETE)
		} catch (err) {
			handleFail(dispatch, types.GET_PATIENTS_FAIL, err)
		}
	}
	return thunk
}

export const getStudies = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_STUDIES })
		try {
			const res = await fetchStudies(args)
			handleSuccess(dispatch, types.GET_STUDIES_SUCCESS, res.data)
		} catch (err) {
			handleFail(dispatch, types.GET_STUDIES_FAIL, err)
		}
	}
	return thunk
}

export const getUserPrograms = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_USER_PROGRAMS })
		try {
			const res = await fetchPrograms(args)
			handleSuccess(dispatch, types.GET_USER_PROGRAMS_SUCCESS, res.data)
		} catch (err) {
			handleFail(dispatch, types.GET_USER_PROGRAMS_FAIL, err)
		}
	}
	return thunk
}

export const setUpdateMeta = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.PUT_PATIENT_META })
		try {
			const res = await updatePatientMeta(args)
			handleSuccess(dispatch, types.PUT_PATIENT_META_SUCCESS, { ...res.data, args })
		} catch (err) {
			handleFail(dispatch, types.PUT_PATIENT_META_FAIL, err)
		}
	}
	return thunk
}

export const setCurrentPatient = (patient = getDefaultPatient(), study = false, program = false) => {
	async function thunk(dispatch) {
		dispatch({ type: types.WAIT_LOADING_START })
		AxiosAbortController.abort()
		await Promise.all([
			dispatch({ type: types.CURRENT_PATIENT_SET, payload: { ...patient, isStudy: study, isProgram: program } }),
			dispatch({ type: types.ANALYTICS_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.BOWEL_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.CLINICIAN_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.EXERCISE_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.INTERVENTION_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.MEAL_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.MEDICATION_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.MOOD_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.OTHER_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.QUESTION_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.SLEEP_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.SUMMARY_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.SURVEY_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.SYMPTOM_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.SYMPTOM_SCORE_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.NOTE_CONTEXT_RESET, payload: patient }),
			dispatch({ type: types.MENSTRUATION_CONTEXT_RESET, payload: patient }),
		])

		// fetch recursize summary

		await dispatch(
			actions.fetchSummary({
				to: new Date().toISOString(),
			})
		)

		dispatch({ type: types.WAIT_LOADING_STOP })
		dispatch(
			actions.fetchSymptomScores({
				to: new Date().toISOString(),
			})
		)
		dispatch(
			actions.fetchMenstruations({
				to: new Date().toISOString(),
			})
		)
		// dispatch(
		// 	actions.getBowels({
		// 		to: new Date().toISOString(),
		// 	})
		// )
		// dispatch(
		// 	actions.getExercises({
		// 		to: new Date().toISOString(),
		// 	})
		// )
		// dispatch(
		// 	actions.getMeals({
		// 		to: new Date().toISOString(),
		// 	})
		// )
		// dispatch(
		// 	actions.getMedications({
		// 		to: new Date().toISOString(),
		// 	})
		// )
		// dispatch(
		// 	actions.getMoods({
		// 		to: new Date().toISOString(),
		// 	})
		// )
		// dispatch(
		// 	actions.getOthers({
		// 		to: new Date().toISOString(),
		// 	})
		// )
		// dispatch(
		// 	actions.getSleeps({
		// 		to: new Date().toISOString(),
		// 	})
		// )
		// dispatch(
		// 	actions.getSymptoms({
		// 		to: new Date().toISOString(),
		// 	})
		// )
		dispatch(actions.getQuestions())
		dispatch(actions.getSurveys({ historical: true }))
	}
	return thunk
}

export const clearCurrentPatient = (patient = getDefaultPatient()) => {
	async function thunk(dispatch) {
		dispatch({ type: types.WAIT_LOADING_START })
		AxiosAbortController.abort()
		await Promise.all([
			dispatch({ type: types.CURRENT_PATIENT_CLEAR, payload: null }),
			dispatch({ type: types.ANALYTICS_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.BOWEL_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.CLINICIAN_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.EXERCISE_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.INTERVENTION_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.MEAL_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.MEDICATION_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.MOOD_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.OTHER_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.QUESTION_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.SLEEP_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.SUMMARY_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.SURVEY_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.SYMPTOM_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.SYMPTOM_SCORE_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.NOTE_CONTEXT_RESET, payload: null }),
			dispatch({ type: types.MENSTRUATION_CONTEXT_RESET, payload: null }),
		])
		await dispatch(actions.hydrateApplication())
		dispatch({ type: types.WAIT_LOADING_STOP })
	}
	return thunk
}
