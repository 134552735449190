import axios from 'axios'
import axiosRetry from 'axios-retry'
import * as actions from 'actions'
import * as types from 'types'
import { configuredStore, storage } from '/src/configureStore'
import { snackActions } from 'utils/SnackbarUtils'
import { debounce } from 'utils/helpers'
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only'

let refreshTokenErrorCount = 0
const tokenCache = {}

function timeout(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

// Add a request interceptor
const axiosInstance = axios.create({
	baseURL: process.env.API_BASE_URL,
	transformRequest: [
		(data, headers) => {
			return data
		},
		...axios.defaults.transformRequest,
	],
})

const AxiosAbortController = new AbortController()

axiosRetry(axiosInstance, {
	retries: 3,
	retryDelay: axiosRetry.exponentialDelay,
})

const checkConnectionChange = async () => {
	const isConnected = navigator.onLine
	const networkState = configuredStore.store.getState().network
	if (networkState.isConnected !== isConnected) {
		configuredStore.store.dispatch({
			type: types.NETWORK_CONNECTION_CHANGE,
			payload: isConnected,
		})
	}
	return isConnected
}

const tokenInjector = async (config) => {
	// Do something before request is sent
	// If the header does not contain the token and the url not public, redirect to login
	const { auth, patient } = configuredStore.store.getState()
	if (auth.accessToken) {
		if (config.method !== 'OPTIONS') {
			config.headers.authorization = `Bearer ${auth.accessToken}`
			config.headers['x-dieta-app-id'] = process.env.APP_ID
			if (!config.skipIdentity) config.headers['x-dieta-app-identity'] = auth.sub
			// Important
			if (patient?.current?.key && !config.noFederate && !config.skipIdentity) {
				config.headers['x-dieta-identity-federation'] = patient.current.key
			}
		}
	}
	return config
}

axiosInstance.interceptors.request.use(
	async (config) => await tokenInjector(config),
	(error) => {
		// Do something with request error
		return Promise.reject(error)
	}
)

axiosInstance.interceptors.response.use(
	(response) => {
		if (response) {
			if (response.status >= 200 && response.status < 300) {
				const federation = response.config.headers['x-dieta-identity-federation']
				const { patient } = configuredStore.store.getState()
				if (federation || patient?.current?.key) {
					// console.log('TARGET IDTY', patient?.current?.key)
					// console.log('RESP IDTY', federation)
					if (patient?.current?.key && !response.config.noFederate) {
						if (patient.current.key !== federation) {
							throw new Error('Not Federated')
						}
					}
				}
				if (!Array.isArray(response.data)) {
					response.data = {
						...response.data,
						_app: {
							...response.data._app,
							synced: true,
						},
					}
				}
			}
		}
		return response
	},
	async (error) => {
		console.log('error', error)
		if (!error.response) {
			const isConnected = await checkConnectionChange()
			if (!isConnected) {
				return Promise.reject(error)
			}
			return Promise.reject(error)
		}
		if (error.response.status === 401) {
			const auth = configuredStore.store.getState().auth
			if (refreshTokenErrorCount >= 2) {
				refreshTokenErrorCount = 0
				if (auth.email) {
					await configuredStore.store.dispatch(actions.logoutUser('Logging out due to corrupt Session'))
				}
			} else {
				try {
					if (auth.email) {
						if (refreshTokenErrorCount) {
							// displaySnackbar(`Refreshing Secure Session: Attempt ${refreshTokenErrorCount + 1}`)
						} else {
							// displaySnackbar('Refreshing Secure Session')
						}
						const token = await storage.getItem('dieta_r')
						if (auth.email && token) {
							if (!tokenCache[token]) {
								tokenCache[token] = auth.accessToken
								await configuredStore.store.dispatch(actions.refreshToken())
								delete tokenCache[token]
								error.config = await tokenInjector(error.config)
								return axiosInstance.request(error.config) // retry request
							} else {
								await timeout(1000)
								error.config = await tokenInjector(error.config)
								return axiosInstance.request(error.config) // retry request
							}
						} else {
							throw new Error('No Token found')
						}
					}
				} catch (err) {
					refreshTokenErrorCount++
					return axiosInstance.request(error.config)
				}
			}
		}
		if (error.response.status >= 400) {
			return Promise.reject(error)
		}
		if (error.Cancel) {
			console.log('Request was cancelled')
		}
	}
)
export { AxiosAbortController }
export default axiosInstance
