import React, { Component } from 'react'
import { styled } from '@mui/material/styles'
import { compose } from 'recompose'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Switch from '@mui/material/Switch'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
import { withTheme } from '@mui/styles'
import { useTheme } from '@mui/material'
import { associateRequest } from 'utils/helpers'
import { paths } from 'routes'
import { navigate } from 'gatsby'
import { snackActions } from '../../../utils/SnackbarUtils'

const PREFIX = 'InterventionCreateDialog'

const classes = {
	container: `${PREFIX}-container`,
	formControl: `${PREFIX}-formControl`,
	compactSearch: `${PREFIX}-compactSearch`,
	selectMarginLabel: `${PREFIX}-selectMarginLabel`,
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
	[`& .${classes.container}`]: {
		minWidth: 500,
	},

	[`& .${classes.formControl}`]: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		width: '100%',
	},

	[`& .${classes.compactSearch}`]: {
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},

	[`& .${classes.selectMarginLabel}`]: {
		margin: theme.spacing(1),
	},
}))

const withMediaQuery =
	(queries = []) =>
	(Component) =>
	(props) => {
		const mediaProps = {}
		queries.forEach((q) => {
			mediaProps[q[0]] = useMediaQuery(q[1])
		})
		return <Component {...mediaProps} {...props} />
	}

const SYMPTOM_TARGETS = [
	{
		label: 'Abdominal Pain',
		value: 'Abdominal Pain',
	},
	{
		label: 'Belching',
		value: 'Belching',
	},
	{
		label: 'Constipation',
		value: 'Constipation',
	},
	{
		label: 'Diarrhea',
		value: 'Diarrhea',
	},
	{
		label: 'Distention',
		value: 'Distention',
	},
	{
		label: 'Early Satiety',
		value: 'Early Satiety',
	},
	{
		label: 'Flatulence',
		value: 'Flatulence',
	},
	{
		label: 'Nausea/Vomiting',
		value: 'Nausea/Vomiting',
	},
	{
		label: 'Reflux/Hearburn',
		value: 'Reflux/Hearburn',
	},
	{
		label: 'Other',
		value: 'Other',
	},
]

const SYMPTOM_DURATIONS = [
	{
		label: '1 Day',
		value: 86400,
	},
	{
		label: '5 Days',
		value: 432000,
	},
	{
		label: '1 Week',
		value: 604800,
	},
	{
		label: '2 Weeks',
		value: 1210000,
	},
	{
		label: '3 Weeks',
		value: 1814400,
	},
	{
		label: '1 Month',
		value: 2628000,
	},
	{
		label: '2 Months',
		value: 5256000,
	},
	{
		label: 'Self Bounded',
		value: 0,
	},
]

class InterventionForkState extends Component {
	state = {
		allowDuration: false,
		name: this.props.intervention.current?.meta?.friendlyName,
		duration: this.props.intervention.current?.duration,
		symptom: this.props.intervention.current?.symptom,
		recommendation: this.props.intervention.current?.recommendation,
		meta: this.props.intervention.current?.meta,
	}

	static getDerivedStateFromProps(props, state) {
		if (state.id !== props.intervention.current?.id) {
			return {
				id: props.intervention.current?.id,
				allowDuration: false,
				name: props.intervention.current?.meta?.friendlyName,
				duration: props.intervention.current?.duration,
				symptom: props.intervention.current?.symptom,
				recommendation: props.intervention.current?.recommendation,
				meta: props.intervention.current?.meta,
			}
		}

		return state
	}

	onNameChange = (name) => {
		// this.props.setCurrentIntervention({
		// 	...this.props.intervention.current,
		// 	name,
		// 	meta: {
		// 		...this.props.intervention.current.meta,
		// 		friendlyName: name,
		// 	},
		// })
		this.setState({
			...this.state,
			name,
			meta: {
				...this.props.intervention.current.meta,
				friendlyName: name,
			},
		})
	}

	onSelectSymptomType = (symptomType) => {
		// this.props.setCurrentIntervention({
		// 	...this.props.intervention.current,
		// 	symptom: symptomType,
		// })
		this.setState({ ...this.state, symptom: symptomType })
	}

	onRecommendationChange = (recommendation) => {
		// this.props.setCurrentIntervention({
		// 	...this.props.intervention.current,
		// 	recommendation: recommendation,
		// })
		this.setState({ ...this.state, recommendation: recommendation })
	}

	onSelectInterventionDuration = (duration) => {
		// this.props.setCurrentIntervention({
		// 	...this.props.intervention.current,
		// 	duration: duration,
		// })
		this.setState({ ...this.state, duration: duration })
	}

	toggleDuration = () => {
		const newState = { ...this.state, allowDuration: !this.state.allowDuration }
		if (this.state.allowDuration) {
			newState.duration = null
		}
		this.setState(newState)
		// if (!this.state.allowDuration) {

		// 	this.props.setCurrentIntervention({
		// 		...this.props.intervention.current,
		// 		duration: null,
		// 	})
		// }
	}

	createNewIntervention = () => {
		this.props.addIntervention(
			associateRequest(
				{
					name: this.state.name,
					duration: this.state.duration,
					symptom: this.state.symptom,
					recommendation: this.state.recommendation,
					meta: this.state.meta,
					creator_key: this.props.auth.sub,
				},
				'intervention'
			)
		)
		this.props.handleClose()
		navigate(paths.interventions)
	}

	render() {
		const { auth, fullScreen, open = false, intervention, addIntervention, handleClose = () => {} } = this.props

		const isValid = this.state.symptom && this.state.name && this.state.recommendation
		return (
			<StyledDialog
				fullScreen={fullScreen}
				disableEscapeKeyDown
				disableBackdropClick
				open={open}
				TransitionProps={{
					onClose: handleClose,
				}}
				aria-labelledby="create-intervention"
			>
				<DialogTitle id="create-intervention">Create Intervention</DialogTitle>
				<DialogContent className={classes.container}>
					<Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
						<FormControl variant="outlined" className={classes.formControl}>
							<InputLabel htmlFor="experiment-symptom-group-options">Symptom Target</InputLabel>
							<Select
								label="Symptom Target:"
								id="experiment-symptom-group-options"
								value={this.state.symptom}
								onChange={(event) => this.onSelectSymptomType(event.target.value)}
							>
								{SYMPTOM_TARGETS.map((tg, i) => {
									return (
										<MenuItem key={i} value={tg.value} name={tg.label}>
											{tg.label}
										</MenuItem>
									)
								})}
							</Select>
						</FormControl>
						<FormControl variant="outlined" className={classes.formControl}>
							<TextField
								variant="outlined"
								id="experiment-name"
								label="Experiment Name"
								value={this.state?.meta?.friendlyName}
								onChange={(event) => this.onNameChange(event.target.value)}
							/>
						</FormControl>
						<FormControl variant="outlined" className={classes.formControl}>
							<TextField
								id="experiment-recommendation"
								label="Instructions"
								multiline
								rows={4}
								variant="outlined"
								value={this.state.recommendation}
								onChange={(event) => this.onRecommendationChange(event.target.value)}
							/>
						</FormControl>
						<FormControl variant="outlined" className={classes.formControl}>
							<Grid container direction="row" justifyContent="flex-start" alignItems="center">
								<Grid item>
									<Typography variant="body1">Duration:</Typography>
								</Grid>
								<Grid item>
									<Switch
										checked={this.state.allowDuration}
										color="primary"
										onChange={this.toggleDuration}
										name="Time Constrained"
										inputProps={{ 'aria-label': 'Time Constrained' }}
									/>
								</Grid>
							</Grid>
						</FormControl>
						{this.state.allowDuration ? (
							<FormControl variant="outlined" className={classes.formControl}>
								<InputLabel id="experiment-symptom-duration">Duration:</InputLabel>
								<Select
									labelId="experiment-symptom-duration"
									id="experiment-symptom-duration-options"
									value={this.state.duration}
									onChange={(event) => this.onSelectInterventionDuration(event.target.value)}
								>
									{SYMPTOM_DURATIONS.map((dur, i) => {
										return (
											<MenuItem key={i} value={dur.value} name={dur.label}>
												{dur.label}
											</MenuItem>
										)
									})}
								</Select>
							</FormControl>
						) : (
							<>Self Bounded</>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} variant="contained">
						Close
					</Button>
					<Button onClick={this.createNewIntervention} variant="contained" disabled={!isValid} autoFocus>
						Create and Assign
					</Button>
				</DialogActions>
			</StyledDialog>
		)
	}
}

const InterventionForkStateComp = compose(
	withMediaQuery([
		[
			'fullScreen',
			(theme) => theme.breakpoints.down('md'),
			{
				defaultMatches: true,
			},
		],
	])
)(InterventionForkState)

function InterventionFinalizeState({ open = false, intervention, addIntervention, handleClose = () => {} }) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
	const assignIntervention = async () => {
		const response = await addIntervention(
			associateRequest(
				{
					id: intervention.current.id,
				},
				'intervention'
			)
		)
		if (response) snackActions.success('Success! Intervention has been asigned and is awaiting patient acceptance.')
		else snackActions.error('Oops, something went wrong. Please try again.')
		handleClose()
		navigate(paths.interventions)
	}

	return (
		<Dialog
			fullScreen={fullScreen}
			disableEscapeKeyDown
			open={open}
			TransitionProps={{
				onClose: handleClose,
			}}
			aria-labelledby="create-intervention"
		>
			<DialogTitle id="create-intervention">Intervention Assignment</DialogTitle>
			<DialogContent>
				Do you want to assign <b>{intervention.current?.meta?.friendlyName || intervention.current.name}</b> to
				the patient now?
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} variant="contained">
					Close
				</Button>
				<Button onClick={assignIntervention} variant="contained" autoFocus>
					Assign
				</Button>
			</DialogActions>
		</Dialog>
	)
}

function InterventionCreateDialog({
	open = false,
	finalize = false,
	auth,
	intervention,
	addIntervention,
	handleClose = () => {},
	setCurrentIntervention,
	clearCurrentIntervention,
}) {
	return finalize ? (
		<InterventionForkStateComp
			open={open}
			auth={auth}
			intervention={intervention}
			addIntervention={addIntervention}
			setCurrentIntervention={setCurrentIntervention}
			clearCurrentIntervention={clearCurrentIntervention}
			handleClose={handleClose}
		/>
	) : (
		<InterventionFinalizeState
			open={open}
			auth={auth}
			intervention={intervention}
			addIntervention={addIntervention}
			setCurrentIntervention={setCurrentIntervention}
			clearCurrentIntervention={clearCurrentIntervention}
			handleClose={handleClose}
		/>
	)
}

export { InterventionCreateDialog }
