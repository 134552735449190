import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateDefaultArgs } from 'api/api_utils'

export const requestSession = async () => {
	return axiosInstance.request({
		source: AxiosAbortController.signal,
		url: `v1/chat/authenticate`,
		method: 'get',
	})
}

export const getSortedChannelsApi = async (user_id, token = '') => {
	return axiosInstance.request({
		source: AxiosAbortController.signal,
		url: `v3/get-channels`,
		method: 'post',
		data: {
			user_id,
			token,
		},
	})
}
