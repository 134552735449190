import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { lighten, darken } from '@mui/system'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TablePagination from '@mui/material/TablePagination'
import CircularProgress from '@mui/material/CircularProgress'
import RefreshIcon from '@mui/icons-material/Refresh'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'
import Tooltip from '@mui/material/Tooltip'
import HumanizeDuration from 'humanize-duration'
import moment from 'moment'
import SymptomScoresChart from '@components/SymptomScoresChart'
import InterventionStoolDetailsCard from '@components/Interventions/InterventionStoolDetailsCard'
import InterventionDSSDetailsCard from '@components/Interventions/InterventionDSSDetailsCard'
import InterventionComplianceDetailsCard from '@components/Interventions/InterventionComplianceDetailsCard'
import { EnumerateDaysBetweenDates } from 'utils/helpers'
import { std } from 'mathjs'

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

const headCells = [
	{ id: 'name', align: 'left', numeric: false, disablePadding: false, label: 'Name', format: (value) => value },
	// {
	// 	id: 'symptom',
	// 	align: 'center',
	// 	numeric: false,
	// 	disablePadding: false,
	// 	label: 'Symptom',
	// 	format: (value) => value,
	// },
	{
		id: 'endedDuration',
		align: 'center',
		numeric: true,
		disablePadding: false,
		label: 'Duration',
		format: (value) => value,
	},
	{
		id: 'occurred_at',
		align: 'center',
		numeric: false,
		disablePadding: false,
		label: 'Begun',
		format: (value) => value,
	},
	{
		id: 'dss',
		align: 'center',
		numeric: false,
		disablePadding: false,
		label: 'Symptom Score',
		format: (value) => value,
	},
	// { id: 'ended', align: 'center', numeric: false, disablePadding: false, label: 'Ended', format: (value) => value },
	{ id: 'effect', align: 'center', numeric: true, disablePadding: false, label: 'Effect', format: (value) => value },
]

function EnhancedTableHead(props) {
	const { classes, order, orderBy, rowCount, onRequestSort } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				<TableCell />
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.align}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

const ROW_PREFIX = 'InterventionsTableRow'

const row_classes = {
	root: `${ROW_PREFIX}-root`,
	emptyGraph: `${ROW_PREFIX}-emptyGraph`,
	emptyGraphContents: `${ROW_PREFIX}-emptyGraphContents`,
	bodyPadding: `${ROW_PREFIX}-bodyPadding`,
}

const RowBody = styled(Stack)(({ theme }) => ({
	[`&.${row_classes.root}`]: {
		marginTop: 20,
		marginBottom: 20,
	},
	// [`& .${row_classes.emptyGraphContents}`]: {
	// 	padding: 10,
	// 	textAlign: 'center',
	// },
	// [`& .${row_classes.bodyPadding}`]: {

	// },
}))

const effectIcon = (effect) => {
	if (effect === null) {
		return <></>
	} else if (effect === 50) {
		return (
			<Tooltip title="No effect on symptoms">
				<TrendingFlatIcon color={'disabled'} />
			</Tooltip>
		)
	} else if (effect < 50) {
		return (
			<Tooltip title="Symptoms have gotten worse after experiment">
				<TrendingDownIcon color={'error'} />
			</Tooltip>
		)
	} else {
		return (
			<Tooltip title="Symptoms have improved after experiment">
				<TrendingUpIcon color={'primary'} />
			</Tooltip>
		)
	}
}

function Row(props) {
	const { row, symptomScore, interventionCompliance, hydrateChildren } = props
	const [open, setOpen] = React.useState(false)
	const [compliance, setCompliance] = React.useState({})
	const startDate = moment(row.occurred_at).format('YYYY-MM-DD')
	const endDate = row.completed_at ? moment(row.completed_at).format('YYYY-MM-DD') : moment('YYYY-MM-DD')

	useEffect(() => {
		if (open && !interventionCompliance[row.id]) {
			hydrateChildren(row)
		}
	}, [open, row.id])

	useEffect(() => {
		setCompliance(
			(interventionCompliance.interventionMap[row.id] || []).reduce((acc, interv) => {
				if (interv.compliance === 'true') {
					acc[moment(interv.range_start).format('YYYY-MM-DD')] = {
						range_start: interv.range_start,
						Compliance: 100,
					}
				} else if (interv.compliance === 'false') {
					acc[moment(interv.range_start).format('YYYY-MM-DD')] = {
						range_start: interv.range_start,
						'Non-Compliance': 100,
					}
				}

				return acc
			}, {})
		)
	}, [row.id, interventionCompliance.interventionMap[row.id]])

	return (
		<>
			<TableRow>
				<TableCell>
					<IconButton color="inherit" aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row" align="left">
					{row.meta?.friendlyName || row.name}
				</TableCell>
				{/* <TableCell align="right">{row.symptom}</TableCell> */}
				<TableCell align="center">
					~
					{HumanizeDuration(row.endedDuration, {
						largest: 1,
						units: ['w', 'd'],
						maxDecimalPoints: 0,
					})}
				</TableCell>
				<TableCell align="center">{moment(row.occurred_at).format('YYYY-MM-DD')}</TableCell>
				{/* <TableCell align="right">
					{row.completed_at ? moment(row.completed_at).format('YYYY-MM-DD') : 'In Progress'}
				</TableCell> */}
				<TableCell align="center">{row.dss}</TableCell>
				<TableCell align="center">{effectIcon(row.effect)}</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Table size="small" aria-label="details">
								<TableBody>
									{/* <TableCell
										style={{
											borderStyle: 'none',
										}}
										component="th"
										scope="row"
										align="left"
									>
										{row.recommendation}
									</TableCell> */}
									<RowBody
										className={row_classes.root}
										spacing={2}
										alignItems={'center'}
										justifyContent="center"
									>
										<Container>
											<Grid container spacing={2} alignItems="center">
												<Grid item xs={12} style={{ marginBottom: 10 }}>
													<SymptomScoresChart
														scores={symptomScore.daysScores}
														range={{
															start: startDate,
															end: endDate || moment().format('YYYY-MM-DD'),
														}}
														regions={compliance}
													/>
												</Grid>
											</Grid>
										</Container>
										<Container>
											<Grid container spacing={2} alignItems="flex-start">
												<Grid item xs={12} sm={6}>
													<Stack spacing={2} justifyContent="flex-start">
														<InterventionDSSDetailsCard currentIntervention={row} />
														<InterventionComplianceDetailsCard currentIntervention={row} />
													</Stack>
												</Grid>
												<Grid item xs={12} sm={6}>
													<InterventionStoolDetailsCard currentIntervention={row} />
												</Grid>
											</Grid>
										</Container>
									</RowBody>
								</TableBody>
							</Table>
							<Grid container spacing={2}>
								<Grid item xs={12}></Grid>
							</Grid>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

Row.propTypes = {
	row: PropTypes.shape({
		name: PropTypes.string.isRequired,
		symptom: PropTypes.string.isRequired,
		duration: PropTypes.number,
		occurred_at: PropTypes.string,
		completed_at: PropTypes.string,
		effect: PropTypes.number.isRequired,
		meta: PropTypes.shape({
			theme: PropTypes.string.isRequired,
			friendlyName: PropTypes.string.isRequired,
		}),
	}).isRequired,
}

const PREFIX = 'InterventionsTable'

const classes = {
	root: `${PREFIX}-root`,
	loadingContainer: `${PREFIX}-loadingContainer`,
	panel: `${PREFIX}-panel`,
	wrapper: `${PREFIX}-wrapper`,
	titleBar: `${PREFIX}-titleBar`,
}

const Root = styled('div')(({ theme }) => ({
	[`& .${classes.loadingContainer}`]: {
		padding: theme.spacing(3),
	},
	[`& .${classes.wrapper}`]: {
		// maxHeight: 440,
	},

	[`& .${classes.titleBar}`]: {
		padding: theme.spacing(1),
	},
}))

function InterventionsTable(props) {
	const { interventions, interventionCompliance, symptomScore, hydrateChildren, loading, onRefresh } = props
	const [order, setOrder] = React.useState('desc')
	const [orderBy, setOrderBy] = React.useState('occurred_at')
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		const interestedDates = stableSort(interventions, getComparator(order, orderBy))
			.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
			.reduce(
				(acc, item) => {
					const start = acc.start ? moment(acc.start) : moment()
					const end = acc.end ? moment(acc.end) : moment()
					if (moment(item.created_at).isSameOrBefore(start)) {
						acc.start = item.created_at
					}
					if (item.completed_at && moment(item.completed_at).isSameOrAfter(end)) {
						acc.end = item.completed_at
					}
					return acc
				},
				{
					start: null,
					end: null,
				}
			)
		if (!interestedDates.end) {
			interestedDates.end = moment().toISOString()
		}
		hydrateChildren(null, {
			from: interestedDates.start,
			to: interestedDates.end,
		})
	}, [page, rowsPerPage, order, interventions.length])

	return (
		<Root>
			<AppBar className={classes.titleBar} position="static" color="default" elevation={0}>
				<Toolbar>
					<Grid container spacing={2} alignItems="center">
						<Grid item xs>
							<Typography variant="h5" color="textSecondary" component="h5">
								Historical Interventions
							</Typography>
						</Grid>
						<Grid item>
							<IconButton color="inherit" onClick={onRefresh} size="large">
								<RefreshIcon className={classes.block} color="inherit" />
							</IconButton>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>

			{!loading ? (
				<Grid container spacing={2} direction="row">
					<Grid item xs>
						<TableContainer component={'div'} className={classes.wrapper}>
							<Table size={'small'} stickyHeader aria-label="collapsible table">
								<EnhancedTableHead
									classes={classes}
									order={order}
									orderBy={orderBy}
									onRequestSort={handleRequestSort}
									rowCount={interventions.length}
								/>
								<TableBody>
									{stableSort(interventions, getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, i) => {
											const startDate = moment(row.occurred_at).format('YYYY-MM-DD')
											const endDate = row.completed_at ? moment(row.completed_at) : moment()
											const endedDuration = moment(row.occurred_at).diff(endDate)
											row.endedDuration = endedDuration

											const scopedDays = EnumerateDaysBetweenDates(startDate, endDate)

											const dss = scopedDays.reduce(
												(acc, day) => {
													if (symptomScore.daysScores[day]) {
														acc.total += symptomScore.daysScores[day][0].score
														acc.data.push(symptomScore.daysScores[day][0].score)
													}
													return acc
												},
												{
													total: 0,
													data: [],
												}
											)

											const dssResults = {
												name: 'Symptom Score',
												average: dss.data.length ? (dss.total / dss.data.length).toFixed(2) : 0,
												total: dss.data.length,
												std: dss.data.length ? std(dss.data).toFixed(2) : 0,
											}
											row.dss = dssResults.average
											return (
												<Row
													symptomScore={symptomScore}
													interventionCompliance={interventionCompliance}
													key={row.name}
													row={row}
													hydrateChildren={hydrateChildren}
												/>
											)
										})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={interventions.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Grid>
				</Grid>
			) : (
				<Grid container alignItems={'center'} justifyContent="center" className={classes.loadingContainer}>
					<Grid item>
						<CircularProgress disableShrink={true} />
					</Grid>
				</Grid>
			)}
		</Root>
	)
}

export { InterventionsTable }
