import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import * as storage from 'localforage' // defaults to localStorage for web
import PropTypes from 'prop-types'
import CssBaseline from '@mui/material/CssBaseline'
import Hidden from '@mui/material/Hidden'
import Navigator from 'organisms/Navigator'
import NotificationsPanel from 'organisms/NotificationsPanel'
import Header from 'organisms/Header'
import { useTranslation } from 'react-i18next'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ThemeProvider from 'styles/theme-provider'
import { QueryParamProvider } from 'use-query-params'

const drawerWidth = 226
const panelWidth = 350

const PREFIX = 'Layout'

const classes = {
	root: `${PREFIX}-root`,
	drawer: `${PREFIX}-drawer`,
	panel: `${PREFIX}-panel`,
	appContent: `${PREFIX}-appContent`,
	mainContent: `${PREFIX}-mainContent`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		display: 'flex',
		minHeight: '100vh',
	},

	[`& .${classes.drawer}`]: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},

	[`& .${classes.panel}`]: {
		[theme.breakpoints.up('sm')]: {
			width: panelWidth,
			flexShrink: 0,
		},
	},

	[`& .${classes.appContent}`]: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		zIndex: 1,
	},

	[`&.${classes.mainContent}`]: {
		flex: 1,
		padding: '0px',
	},
}))

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

function Layout({ location, children, pageContext = { titleKey: 'home', layout: true } }) {
	const theme = useTheme()
	const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false)
	const [showAlert, setAlert] = useState(false)
	useEffect(() => {
		;(async () => {
			setAlert(await storage.getItem('betaUnderstood'))
		})()
	}, [showAlert])

	const onDrawerToggle = () => setMobileDrawerOpen(!mobileDrawerOpen)

	const [mobilePanelOpen, setMobilePanelOpen] = useState(false)
	const onPanelToggle = () => setMobilePanelOpen(!mobilePanelOpen)

	const { t } = useTranslation()
	const hideMobileNav = useMediaQuery(theme.breakpoints.up('sm'))
	const hideMobileNotifPanel = useMediaQuery(theme.breakpoints.up('sm'))

	const title = t(`${pageContext.titleKey}.title`)
	const pathsArray = location.pathname.split('/').filter((p) => p)
	const targetLocation = `/${pathsArray.pop()}`

	if (pageContext.layout) {
		return (
			<QueryParamProvider>
				<Root className={classes.root}>
					<nav className={classes.drawer}>
						{!hideMobileNav ? (
							<Navigator
								PaperProps={{ style: { borderWidth: 0, width: drawerWidth } }}
								variant="temporary"
								open={mobileDrawerOpen}
								onClose={onDrawerToggle}
								title={title}
								location={location.pathname}
								iOS={iOS}
							/>
						) : (
							<Navigator
								PaperProps={{ style: { borderWidth: 0, width: drawerWidth } }}
								variant="permanent"
								onClose={onDrawerToggle}
								title={title}
								location={location.pathname}
								iOS={iOS}
							/>
						)}
					</nav>
					<nav>
						{!hideMobileNotifPanel ? (
							<NotificationsPanel
								PaperProps={{ style: { width: panelWidth } }}
								variant="temporary"
								open={mobilePanelOpen}
								onClose={onPanelToggle}
								title={title}
								location={location.pathname}
								iOS={iOS}
							/>
						) : (
							<NotificationsPanel
								PaperProps={{ style: { width: panelWidth } }}
								variant="temporary"
								open={mobilePanelOpen}
								onClose={onPanelToggle}
								title={title}
								location={location.pathname}
								iOS={iOS}
							/>
						)}
					</nav>
					<div className={classes.appContent}>
						{showAlert ? (
							<Alert
								variant="outlined"
								severity="warning"
								onClose={async () => {
									await storage.setItem('betaUnderstood', false)
									setAlert(false)
								}}
							>
								<AlertTitle>We're under construction</AlertTitle>
								The portal is under heavy development! There are several bugs and incomplete features —{' '}
								<strong>stay tuned!</strong>
							</Alert>
						) : (
							<></>
						)}

						<Header onDrawerToggle={onDrawerToggle} onPanelToggle={onPanelToggle} title={title} />

						<main className={classes.mainContent}>
							<Box>{children}</Box>
						</main>
					</div>
				</Root>
			</QueryParamProvider>
		)
	}
	return (
		<QueryParamProvider>
			<Root>
				<main className={classes.mainContent}>
					<Box>{children}</Box>
				</main>
			</Root>
		</QueryParamProvider>
	)
}

Layout.propTypes = {
	location: PropTypes.object.isRequired,
	children: PropTypes.object,
	pageContext: PropTypes.object,
}

function RootLayout(props) {
	return (
		<ThemeProvider>
			<Layout {...props} />
		</ThemeProvider>
	)
}
export default RootLayout
