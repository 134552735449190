import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { ThemeProvider, FormProgress, FormProvider, FormBody } from '@dieta/web-dieta-components'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

const PREFIX = 'StaticSurveyQuestionView'

const classes = {
	surveyContainer: `${PREFIX}-surveyContainer`,
	loadingContainer: `${PREFIX}-loadingContainer`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.surveyContainer}`]: {
		backgroundColor: 'white',
		color: 'black',
	},
	[`& .${classes.loadingContainer}`]: {
		padding: theme.spacing(3),
	},
}))

function StaticSurveyQuestionView(props) {
	useEffect(() => {
		if (!props.survey?.states) {
			props.requestData(props.survey)
		}
	}, [props.survey?.states])

	if (!props.survey?.states) {
		return (
			<Grid container justifyContent="center" className={classes.loadingContainer}>
				<CircularProgress disableShrink={true} />
			</Grid>
		)
	}
	const question = props.question

	let formattedAnswer = []
	if (props.answer) {
		Object.keys(props.answer).forEach((key) => {
			if (isNaN(parseInt(key))) {
				formattedAnswer.push({ [key]: props.answer[key] })
			} else {
				if (typeof props.answer[key] === 'object') {
					formattedAnswer = props.answer[key]
				} else {
					formattedAnswer.push(props.answer[key])
				}
			}
		})
	}

	const statesData = props?.survey?.states?.data
		.filter((state) => state.question === question)
		.map((state, i) => {
			state.step = `${i + 1}`
			state.questionLabel = ''
			state.answer = formattedAnswer
			state.complete = true
			return state
		})

	return (
		<Root className={classes.surveyContainer}>
			<ThemeProvider>
				<FormProvider static currentState={'1'} states={statesData}>
					{props.showNav ? <FormProgress /> : <></>}
					<FormBody noFooter />
				</FormProvider>
			</ThemeProvider>
		</Root>
	)
}

export { StaticSurveyQuestionView }
