import { getSafeWorst, getInterventionRecs, getStoolLabels } from 'api/analytics'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'

export const fetchSafeWorst = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.FETCH_SAFE_WORST_TRIGGERS })
		try {
			const res = await getSafeWorst(args, {
				dispatch,
				event: types.FETCH_SAFE_WORST_TRIGGERS_SUCCESS,
			})
			handleSuccess(dispatch, types.FETCH_SAFE_WORST_TRIGGERS_SUCCESS, res.data)
			return res
		} catch (err) {
			console.log(err)
			handleFail(dispatch, types.FETCH_SAFE_WORST_TRIGGERS_FAIL, err)
		}
	}

	return thunk
}

export const fetchStoolLabels = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.FETCH_STOOL_LABELS_TIMESERIES })
		try {
			const res = await getStoolLabels(args, {
				dispatch,
				event: types.FETCH_STOOL_LABELS_TIMESERIES_SUCCESS,
			})
			handleSuccess(dispatch, types.FETCH_STOOL_LABELS_TIMESERIES_SUCCESS, res.data && res.data.timeseries)
			return res
		} catch (err) {
			console.log(err)
			handleFail(dispatch, types.FETCH_STOOL_LABELS_TIMESERIES_FAIL, err)
		}
	}

	return thunk
}

export const fetchInterventionRecs = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.FETCH_INTERVENTION_RECS })
		try {
			const res = await getInterventionRecs(args, {
				dispatch,
				event: types.FETCH_INTERVENTION_RECS_SUCCESS,
			})
			handleSuccess(dispatch, types.FETCH_INTERVENTION_RECS_SUCCESS, res.data)
			return res
		} catch (err) {
			console.log(err)
			handleFail(dispatch, types.FETCH_INTERVENTION_RECS_FAIL, err)
		}
	}

	return thunk
}
