import { fetchNotes, fetchNote, addNote as postNote, updateNote as putNote, removeNote } from 'api/note'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'

const getDefaultNote = (attentionDate) => ({
	_app: {
		uuid: null,
		type: 'note',
		synced: false,
	},
})

export const resetNoteLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_NOTE_LOADERS })
	}
}

export const addNote = (note) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_NOTE, payload: note })
		try {
			const res = await postNote(note)
			const contextResponse = {
				...res.data,
				_app: {
					...note._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_NOTE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...note,
				_app: {
					err,
					...note._app,
				},
			}
			handleFail(dispatch, types.ADD_NOTE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateNote = (note, previousNote) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.UPDATE_NOTE,
			payload: { new: note, old: previousNote },
		})
		try {
			const res = note.id ? await putNote(note) : await postNote(note)
			const contextResponse = {
				...res.data,
				_app: {
					...note._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_NOTE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...note,
				_app: {
					err,
					...note._app,
				},
			}
			handleFail(dispatch, types.UPDATE_NOTE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteNote = (note) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.DELETE_NOTE, payload: note })
		try {
			const res = await removeNote(note)
			const contextResponse = {
				...res.data,
				_app: {
					...note._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_NOTE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...note,
				_app: {
					err,
					...note._app,
				},
			}
			handleFail(dispatch, types.DELETE_NOTE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getNote = (note) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.GET_NOTE,
			payload: note,
		})
		try {
			const res = await fetchNote(note)
			const contextResponse = {
				...res.data,
				_app: {
					...note._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.GET_NOTE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...note,
				_app: {
					err,
					...note._app,
				},
			}
			handleFail(dispatch, types.GET_NOTE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getNotes = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_NOTES })
		try {
			await fetchNotes(args, {
				dispatch,
				event: types.GET_NOTES_SUCCESS,
			})
			handleSuccess(dispatch, types.GET_NOTES_SUCCESS_COMPLETE)
		} catch (err) {
			handleFail(dispatch, types.GET_NOTES_FAIL, err)
		}
	}
	return thunk
}

export const setCurrentNote = (note, attentionDate) => {
	if (!note) {
		note = getDefaultNote(attentionDate)
	}
	return (dispatch) => {
		dispatch({ type: types.CURRENT_NOTE_SET, payload: note })
	}
}

export const clearCurrentNote = (note = getDefaultNote()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_NOTE_CLEAR, payload: note })
	}
}
