import * as types from 'types'

import { storeItemsTransactor, storeItemsMerger, deepMerge } from 'reducers/z_redux_helpers'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	data: [],
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

const questionDecorator = (entity, questionEntitiesMap) => {
	return questionEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

const questionUnsyncedDecorator = (entity, questionEntitiesMap) => {
	return questionEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// GET QUESTIONS
		case types.GET_QUESTIONS_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					action.payload.questions
						.map((entity) => {
							return storeItemsTransactor(state, 'data', entity, {
								id: entity.id,
							})
						})
						.reduce((acc, current) => acc.concat(current), [])
				),
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_QUESTIONS_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		case types.GET_QUESTIONS:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		// UPDATE QUESTION
		case types.UPDATE_QUESTION_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						questionDecorator
					)
				),
				loading: {
					...state.loading,
					update: false,
				},
			}
		case types.UPDATE_QUESTION_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					update: false,
				},
			}
		case types.UPDATE_QUESTION:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),

				loading: {
					...state.loading,
					update: true,
				},
			}

		// DELETE QUESTION
		case types.DELETE_QUESTION_SUCCESS:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: false,
				},
			}
		case types.DELETE_QUESTION_FAIL:
			return {
				...state,

				loading: {
					...state.loading,
					delete: false,
				},
			}
		case types.DELETE_QUESTION:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: true,
				},
			}

		// ADD QUESTION
		case types.ADD_QUESTION_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						questionDecorator
					)
				),
				loading: {
					...state.loading,
					create: false,
				},
			}
		case types.ADD_QUESTION_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					create: false,
				},
			}
		case types.ADD_QUESTION:
			return {
				...state,
				data: state.data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					create: true,
				},
			}

		case types.RESET_QUESTION_LOADERS:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		// case 'persist/REHYDRATE':
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.question,
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		case types.SCENE:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		case types.QUESTION_CONTEXT_RESET:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// DEFAULT
		default:
			return state
	}
}
