import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'

export const putAnnotation = (bowelId, clinicianId, payload) => {
	return axiosInstance.request({
		url: `v3/annotation/${bowelId}?annotatedBy=${clinicianId}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: { payload },
	})
}

export const getAnnotations = (uuid, skip, limit) => {
	return axiosInstance.request({
		url: `v3/annotations?uuid=${uuid}&skip=${skip}&limit=${limit}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}
