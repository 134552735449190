import { fetchSurveys, enrichSurvey } from 'api/survey'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'

const getDefaultSurvey = () => ({
	_app: {
		uuid: null,
		type: 'survey',
		synced: false,
	},
})

export const resetSurveyLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_SURVEY_LOADERS })
	}
}

export const deleteSurvey = (survey) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.FINISH_SURVEY, payload: survey })
		try {
			handleSuccess(dispatch, types.FINISH_SURVEY_SUCCESS, survey)
			return survey
		} catch (err) {
			handleFail(dispatch, types.FINISH_SURVEY_FAIL, survey)
		}
	}
	return thunk
}

export const getSurveys = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_SURVEYS })
		try {
			const res = await fetchSurveys(args)
			await handleSuccess(dispatch, types.GET_SURVEYS_SUCCESS, res.data)
			return res.data
		} catch (err) {
			handleFail(dispatch, types.GET_SURVEYS_FAIL, err)
		}
	}
	return thunk
}

export const assembleSurvey = (survey) => {
	async function thunk(dispatch) {
		dispatch({ type: types.ENRICH_SURVEYS })
		try {
			const res = await enrichSurvey(survey.id)
			await handleSuccess(dispatch, types.ENRICH_SURVEYS_SUCCESS, res.data)
			return res.data
		} catch (err) {
			handleFail(dispatch, types.ENRICH_SURVEYS_FAIL, err)
		}
	}
	return thunk
}

export const setCurrentSurvey = (survey = getDefaultSurvey()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_SURVEY_SET, payload: survey })
	}
}

export const clearCurrentSurvey = (survey = getDefaultSurvey()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_SURVEY_CLEAR, payload: survey })
	}
}
