import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import ImageGallery from 'react-image-gallery'
import { Chip, Stack } from '@mui/material'
const useKeyPress = function (targetKey) {
	const [keyPressed, setKeyPressed] = useState(true)

	function downHandler({ key }) {
		if (key === targetKey) {
			setKeyPressed(true)
		}
	}

	function upHandler({ key }) {
		if (key === targetKey) {
			setKeyPressed(false)
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', downHandler)
		window.addEventListener('keyup', upHandler)
		return () => {
			window.removeEventListener('keydown', downHandler)
			window.removeEventListener('keyup', upHandler)
		}
	}, [keyPressed])

	return keyPressed
}

const PREFIX = 'Carousel'

const classes = {
	appBar: `${PREFIX}-appBar`,
	title: `${PREFIX}-title`,
}

const StyledContainer = styled('div')(({ theme }) => ({
	[`& .${classes.appBar}`]: {
		position: 'relative',
	},
	[`& .${classes.title}`]: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}))

// const Transition = React.forwardRef(function Transition(props, ref) {
// 	return <Slide direction="up" ref={ref} {...props} />
// })

function ImageCarouselDialog({ open, entityImages, startIndex = 0, handleClickOpen, handleClose }) {
	const escapePress = useKeyPress('Escape')

	useEffect(() => {
		if (open && !escapePress) {
			handleClose()
		}
	}, [escapePress])

	const images = entityImages.map((image) => {
		return {
			original: image.uri,
			thumbnail: image.uri,
			confidence: image.confidence,
		}
	})
	return (
		<StyledContainer>
			<Dialog fullScreen open={open} TransitionProps={{ onClose: handleClose }}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<Typography variant="h6" className={classes.title}></Typography>
						<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
				<ImageGallery
					thumbnailPosition={'left'}
					showPlayButton={false}
					items={images}
					startIndex={startIndex}
					renderItem={(item) => {
						return (
							<div className="image-gallery-image">
								<img
									src={item.original}
									style={{
										maxHeight: `calc(100vh - 46px)`,
										maxWidth: '100vw',
										objectFit: 'scale-down',
										fontFamily: "'object-fit: scale-down'",
									}}
								/>
								<Stack py={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
									<Chip
										sx={{
											'& .MuiChip-label': {
												overflow: 'visible !important',
												fontSize: 17,
												px: 3,
											},
										}}
										style={{ boxShadow: '0px 0px 10px gray' }}
										label={`Is-Stool Confidence = ${item?.confidence}`}
										size="medium"
										variant="filled"
										color="primary"
									/>
								</Stack>
							</div>
						)
					}}
				/>
			</Dialog>
		</StyledContainer>
	);
}

export { ImageCarouselDialog }
