import { auth0_auth_instance, auth0_social_instance } from 'src/auth0config'
import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import * as actions from 'actions'
import { configuredStore } from 'src/configureStore'

export const getValidTokens = async () => {
	return await configuredStore.store.dispatch(actions.refreshToken())
}

export const getUserStats = async () => {
	try {
		const auth = configuredStore.store.getState().auth
		const userStats = await axiosInstance.request({
			method: 'get',
			source: AxiosAbortController.signal,
			url: `v1/users/${encodeURIComponent(auth.sub)}/stats`,
		})
		return userStats.data
	} catch (err) {}
}

// export const getUserData = async () => {
// 	try {
// 		const auth = configuredStore.store.getState().auth
// 		const user = await axiosInstance.request({
// 			method: 'get',
//      source: AxiosAbortController.signal,
// 			url: `v1/users/${encodeURIComponent(auth.sub)}`,
// 			noFederate: true,
// 		})
// 		return user.data
// 	} catch (err) {}
// }

export const getUserProfilePicture = async () => {
	try {
		const auth = configuredStore.store.getState().auth
		const user = await axiosInstance.request({
			method: 'get',
			source: AxiosAbortController.signal,
			url: `v1/users/${encodeURIComponent(auth.sub)}/profilePicture`,
		})
		return user.data
	} catch (err) {}
}

// export const createAccount = async (username, password) => {
// 	return auth0_auth_instance.webAuth
// 		.signup({
// 			email: username,
// 			password,
// 			connection: process.env.AUTH0_REALM,
// 			avatar: null,
// 		})
// 		.then((res) => {
// 			return loginWithUserPass(res.email, password)
// 		})
// }

export const resetPassword = async (username) => {
	return new Promise((resolve, reject) => {
		auth0_social_instance.changePassword(
			{
				email: username,
				connection: 'Username-Password-Authentication',
				avatar: null,
			},
			(err, res) => {
				if (err) {
					reject(err)
				}
				resolve(res)
			}
		)
	})
}

export const loginWithSocial = async (connection) => {
	return new Promise((resolve, reject) => {
		auth0_social_instance.popup.authorize(
			{
				scope: 'openid email profile offline_access roles profile roles https://bigdieta.com/roles',
				audience: process.env.AUTH0_AUDIENCE,
				connection: connection,
				prompt: 'login',
				avatar: null,
				owp: true,
			},
			(err, res) => {
				if (err) {
					reject(err)
				}
				resolve(res)
			}
		)
	})
}

export const adminLogin = async (auth, creds) => {
	try {
		const res = await axiosInstance.request({
			method: 'POST',
			source: AxiosAbortController.signal,
			url: `v1/auth/admin`,
			headers: {
				authorization: `Bearer ${auth.accessToken}`,
			},
			data: {
				target: creds.target,
				client_id: process.env.AUTH0_CLIENT_ID,
			},
		})

		return res.data.redirect
	} catch (err) {
		console.log(err)
	}
}

export const loginWithUserPass = async (username, password) => {
	return new Promise((resolve, reject) => {
		auth0_auth_instance.login(
			{
				scope: 'openid email profile offline_access roles profile roles https://bigdieta.com/roles',
				audience: process.env.AUTH0_AUDIENCE,
				realm: 'Username-Password-Authentication',
				username,
				password,
			},
			(err, res) => {
				if (err) {
					reject(err)
				}
				resolve(res)
			}
		)
	})
}

export const logout = async () => {
	try {
		// await cognitoLogout();
		AxiosAbortController.abort('Request cancelled')
	} catch (err) {}
}

export const updateUserMeta = async (auth, meta) => {
	const fullUserRes = await axiosInstance.request({
		method: 'PUT',
		source: AxiosAbortController.signal,
		url: `v1/users/${encodeURIComponent(auth.sub || auth.user_id)}`,
		data: {
			...meta,
		},
		noFederate: true,
	})
	return fullUserRes.data
}
