export const SENDBIRD_CONNECT = 'SENDBIRD_CONNECT'
export const SENDBIRD_CONNECT_SUCCESS = 'SENDBIRD_CONNECT_SUCCESS'
export const SENDBIRD_CONNECT_FAIL = 'SENDBIRD_CONNECT_FAIL'

export const SENDBIRD_RECONNECT = 'SENDBIRD_RECONNECT'
export const SENDBIRD_RECONNECT_SUCCESS = 'SENDBIRD_RECONNECT_SUCCESS'
export const SENDBIRD_RECONNECT_FAIL = 'SENDBIRD_RECONNECT_FAIL'

export const SENDBIRD_DISCONNECT = 'SENDBIRD_DISCONNECT'

export const CHAT_CONTEXT_RESET = 'CHAT_CONTEXT_RESET'

export const GET_SORTED_CHANNELS = 'GET_SORTED_CHANNELS'
export const GET_SORTED_CHANNELS_SUCCESS = 'GET_SORTED_CHANNELS_SUCCESS'
export const GET_SORTED_CHANNELS_FAIL = 'GET_SORTED_CHANNELS_FAIL'
export const CLEAR_SORTED_CHANNELS = 'CLEAR_SORTED_CHANNELS'
