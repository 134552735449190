import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateDefaultArgs } from 'api/api_utils'

const formatClinicianData = (clinicianData) => ({
	id: clinicianData.id,
	is_verified: clinicianData.is_verified || 0,
})

const formatClinicianAdminSettings = (clinicianData) => ({
	key: clinicianData.key,
	is_enrolling: clinicianData.is_enrolling,
})

export const addClinician = (clinicianData) => {
	return axiosInstance.request({
		url: 'v1/clinicians',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatClinicianData(clinicianData),
	})
}

export const updateClinician = (clinicianData) => {
	return axiosInstance.request({
		url: `v1/clinicians/${clinicianData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatClinicianData(clinicianData),
	})
}

export const removeClinician = (clinicianData) => {
	return axiosInstance.request({
		url: `v1/clinicians/${clinicianData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

export const fetchClinicians = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/clinicians?${queryString}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const getClinicianSettings = (clinicianData) => {
	return axiosInstance.request({
		url: `v1/clinicians/${clinicianData.key}/administrate`,
		source: AxiosAbortController.signal,
		method: 'get',
		noFederate: true,
	})
}
export const updateClinicianSettings = (clinicianData) => {
	return axiosInstance.request({
		url: `v1/clinicians/${clinicianData.key}/administrate`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatClinicianAdminSettings(clinicianData),
		noFederate: true,
	})
}
