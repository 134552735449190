import React from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { red } from '@mui/material/colors'
import ReplyIcon from '@mui/icons-material/Reply'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'

const PREFIX = 'Layout'

const classes = {
	root: `${PREFIX}-root`,
	drawer: `${PREFIX}-drawer`,
	panel: `${PREFIX}-panel`,
	appContent: `${PREFIX}-appContent`,
	mainContent: `${PREFIX}-mainContent`,
}

const StyledCard = styled(Card)(({ theme }) => ({
	[`&.${classes.root}`]: {
		margin: 10,
	},
	[`& .${classes.expand}`]: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	[`& .${classes.expandOpen}`]: {
		transform: 'rotate(180deg)',
	},
	[`& .${classes.avatar}`]: {
		backgroundColor: red[500],
	},
}))

function MessageCard() {
	const [expanded, setExpanded] = React.useState(false)

	const handleExpandClick = () => {
		setExpanded(!expanded)
	}

	return (
		<StyledCard className={classes.root}>
			<CardHeader
				avatar={
					<Avatar aria-label="recipe" className={classes.avatar}>
						B
					</Avatar>
				}
				action={
					<IconButton color="inherit" aria-label="settings" size="large">
						<MoreVertIcon />
					</IconButton>
				}
				title={'Patient A'}
				subheader="May 20, 2020"
			/>
			<CardActions disableSpacing>
				<IconButton color="inherit" aria-label="reply" size="large">
					<ReplyIcon />
				</IconButton>
				<IconButton color="inherit" aria-label="dismiss" size="large">
					<CloseIcon />
				</IconButton>
				<IconButton
					className={classNames(classes.expand, {
						[classes.expandOpen]: expanded,
					})}
					color="inherit"
					onClick={handleExpandClick}
					aria-expanded={expanded}
					aria-label="show more"
					size="large"
				>
					<ExpandMoreIcon />
				</IconButton>
			</CardActions>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				<CardContent>
					<Typography variant="body2" color="textSecondary" component="p">
						Hey, I'm about finishing my diet. When can we sync on next steps?
					</Typography>
				</CardContent>
			</Collapse>
		</StyledCard>
	)
}

export { MessageCard }
