exports.components = {
  "component---src-routes-404-js": () => import("./../../../src/routes/404.js" /* webpackChunkName: "component---src-routes-404-js" */),
  "component---src-routes-admin-login-js": () => import("./../../../src/routes/adminLogin.js" /* webpackChunkName: "component---src-routes-admin-login-js" */),
  "component---src-routes-analytics-js": () => import("./../../../src/routes/analytics.js" /* webpackChunkName: "component---src-routes-analytics-js" */),
  "component---src-routes-annotations-page-js": () => import("./../../../src/routes/annotationsPage.js" /* webpackChunkName: "component---src-routes-annotations-page-js" */),
  "component---src-routes-authentication-js": () => import("./../../../src/routes/authentication.js" /* webpackChunkName: "component---src-routes-authentication-js" */),
  "component---src-routes-callback-js": () => import("./../../../src/routes/callback.js" /* webpackChunkName: "component---src-routes-callback-js" */),
  "component---src-routes-chat-js": () => import("./../../../src/routes/chat.js" /* webpackChunkName: "component---src-routes-chat-js" */),
  "component---src-routes-diary-js": () => import("./../../../src/routes/diary.js" /* webpackChunkName: "component---src-routes-diary-js" */),
  "component---src-routes-forgot-password-js": () => import("./../../../src/routes/forgotPassword.js" /* webpackChunkName: "component---src-routes-forgot-password-js" */),
  "component---src-routes-functions-js": () => import("./../../../src/routes/functions.js" /* webpackChunkName: "component---src-routes-functions-js" */),
  "component---src-routes-history-js": () => import("./../../../src/routes/history.js" /* webpackChunkName: "component---src-routes-history-js" */),
  "component---src-routes-hosting-js": () => import("./../../../src/routes/hosting.js" /* webpackChunkName: "component---src-routes-hosting-js" */),
  "component---src-routes-interventions-js": () => import("./../../../src/routes/interventions.js" /* webpackChunkName: "component---src-routes-interventions-js" */),
  "component---src-routes-jotform-js": () => import("./../../../src/routes/jotform.js" /* webpackChunkName: "component---src-routes-jotform-js" */),
  "component---src-routes-legacy-js": () => import("./../../../src/routes/legacy.js" /* webpackChunkName: "component---src-routes-legacy-js" */),
  "component---src-routes-login-js": () => import("./../../../src/routes/login.js" /* webpackChunkName: "component---src-routes-login-js" */),
  "component---src-routes-ml-js": () => import("./../../../src/routes/ml.js" /* webpackChunkName: "component---src-routes-ml-js" */),
  "component---src-routes-notes-js": () => import("./../../../src/routes/notes.js" /* webpackChunkName: "component---src-routes-notes-js" */),
  "component---src-routes-patient-js": () => import("./../../../src/routes/patient.js" /* webpackChunkName: "component---src-routes-patient-js" */),
  "component---src-routes-profile-js": () => import("./../../../src/routes/profile.js" /* webpackChunkName: "component---src-routes-profile-js" */),
  "component---src-routes-program-js": () => import("./../../../src/routes/program.js" /* webpackChunkName: "component---src-routes-program-js" */),
  "component---src-routes-settings-js": () => import("./../../../src/routes/settings.js" /* webpackChunkName: "component---src-routes-settings-js" */),
  "component---src-routes-signup-js": () => import("./../../../src/routes/signup.js" /* webpackChunkName: "component---src-routes-signup-js" */),
  "component---src-routes-stool-trends-js": () => import("./../../../src/routes/stoolTrends.js" /* webpackChunkName: "component---src-routes-stool-trends-js" */),
  "component---src-routes-study-js": () => import("./../../../src/routes/study.js" /* webpackChunkName: "component---src-routes-study-js" */),
  "component---src-routes-survey-js": () => import("./../../../src/routes/survey.js" /* webpackChunkName: "component---src-routes-survey-js" */)
}

