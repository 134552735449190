import React, { Component, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { EnumerateDaysBetweenDates } from 'utils/helpers'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import { std } from 'mathjs'

import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn'

const PREFIX = 'InterventionComplianceDetailsCard'

const classes = {
	root: `${PREFIX}-root`,
	icon: `${PREFIX}-icon`,
	tableTitle: `${PREFIX}-tableTitle`,
}

const Root = styled(Paper)(({ theme }) => ({
	[`&.${classes.root}`]: {},
	[`& .${classes.icon}`]: {
		height: 30,
		width: 30,
		verticalAlign: 'middle',
	},
	[`& .${classes.tableTitle}`]: {
		fontWeight: 'bold',
	},
}))

class InterventionComplianceDetailsCard extends Component {
	constructor(props) {
		super(props)
	}

	static getDerivedStateFromProps(props, state) {
		const scopedDays = EnumerateDaysBetweenDates(
			props.currentIntervention.occurred_at,
			props.currentIntervention.completed_at
		)

		const compliance = (
			props.intervention.interventionCompliance.interventionMap[props.currentIntervention.id] || []
		).reduce(
			(acc, interv) => {
				if (interv.compliance === 'true') {
					acc.complied += 1
				} else if (interv.compliance === 'false') {
					acc.noncompliance += 1
				}
				return acc
			},
			{
				complied: 0,
				unknown: 0,
				noncompliance: 0,
				total: scopedDays.length,
			}
		)
		compliance.unknown = compliance.total - (compliance.complied + compliance.noncompliance)

		const results = [
			{
				name: 'Adherence',
				compliance: compliance.total ? `${((compliance.complied / compliance.total) * 100).toFixed(2)}%` : '0%',
				noncompliance: compliance.total
					? `${((compliance.noncompliance / compliance.total) * 100).toFixed(2)}%`
					: '0%',
				unknownCompliance: `${
					compliance.unknown ? ((compliance.unknown / compliance.total) * 100).toFixed(2) : '0'
				}%`,
				total: compliance.total,
			},
		]

		return {
			...state,
			conclusions: results,
		}
	}

	state = {
		conclusions: [],
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		return true
	}
	componentDidMount = () => {}

	render() {
		return (
			<Root className={classes.root}>
				<AppBar className={classes.titleBar} position="static" color="default" elevation={1}>
					<Toolbar>
						<Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
							<Grid item>
								<AssignmentTurnedIn
									className={classes.icon}
									style={{
										color: '#767676',
									}}
								/>
							</Grid>
							<Grid item>
								<Typography variant="body1" color="textSecondary" className={classes.tableTitle}>
									Adherence Properties
								</Typography>
							</Grid>
							<Grid item xs>
								{!!(
									this.props.intervention.loading.read ||
									this.props.intervention.interventionCompliance.loading.read
								) && (
									// <CircularProgress
									// 	color="inherit"
									// 	disableShrink={true}
									// 	style={{ width: 20, height: 20 }}
									// />
									<LinearProgress color="inherit" disableShrink={true} />
								)}
							</Grid>
						</Grid>
					</Toolbar>
				</AppBar>

				<Grid container>
					<TableContainer component={'div'}>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>Statistic</TableCell>
									<TableCell align="right">Complied</TableCell>
									<TableCell align="right">Not Complied</TableCell>
									<TableCell align="right">Unmarked</TableCell>
									<TableCell align="right">Total Days</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{this.state.conclusions.map((characteristic) => {
									return (
										<TableRow
											key={characteristic.name}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell
												component="th"
												scope="row"
												style={{
													textTransform: 'capitalize',
												}}
											>
												{characteristic.name}
											</TableCell>
											<TableCell align="right">{characteristic.compliance}</TableCell>
											<TableCell align="right">{characteristic.noncompliance}</TableCell>
											<TableCell align="right">{characteristic.unknownCompliance}</TableCell>
											<TableCell align="right">{characteristic.total}</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Root>
		)
	}
}

InterventionComplianceDetailsCard.propTypes = {
	intervention: PropTypes.shape({
		data: PropTypes.array,
		interventionCompliance: PropTypes.shape({
			interventionMap: PropTypes.object.isRequired,
			daysCompliance: PropTypes.object.isRequired,
			loading: PropTypes.shape({
				read: PropTypes.bool.isRequired,
			}),
		}),
		loading: PropTypes.shape({
			read: PropTypes.bool.isRequired,
		}),
	}),
}

const mapStateToProps = ({ intervention }) => {
	return {
		intervention,
	}
}

export default compose(connect(mapStateToProps, actions), withTranslation())(InterventionComplianceDetailsCard)
