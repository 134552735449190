import { v4 as uuidv4 } from 'uuid'
import { handleSuccess } from 'actions/z_action_helpers'

import { API } from 'src/constants'

const baseOpts = {
	page: 1,
	limit: API.PAGESIZE,
}

export const checkDeepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)

export const removeProp = (obj, prop) => {
	if (obj) {
		let { [prop]: omit, ...res } = obj
		return res
	}
	return obj
}
/**
 * @description method that takes in pagination query string key pairs and returns a query string
 * @param {Object} args
 *   like: string
 *   |-- AND
 *     |  page: integer
 *     |  limit: integer
 *     |--- OR
 *     |  from: Date
 *     |  to: Date
 * @returns query string
 */
export const paginateDefaultArgs = (safeArgs = {}) => {
	return Object.entries(safeArgs)
		.map((pair) => pair.map(encodeURIComponent).join('='))
		.join('&')
}

export const paginateExhaust = async (req, args, params, type, page = 1, data = [], reduxEvent, extraPayload = {}) => {
	const safeArgs = { ...baseOpts, ...args }
	const queryString = paginateDefaultArgs({ ...safeArgs, page })
	const reqParams = queryString ? `${params.url}?${queryString}` : params.url
	const results = await req(reqParams)
	if (results.error) {
		throw results.error
	}

	if (reduxEvent) {
		reduxEvent = {
			...reduxEvent,
			data: {
				...results.data,
				pageNum: safeArgs.page,
			},
		}
		await handleSuccess(reduxEvent.dispatch, reduxEvent.event, { ...reduxEvent.data, ...extraPayload })
	}

	if (results.data.pages > page && results.data[type].length === safeArgs.limit) {
		return await paginateExhaust(
			req,
			safeArgs,
			params,
			type,
			++page,
			[...data, ...results.data[type]],
			reduxEvent,
			extraPayload
		)
	} else {
		results.data[type] = [...data, ...results.data[type]]
		return results
	}
}

export const getAddedItemsInArrayByID = (prevArray, nextArray) => {
	return nextArray.reduce((accum, nextItem) => {
		if (!prevArray.some((prevItem) => prevItem.id === nextItem.id)) {
			// ingredient was added
			return [...accum, nextItem]
		}
		return accum
	}, [])
}

export const getRemovedItemsInArrayByID = (prevArray, nextArray) => {
	return prevArray.reduce((accum, prevItem) => {
		if (!nextArray.some((nextItem) => nextItem.id === prevItem.id)) {
			return [...accum, prevItem]
		}
		return accum
	}, [])
}

export const getSameItemsInArrayByID = (prevArray, nextArray) => {
	return nextArray.reduce((accum, nextItem) => {
		if (prevArray.some((prevItem) => prevItem.id === nextItem.id)) {
			return [...accum, nextItem]
		}
		return accum
	}, [])
}

export const getSameItemsInArrayByName = (prevArray, nextArray) => {
	return nextArray.reduce((accum, nextItem) => {
		if (prevArray.some((prevItem) => prevItem.name === nextItem.name)) {
			return [...accum, nextItem]
		}
		return accum
	}, [])
}

export const mediaEntityDecorator = (entity) => {
	if (entity.media && entity.media.length && !entity.uuid) {
		return {
			...entity,
			uuid: uuidv4(),
		}
	}
	if (entity.media && entity.media.length) {
		return entity
	}
	return {
		...entity,
		uuid: null,
	}
}
