import React from 'react'
import { styled } from '@mui/material/styles'
import classnames from 'classnames'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Divider from '@mui/material/Divider'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faDumbbell,
	faPills,
	faQuestionCircle,
	faUtensils,
	faNotesMedical,
	faSmile,
	faPoo,
	faBed,
	faPenSquare,
	faNotes,
	faClipboardList,
} from '@fortawesome/free-solid-svg-icons'

import { faClock } from '@fortawesome/free-regular-svg-icons'
import moment from 'moment'
import { ENTITY_THEME } from 'constants'
import Grid from '@mui/material/Grid'
import { EntityImage } from '@components'
import { Button } from '@mui/material'
import { navigate } from 'gatsby'

const PREFIX = 'EventCard'

const classes = {
	root: `${PREFIX}-root`,
	inline: `${PREFIX}-inline`,
	cardTextContent: `${PREFIX}-cardTextContent`,
	columnSpacer: `${PREFIX}-columnSpacer`,
	columnSpacer2x: `${PREFIX}-columnSpacer2x`,
	continuousFlowLine: `${PREFIX}-continuousFlowLine`,
	imagesContainer: `${PREFIX}-imagesContainer`,
	rowContainer: `${PREFIX}-rowContainer`,
	detectedText: `${PREFIX}-detectedText`,
}

const StyledGridContainer = styled(Grid)(({ theme }) => ({
	[`&.${classes.root}`]: {
		// width: '100%',
		// backgroundColor: theme.palette.background.paper,
	},

	[`& .${classes.inline}`]: {
		display: 'inline',
		margin: 'auto',
		width: '100%',
	},
	[`& .${classes.cardTextContent}`]: {
		marginLeft: 5,
	},
	[`& .${classes.columnSpacer}`]: {
		// marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
		overflowY: 'hidden',
	},
	[`& .${classes.columnSpacer2x}`]: {
		marginRight: theme.spacing(1),
	},
	[`& .${classes.continuousFlowLine}`]: {
		width: 1,
		top: '18px',
		left: `calc(50% - 1px)`,
		right: '50%',
		position: 'absolute',
		height: '100%',
		content: '""',
		backgroundColor: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
		margin: 'auto',
	},
	[`& .${classes.imagesContainer}`]: {
		maxWidth: 240,
		whiteSpace: 'nowrap',
		overflowX: 'auto',
		display: 'inline-flex',
	},
	[`& .${classes.rowContainer}`]: {
		minHeight: 80,
		position: 'relative',
		paddingBottom: theme.spacing(1),
	},
	[`& .${classes.detectedText}`]: {
		fontWeight: 'bold',
		color: theme.palette.primary[800],
		marginBottom: 5,
	},
}))

const getIcon = (type, event) => {
	switch (type) {
		case 'sleep':
			return (
				<Avatar
					alt={type}
					style={{
						margin: 'auto',
						backgroundColor: ENTITY_THEME.SLEEP,
					}}
				>
					<FontAwesomeIcon
						style={{
							padding: 2,
							color: '#303030',
						}}
						icon={faBed}
					/>
				</Avatar>
			)
		case 'bowel':
			return (
				<Avatar
					alt={type}
					style={{
						margin: 'auto',
						backgroundColor: ENTITY_THEME.BOWEL,
					}}
				>
					<FontAwesomeIcon
						style={{
							padding: 2,
							color: '#303030',
						}}
						icon={faPoo}
					/>
				</Avatar>
			)
		case 'mood':
			return (
				<Avatar
					alt={type}
					style={{
						margin: 'auto',
						backgroundColor: ENTITY_THEME.MOOD,
					}}
				>
					<FontAwesomeIcon
						style={{
							padding: 2,
							color: '#303030',
						}}
						icon={faSmile}
					/>
				</Avatar>
			)
		case 'symptom':
			return (
				<Avatar
					alt={type}
					style={{
						margin: 'auto',
						backgroundColor: ENTITY_THEME.SYMPTOM,
					}}
				>
					<FontAwesomeIcon
						style={{
							padding: 2,
							color: '#303030',
						}}
						icon={faNotesMedical}
					/>
				</Avatar>
			)
		case 'meal':
			return (
				<Avatar
					alt={type}
					style={{
						margin: 'auto',
						backgroundColor: ENTITY_THEME.MEAL,
					}}
				>
					<FontAwesomeIcon
						style={{
							padding: 2,
							color: '#303030',
						}}
						icon={faUtensils}
					/>
				</Avatar>
			)
		case 'other':
			return (
				<Avatar
					alt={type}
					style={{
						margin: 'auto',
						backgroundColor: ENTITY_THEME.OTHER,
					}}
				>
					<FontAwesomeIcon
						style={{
							padding: 2,
							color: '#303030',
						}}
						icon={faQuestionCircle}
					/>
				</Avatar>
			)
		case 'medication':
			return (
				<Avatar
					alt={type}
					style={{
						margin: 'auto',
						backgroundColor: ENTITY_THEME.MEDICATION,
					}}
				>
					<FontAwesomeIcon
						style={{
							padding: 2,
							color: '#303030',
						}}
						icon={faPills}
					/>
				</Avatar>
			)
		case 'programSurvey':
		case 'program_survey':
		case 'user_program_survey':
			return (
				<Avatar
					alt={type}
					style={{
						margin: 'auto',
						backgroundColor: ENTITY_THEME.PROGRAM_SURVEY,
					}}
				>
					<FontAwesomeIcon
						style={{
							padding: 2,
							color: '#303030',
						}}
						icon={faClipboardList}
					/>
				</Avatar>
			)
		case 'exercise':
			return (
				<Avatar
					alt={type}
					style={{
						margin: 'auto',
						backgroundColor: ENTITY_THEME.EXERCISE,
					}}
				>
					<FontAwesomeIcon
						style={{
							padding: 2,
							color: '#303030',
						}}
						icon={faDumbbell}
					/>
				</Avatar>
			)
		default:
			return (
				<Avatar
					alt={type}
					style={{
						margin: 'auto',
						backgroundColor: ENTITY_THEME.SLEEP,
					}}
				>
					<HelpOutlineIcon
						style={{
							padding: 2,
							color: '#303030',
						}}
					/>
				</Avatar>
			)
	}
}

const getCameraIcon = () => {
	return (
		<CameraAltIcon
			style={{
				fontSize: 18,
				position: 'absolute',
				margin: 'auto',
				bottom: -8,
				right: 0,
				padding: 2,
				color: '#aaaaaa',
			}}
		/>
	)
}

const onEventInteract = (onClick, e, type, id) => {
	onClick(e, type, id)
}

function EventCard(props) {
	const { event, onClick, onError, index, totalEvents, onOpenCarousel, shouldShowImages } = props
	let firstChild = index === 0
	let lastChild = index === totalEvents - 1
	return (
		<StyledGridContainer
			container
			className={classes.rowContainer}
			style={
				lastChild
					? {
							marginBottom: 0,
					  }
					: {}
			}
			onClick={(e) => onEventInteract(onClick, e, event._app.type, event.id)}
		>
			<Grid item className={classes.columnSpacer} style={{ position: 'relative' }}>
				<Grid container direction="column">
					<FontAwesomeIcon
						style={{
							padding: 2,
						}}
						icon={faClock}
					/>

					{!lastChild && <span className={classes.continuousFlowLine} />}
				</Grid>
			</Grid>

			<Grid item className={classes.columnSpacer2x}>
				<Stack spacing={1}>
					<div
						style={{
							textAlign: 'center',
							height: 20,
							marginTop: -6,
							width: 55,
						}}
					>
						<Typography
							component="span"
							variant="body2"
							className={classes.inline}
							color="textPrimary"
							style={
								{
									// lineHeight: 1,
								}
							}
						>
							{moment(event.occurred_at).format('h:mma')}
						</Typography>
					</div>
					<div style={{ position: 'relative', marginBottom: 10 }}>
						{getIcon(event._app.type, event)}
						{event.uuid ? getCameraIcon() : <></>}
					</div>
				</Stack>
			</Grid>
			<Grid item xs>
				<Grid
					container
					direction="row"
					style={{
						marginTop: -7,
					}}
				>
					<Typography component="h5" variant="subtitle1" className={classes.inline} color="textPrimary">
						{!event._app.title ? <Skeleton animation="wave" height={35} /> : event._app.title}
					</Typography>

					{event?._app?.type === 'meal' ? (
						event?.items?.map((item, index) => (
							<Grid
								key={`${item?.name}_${index}`}
								direction={'column'}
								container
								columns={1}
								mb={1}
								textTransform={'capitalize'}
							>
								<Grid item>
									<Typography variant="body2" color={'black'}>
										({index + 1}) {item?.name}
									</Typography>
								</Grid>
								<Grid item>
									<Typography variant="body2" color={'GrayText'}>
										{item?.ingredients?.map((ingredient) => ingredient?.name)?.join(', ')}
									</Typography>
								</Grid>
							</Grid>
						))
					) : (
						<Typography component="h6" variant="body2" className={classes.inline} color="textPrimary">
							{!event._app.subTitle ? <Skeleton animation="wave" height={20} /> : event._app.subTitle}
						</Typography>
					)}
					<div style={{ marginBottom: 8 }}>
						{['programSurvey', 'program_survey', 'user_program_survey'].includes(event._app.type) && (
							<div style={{}}>
								{event.completed_at ? (
									<div
										style={{
											backgroundColor: '#d1fae5',
											borderRadius: 8,
											padding: '1px 8px',
											fontWeight: 600,
											fontSize: 12,
											marginTop: 2,
											marginBottom: 8,
										}}
									>
										<span style={{ color: '#059669' }}>Complete</span>
									</div>
								) : (
									<div
										style={{
											backgroundColor: '#fef3c7',
											borderRadius: 8,
											padding: '1px 8px',
											fontWeight: 600,
											fontSize: 12,
											marginTop: 2,
											marginBottom: 8,
										}}
									>
										<span style={{ color: '#d97706' }}>Incomplete</span>
									</div>
								)}
							</div>
						)}
					</div>

					{event && event.uuid && event._labels ? (
						<Stack flexDirection={'column'} alignItems={'flex-start'}>
							<Typography
								component="h6"
								variant="body2"
								className={classnames(classes.inline, classes.detectedText)}
							>
								{Object.keys(event._labels)
									.sort()
									?.filter((label) => label !== 'stool_confidence')
									?.reduce((acc, label) => {
										const val = event._labels[label]
										if (typeof val === 'object') {
											return acc
										}
										return (acc += `${label} [${Number.isInteger(val) ? val : val.toFixed(2)}] `)
									}, '')}
							</Typography>
							
							{/* {event._labels['stool_confidence']?.median !== undefined &&
								event._labels['stool_confidence']?.median !== null &&
								event?._labels?.['stool_confidence']?.median < 0.5 && (
									<Typography bgcolor={'red'} px={2} borderRadius={30} color={'white'} my={1}>
										Warning: Non Stool [IsStool {event._labels['stool_confidence']?.median}]
									</Typography>
								)} */}
						</Stack>
					) : (
						<></>
					)}

					{event.notes ? (
						<Typography component="h6" variant="body2" className={classes.inline} color="textPrimary">
							<FontAwesomeIcon
								style={{
									padding: 2,
									color: '#a6a832',
								}}
								icon={faPenSquare}
							/>
							{event.notes}
						</Typography>
					) : (
						<></>
					)}
					{props?.onClickAnnotate && event?._app?.type === 'bowel' && event?.media && (
						<Grid item xs={13}>
							<Button
								size="small"
								variant="contained"
								style={{ marginTop: 3, marginBottom: 10 }}
								onClick={() => {
									props?.onClickAnnotate(event)
								}}
							>
								Annotate
							</Button>
						</Grid>
					)}
				</Grid>
			</Grid>
			{event.media && shouldShowImages ? (
				<Grid item className={classes.imagesContainer}>
					{event.media.map((item, index) => {
						return (
							<EntityImage
								image={item}
								type={event._app.type}
								key={item.eTag}
								index={index}
								onError={onError}
								onClick={() => {}}
								onOpenCarousel={() =>
									onOpenCarousel(
										event.media,
										index,
										event._app.uuid,
										event._labels?.['stool_confidence']
									)
								}
							/>
						)
					})}
				</Grid>
			) : (
				<></>
			)}
		</StyledGridContainer>
	)
}

export { EventCard }
