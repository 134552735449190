import * as types from 'types'

import { storeItemsTransactor, storeItemsMerger, deepMerge } from 'reducers/z_redux_helpers'
import _groupBy from 'lodash.groupBy'
import _merge from 'lodash.merge'
import moment from 'moment'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	data: [],
	daysEvents: {},
	current: {},
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

const sleepDecorator = (entity, sleepEntitiesMap) => {
	return sleepEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

const sleepUnsyncedDecorator = (entity, sleepEntitiesMap) => {
	return sleepEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// GET SLEEPS
		case types.GET_SLEEPS_SUCCESS:
			const events = storeItemsMerger(
				state.data,
				action.payload.sleeps
					.map((entity) => {
						return storeItemsTransactor(state, 'data', entity, {
							id: entity.id,
						})
					})
					.reduce((acc, current) => acc.concat(current), [])
			)
			return {
				...state,
				data: events,
				daysEvents: _merge(
					{},
					state.daysEvents,
					_groupBy(events, (item) => {
						return moment(item.occurred_at).format('YYYY-MM-DD')
					})
				),
			}

		case types.GET_SLEEPS_SUCCESS_COMPLETE:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_SLEEPS_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		case types.GET_SLEEPS:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		// UPDATE SLEEP
		case types.UPDATE_SLEEP_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						sleepDecorator
					)
				),
				loading: {
					...state.loading,
					update: false,
				},
			}
		case types.UPDATE_SLEEP_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					update: false,
				},
			}
		case types.UPDATE_SLEEP:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),

				loading: {
					...state.loading,
					update: true,
				},
			}

		// DELETE SLEEP
		case types.DELETE_SLEEP_SUCCESS:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: false,
				},
			}
		case types.DELETE_SLEEP_FAIL:
			return {
				...state,

				loading: {
					...state.loading,
					delete: false,
				},
			}
		case types.DELETE_SLEEP:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: true,
				},
			}

		// ADD SLEEP
		case types.ADD_SLEEP_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						sleepDecorator
					)
				),
				loading: {
					...state.loading,
					create: false,
				},
			}
		case types.ADD_SLEEP_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					create: false,
				},
			}
		case types.ADD_SLEEP:
			return {
				...state,
				data: state.data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					create: true,
				},
			}

		// CURRENT SLEEP
		case types.CURRENT_SLEEP_SET:
		case types.CURRENT_SLEEP_CLEAR:
			return { ...state, current: action.payload }

		case types.RESET_SLEEP_LOADERS:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		// case 'persist/REHYDRATE':
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.sleep,
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		case types.SCENE:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		case types.SLEEP_CONTEXT_RESET:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// DEFAULT
		default:
			return state
	}
}
