import * as types from 'types'

const INITIAL_STATE = {
	currentProgramTabName: null,
	programTableSearchValue: '',
	programTableSort: {
		column: 'created_at',
		direction: 'desc',
	},
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.CURRENT_PROGRAM_TAB_NAME_SET: {
			return {
				...state,
				currentProgramTabName: action.payload,
			}
		}

		case types.PROGRAM_TABLE_SEARCH_VALUE_SET: {
			return {
				...state,
				programTableSearchValue: action.payload,
			}
		}

		case types.PROGRAM_TABLE_SORT_SET: {
			return {
				...state,
				programTableSort: action.payload,
			}
		}

		// DEFAULT
		default: {
			return state
		}
	}
}
