import { getBowelMedia } from 'api/bowel'
import { getMealMedia } from 'api/meal'
import { configuredStore } from '/src/configureStore'
import * as types from 'types'
export * from 'actions/AnalyticsActions'
export * from 'actions/AnnotationActions'
export * from 'actions/AuthActions'
export * from 'actions/BowelActions'
export * from 'actions/ChatActions'
export * from 'actions/DeviceActions'
export * from 'actions/SleepActions'
export * from 'actions/ClinicianActions'
export * from 'actions/PatientActions'
export * from 'actions/ExerciseActions'
export * from 'actions/InterventionActions'
export * from 'actions/InterventionComplianceActions'
export * from 'actions/MealActions'
export * from 'actions/MedicationActions'
export * from 'actions/MenstruationActions'
export * from 'actions/MoodActions'
export * from 'actions/NoteActions'
export * from 'actions/OtherActions'
export * from 'actions/ProgramActions'
export * from 'actions/ProgramSurveyActions'
export * from 'actions/QuestionActions'
export * from 'actions/SummaryActions'
export * from 'actions/SurveyActions'
export * from 'actions/SymptomActions'
export * from 'actions/SymptomScoreActions'

export const triggerConnectivityCheck = () => {
	return (dispatch) => {
		const isConnected = navigator.onLine
		dispatch({
			type: types.NETWORK_CONNECTION_CHANGE,
			payload: isConnected,
		})
	}
}

export const readMedia = (entity, type, attempts = 0) => {
	async function thunk(dispatch) {
		if (entity.media && entity.media.length && !entity.uuid) {
			return entity
		}

		try {
			const mediaRequest = type === 'meals' ? getMealMedia : getBowelMedia
			const res = await mediaRequest(entity)
			configuredStore.store.dispatch({
				type: `MEDIA_${type.replace(/s$/, '').toUpperCase()}_SET`,
				payload: {
					...entity,
					media: res.data.media,
				},
			})
			return {
				...entity,
				media: res.data.media,
			}
		} catch (err) {
			console.log(err)
		}
	}
	return thunk
}
