import React, { useRef, useState, useLayoutEffect } from 'react'
import { styled } from '@mui/material/styles'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { withTheme } from '@mui/styles'
import { useTheme } from '@mui/material'

import { faPoo } from '@fortawesome/free-solid-svg-icons'

const PREFIX = 'StudyTable'

const classes = {
	maskContainer: `${PREFIX}-maskContainer`,
	mediaImage: `${PREFIX}-mediaImage`,
	maskedImage: `${PREFIX}-maskedImage`,
	unmaskedImage: `${PREFIX}-unmaskedImage`,
	hideImageIcon: `${PREFIX}-hideImageIcon`,
}

const StyledContainer = styled('div')(({ theme }) => ({
	[`&.${classes.maskContainer}`]: {
		margin: 'auto',
		position: 'relative',
		zIndex: 1,
	},
	[`& .${classes.mediaImage}`]: {
		width: 60,
		margin: 5,
		maxHeight: 60,
		borderRadius: 4,
		justifyContent: 'center',
		alignItems: 'center',
	},
	[`& .${classes.maskedImage}`]: {
		margin: 5,
		maxHeight: 60,
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		zIndex: 3,
		borderRadius: 4,
		backgroundColor: '#aaa',
	},
	[`& .${classes.unmaskedImage}`]: {
		margin: 5,
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		zIndex: 1,
		borderRadius: 4,
	},
	[`& .${classes.hideImageIcon}`]: {
		margin: 7,
		position: 'absolute',
		bottom: 0,
		right: 2,
		zIndex: 2,
		borderRadius: 4,
		color: theme.palette.primary[500],
	},
}))

const shouldMask = ['bowel']

function EntityImage({ image, type, onError, onClick, onOpenCarousel }) {
	const [isMasked, toggleMask] = useState(shouldMask.includes(type))
	const [size, setSize] = useState({ height: 0, width: 0 })
	const [loading, setLoading] = useState(true)
	const refContainer = useRef(null)
	const theme = useTheme()

	useLayoutEffect(() => {
		if (refContainer.current) {
			setSize({ height: 'auto', width: 60 })
		}
	}, [refContainer])

	return isMasked ? (
		<StyledContainer className={classes.maskContainer}>
			{loading ? (
				<div className={classes.mediaImage} style={{ display: 'flex', justifyContent: 'center' }}>
					<CircularProgress disableShrink={true} />
				</div>
			) : (
				<></>
			)}
			<div style={{ display: loading ? 'none' : 'inline-flex' }}>
				<img
					onLoad={() => setLoading(false)}
					ref={refContainer}
					key={image.eTag}
					className={classes.mediaImage}
					src={image.uri}
					onError={onError}
				/>
				<div
					onClick={() => toggleMask(!isMasked)}
					className={classes.maskedImage}
					style={refContainer.current ? size : {}}
				>
					<FontAwesomeIcon
						style={{
							position: 'absolute',
							top: 0,
							bottom: 0,
							left: 0,
							right: 0,
							margin: 'auto',
							color:
								theme.palette.mode === 'light'
									? theme.palette.common.white
									: theme.palette.common.white,
						}}
						size="2x"
						icon={faPoo}
					/>
				</div>
			</div>
		</StyledContainer>
	) : (
		<StyledContainer className={classes.maskContainer}>
			{loading ? (
				<div className={classes.mediaImage} style={{ display: 'flex', justifyContent: 'center' }}>
					<CircularProgress disableShrink={true} />
				</div>
			) : (
				<></>
			)}
			<div style={{ display: loading ? 'none' : 'inline-flex' }}>
				<img
					key={image.eTag}
					className={classes.mediaImage}
					src={image.uri}
					onLoad={() => setLoading(false)}
					onError={onError}
					onClick={onClick}
				/>
				{shouldMask.includes(type) ? (
					<VisibilityOffIcon
						onClick={() => toggleMask(true)}
						fontSize="small"
						className={classes.hideImageIcon}
					/>
				) : (
					<></>
				)}

				<div onClick={() => onOpenCarousel(true)} className={classes.unmaskedImage} />
			</div>
		</StyledContainer>
	)
}

export { EntityImage }
