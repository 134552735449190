export const PURGE = 'PURGE'

export const NETWORK_CONNECTION_CHANGE = 'NETWORK_CONNECTION_CHANGE'

export const SCENE = 'SCENE'

export const WAIT_LOADING_START = 'WAIT_LOADING_START'
export const WAIT_LOADING_STOP = 'WAIT_LOADING_STOP'

export const APP_LOADED = 'APP_LOADED'
