import React, { useEffect, useCallback, useMemo } from 'react'
import * as actions from 'actions'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { SendBirdProvider } from 'sendbird-uikit'
import { CVETA_SB_ACCOUNT_INFO, SUPPORT_SB_ACCOUNT_INFO } from '../environment'

class CustomUserPaginatedQuery {
	constructor(props) {
		this.props = props
	}

	// Required public property
	next(callback) {
		const patientList = [...this.props.patient.data].map((patient) => {
			return {
				userId: patient.key,
				profileUrl: patient.picture,
				nickname: patient.nickname,
			}
		})

		callback(patientList, null)
	}
}

const ChatService = (props) => {
	useEffect(() => {
		props.reconnectChat()
	}, [props.auth.sub])

	const getCustomPaginatedQuery = useCallback(() => {
		return new CustomUserPaginatedQuery({ patient: props.patient })
	}, [props.patient.data])

	const alteredConfig = useMemo(() => {
		if (props.auth.sub === SUPPORT_SB_ACCOUNT_INFO) {
			return {
				profileUrl: CVETA_SB_ACCOUNT_INFO.profileUrl,
				nickname: CVETA_SB_ACCOUNT_INFO.nickname,
				userId: CVETA_SB_ACCOUNT_INFO.userId,
			}
		}
		return {
			profileUrl: props.auth.picture,
			nickname: props.auth.nickname,
			userId: props.auth.sub,
		}
	}, [props.auth.sub])

	// return props.auth.sub && props.chat.session_token.token ? (

	return (
		<SendBirdProvider
			theme={'light'}
			nickname={alteredConfig.nickname}
			profileUrl={alteredConfig.profileUrl}
			appId={process.env.SENDBIRD_APP_ID}
			userId={alteredConfig.userId}
			accessToken={props.chat.session_token.token}
			userListQuery={getCustomPaginatedQuery}
		>
			{props.children}
		</SendBirdProvider>
	)
}

const mapStateToProps = ({ auth, patient, chat }) => {
	return {
		auth,
		patient,
		chat,
	}
}

export default compose(connect(mapStateToProps, actions))(ChatService)
