import React from 'react'
import { styled } from '@mui/material/styles'
import MuiLink from '@mui/material/Link'
import { Link as GLink } from 'gatsby'

const PREFIX = 'Link'

const classes = {
	link: `${PREFIX}-link`,
}

const StyledLinkContainer = styled('span')(({ theme }) => ({
	[`& .${classes.link}`]: {
		fontWeight: 'bold',
		color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
	},
}))

const Link = (props) => {
	return (
		<StyledLinkContainer>
			{props.href ? (
				<MuiLink {...props} className={classes.link} />
			) : (
				<GLink {...props} className={classes.link} />
			)}
		</StyledLinkContainer>
	)
}
export { Link }
