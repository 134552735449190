import {
	fetchMoods,
	addMood as postMood,
	updateMood as putMood,
	removeMood,
	fetchMoodTypes,
	addMoodType as postMoodType,
	updateMoodType as putMoodType,
	removeMoodType,
} from 'api/mood'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { getDefaultOccurredAt } from 'utils/helpers'

const getDefaultMood = (attentionDate) => ({
	notes: null,
	intensity: 0,
	duration: 0,
	occurred_at: getDefaultOccurredAt(attentionDate),
	types: [],
	_app: {
		uuid: null,
		type: 'mood',
		synced: false,
	},
})

export const resetMoodLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_MOOD_LOADERS })
	}
}

export const addMood = (mood) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_MOOD, payload: mood })
		try {
			const res = await postMood(mood)
			const contextResponse = {
				...res.data,
				_app: {
					...mood._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.ADD_MOOD_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...mood,
				_app: {
					err,
					...mood._app,
				},
			}
			handleFail(dispatch, types.ADD_MOOD_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateMood = (mood, previousMood) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.UPDATE_MOOD,
			payload: { new: mood, old: previousMood },
		})
		try {
			const res = mood.id ? await putMood(mood, previousMood) : await postMood(mood)
			const contextResponse = {
				...res.data,
				_app: {
					...mood._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_MOOD_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...mood,
				_app: {
					err,
					...mood._app,
				},
			}
			handleFail(dispatch, types.UPDATE_MOOD_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteMood = (mood) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.DELETE_MOOD, payload: mood })
		try {
			const res = await removeMood(mood)
			const contextResponse = {
				...res.data,
				_app: {
					...mood._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.DELETE_MOOD_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...mood,
				_app: {
					err,
					...mood._app,
				},
			}
			handleFail(dispatch, types.DELETE_MOOD_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getMoods = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_MOODS })
		try {
			const res = await fetchMoods(args, {
				dispatch,
				event: types.GET_MOODS_SUCCESS,
			})
			await handleSuccess(dispatch, types.GET_MOODS_SUCCESS_COMPLETE)
			return res
		} catch (err) {
			handleFail(dispatch, types.GET_MOODS_FAIL, err)
		}
	}
	return thunk
}

export const addMoodType = (moodType) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_MOOD_TYPE, payload: moodType })
		try {
			const res = await postMoodType(moodType)
			const contextResponse = {
				...res.data,
				_app: {
					...moodType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_MOOD_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...moodType,
				_app: {
					err,
					...moodType._app,
				},
			}
			handleFail(dispatch, types.ADD_MOOD_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateMoodType = (moodType) => {
	async function thunk(dispatch) {
		dispatch({ type: types.UPDATE_MOOD_TYPE, payload: moodType })
		try {
			const res = moodType.id ? await putMoodType(moodType) : await postMoodType(moodType)
			const contextResponse = {
				...res.data,
				_app: {
					...moodType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.UPDATE_MOOD_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...moodType,
				_app: {
					err,
					...moodType._app,
				},
			}
			handleFail(dispatch, types.UPDATE_MOOD_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteMoodType = (moodType) => {
	async function thunk(dispatch) {
		dispatch({ type: types.DELETE_MOOD_TYPE, payload: moodType })
		try {
			const res = await removeMoodType(moodType)
			const contextResponse = {
				...res.data,
				_app: {
					...moodType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_MOOD_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...moodType,
				_app: {
					err,
					...moodType._app,
				},
			}
			handleFail(dispatch, types.DELETE_MOOD_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getMoodTypes = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_MOOD_TYPES })
		try {
			const res = await fetchMoodTypes(args)
			handleSuccess(dispatch, types.GET_MOOD_TYPES_SUCCESS, res.data, {
				...args,
				invoked_at: new Date().toISOString(),
			})
			return res.data
		} catch (err) {
			handleFail(dispatch, types.GET_MOOD_TYPES_FAIL, err)
		}
	}
	return thunk
}

export const setCurrentMood = (mood, attentionDate) => {
	if (!mood) {
		mood = getDefaultMood(attentionDate)
	}
	return (dispatch) => {
		dispatch({ type: types.CURRENT_MOOD_SET, payload: mood })
	}
}

export const clearCurrentMood = (mood = getDefaultMood()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_MOOD_CLEAR, payload: mood })
	}
}

export const addCurrentMoodType = (moodType) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({ type: types.CURRENT_MOOD_TYPE_ADD, payload: moodType })
			res(moodType)
		})
}

export const removeCurrentMoodType = (moodType) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({ type: types.CURRENT_MOOD_TYPE_REMOVE, payload: moodType })
			res(moodType)
		})
}
