import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { lighten } from '@mui/system'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { PieRadialChart, SafeWorstBarChart } from '@components'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

const headCells = [
	{ id: 'trigger', numeric: false, disablePadding: false, label: 'Trigger' },
	{ id: 'lag', numeric: false, disablePadding: false, label: 'Lag' },
	{ id: 'impact', numeric: true, disablePadding: false, label: 'Impact' },
]

function EnhancedTableHead(props) {
	const { classes, order, orderBy, rowCount, onRequestSort } = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={'center'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
}

const PREFIXTOOLBAR = 'SafeWorstTableToolbar'

const toolbarClasses = {
	root: `${PREFIXTOOLBAR}-root`,
	highlight: `${PREFIXTOOLBAR}-highlight`,
	title: `${PREFIXTOOLBAR}-title`,
	titleContainer: `${PREFIXTOOLBAR}-titleContainer`,
}

const StyledToolBar = styled(Toolbar)(({ theme }) => ({
	[`&.${classes.root}`]: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	[`& .${classes.highlight}`]:
		theme.palette.mode === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85),
			  }
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark,
			  },
	[`& .${classes.title}`]: {
		flex: '1 1 100%',
	},
	[`& .${classes.titleContainer}`]: {
		paddingTop: theme.spacing(1),
	},
}))

const EnhancedTableToolbar = (props) => {
	const [open, setOpen] = React.useState(false)

	const handleTooltipClose = () => {
		setOpen(false)
	}

	const handleTooltipOpen = () => {
		setOpen(true)
	}

	return (
		<StyledToolBar className={classNames(toolbarClasses.root)}>
			<Grid
				container
				spacing={2}
				justifyContent="center"
				alignItems="center"
				className={toolbarClasses.titleContainer}
			>
				<Grid item>
					<Typography className={toolbarClasses.title} variant="h6" id="tableTitle" component="div">
						{props.title}
					</Typography>
				</Grid>
				<Grid item>
					<ClickAwayListener onClickAway={handleTooltipClose}>
						<div>
							<Tooltip
								PopperProps={{
									disablePortal: true,
								}}
								onClose={handleTooltipClose}
								open={open}
								disableFocusListener
								disableHoverListener
								disableTouchListener
								title={props.tooltip}
							>
								<HelpOutlineIcon onClick={handleTooltipOpen} />
							</Tooltip>
						</div>
					</ClickAwayListener>
				</Grid>
			</Grid>
		</StyledToolBar>
	)
}

EnhancedTableToolbar.propTypes = {
	tooltip: PropTypes.string,
}

const PREFIX = 'SafeWorstTable'

const classes = {
	root: `${PREFIX}-root`,
	paper: `${PREFIX}-paper`,
	table: `${PREFIX}-table`,
	visuallyHidden: `${PREFIX}-visuallyHidden`,
}
const StyledContainer = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		width: '100%',
	},
	[`& .${classes.paper}`]: {
		width: '100%',
		overflow: 'hidden',
	},
	[`& .${classes.table}`]: {
		// minWidth: 500,
	},
	[`& .${classes.visuallyHidden}`]: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
}))

function SafeWorstTable(props) {
	const [order, setOrder] = React.useState(props.defaultOrder || 'asc')
	const [orderBy, setOrderBy] = React.useState('impact')
	const [page, setPage] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(5)

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.triggers.length - page * rowsPerPage)

	return (
		<StyledContainer className={classes.root}>
			<Paper className={classes.paper}>
				<EnhancedTableToolbar title={props.title} tooltip={props.tooltip} />
				<SafeWorstBarChart
					title={props.title}
					triggers={props.triggers}
					defaultOrder={props.defaultOrder}
					colorRange={props.colorRange}
				/>
				{/* <PieRadialChart title={props.title} triggers={props.triggers} /> */}
				<TableContainer>
					<Table
						className={classes.table}
						aria-labelledby="tableTitle"
						size={'medium'}
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							classes={classes}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={props.triggers.length}
						/>
						<TableBody>
							{stableSort(props.triggers, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									return (
										<TableRow tabIndex={-1} key={`${row.trigger}-${index}`}>
											<TableCell align="center">{row.trigger}</TableCell>
											<TableCell align="center">{row.lag}</TableCell>
											<TableCell align="center">{row.impact}</TableCell>
										</TableRow>
									)
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={props.triggers.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</StyledContainer>
	)
}

export { SafeWorstTable }
