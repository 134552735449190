/**
 * CRUD Actions for OTHER
 */
// ADD - OTHER
export const ADD_OTHER_SUCCESS = 'ADD_OTHER_SUCCESS'
export const ADD_OTHER_FAIL = 'ADD_OTHER_FAIL'
export const ADD_OTHER = 'ADD_OTHER'
// DELETE - OTHER
export const DELETE_OTHER_SUCCESS = 'DELETE_OTHER_SUCCESS'
export const DELETE_OTHER_FAIL = 'DELETE_OTHER_FAIL'
export const DELETE_OTHER = 'DELETE_OTHER'
// UPDATE - OTHER
export const UPDATE_OTHER_SUCCESS = 'UPDATE_OTHER_SUCCESS'
export const UPDATE_OTHER_FAIL = 'UPDATE_OTHER_FAIL'
export const UPDATE_OTHER = 'UPDATE_OTHER'
// GET ALL - OTHER
export const GET_OTHERS_SUCCESS = 'GET_OTHERS_SUCCESS'
export const GET_OTHERS_SUCCESS_COMPLETE = 'GET_OTHERS_SUCCESS_COMPLETE'
export const GET_OTHERS_FAIL = 'GET_OTHERS_FAIL'
export const GET_OTHERS = 'GET_OTHERS'

export const GET_OTHER_SUCCESS = 'GET_OTHER_SUCCESS'
export const GET_OTHER_FAIL = 'GET_OTHER_FAIL'
export const GET_OTHER = 'GET_OTHER'

// ADD - OTHER_TYPE
export const ADD_OTHER_TYPE_SUCCESS = 'ADD_OTHER_TYPE_SUCCESS'
export const ADD_OTHER_TYPE_FAIL = 'ADD_OTHER_TYPE_FAIL'
export const ADD_OTHER_TYPE = 'ADD_OTHER_TYPE'
// DELETE - OTHER_TYPE
export const DELETE_OTHER_TYPE_SUCCESS = 'DELETE_OTHER_TYPE_SUCCESS'
export const DELETE_OTHER_TYPE_FAIL = 'DELETE_OTHER_TYPE_FAIL'
export const DELETE_OTHER_TYPE = 'DELETE_OTHER_TYPE'
// UPDATE - OTHER_TYPE
export const UPDATE_OTHER_TYPE_SUCCESS = 'UPDATE_OTHER_TYPE_SUCCESS'
export const UPDATE_OTHER_TYPE_FAIL = 'UPDATE_OTHER_TYPE_FAIL'
export const UPDATE_OTHER_TYPE = 'UPDATE_OTHER_TYPE'
// GET ALL - OTHER_TYPE
export const GET_OTHER_TYPES_SUCCESS = 'GET_OTHER_TYPES_SUCCESS'
export const GET_OTHER_TYPES_FAIL = 'GET_OTHER_TYPES_FAIL'
export const GET_OTHER_TYPES = 'GET_OTHER_TYPES'

/**
 * Current Types
 */
// CURRENT OTHER
export const CURRENT_OTHER_SET = 'CURRENT_OTHER_SET'
export const CURRENT_OTHER_CLEAR = 'CURRENT_OTHER_CLEAR'
// CURRENT OTHER_TYPE
export const CURRENT_OTHER_TYPE_ADD = 'CURRENT_OTHER_TYPE_ADD'
export const CURRENT_OTHER_TYPE_REMOVE = 'CURRENT_OTHER_TYPE_REMOVE'

export const MEDIA_OTHER_SET = 'MEDIA_OTHER_SET'

export const RESET_OTHER_LOADERS = 'RESET_OTHER_LOADERS'

export const OTHER_CONTEXT_RESET = 'OTHER_CONTEXT_RESET'
