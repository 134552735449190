/**
 * CRUD Actions for MOOD
 */
// ADD - MOOD
export const ADD_MOOD_SUCCESS = 'ADD_MOOD_SUCCESS'
export const ADD_MOOD_FAIL = 'ADD_MOOD_FAIL'
export const ADD_MOOD = 'ADD_MOOD'
// DELETE - MOOD
export const DELETE_MOOD_SUCCESS = 'DELETE_MOOD_SUCCESS'
export const DELETE_MOOD_FAIL = 'DELETE_MOOD_FAIL'
export const DELETE_MOOD = 'DELETE_MOOD'
// UPDATE - MOOD
export const UPDATE_MOOD_SUCCESS = 'UPDATE_MOOD_SUCCESS'
export const UPDATE_MOOD_FAIL = 'UPDATE_MOOD_FAIL'
export const UPDATE_MOOD = 'UPDATE_MOOD'
// GET ALL - MOOD
export const GET_MOODS_SUCCESS = 'GET_MOODS_SUCCESS'
export const GET_MOODS_SUCCESS_COMPLETE = 'GET_MOODS_SUCCESS_COMPLETE'
export const GET_MOODS_FAIL = 'GET_MOODS_FAIL'
export const GET_MOODS = 'GET_MOODS'
// ADD - MOOD_TYPE
export const ADD_MOOD_TYPE_SUCCESS = 'ADD_MOOD_TYPE_SUCCESS'
export const ADD_MOOD_TYPE_FAIL = 'ADD_MOOD_TYPE_FAIL'
export const ADD_MOOD_TYPE = 'ADD_MOOD_TYPE'
// DELETE - MOOD_TYPE
export const DELETE_MOOD_TYPE_SUCCESS = 'DELETE_MOOD_TYPE_SUCCESS'
export const DELETE_MOOD_TYPE_FAIL = 'DELETE_MOOD_TYPE_FAIL'
export const DELETE_MOOD_TYPE = 'DELETE_MOOD_TYPE'
// UPDATE - MOOD_TYPE
export const UPDATE_MOOD_TYPE_SUCCESS = 'UPDATE_MOOD_TYPE_SUCCESS'
export const UPDATE_MOOD_TYPE_FAIL = 'UPDATE_MOOD_TYPE_FAIL'
export const UPDATE_MOOD_TYPE = 'UPDATE_MOOD_TYPE'
// GET ALL - MOOD_TYPE
export const GET_MOOD_TYPES_SUCCESS = 'GET_MOOD_TYPES_SUCCESS'
export const GET_MOOD_TYPES_FAIL = 'GET_MOOD_TYPES_FAIL'
export const GET_MOOD_TYPES = 'GET_MOOD_TYPES'

export const GET_MOOD_SUCCESS = 'GET_MOOD_SUCCESS'
export const GET_MOOD_FAIL = 'GET_MOOD_FAIL'
export const GET_MOOD = 'GET_MOOD'
/**
 * Current Types
 */
// CURRENT MOOD
export const CURRENT_MOOD_SET = 'CURRENT_MOOD_SET'
export const CURRENT_MOOD_CLEAR = 'CURRENT_MOOD_CLEAR'
// CURRENT MOOD_TYPE
export const CURRENT_MOOD_TYPE_ADD = 'CURRENT_MOOD_TYPE_ADD'
export const CURRENT_MOOD_TYPE_REMOVE = 'CURRENT_MOOD_TYPE_REMOVE'

export const MEDIA_MOOD_SET = 'MEDIA_MOOD_SET'

export const RESET_MOOD_LOADERS = 'RESET_MOOD_LOADERS'

export const MOOD_CONTEXT_RESET = 'MOOD_CONTEXT_RESET'
