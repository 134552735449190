import * as types from 'types'

const INITIAL_STATE = {
	targetBowel: {},
	annotations: {},
	loading: false,
	failed: false,
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.LOAD_TARGET_BOWEL: {
			return {
				...state,
				targetBowel: action.payload,
			}
		}

		case types.GET_ANNOTATIONS: {
			return {
				...state,
				loading: true,
				failed: false,
			}
		}

		case types.GET_ANNOTATIONS_SUCCESS: {
			return {
				...state,
				annotations: action.payload,
				loading: false,
				failed: false,
			}
		}

		case types.GET_ANNOTATIONS_FAILED: {
			return {
				...state,
				loading: false,
				failed: true,
			}
		}

		// DEFAULT
		default: {
			return state
		}
	}
}
