import moment from 'moment'
import humanizeDuration from 'humanize-duration'
import { v4 as uuidv4 } from 'uuid'

const ROUND_TO_NTH_MINUTE = 5

export const debounce = (delay, fn) => {
	let timeout

	return function (...args) {
		if (timeout) {
			clearTimeout(timeout)
		}

		timeout = setTimeout(() => {
			fn(...args)
			timeout = null
		}, delay)
	}
}

export const associateRequest = (data, type) => {
	if (!data) {
		data = {}
	}
	if (!data._app) {
		data._app = {}
	}
	if (!data._app.uuid) {
		data._app.uuid = uuidv4()
	}
	if (type) {
		data._app.type = type
	}
	delete data._app.err // remove any prior errors
	return data
}

export const getTimeAtCurrentDay = (date, hours, minutes = 0) => {
	const specifiedTime = moment(date).startOf('day').add(hours, 'hours').add(minutes, 'minutes')

	return specifiedTime
}

export const TimeSeconds = (seconds, largest = 2) => {
	return humanizeDuration(seconds * 1000, { largest, round: true })
}

export const getRange = (startDate, endDate, type) => {
	let fromDate = startDate ? moment(startDate) : moment()
	let toDate = endDate ? moment(endDate) : moment()
	let diff = toDate.diff(fromDate, type)
	let range = []
	for (let i = 0; i <= diff; i++) {
		range.push(fromDate.add(i, type))
	}
	return range
}

export const EnumerateDaysBetweenDates = (startDate, endDate) => {
	const now = startDate ? moment(startDate).clone() : moment()
	const finish = endDate ? moment(endDate).clone() : moment()
	const dates = []
	while (now.isSameOrBefore(finish)) {
		dates.push(now.format('YYYY-MM-DD'))
		now.add(1, 'days')
	}
	return dates
}

export const getDefaultOccurredAt = (attentionDate) => {
	const start = moment(attentionDate)
	const remainder = ROUND_TO_NTH_MINUTE - (start.minute() % ROUND_TO_NTH_MINUTE)
	let occurred_at = moment(start)

	if (remainder <= 2) {
		occurred_at = occurred_at.add(remainder, 'minutes')
	} else {
		occurred_at = occurred_at.subtract(start.minute() % ROUND_TO_NTH_MINUTE, 'minutes')
	}
}
