import React, { useMemo } from 'react'
import { createTheme } from '@mui/material'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import CssBaseline from '@mui/material/CssBaseline'

const PRIMARY = {
	50: '#f2f0fe',
	100: '#dddafc',
	200: '#c7c1fa',
	300: '#b1a8f7',
	400: '#a095f6',
	500: '#8f82f4',
	600: '#877af3',
	700: '#7c6ff1',
	800: '#7265ef',
	900: '#6052ec',
	A100: '#ffffff',
	A200: '#ffffff',
	A400: '#e7e5ff',
	A700: '#cfcbff',
	light: '#112233',
	contrastDefaultColor: 'light',
}

const SECONDARY = {
	50: '#e3e5e8',
	100: '#b9bdc5',
	200: '#8b919e',
	300: '#5c6577',
	400: '#394459',
	500: '#16233c',
	600: '#131f36',
	700: '#101a2e',
	800: '#0c1527',
	900: '#060c1a',
	A100: '#597eff',
	A200: '#2656ff',
	A400: '#0035f2',
	A700: '#0030d9',
	contrastDefaultColor: 'light',
}

const ERROR = {
	50: '#ffebee',
	100: '#ffcdd2',
	200: '#ef9a9a',
	300: '#e57373',
	400: '#ef5350',
	500: '#f44336',
	600: '#e53935',
	700: '#d32f2f',
	800: '#c62828',
	900: '#b71c1c',
	A100: '#ff8a80',
	A200: '#ff5252',
	A400: '#ff1744',
	A700: '#d50000',
}

function Theme({ path, children }) {
	const isDark = false
	const theme = React.useMemo(() => {
		const defaultTheme = createTheme()
		return createTheme({
			typography: {
				useNextVariants: true,
				h5: {
					fontWeight: 500,
					fontSize: 26,
					letterSpacing: 0.5,
				},
			},
			shape: {
				borderRadius: 8,
			},
			components: {
				MuiTab: {
					defaultProps: {
						// disableRipple: true,
					},
					styleOverrides: {
						root: {
							// '&.Mui-selected': {
							// 	color: 'white',
							// },
							textTransform: 'initial',
							margin: '0 16px',
							minWidth: 0,
							[defaultTheme.breakpoints.up('md')]: {
								minWidth: 0,
							},
						},
					},
				},
				MuiDrawer: {
					styleOverrides: {
						paper: {
							backgroundColor: SECONDARY[500],
						},
					},
				},
				MuiButton: {
					styleOverrides: {
						root: {
							textTransform: 'initial',
						},
						contained: {
							boxShadow: 'none',
							'&:active': {
								boxShadow: 'none',
							},
						},
					},
				},
				MuiTabs: {
					styleOverrides: {
						root: {
							marginLeft: defaultTheme.spacing(1),
						},
						indicator: {
							height: 3,
							borderTopLeftRadius: 3,
							borderTopRightRadius: 3,
							backgroundColor: defaultTheme.palette.common.white,
						},
					},
				},
				MuiTableHeader: {
					styleOverrides: {
						root: {
							backgroundColor: isDark ? '#212121' : '#f5f5f5',
						},
					},
				},
				MuiTableCell: {
					styleOverrides: {
						stickyHeader: {
							'&:first-of-type': {
								borderTopLeftRadius: defaultTheme.spacing(1),
							},
							'&:last-of-type': {
								borderTopRightRadius: defaultTheme.spacing(1),
							},
							backgroundColor: isDark ? '#212121' : '#f5f5f5',
						},
					},
				},
				MuiToolbar: {
					styleOverrides: {
						regular: {
							minHeight: 46,
						},
					},
				},
				MuiPickersToolbar: {
					styleOverrides: {
						root: {
							backgroundColor: isDark ? '#212121' : PRIMARY[500],
							color: defaultTheme.palette.common.white,
							'& .MuiTypography-root': {
								color: defaultTheme.palette.common.white,
							},
						},
					},
				},
				MuiDateRangePickerViewDesktop: {
					styleOverrides: {
						root: {
							justifyContent: 'center',
						},
					},
				},
				MuiPickersToolbarText: {
					styleOverrides: {
						root: {
							color: defaultTheme.palette.common.white,
							'&.Mui-selected': {
								color: defaultTheme.palette.common.white,
								textDecoration: 'underline',
							},
						},
					},
				},
				MuiIconButton: {
					styleOverrides: {
						root: {
							padding: defaultTheme.spacing(1),
						},
					},
				},
				MuiTooltip: {
					styleOverrides: {
						tooltip: {
							borderRadius: 4,
						},
					},
				},
				// MuiInputLabel: {
				//   styleOverrides: {
				//     select: {
				//       transform: `translate(${defaultTheme.spacing(-1)}, ${defaultTheme.spacing(-1)}) scale(0.75)`
				//     },
				//   },
				// },
				// MuiOutlinedInput: {
				//   styleOverrides: {
				//     input: {

				//     },
				//   },
				// },
				MuiLink: {
					styleOverrides: {
						root: {
							'&&:visited': {
								color: 'inherit',
							},
						},
					},
				},
				MuiDivider: {
					styleOverrides: {
						root: {
							backgroundColor: '#404854',
						},
					},
				},
				MuiListItemText: {
					styleOverrides: {
						root: {
							fontWeight: defaultTheme.typography.fontWeightMedium,
							textTransform: 'initial',
						},
					},
				},
				MuiListItemIcon: {
					styleOverrides: {
						root: {
							color: 'inherit',
							marginRight: 0,
							'& svg': {
								fontSize: 20,
							},
						},
					},
				},
				MuiBottomNavigationAction: {
					styleOverrides: {
						root: {
							minWidth: 70,
						},
					},
				},
				MuiAvatar: {
					styleOverrides: {
						root: {
							width: 32,
							height: 32,
						},
					},
				},
			},
			mixins: {
				toolbar: {
					minHeight: 48,
				},
			},
			palette: {
				text: {
					hint: 'rgba(255, 255, 255, 0.5)',
				},
				mode: isDark ? 'dark' : 'light',
				primary: PRIMARY,
				secondary: SECONDARY,
				error: ERROR,
				background: {
					paper: isDark ? '#424242' : '#fff',
					default: isDark ? '#303030' : '#fafafa',
				},
			},
		})
	}, [])
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<>
					<CssBaseline />
					{children}
				</>
			</ThemeProvider>
		</StyledEngineProvider>
	)
}

export default Theme
