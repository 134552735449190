import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'

function ResponsiveFullscreenDialog({
	open = false,
	title = 'Screen Access Hidden',
	message = 'Close this at any time to proceed with the viewing experience',
	onClose = () => {},
}) {
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

	const handleClose = () => {
		onClose(false)
	}

	return (
        <div>
			<Dialog
                fullScreen={fullScreen}
                disableEscapeKeyDown
                open={open}
                TransitionProps={{ onClose: handleClose }}
                aria-labelledby="responsive-dialog-title">
				<DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText>{message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} variant="contained" autoFocus>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
    );
}

export { ResponsiveFullscreenDialog }
