import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import * as types from 'types'
import { summaryRenderers, daysEventsMerger } from 'reducers/z_summary_helpers'
import { storeItemsTransactor, storeItemsMerger, flatJsonAccess, deepMerge } from 'reducers/z_redux_helpers'
import _groupBy from 'lodash.groupBy'

const INITIAL_STATE = {
	events: [],
	daysEvents: {},
	loading: false,
	attentionDate: new Date().toISOString(),
	error: '',
}

// adds title and subTitle to the entity._app object
// entity = new object
// summaryEntitiesMap = stored object (if exists)
const summaryDecorator = (entity, summaryEntitiesMap) => {
	return summaryEntitiesMap.map((entityMap) => {
		const renderers = summaryRenderers(entityMap.object._app.type)
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			title: renderers.title(entity),
			subTitle: renderers.subTitle(entity),
			synced: true,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		const isLabled = renderers.isLabled(entityMap.object)
		if (!isLabled) {
			entityMap.object._app.unlabled = true
		}
		return entityMap
	})
}

const summaryLabeler = (entity) => {
	const renderers = summaryRenderers(entity._app.type)
	entity._app = {
		...entity._app,
		title: renderers.title(entity),
		subTitle: renderers.subTitle(entity),
		synced: true,
	}
	const isLabled = renderers.isLabled(entity)
	if (!isLabled) {
		entity._app.unlabled = true
	}
	return entity
}

const summaryUnsyncedDecorator = (entity, summaryEntitiesMap) => {
	return summaryEntitiesMap.map((entityMap) => {
		const renderers = summaryRenderers(entityMap.object._app.type)
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			title: renderers.title(entity),
			subTitle: renderers.subTitle(entity),
			synced: false,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		const isLabled = renderers.isLabled(entityMap.object)
		if (!isLabled) {
			entityMap.object._app.unlabled = true
		}
		return entityMap
	})
}

const commonSummaryAddSuccess = (state, payload, keys) => {
	const associatedKeys = keys.reduce((acc, key) => {
		acc[key] = flatJsonAccess(payload, key)
		return acc
	}, {})

	const events = storeItemsMerger(
		state.events,
		storeItemsTransactor(state, 'events', payload, associatedKeys, summaryDecorator)
	)
	return {
		...state,
		loading: false,
		error: '',
		events,
		daysEvents: daysEventsMerger(state.daysEvents, payload, true),
	}
}

const commonSummaryAddFail = (state, payload, keys) => {
	const associatedKeys = keys.reduce((acc, key) => {
		acc[key] = flatJsonAccess(payload.error, key)
		return acc
	}, {})
	return {
		...state,
		daysEvents: daysEventsMerger(state.daysEvents, payload, true),
	}
}

const commonSummaryAdd = (state, payload, keys) => {
	const associatedKeys = keys.reduce((acc, key) => {
		acc[key] = flatJsonAccess(payload, key)
		return acc
	}, {})

	return {
		...state,
		attentionDate: payload.occurred_at,
		daysEvents: daysEventsMerger(state.daysEvents, payload, false),
	}
}

const commonSummaryUpdateSuccess = (state, payload, keys) => {
	const associatedKeys = keys.reduce((acc, key) => {
		acc[key] = flatJsonAccess(payload, key)
		return acc
	}, {})
	const events = storeItemsMerger(
		state.events,
		storeItemsTransactor(state, 'events', payload, associatedKeys, summaryDecorator)
	)

	return {
		...state,
		loading: false,
		error: '',
		events,
		daysEvents: daysEventsMerger(state.daysEvents, payload, true),
	}
}

const commonSummaryUpdateFail = (state, payload, keys) => {
	const associatedKeys = keys.reduce((acc, key) => {
		acc[key] = flatJsonAccess(payload.error, key)
		return acc
	}, {})

	return {
		...state,
		daysEvents: daysEventsMerger(state.daysEvents, payload, true),
	}
}

const commonSummaryUpdate = (state, payload, keys) => {
	const associatedKeys = keys.reduce((acc, key) => {
		acc[key] = flatJsonAccess(payload.new, key)
		return acc
	}, {})
	const events = state.events.filter((item) => {
		if (Number.isInteger(payload.id) && Number.isInteger(item.id)) {
			return item.id !== payload.id
		} else if (payload._app && payload._app.uuid && item._app && item._app.uuid) {
			return payload._app.uuid !== item._app.uuid
		}
		return true
	})

	return {
		...state,
		attentionDate: payload.new.occurred_at,
		events,
		daysEvents: daysEventsMerger(state.daysEvents, payload, false),
	}
}

const commonSummaryDeleteSuccess = (state, payload, keys) => {
	const events = state.events.filter((item) => item.id !== payload.id)

	const daysEvents = state.daysEvents
	const date = moment(payload.occurred_at).format('YYYY-MM-DD')
	daysEvents[date] = (daysEvents[date] ? daysEvents[date] : []).filter((item) => {
		if (Number.isInteger(payload.id) && Number.isInteger(item.id)) {
			return item.id !== payload.id
		} else if (payload._app && payload._app.uuid && item._app && item._app.uuid) {
			return payload._app.uuid !== item._app.uuid
		}
		return true
	})

	return {
		...state,
		loading: false,
		error: '',
		events,
		daysEvents,
	}
}

const commonSummaryDeleteFail = (state, payload, keys) => {
	const events = state.events.filter((item) => item.id !== payload.error.id)
	const daysEvents = state.daysEvents
	const date = moment(payload.occurred_at).format('YYYY-MM-DD')
	daysEvents[date] = (daysEvents[date] ? daysEvents[date] : []).filter((item) => {
		if (Number.isInteger(payload.id) && Number.isInteger(item.id)) {
			return item.id !== payload.id
		} else if (payload._app && payload._app.uuid && item._app && item._app.uuid) {
			return payload._app.uuid !== item._app.uuid
		}
		return true
	})
	return {
		...state,
		loading: false,
		error: '',
		events,
		daysEvents,
	}
}

const commonSummaryDelete = (state, payload, keys) => {
	const events = state.events.filter((item) => item.id !== payload.id)
	const daysEvents = state.daysEvents
	const date = moment(payload.occurred_at).format('YYYY-MM-DD')
	daysEvents[date] = (daysEvents[date] ? daysEvents[date] : []).filter((item) => {
		if (Number.isInteger(payload.id) && Number.isInteger(item.id)) {
			return item.id !== payload.id
		} else if (payload._app && payload._app.uuid && item._app && item._app.uuid) {
			return payload._app.uuid !== item._app.uuid
		}
		return true
	})
	return {
		...state,
		events,
		daysEvents,
	}
}

const commonSummaryGetSuccess = (state, payload, keys) => {
	const associatedKeys = keys.reduce((acc, key) => {
		acc[key] = flatJsonAccess(payload, key)
		return acc
	}, {})
	const events = storeItemsMerger(
		state.events,
		storeItemsTransactor(state, 'events', payload, associatedKeys, summaryDecorator)
	)
	return {
		...state,
		loading: false,
		error: '',
		events,
		daysEvents: daysEventsMerger(state.daysEvents, payload, true),
	}
}

const commonSummaryGetAllSuccess = (state, payload, keys) => {
	const events = storeItemsMerger(
		state.events,
		payload
			.map((item) => {
				const associatedKeys = keys.reduce((acc, key) => {
					acc[key] = flatJsonAccess(item, key)
					return acc
				}, {})
				return storeItemsTransactor(state, 'events', item, associatedKeys, summaryDecorator)
			})
			.reduce((acc, current) => acc.concat(current), [])
	)

	return {
		...state,
		loading: false,
		error: '',
		events,
		daysEvents: _groupBy(events, (item) => {
			return moment(item.occurred_at).format('YYYY-MM-DD')
		}),
	}
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		case types.GET_SUMMARY_SUCCESS:
			const events = state.events.length
				? storeItemsMerger(
						state.events,
						action.payload
							.map((entity) => {
								return storeItemsTransactor(state, 'events', entity, {
									id: entity.id,
									[`${entity._app.type}_id`]: entity[`${entity._app.type}_id`],
								})
							})
							.reduce((acc, current) => acc.concat(current), [])
				  )
				: action.payload

			return {
				...state,
				error: '',
				loading: false,
				events,
				daysEvents: _groupBy(events, (item) => {
					return moment(item.occurred_at).format('YYYY-MM-DD')
				}),
			}

		case types.GET_SUMMARY_FAIL:
			return { ...state, error: 'Could Not Load Summary!', loading: false }
		case types.GET_SUMMARY:
			return { ...state, loading: true, error: '' }

		// Sleeps
		case types.ADD_SLEEP_SUCCESS:
			return commonSummaryAddSuccess(state, action.payload, ['id', 'sleep_id'])
		case types.ADD_SLEEP_FAIL:
			return commonSummaryAddFail(state, action.payload, ['_app.uuid'])
		case types.ADD_SLEEP:
			return commonSummaryAdd(state, action.payload, ['_app.uuid'])

		case types.UPDATE_SLEEP_SUCCESS:
			return commonSummaryUpdateSuccess(state, action.payload, ['id', 'sleep_id'])
		case types.UPDATE_SLEEP_FAIL:
			return commonSummaryUpdateFail(state, action.payload, ['id', 'sleep_id'])
		case types.UPDATE_SLEEP:
			return commonSummaryUpdate(state, action.payload, ['id', 'sleep_id'])

		case types.DELETE_SLEEP_SUCCESS:
			return commonSummaryDeleteSuccess(state, action.payload, ['id', 'sleep_id'])
		case types.DELETE_SLEEP_FAIL:
			return commonSummaryDeleteFail(state, action.payload, ['id', 'sleep_id'])
		case types.DELETE_SLEEP:
			return commonSummaryDelete(state, action.payload, ['id', 'sleep_id'])

		case types.GET_SLEEP_SUCCESS:
			return commonSummaryGetSuccess(state, action.payload, ['id', 'sleep_id'])

		case types.GET_SLEEPS_SUCCESS:
			return commonSummaryGetAllSuccess(state, action.payload.sleeps, ['id', 'sleep_id'])

		// Bowels
		case types.ADD_BOWEL_SUCCESS:
			return commonSummaryAddSuccess(state, action.payload, ['id', 'bowel_id'])
		case types.ADD_BOWEL_FAIL:
			return commonSummaryAddFail(state, action.payload, ['_app.uuid'])
		case types.ADD_BOWEL:
			return commonSummaryAdd(state, action.payload, ['_app.uuid'])

		case types.UPDATE_BOWEL_SUCCESS:
			return commonSummaryUpdateSuccess(state, action.payload, ['id', 'bowel_id'])
		case types.UPDATE_BOWEL_FAIL:
			return commonSummaryUpdateFail(state, action.payload, ['id', 'bowel_id'])
		case types.UPDATE_BOWEL:
			return commonSummaryUpdate(state, action.payload, ['id', 'bowel_id'])

		case types.DELETE_BOWEL_SUCCESS:
			return commonSummaryDeleteSuccess(state, action.payload, ['id', 'bowel_id'])
		case types.DELETE_BOWEL_FAIL:
			return commonSummaryDeleteFail(state, action.payload, ['id', 'bowel_id'])
		case types.DELETE_BOWEL:
			return commonSummaryDelete(state, action.payload, ['id', 'bowel_id'])

		case types.GET_BOWEL_SUCCESS:
			return commonSummaryGetSuccess(state, action.payload, ['id', 'bowel_id'])
		case types.GET_BOWELS_SUCCESS:
			return commonSummaryGetAllSuccess(state, action.payload.bowels, ['id', 'bowel_id'])

		// Moods
		case types.ADD_MOOD_SUCCESS:
			return commonSummaryAddSuccess(state, action.payload, ['id', 'mood_id'])
		case types.ADD_MOOD_FAIL:
			return commonSummaryAddFail(state, action.payload, ['_app.uuid'])
		case types.ADD_MOOD:
			return commonSummaryAdd(state, action.payload, ['_app.uuid'])

		case types.UPDATE_MOOD_SUCCESS:
			return commonSummaryUpdateSuccess(state, action.payload, ['id', 'mood_id'])
		case types.UPDATE_MOOD_FAIL:
			return commonSummaryUpdateFail(state, action.payload, ['id', 'mood_id'])
		case types.UPDATE_MOOD:
			return commonSummaryUpdate(state, action.payload, ['id', 'mood_id'])

		case types.DELETE_MOOD_SUCCESS:
			return commonSummaryDeleteSuccess(state, action.payload, ['id', 'mood_id'])
		case types.DELETE_MOOD_FAIL:
			return commonSummaryDeleteFail(state, action.payload, ['id', 'mood_id'])
		case types.DELETE_MOOD:
			return commonSummaryDelete(state, action.payload, ['id', 'mood_id'])

		case types.GET_MOOD_SUCCESS:
			return commonSummaryGetSuccess(state, action.payload, ['id', 'mood_id'])

		case types.GET_MOODS_SUCCESS:
			return commonSummaryGetAllSuccess(state, action.payload.moods, ['id', 'mood_id'])

		// Symptoms
		case types.ADD_SYMPTOM_SUCCESS:
			return commonSummaryAddSuccess(state, action.payload, ['id', 'symptom_id'])
		case types.ADD_SYMPTOM_FAIL:
			return commonSummaryAddFail(state, action.payload, ['_app.uuid'])
		case types.ADD_SYMPTOM:
			return commonSummaryAdd(state, action.payload, ['_app.uuid'])

		case types.UPDATE_SYMPTOM_SUCCESS:
			return commonSummaryUpdateSuccess(state, action.payload, ['id', 'symptom_id'])
		case types.UPDATE_SYMPTOM_FAIL:
			return commonSummaryUpdateFail(state, action.payload, ['id', 'symptom_id'])
		case types.UPDATE_SYMPTOM:
			return commonSummaryUpdate(state, action.payload, ['id', 'symptom_id'])

		case types.DELETE_SYMPTOM_SUCCESS:
			return commonSummaryDeleteSuccess(state, action.payload, ['id', 'symptom_id'])
		case types.DELETE_SYMPTOM_FAIL:
			return commonSummaryDeleteFail(state, action.payload, ['id', 'symptom_id'])
		case types.DELETE_SYMPTOM:
			return commonSummaryDelete(state, action.payload, ['id', 'symptom_id'])

		case types.GET_SYMPTOM_SUCCESS:
			return commonSummaryGetSuccess(state, action.payload, ['id', 'symptom_id'])

		case types.GET_SYMPTOMS_SUCCESS:
			return commonSummaryGetAllSuccess(state, action.payload.symptoms, ['id', 'symptom_id'])

		// Meals
		case types.ADD_MEAL_SUCCESS:
			return commonSummaryAddSuccess(state, action.payload, ['id', 'meal_id'])
		case types.ADD_MEAL_FAIL:
			return commonSummaryAddFail(state, action.payload, ['_app.uuid'])
		case types.ADD_MEAL:
			return commonSummaryAdd(state, action.payload, ['_app.uuid'])

		case types.UPDATE_MEAL_SUCCESS:
			return commonSummaryUpdateSuccess(state, action.payload, ['id', 'meal_id'])
		case types.UPDATE_MEAL_FAIL:
			return commonSummaryUpdateFail(state, action.payload, ['id', 'meal_id'])
		case types.UPDATE_MEAL:
			return commonSummaryUpdate(state, action.payload, ['id', 'meal_id'])

		case types.DELETE_MEAL_SUCCESS:
			return commonSummaryDeleteSuccess(state, action.payload, ['id', 'meal_id'])
		case types.DELETE_MEAL_FAIL:
			return commonSummaryDeleteFail(state, action.payload, ['id', 'meal_id'])
		case types.DELETE_MEAL:
			return commonSummaryDelete(state, action.payload, ['id', 'meal_id'])

		case types.GET_MEAL_SUCCESS:
			return commonSummaryGetSuccess(state, action.payload, ['id', 'meal_id'])

		case types.GET_MEALS_SUCCESS:
			return commonSummaryGetAllSuccess(state, action.payload.meals, ['id', 'meal_id'])

		// PROGRAM SURVEY
		case types.ADD_PROGRAM_SURVEY_SUCCESS:
			return commonSummaryAddSuccess(state, action.payload, ['id', 'program_survey_id'])
		case types.ADD_PROGRAM_SURVEY_FAIL:
			return commonSummaryAddFail(state, action.payload, ['_app.uuid'])
		case types.ADD_PROGRAM_SURVEY:
			return commonSummaryAdd(state, action.payload, ['_app.uuid'])

		case types.UPDATE_PROGRAM_SURVEY_SUCCESS:
			return commonSummaryUpdateSuccess(state, action.payload, ['id', 'program_survey_id'])
		case types.UPDATE_PROGRAM_SURVEY_FAIL:
			return commonSummaryUpdateFail(state, action.payload, ['id', 'program_survey_id'])
		case types.UPDATE_PROGRAM_SURVEY:
			return commonSummaryUpdate(state, action.payload, ['id', 'program_survey_id'])

		case types.DELETE_PROGRAM_SURVEY_SUCCESS:
			return commonSummaryDeleteSuccess(state, action.payload, ['id', 'program_survey_id'])
		case types.DELETE_PROGRAM_SURVEY_FAIL:
			return commonSummaryDeleteFail(state, action.payload, ['id', 'program_survey_id'])
		case types.DELETE_PROGRAM_SURVEY:
			return commonSummaryDelete(state, action.payload, ['id', 'program_survey_id'])

		case types.GET_PROGRAM_SURVEY_SUCCESS:
			return commonSummaryGetSuccess(state, action.payload, ['id', 'program_survey_id'])

		case types.GET_PROGRAM_SURVEYS_SUCCESS:
			return commonSummaryGetAllSuccess(state, action.payload.data, ['id', 'program_survey_id'])

		// Others
		case types.ADD_OTHER_SUCCESS:
			return commonSummaryAddSuccess(state, action.payload, ['id', 'other_id'])
		case types.ADD_OTHER_FAIL:
			return commonSummaryAddFail(state, action.payload, ['_app.uuid'])
		case types.ADD_OTHER:
			return commonSummaryAdd(state, action.payload, ['_app.uuid'])

		case types.UPDATE_OTHER_SUCCESS:
			return commonSummaryUpdateSuccess(state, action.payload, ['id', 'other_id'])
		case types.UPDATE_OTHER_FAIL:
			return commonSummaryUpdateFail(state, action.payload, ['id', 'other_id'])
		case types.UPDATE_OTHER:
			return commonSummaryUpdate(state, action.payload, ['id', 'other_id'])

		case types.DELETE_OTHER_SUCCESS:
			return commonSummaryDeleteSuccess(state, action.payload, ['id', 'other_id'])
		case types.DELETE_OTHER_FAIL:
			return commonSummaryDeleteFail(state, action.payload, ['id', 'other_id'])
		case types.DELETE_OTHER:
			return commonSummaryDelete(state, action.payload, ['id', 'other_id'])

		case types.GET_OTHER_SUCCESS:
			return commonSummaryGetSuccess(state, action.payload, ['id', 'other_id'])

		case types.GET_OTHERS_SUCCESS:
			return commonSummaryGetAllSuccess(state, action.payload.others, ['id', 'other_id'])

		// Medications
		case types.ADD_MEDICATION_SUCCESS:
			return commonSummaryAddSuccess(state, action.payload, ['id', 'medication_id'])
		case types.ADD_MEDICATION_FAIL:
			return commonSummaryAddFail(state, action.payload, ['_app.uuid'])
		case types.ADD_MEDICATION:
			return commonSummaryAdd(state, action.payload, ['_app.uuid'])

		case types.UPDATE_MEDICATION_SUCCESS:
			return commonSummaryUpdateSuccess(state, action.payload, ['id', 'medication_id'])
		case types.UPDATE_MEDICATION_FAIL:
			return commonSummaryUpdateFail(state, action.payload, ['id', 'medication_id'])
		case types.UPDATE_MEDICATION:
			return commonSummaryUpdate(state, action.payload, ['id', 'medication_id'])

		case types.DELETE_MEDICATION_SUCCESS:
			return commonSummaryDeleteSuccess(state, action.payload, ['id', 'medication_id'])
		case types.DELETE_MEDICATION_FAIL:
			return commonSummaryDeleteFail(state, action.payload, ['id', 'medication_id'])
		case types.DELETE_MEDICATION:
			return commonSummaryDelete(state, action.payload, ['id', 'medication_id'])

		case types.GET_MEDICATION_SUCCESS:
			return commonSummaryGetSuccess(state, action.payload, ['id', 'medication_id'])

		case types.GET_MEDICATIONS_SUCCESS:
			return commonSummaryGetAllSuccess(state, action.payload.medications, ['id', 'medication_id'])

		// Exercises
		case types.ADD_EXERCISE_SUCCESS:
			return commonSummaryAddSuccess(state, action.payload, ['id', 'exercise_id'])
		case types.ADD_EXERCISE_FAIL:
			return commonSummaryAddFail(state, action.payload, ['_app.uuid'])
		case types.ADD_EXERCISE:
			return commonSummaryAdd(state, action.payload, ['_app.uuid'])

		case types.UPDATE_EXERCISE_SUCCESS:
			return commonSummaryUpdateSuccess(state, action.payload, ['id', 'exercise_id'])
		case types.UPDATE_EXERCISE_FAIL:
			return commonSummaryUpdateFail(state, action.payload, ['id', 'exercise_id'])
		case types.UPDATE_EXERCISE:
			return commonSummaryUpdate(state, action.payload, ['id', 'exercise_id'])

		case types.DELETE_EXERCISE_SUCCESS:
			return commonSummaryDeleteSuccess(state, action.payload, ['id', 'exercise_id'])
		case types.DELETE_EXERCISE_FAIL:
			return commonSummaryDeleteFail(state, action.payload, ['id', 'exercise_id'])
		case types.DELETE_EXERCISE:
			return commonSummaryDelete(state, action.payload, ['id', 'exercise_id'])

		case types.GET_EXERCISE_SUCCESS:
			return commonSummaryGetSuccess(state, action.payload, ['id', 'exercise_id'])

		case types.GET_EXERCISES_SUCCESS:
			return commonSummaryGetAllSuccess(state, action.payload.exercises, ['id', 'exercise_id'])

		case types.RESET_SUMMARY_LOADERS:
			return {
				...state,
				loading: false,
				error: '',
			}

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), {
				...state,
				attentionDate: new Date().toISOString(),
			})

		case 'SET_ATTENTION_DATE':
			return {
				...state,
				attentionDate: action.payload,
			}

		case types.SUMMARY_CONTEXT_RESET:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		default:
			return state
	}
}
