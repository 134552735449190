import {
	fetchExercises,
	addExercise as postExercise,
	updateExercise as putExercise,
	removeExercise,
	fetchExerciseTypes,
	addExerciseType as postExerciseType,
	updateExerciseType as putExerciseType,
	removeExerciseType,
} from 'api/exercise'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { getDefaultOccurredAt } from 'utils/helpers'

const getDefaultExercise = (attentionDate) => ({
	notes: null,
	intensity: 0,
	duration: null,
	occurred_at: getDefaultOccurredAt(attentionDate),
	types: [],
	_app: {
		uuid: null,
		type: 'exercise',
		synced: false,
	},
})

export const resetExerciseLoaders = () => {
	return (dispatch) => {
		handleSuccess(dispatch, types.RESET_EXERCISE_LOADERS)
	}
}

export const addExercise = (exercise) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_EXERCISE, payload: exercise })
		try {
			const res = await postExercise(exercise)
			const contextResponse = {
				...res.data,
				_app: {
					...exercise._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.ADD_EXERCISE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...exercise,
				_app: {
					err,
					...exercise._app,
				},
			}
			handleFail(dispatch, types.ADD_EXERCISE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateExercise = (exercise, previousExercise) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.UPDATE_EXERCISE,
			payload: {
				new: exercise,
				old: previousExercise,
			},
		})
		try {
			const res = exercise.id ? await putExercise(exercise, previousExercise) : await postExercise(exercise)
			const contextResponse = {
				...res.data,
				_app: {
					...exercise._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_EXERCISE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...exercise,
				_app: {
					err,
					...exercise._app,
				},
			}
			handleFail(dispatch, types.UPDATE_EXERCISE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteExercise = (exercise) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.DELETE_EXERCISE, payload: exercise })
		try {
			const res = await removeExercise(exercise)
			const contextResponse = {
				...res.data,
				_app: {
					...exercise._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.DELETE_EXERCISE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...exercise,
				_app: {
					err,
					...exercise._app,
				},
			}
			handleFail(dispatch, types.DELETE_EXERCISE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getExercises = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_EXERCISES })
		try {
			const res = await fetchExercises(args, {
				dispatch,
				event: types.GET_EXERCISES_SUCCESS,
			})
			await handleSuccess(dispatch, types.GET_EXERCISES_SUCCESS_COMPLETE)
			return res
		} catch (err) {
			handleFail(dispatch, types.GET_EXERCISES_FAIL, err)
		}
	}
	return thunk
}

export const addExerciseType = (exerciseType) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_EXERCISE_TYPE, payload: exerciseType })
		try {
			const res = await postExerciseType(exerciseType)
			const contextResponse = {
				...res.data,
				_app: {
					...exerciseType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_EXERCISE_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...exerciseType,
				_app: {
					err,
					...exerciseType._app,
				},
			}
			handleFail(dispatch, types.ADD_EXERCISE_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateExerciseType = (exerciseType) => {
	async function thunk(dispatch) {
		dispatch({ type: types.UPDATE_EXERCISE_TYPE, payload: exerciseType })
		try {
			const res = exerciseType.id ? await putExerciseType(exerciseType) : await postExerciseType(exerciseType)
			const contextResponse = {
				...res.data,
				_app: {
					...exerciseType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.UPDATE_EXERCISE_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...exerciseType,
				_app: {
					err,
					...exerciseType._app,
				},
			}
			handleFail(dispatch, types.UPDATE_EXERCISE_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteExerciseType = (exerciseType) => {
	async function thunk(dispatch) {
		dispatch({ type: types.DELETE_EXERCISE_TYPE, payload: exerciseType })
		try {
			const res = await removeExerciseType(exerciseType)
			const contextResponse = {
				...res.data,
				_app: {
					...exerciseType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_EXERCISE_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...exerciseType,
				_app: {
					err,
					...exerciseType._app,
				},
			}
			handleFail(dispatch, types.DELETE_EXERCISE_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getExerciseTypes = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_EXERCISE_TYPES })
		try {
			const res = await fetchExerciseTypes(args)
			handleSuccess(dispatch, types.GET_EXERCISE_TYPES_SUCCESS, res.data, {
				...args,
				invoked_at: new Date().toISOString(),
			})
			return res.data
		} catch (err) {
			handleFail(dispatch, types.GET_EXERCISE_TYPES_FAIL, err)
		}
	}
	return thunk
}

export const setCurrentExercise = (exercise, attentionDate) => {
	if (!exercise) {
		exercise = getDefaultExercise()
	}
	return (dispatch) => {
		dispatch({ type: types.CURRENT_EXERCISE_SET, payload: exercise })
	}
}

export const clearCurrentExercise = (exercise = getDefaultExercise()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_EXERCISE_CLEAR, payload: exercise })
	}
}

export const addCurrentExerciseType = (exerciseType) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: types.CURRENT_EXERCISE_TYPE_ADD,
				payload: exerciseType,
			})
			res(exerciseType)
		})
}

export const removeCurrentExerciseType = (exerciseType) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: types.CURRENT_EXERCISE_TYPE_REMOVE,
				payload: exerciseType,
			})
			res(exerciseType)
		})
}
