import { getAllPrograms, getProgram, addProgram as assignProgram } from 'api/program'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { getAnnotations, putAnnotation } from '../apis/annotations'

export const loadTargetBowel = (args) => {
	async function thunk(dispatch) {
		handleSuccess(dispatch, types.LOAD_TARGET_BOWEL, args)
		return args
	}
	return thunk
}

export const putAnnotationForBowel = (bowelId, clinicianId, annotation) => {
	async function thunk(dispatch) {
		dispatch({ type: types.PUT_ANNOTATION })
		try {
			const res = await putAnnotation(bowelId, clinicianId, annotation)
			handleSuccess(dispatch, types.PUT_ANNOTATION_SUCCESS)
			return res?.data
		} catch (err) {
			handleFail(dispatch, types.PUT_ANNOTATION_FAIL, err)
		}
	}
	return thunk
}
export const loadAnnotationsFor = (uuid, skip, limit) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_ANNOTATIONS })
		try {
			const res = await getAnnotations(uuid, skip, limit)
			handleSuccess(dispatch, types.GET_ANNOTATIONS_SUCCESS, res.data)
			return res
		} catch (err) {
			handleFail(dispatch, types.GET_ANNOTATIONS_FAIL, err)
		}
	}
	return thunk
}
