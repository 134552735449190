import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateDefaultArgs } from 'api/api_utils'

// General Rules:
// 1. When login, get ALL data with no date filter
// 2. When app open, get all data from last time app was open till now
// 3. When manual refresh triggered, fetch that day's data (Day 12:00 AM -> Day + 1 12:00 AM)
export const getSummary = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/summary?${queryString}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}
