import * as types from 'types'

import {
	storeItemsTransactor,
	storeItemsMerger,
	storeChildTypeRanker,
	deepMerge,
	ArrayMerger,
} from 'reducers/z_redux_helpers'

import { v4 as uuidv4 } from 'uuid'
import _groupBy from 'lodash.groupBy'
import _merge from 'lodash.merge'
import moment from 'moment'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	data: [],
	otherTypes: {
		data: [],
		used: [],
		loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
	},
	daysEvents: {},
	current: {},
	searchCache: {
		otherTypes: {},
	},
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

const otherDecorator = (entity, otherEntitiesMap) => {
	return otherEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			types: (entity.types || []).map((type) => {
				if (!type._app) {
					type._app = {}
					type._app.uuid = uuidv4()
				}
				return type
			}),
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

const otherUnsyncedDecorator = (entity, otherEntitiesMap) => {
	return otherEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
			types: (entity.types || []).map((type) => {
				if (!type._app) {
					type._app = {}
					type._app.uuid = uuidv4()
				}
				return type
			}),
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		return entityMap
	})
}

const otherTypeDecorator = (entity, otherTypeEntitiesMap) => {
	return otherTypeEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

const otherTypeUnsyncedDecorator = (entity, otherTypeEntitiesMap) => {
	return otherTypeEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: false,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// GET OTHERS
		case types.GET_OTHERS_SUCCESS:
			let newPayload = ArrayMerger(state.data, [...action.payload.others, ...state.data])
			return {
				...state,
				data: newPayload,
				otherTypes: {
					...state.otherTypes,
					data: storeChildTypeRanker(state.otherTypes.data, 'types', state.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: newPayload.reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				daysEvents: _merge(
					{},
					state.daysEvents,
					_groupBy(action.payload.others, (item) => {
						return moment(item.occurred_at).format('YYYY-MM-DD')
					})
				),
			}

		case types.GET_OTHERS_SUCCESS_COMPLETE:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_OTHERS_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		case types.GET_OTHERS:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		// UPDATE OTHER
		case types.UPDATE_OTHER_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						otherDecorator
					)
				),
				otherTypes: {
					...state.otherTypes,
					data: storeChildTypeRanker(state.otherTypes.data, 'types', state.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: [...action.payload, ...state.data].reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					update: false,
				},
			}
		case types.UPDATE_OTHER_FAIL:
			return {
				...state,

				loading: {
					...state.loading,
					update: false,
				},
			}
		case types.UPDATE_OTHER:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),

				loading: {
					...state.loading,
					update: true,
				},
			}

		// DELETE OTHER
		case types.DELETE_OTHER_SUCCESS:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				otherTypes: {
					...state.otherTypes,
					used: state.data.reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					delete: false,
				},
			}
		case types.DELETE_OTHER_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					delete: false,
				},
			}
		case types.DELETE_OTHER:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: true,
				},
			}

		// ADD OTHER
		case types.ADD_OTHER_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						otherDecorator
					)
				),
				otherTypes: {
					...state.otherTypes,
					data: storeChildTypeRanker(state.otherTypes.data, 'types', state.data, [
						'id',
						'name',
						'creator_id',
						'migration_id',
						'_app',
					]),
					used: [action.payload, ...state.data].reduce((acc, entity) => {
						acc = entity.types ? [...acc, ...entity.types.map((type) => type.id)] : acc
						return acc
					}, []),
				},
				loading: {
					...state.loading,
					create: false,
				},
			}
		case types.ADD_OTHER_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_OTHER:
			return {
				...state,
				data: state.data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					create: true,
				},
			}

		// GET OTHER TYPES
		case types.GET_OTHER_TYPES_SUCCESS:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.otherTypes.data,
							[
								...action.payload.local.map((local) => {
									local._app = {
										...local._app,
										type: 'local',
									}
									return local
								}),
								...action.payload.community.map((community) => {
									community._app = {
										...community._app,
										type: 'community',
									}
									return community
								}),
							]
								.map((entity) => {
									return storeItemsTransactor(state, 'otherTypes.data', entity, {
										id: entity.id,
									})
								})
								.reduce((acc, current) => acc.concat(current), [])
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'migration_id', '_app']
					),
					loading: {
						...state.otherTypes.loading,
						read: false,
					},
				},
				searchCache: {
					...state.searchCache,
					otherTypes: {
						...state.searchCache.otherTypes,
						[action.meta.like || '']: {
							invoked_at: action.meta.invoked_at,
						},
					},
				},
			}

		case types.GET_OTHER_TYPES_FAIL:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,
					loading: {
						...state.otherTypes.loading,
						read: false,
					},
				},
			}

		case types.GET_OTHER_TYPES:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,
					loading: {
						...state.otherTypes.loading,
						read: true,
					},
				},
			}

		// UPDATE OTHER TYPE
		case types.UPDATE_OTHER_TYPE_SUCCESS:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.otherTypes.data,
							storeItemsTransactor(
								state,
								'otherTypes.data',
								action.payload,
								{
									id: action.payload.id,
								},
								otherTypeDecorator
							)
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'migration_id', '_app']
					),
					loading: {
						...state.otherTypes.loading,
						update: false,
					},
				},
			}

		case types.UPDATE_OTHER_TYPE_FAIL:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,
					loading: {
						...state.otherTypes.loading,
						update: false,
					},
				},
			}

		case types.UPDATE_OTHER_TYPE:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,
					data: state.otherTypes.data.filter((item) => item.id !== action.payload.id),
					loading: {
						...state.otherTypes.loading,
						update: true,
					},
				},
			}

		// DELETE OTHER TYPE
		case types.DELETE_OTHER_TYPE_SUCCESS:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,
					data: state.otherTypes.data.filter((type) => type.id !== action.payload.id),
					loading: {
						...state.otherTypes.loading,
						delete: false,
					},
				},
			}

		case types.DELETE_OTHER_TYPE_FAIL:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,
					loading: {
						...state.otherTypes.loading,
						delete: false,
					},
				},
			}

		case types.DELETE_OTHER_TYPE:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,
					data: state.otherTypes.data.filter((item) => item.id !== action.payload.id),
					loading: {
						...state.otherTypes.loading,
						delete: true,
					},
				},
			}

		// ADD OTHER TYPE
		case types.ADD_OTHER_TYPE_SUCCESS:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,
					data: storeChildTypeRanker(
						storeItemsMerger(
							state.otherTypes.data,
							storeItemsTransactor(state, 'otherTypes.data', action.payload, {
								id: action.payload.id,
							})
						),
						'types',
						state.data,
						['id', 'name', 'creator_id', 'migration_id', '_app']
					),
					loading: {
						...state.otherTypes.loading,
						create: false,
					},
				},
			}

		case types.ADD_OTHER_TYPE_FAIL:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,

					loading: {
						...state.otherTypes.loading,
						create: false,
					},
				},
			}

		case types.ADD_OTHER_TYPE:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,
					data: state.otherTypes.data.filter((item) => {
						if (action.payload.id) {
							return item.id !== action.payload.id
						} else if (action.payload._app) {
							if (item._app) {
								return item._app.uuid !== action.payload._app.uuid
							}
						}
						return true
					}),

					loading: {
						...state.otherTypes.loading,
						create: true,
					},
				},
			}

		// CURRENT OTHER
		case types.CURRENT_OTHER_SET:
			return { ...state, current: action.payload }

		case types.CURRENT_OTHER_CLEAR:
			return { ...state, current: action.payload }

		case types.CURRENT_OTHER_TYPE_ADD:
			return {
				...state,
				current: {
					...state.current,
					types: [...state.current.types, action.payload],
				},
			}

		case types.CURRENT_OTHER_TYPE_REMOVE:
			return {
				...state,
				current: {
					...state.current,
					types: state.current.types.filter((type) => {
						if (type.id) {
							return type.id !== action.payload.id
						}
						if (type._app && action.payload._app) {
							return type._app.uuid !== action.payload._app.uuid
						}
					}),
				},
			}

		case types.RESET_OTHER_LOADERS:
			return {
				...state,
				otherTypes: {
					...state.otherTypes,
					loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
				},
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		// case 'persist/REHYDRATE':
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.other,
		//       otherTypes: {
		//         ...action.payload.other.otherTypes,
		//         loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//       },
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		case types.SCENE:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		case types.OTHER_CONTEXT_RESET:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// DEFAULT
		default:
			return state
	}
}
