import { fetchProgramSurveys, enrichProgramSurvey } from 'api/programSurvey'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { addProgramSurvey as postProgramSurvey } from '../apis/programSurvey'

const getDefaultProgramSurvey = () => ({
	_app: {
		uuid: null,
		type: 'programSurvey',
		synced: false,
	},
})

export const resetProgramSurveyLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_PROGRAM_SURVEY_LOADERS })
	}
}

export const deleteProgramSurvey = (programSurvey) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.FINISH_PROGRAM_SURVEY, payload: programSurvey })
		try {
			handleSuccess(dispatch, types.FINISH_PROGRAM_SURVEY_SUCCESS, programSurvey)
			return programSurvey
		} catch (err) {
			handleFail(dispatch, types.FINISH_PROGRAM_SURVEY_FAIL, programSurvey)
		}
	}
	return thunk
}

export const addProgramSurvey = (programSurvey) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_PROGRAM_SURVEY, payload: programSurvey })
		try {
			const res = await postProgramSurvey(programSurvey)
			const contextResponse = {
				...res.data,
				_app: {
					...programSurvey._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_PROGRAM_SURVEY_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...programSurvey,
				_app: {
					err,
					...programSurvey._app,
				},
			}
			handleFail(dispatch, types.ADD_PROGRAM_SURVEY_FAIL, contextResponse)
			let errorMessage = err?.message || 'Request failed'
			errorMessage += `: ${err?.response?.data?.message || 'Unable to create program survey'}`
			throw new Error(errorMessage)
		}
	}
	return thunk
}

export const getProgramSurveys = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_PROGRAM_SURVEYS })
		try {
			const res = await fetchProgramSurveys(args)
			await handleSuccess(dispatch, types.GET_PROGRAM_SURVEYS_SUCCESS, res.data)
			return res.data
		} catch (err) {
			handleFail(dispatch, types.GET_PROGRAM_SURVEYS_FAIL, err)
		}
	}
	return thunk
}

export const assembleProgramSurvey = (programSurvey) => {
	async function thunk(dispatch) {
		dispatch({ type: types.ENRICH_PROGRAM_SURVEYS })
		try {
			const res = await enrichProgramSurvey(programSurvey.id)
			await handleSuccess(dispatch, types.ENRICH_PROGRAM_SURVEYS_SUCCESS, res.data)
			return res.data
		} catch (err) {
			handleFail(dispatch, types.ENRICH_PROGRAM_SURVEYS_FAIL, err)
		}
	}
	return thunk
}

export const setCurrentProgramSurvey = (survey = getDefaultProgramSurvey()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_PROGRAM_SURVEY_SET, payload: survey })
	}
}

export const clearCurrentProgramSurvey = (survey = getDefaultProgramSurvey()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_PROGRAM_SURVEY_CLEAR, payload: survey })
	}
}
