import React from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { useTheme } from '@mui/material'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

import CloseIcon from '@mui/icons-material/Close'

import { MessageCard } from '@components'

const PREFIX = 'NotificationPanel'

const classes = {
	mainPanel: `${PREFIX}-mainPanel`,
	navItemText: `${PREFIX}-navItemText`,
	listContainer: `${PREFIX}-listContainer`,
	actionContainer: `${PREFIX}-actionContainer`,
	alert: `${PREFIX}-alert`,
}

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
	[`& .${classes.mainPanel}`]: {},

	[`& .${classes.navItemText}`]: {
		paddingLeft: theme.spacing(1),
	},

	[`& .${classes.listContainer}`]: {
		zIndex: 10,
	},

	[`& .${classes.actionContainer}`]: {
		paddingTop: 10,
		padding: 2,
	},

	[`& .${classes.alert}`]: {
		margin: theme.spacing(3),
	},
}))

function NotificationsPanel({
	PaperProps,
	variant,
	open = true,
	onClose,
	logoutUser,
	auth,
	title,
	location = null,
	iOS = false,
	...rest
}) {
	const theme = useTheme()
	const [value, setValue] = React.useState(0)

	return (
		<StyledSwipeableDrawer
			anchor={'right'}
			open={open}
			onClose={onClose}
			onOpen={() => {}}
			variant={variant}
			PaperProps={PaperProps}
			disableSwipeToOpen={iOS}
		>
			<div className={classes.listContainer}>
				<List disablePadding className={classes.mainPanel}>
					{/* <MessageCard />
					<MessageCard />
					<MessageCard /> */}
					<Alert variant="filled" severity="info" className={classes.alert}>
						<AlertTitle>No notifications</AlertTitle>
						Check back to see important updates here
					</Alert>
				</List>
				{/* <Grid container alignItems="center" spacing={2} direction="row" className={classes.actionContainer}>
					<ListItem dense>
						<Button fullWidth variant="contained" startIcon={<CloseIcon />}>
							<ListItemText
								className={classes.navItemText}
								classes={{
									primary: classes.itemPrimary,
									textDense: classes.textDense,
								}}
							>
								Dismiss All
							</ListItemText>
						</Button>
					</ListItem>
				</Grid> */}
			</div>
		</StyledSwipeableDrawer>
	)
}

NotificationsPanel.propTypes = {
	title: PropTypes.string,
	location: PropTypes.string,
	auth: PropTypes.shape({
		accessToken: PropTypes.string,
		name: PropTypes.string,
		nickname: PropTypes.string,
		roles: PropTypes.array,
	}).isRequired,
}

const mapStateToProps = ({ auth }) => {
	return {
		auth,
	}
}
export default connect(mapStateToProps, actions)(NotificationsPanel)
