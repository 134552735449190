import { fetchQuestions } from 'api/question'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'

export const getQuestions = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_QUESTIONS })
		try {
			const res = await fetchQuestions(args)
			await handleSuccess(dispatch, types.GET_QUESTIONS_SUCCESS, res.data)
		} catch (err) {
			handleFail(dispatch, types.GET_QUESTIONS_FAIL, err)
		}
	}
	return thunk
}
