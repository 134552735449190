import { fetchSleeps, addSleep as postSleep, updateSleep as putSleep, removeSleep } from 'api/sleep'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { getDefaultOccurredAt } from 'utils/helpers'

const getDefaultSleep = (attentionDate) => ({
	notes: null,
	occurred_at: getDefaultOccurredAt(attentionDate),
	quality: 0,
	duration: 0,
	_app: {
		uuid: null,
		type: 'sleep',
		synced: false,
	},
})

export const resetSleepLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_SLEEP_LOADERS })
	}
}

export const addSleep = (sleep) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_SLEEP, payload: sleep })
		try {
			const res = await postSleep(sleep)
			const contextResponse = {
				...res.data,
				_app: {
					...sleep._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_SLEEP_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...sleep,
				_app: {
					err,
					...sleep._app,
				},
			}
			handleFail(dispatch, types.ADD_SLEEP_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateSleep = (sleep, previousSleep) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.UPDATE_SLEEP,
			payload: { new: sleep, old: previousSleep },
		})
		try {
			const res = sleep.id ? await putSleep(sleep) : await postSleep(sleep)
			const contextResponse = {
				...res.data,
				_app: {
					...sleep._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_SLEEP_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...sleep,
				_app: {
					err,
					...sleep._app,
				},
			}
			handleFail(dispatch, types.UPDATE_SLEEP_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteSleep = (sleep) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.DELETE_SLEEP, payload: sleep })
		try {
			const res = await removeSleep(sleep)
			const contextResponse = {
				...res.data,
				_app: {
					...sleep._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_SLEEP_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...sleep,
				_app: {
					err,
					...sleep._app,
				},
			}
			handleFail(dispatch, types.DELETE_SLEEP_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getSleeps = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_SLEEPS })
		try {
			await fetchSleeps(args, {
				dispatch,
				event: types.GET_SLEEPS_SUCCESS,
			})
			handleSuccess(dispatch, types.GET_SLEEPS_SUCCESS_COMPLETE)
		} catch (err) {
			handleFail(dispatch, types.GET_SLEEPS_FAIL, err)
		}
	}
	return thunk
}

export const setCurrentSleep = (sleep, attentionDate) => {
	if (!sleep) {
		sleep = getDefaultSleep(attentionDate)
	}
	return (dispatch) => {
		dispatch({ type: types.CURRENT_SLEEP_SET, payload: sleep })
	}
}

export const clearCurrentSleep = (sleep = getDefaultSleep()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_SLEEP_CLEAR, payload: sleep })
	}
}
