import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateExhaust } from 'api/api_utils'

const formatInterventionData = (interventionData) => ({
	id: interventionData.id,
	name: interventionData.name,
	symptom: interventionData.symptom,
	occurred_at: interventionData.occurred_at,
	completed_at: interventionData.completed_at,
	notes: interventionData.notes,
	uuid: interventionData.uuid,
	effect: interventionData.effect,
	meta: interventionData.meta,
	duration: interventionData.duration,
	recommendation: interventionData.recommendation,
	creator_key: interventionData.creator_key,
})

const formatInterventionComplianceData = (complianceData) => ({
	user_intervention_id: complianceData.user_intervention_id, // id of user_intervention model (instance)
	compliance: complianceData.compliance, // "true" | "false" | "unknown" | NULL
	range_start: complianceData.range_start,
	range_end: complianceData.range_end,
})

export const getInterventions = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/interventions',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'interventions',
		1,
		[],
		reduxEvent
	)
}

export const getInterventionsSuperset = (args, reduxEvent) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: 'v1/interventions?superset=true',
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'interventions',
		1,
		[],
		reduxEvent
	)
}

export const addIntervention = (interventionData) => {
	return axiosInstance.request({
		url: 'v1/interventions',
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatInterventionData(interventionData),
	})
}

export const updateIntervention = (interventionData) => {
	return axiosInstance.request({
		url: `v1/interventions/${interventionData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatInterventionData(interventionData),
	})
}

export const removeIntervention = (interventionData) => {
	return axiosInstance.request({
		url: `v1/interventions/${interventionData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}

// Compliance
export const getInterventionCompliance = (args, reduxEvent, payloadMerge) => {
	return paginateExhaust(
		axiosInstance.request,
		args,
		{
			url: `v1/interventions/${args.user_intervention_id}/compliance`,
			source: AxiosAbortController.signal,
			method: 'get',
		},
		'interventionCompliance',
		1,
		[],
		reduxEvent,
		payloadMerge
	)
}

export const addInterventionCompliance = (interventionData) => {
	return axiosInstance.request({
		url: `v1/interventions/${interventionData.user_intervention_id}/compliance`,
		source: AxiosAbortController.signal,
		method: 'post',
		data: formatInterventionComplianceData(interventionData),
	})
}

export const updateInterventionCompliance = (interventionData) => {
	return axiosInstance.request({
		url: `v1/interventions/${interventionData.user_intervention_id}/compliance/${interventionData.id}`,
		source: AxiosAbortController.signal,
		method: 'put',
		data: formatInterventionComplianceData(interventionData),
	})
}

export const removeInterventionCompliance = (interventionData) => {
	return axiosInstance.request({
		url: `v1/interventions/${interventionData.user_intervention_id}/compliance/${interventionData.id}`,
		source: AxiosAbortController.signal,
		method: 'delete',
	})
}
