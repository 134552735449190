/**
 * CRUD Actions for SLEEP
 */
// ADD
export const ADD_SLEEP_SUCCESS = 'ADD_SLEEP_SUCCESS'
export const ADD_SLEEP_FAIL = 'ADD_SLEEP_FAIL'
export const ADD_SLEEP = 'ADD_SLEEP'
// UPDATE
export const UPDATE_SLEEP_SUCCESS = 'UPDATE_SLEEP_SUCCESS'
export const UPDATE_SLEEP_FAIL = 'UPDATE_SLEEP_FAIL'
export const UPDATE_SLEEP = 'UPDATE_SLEEP'
// DELETE
export const DELETE_SLEEP_SUCCESS = 'DELETE_SLEEP_SUCCESS'
export const DELETE_SLEEP_FAIL = 'DELETE_SLEEP_FAIL'
export const DELETE_SLEEP = 'DELETE_SLEEP'
// GET ALL
export const GET_SLEEPS_SUCCESS = 'GET_SLEEPS_SUCCESS'
export const GET_SLEEPS_SUCCESS_COMPLETE = 'GET_SLEEPS_SUCCESS_COMPLETE'
export const GET_SLEEPS_FAIL = 'GET_SLEEPS_FAIL'
export const GET_SLEEPS = 'GET_SLEEPS'

export const GET_SLEEP_SUCCESS = 'GET_SLEEP_SUCCESS'
export const GET_SLEEP_FAIL = 'GET_SLEEP_FAIL'
export const GET_SLEEP = 'GET_SLEEP'
/**
 * Current Types
 */
// CURRENT SLEEP
export const CURRENT_SLEEP_SET = 'CURRENT_SLEEP_SET'
export const CURRENT_SLEEP_CLEAR = 'CURRENT_SLEEP_CLEAR'

export const MEDIA_SLEEP_SET = 'MEDIA_SLEEP_SET'

export const RESET_SLEEP_LOADERS = 'RESET_SLEEP_LOADERS'

export const SLEEP_CONTEXT_RESET = 'SLEEP_CONTEXT_RESET'
