export const GET_PROGRAMS_SUCCESS = 'GET_PROGRAMS_SUCCESS'
export const GET_PROGRAMS_FAIL = 'GET_PROGRAMS_FAIL'
export const GET_PROGRAMS = 'GET_PROGRAMS'

export const GET_USER_PROGRAMS_SUCCESS = 'GET_USER_PROGRAMS_SUCCESS'
export const GET_USER_PROGRAMS_FAIL = 'GET_USER_PROGRAMS_FAIL'
export const GET_USER_PROGRAMS = 'GET_USER_PROGRAMS'

export const GET_PROGRAM_SUCCESS = 'GET_PROGRAM_SUCCESS'
export const GET_PROGRAM_FAIL = 'GET_PROGRAM_FAIL'
export const GET_PROGRAM = 'GET_PROGRAM'

export const ADD_PROGRAM_SUCCESS = 'ADD_PROGRAM_SUCCESS'
export const ADD_PROGRAM_FAIL = 'ADD_PROGRAM_FAIL'
export const ADD_PROGRAM = 'ADD_PROGRAM'

export const CURRENT_PROGRAM_SET = 'CURRENT_PROGRAM_SET'
export const CURRENT_PROGRAM_CLEAR = 'CURRENT_PROGRAM_CLEAR'

export const CURRENT_PROGRAM_TAB_NAME_SET = 'CURRENT_PROGRAM_TAB_NAME_SET'
export const PROGRAM_TABLE_SEARCH_VALUE_SET = 'SEARCH_INPUT_SET'
export const PROGRAM_TABLE_SORT_SET = 'PROGRAM_TABLE_SORT_VALUE_SET'
