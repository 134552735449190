import * as types from 'types'
import { deepMerge } from 'reducers/z_redux_helpers'
import { v4 as uuidv4 } from 'uuid'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	user: {},
	session_token: {},
	error: {
		message: '',
	},
	sortedChannels: {
		channels: [],
		next: '',
	},
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE: {
			return JSON.parse(JSON.stringify(INITIAL_STATE))
		}

		case types.SENDBIRD_CONNECT || types.SENDBIRD_RECONNECT: {
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}
		}

		case types.SENDBIRD_CONNECT_SUCCESS || types.SENDBIRD_RECONNECT_SUCCESS: {
			return {
				...state,
				user: action.payload.user,
				session_token: action.payload.session_token,
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.SENDBIRD_CONNECT_FAIL: {
			return {
				...state,
				user: {},
				session_token: {},
				loading: {
					...state.loading,
					read: false,
				},
				error: {
					message: action.payload,
				},
			}
		}

		case types.SENDBIRD_RECONNECT_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}
		}

		case types.SENDBIRD_DISCONNECT: {
			return JSON.parse(JSON.stringify(INITIAL_STATE))
		}

		case 'FAST_FORWARD_STORES': {
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)
		}

		case types.CHAT_CONTEXT_RESET: {
			return JSON.parse(JSON.stringify(INITIAL_STATE))
		}

		case types.GET_SORTED_CHANNELS: {
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
					isLoading: true,
				},
			}
		}

		case types.GET_SORTED_CHANNELS_SUCCESS: {
			const oldChannelsUrls = (state?.sortedChannels?.channels || []).map((channel) => channel.channel_url)
			const newChannelsUrls = action.payload.channels?.map((channel) => channel.channel_url)

			const mergedChannelsUrls = [...new Set([...newChannelsUrls, ...oldChannelsUrls])]

			let mergedChannels = [...action.payload.channels, ...state.sortedChannels.channels]

			mergedChannels = mergedChannelsUrls.map((channel_url) =>
				mergedChannels.find((channel) => channel.channel_url === channel_url)
			)

			mergedChannels = mergedChannels.sort((a, b) => {
				if (a.customBadgeCount > b.customBadgeCount) {
					return -1
				}
				if (a.customBadgeCount < b.customBadgeCount) {
					return 1
				}
				return 0
			})

			const badgeCountChannels = mergedChannels.filter((channel) => channel.customBadgeCount > 0)

			badgeCountChannels.sort((a, b) => {
				if (a.last_message.created_at > b.last_message.created_at) {
					return -1
				}
				if (a.last_message.created_at < b.last_message.created_at) {
					return 1
				}
				return 0
			})

			mergedChannels = mergedChannels.map((channel) => {
				if (channel.customBadgeCount > 0) {
					return badgeCountChannels.shift()
				}
				return channel
			})

			return {
				...state,
				sortedChannels: {
					...action.payload,
					channels: mergedChannels,
				},
				loading: {
					...state.loading,
					read: true,
					isLoading: false,
				},
			}
		}

		case types.GET_SORTED_CHANNELS_FAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
					isLoading: false,
				},
			}
		}

		case types.CLEAR_SORTED_CHANNELS: {
			return {
				...state,
				sortedChannels: {
					channels: [],
					next: '',
				},
				loading: {
					...state.loading,
					read: false,
					isLoading: false,
				},
			}
		}

		// DEFAULT
		default: {
			return state
		}
	}
}
