import * as types from 'types'
import { storeItemsTransactor, storeItemsMerger, deepMerge, ArrayMerger } from 'reducers/z_redux_helpers'
import { daysScoresMerger } from 'reducers/z_summary_helpers'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import groupBy from 'lodash.groupBy'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	data: [],
	collection: [],
	current: {},
	interventionCompliance: {
		interventionMap: {},
		daysCompliance: {},
		loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
	},
	lastFetched: null,
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

// flattens the intervention scores join model through the intervention scores model
// entity = new object
// symtomEntitiesMap = stored object (if exists)
const interventionDecorator = (entity, interventionEntitiesMap) => {
	return interventionEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

const interventionUnsyncedDecorator = (entity, interventionEntitiesMap) => {
	return interventionEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		if (!entityMap.object._app.uuid) {
			entityMap.object._app.uuid = uuidv4()
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// GET INTERVENTIONS
		case types.GET_INTERVENTIONS_SUCCESS:
			let newPayload = ArrayMerger(state.data, [...action.payload.interventions, ...state.data])
			return {
				...state,
				data: newPayload,
				lastFetched: new Date().getTime(),
				loading: {
					...state.loading,
					read: false,
				},
			}

		// GET INTERVENTIONS
		case types.GET_INTERVENTIONS_SUPERSET_SUCCESS:
			return {
				...state,
				collection: storeItemsMerger(
					state.collection,
					action.payload.interventions
						.map((entity) => {
							return storeItemsTransactor(state, 'collection', entity, {
								id: entity.id,
							})
						})
						.reduce((acc, current) => acc.concat(current), [])
				),
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_INTERVENTIONS_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_INTERVENTIONS_SUPERSET_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_INTERVENTIONS:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		case types.GET_INTERVENTIONS_SUPERSET:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		// UPDATE INTERVENTION
		case types.UPDATE_INTERVENTION_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						interventionDecorator
					)
				),
				lastFetched: new Date().getTime(),
				loading: {
					...state.loading,
					update: false,
				},
			}

		case types.UPDATE_INTERVENTION_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					update: false,
				},
			}

		case types.UPDATE_INTERVENTION:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),
				loading: {
					...state.loading,
					update: true,
				},
			}

		// ADD INTERVENTION
		case types.ADD_INTERVENTION_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						interventionDecorator
					)
				),
				lastFetched: new Date().getTime(),
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_INTERVENTION_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_INTERVENTION:
			return {
				...state,
				data: state.data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				loading: {
					...state.loading,
					create: true,
				},
			}

		case types.DELETE_INTERVENTION_SUCCESS:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: false,
				},
			}
		case types.DELETE_INTERVENTION_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					delete: false,
				},
			}

		case types.DELETE_INTERVENTION:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.id),
				loading: {
					...state.loading,
					delete: true,
				},
			}

		case types.GET_INTERVENTION_COMPLIANCE_SUCCESS:
			let combinedResult = [
				...action.payload.interventionCompliance,
				...(state.interventionCompliance.interventionMap[action.payload.user_intervention_id] || []),
			]
			let mergedResult = Array.from(new Set(combinedResult.map((a) => a.id))).map((id) => {
				return combinedResult.find((a) => a.id === id)
			})
			let newDays = groupBy(mergedResult, (item) => {
				return moment(item.range_start).format('YYYY-MM-DD')
			})

			return {
				...state,
				interventionCompliance: {
					...state.interventionCompliance,
					interventionMap: {
						...state.interventionCompliance.interventionMap,
						[action.payload.user_intervention_id]: mergedResult,
					},
					daysCompliance: Object.keys(newDays).reduce((acc, item) => {
						if (!acc[item]) {
							acc[item] = newDays[item]
						} else {
							let combinedDaysResult = [...newDays[item], ...acc[item]]
							acc[item] = Array.from(new Set(combinedDaysResult.map((a) => a.id))).map((id) => {
								return combinedDaysResult.find((a) => a.id === id)
							})
						}
						return acc
					}, state.interventionCompliance.daysCompliance),
				},
			}

		case types.GET_INTERVENTION_COMPLIANCE_SUCCESS_COMPLETE:
			return {
				...state,
				interventionCompliance: {
					...state.interventionCompliance,
					loading: {
						...state.loading,
						read: false,
					},
				},
			}

		case types.GET_INTERVENTION_COMPLIANCE_FAIL:
			return {
				...state,
				interventionCompliance: {
					...state.interventionCompliance,
					loading: {
						...state.loading,
						read: false,
					},
				},
			}

		case types.GET_INTERVENTION_COMPLIANCE:
			return {
				...state,
				interventionCompliance: {
					...state.interventionCompliance,
					loading: {
						...state.loading,
						read: true,
					},
				},
			}

		case types.UPDATE_INTERVENTION_COMPLIANCE_SUCCESS:
			return {
				...state,
				interventionCompliance: {
					...state.interventionCompliance,
					interventionMap: {
						...state.interventionCompliance.interventionMap,
						[action.payload.user_intervention_id]: state.interventionCompliance.interventionMap[
							action.payload.user_intervention_id
						].map((item) => {
							return item.id === action.payload.id ? action.payload : item
						}),
					},
					daysCompliance: daysScoresMerger(state.interventionCompliance.daysCompliance, action.payload, true),
					loading: {
						...state.loading,
						update: false,
					},
				},
			}

		case types.UPDATE_INTERVENTION_COMPLIANCE_FAIL:
			return {
				...state,
				interventionCompliance: {
					...state.interventionCompliance,
					loading: {
						...state.loading,
						update: false,
					},
				},
			}

		case types.UPDATE_INTERVENTION_COMPLIANCE:
			return {
				...state,
				interventionCompliance: {
					...state.interventionCompliance,
					loading: {
						...state.loading,
						update: true,
					},
				},
			}

		case types.ADD_INTERVENTION_COMPLIANCE_SUCCESS:
			return {
				...state,
				interventionCompliance: {
					...state.interventionCompliance,
					interventionMap: {
						...state.interventionCompliance.interventionMap,
						[action.payload.user_intervention_id]: [
							action.payload,
							...state.interventionCompliance.interventionMap[action.payload.user_intervention_id],
						],
					},
					daysCompliance: daysScoresMerger(state.interventionCompliance.daysCompliance, action.payload, true),
					loading: {
						...state.loading,
						create: false,
					},
				},
			}

		case types.ADD_INTERVENTION_COMPLIANCE_FAIL:
			return {
				...state,
				interventionCompliance: {
					...state.interventionCompliance,
					loading: {
						...state.loading,
						create: false,
					},
				},
			}

		case types.ADD_INTERVENTION_COMPLIANCE:
			return {
				...state,
				interventionCompliance: {
					...state.interventionCompliance,
					loading: {
						...state.loading,
						create: true,
					},
				},
			}

		// CURRENT INTERVENTION
		case types.CURRENT_INTERVENTION_SET:
			return { ...state, current: action.payload }

		case types.CURRENT_INTERVENTION_CLEAR:
			return { ...state, current: action.payload }

		case types.RESET_INTERVENTION_LOADERS:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		// case 'persist/REHYDRATE':
		//   if (action.payload.key === 'primary') {
		//     return {
		//       ...action.payload.intervention,
		//       loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
		//     };
		//   }
		//   return state;

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		case types.SCENE:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		case types.INTERVENTION_CONTEXT_RESET:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// DEFAULT
		default:
			return state
	}
}
