import moment from 'moment'
import groupBy from 'lodash.groupBy'
import * as types from 'types'
import { daysScoresMerger } from 'reducers/z_summary_helpers'
import { storeItemsTransactor, storeItemsMerger, deepMerge } from 'reducers/z_redux_helpers'

const FALSY_LOADERS = {
	create: false,
	read: false,
	update: false,
	delete: false,
}

const INITIAL_STATE = {
	data: [],
	daysScores: {},
	current: {},
	lastFetched: null,
	loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
}

// flattens the symptom scores join model through the symptom scores model
// entity = new object
// symtomScoreEntitiesMap = stored object (if exists)
const symptomScoreDecorator = (entity, symtomEntitiesMap) => {
	return symtomEntitiesMap.map((entityMap) => {
		entityMap.object = {
			...entityMap.object,
			...entity,
		}
		entityMap.object._app = {
			...entityMap.object._app,
			synced: true,
		}
		return entityMap
	})
}

export default (state = JSON.parse(JSON.stringify(INITIAL_STATE)), action) => {
	const wasOffline = action && action.meta && action.meta.offline
	const wasOnline = !wasOffline

	switch (action.type) {
		case types.PURGE:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// GET SYMPTOM SCORES
		case types.GET_SYMPTOM_SCORES_SUCCESS:
			const data = storeItemsMerger(
				state.data,
				action.payload.symptomScores
					.map((entity) => {
						return storeItemsTransactor(state, 'data', entity, {
							id: entity.id,
						})
					})
					.reduce((acc, current) => acc.concat(current), [])
			)
			return {
				...state,
				data,
				daysScores: groupBy(data, (item) => {
					return moment(item.range_start).format('YYYY-MM-DD')
				}),
				lastFetched: new Date().getTime(),
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_SYMPTOM_SCORES_FAIL:
			return {
				...state,
				loading: {
					...state.loading,
					read: false,
				},
			}

		case types.GET_SYMPTOM_SCORES:
			return {
				...state,
				loading: {
					...state.loading,
					read: true,
				},
			}

		// UPDATE SYMPTOM SCORE
		case types.UPDATE_SYMPTOM_SCORE_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						symptomScoreDecorator
					)
				),
				lastFetched: new Date().getTime(),
				daysScores: daysScoresMerger(state.daysScores, action.payload, true),
				loading: {
					...state.loading,
					update: false,
				},
			}

		case types.UPDATE_SYMPTOM_SCORE_FAIL:
			return {
				...state,
				daysScores: daysScoresMerger(state.daysScores, action.payload, true),
				loading: {
					...state.loading,
					update: false,
				},
			}

		case types.UPDATE_SYMPTOM_SCORE:
			return {
				...state,
				data: state.data.filter((item) => item.id !== action.payload.new.id),
				daysScores: daysScoresMerger(state.daysScores, action.payload, false),
				loading: {
					...state.loading,
					update: true,
				},
			}

		// ADD SYMPTOM SCORE
		case types.ADD_SYMPTOM_SCORE_SUCCESS:
			return {
				...state,
				data: storeItemsMerger(
					state.data,
					storeItemsTransactor(
						state,
						'data',
						action.payload,
						{
							id: action.payload.id,
						},
						symptomScoreDecorator
					)
				),
				lastFetched: new Date().getTime(),
				daysScores: daysScoresMerger(state.daysScores, action.payload, true),
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_SYMPTOM_SCORE_FAIL:
			return {
				...state,
				daysScores: daysScoresMerger(state.daysScores, action.payload, true),
				loading: {
					...state.loading,
					create: false,
				},
			}

		case types.ADD_SYMPTOM_SCORE:
			return {
				...state,
				data: state.data.filter((item) => item._app.uuid !== action.payload._app.uuid),
				daysScores: daysScoresMerger(state.daysScores, action.payload, false),
				loading: {
					...state.loading,
					create: true,
				},
			}

		case types.RESET_SYMPTOM_SCORE_LOADERS:
			return {
				...state,
				loading: JSON.parse(JSON.stringify(FALSY_LOADERS)),
			}

		case 'FAST_FORWARD_STORES':
			return deepMerge(JSON.parse(JSON.stringify(INITIAL_STATE)), state)

		case types.SYMPTOM_SCORE_CONTEXT_RESET:
			return JSON.parse(JSON.stringify(INITIAL_STATE))

		// DEFAULT
		default:
			return state
	}
}
