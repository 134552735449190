/**
 *
 */
// FINISH
// ADD
export const ADD_PROGRAM_SURVEY_SUCCESS = 'ADD_PROGRAM_SURVEY_SUCCESS'
export const ADD_PROGRAM_SURVEY_MEDIA_SUCCESS = 'ADD_PROGRAM_SURVEY_MEDIA_SUCCESS'
export const ADD_PROGRAM_SURVEY_FAIL = 'ADD_PROGRAM_SURVEY_FAIL'
export const ADD_PROGRAM_SURVEY_MEDIA_FAIL = 'ADD_PROGRAM_SURVEY_MEDIA_FAIL'
export const ADD_PROGRAM_SURVEY = 'ADD_PROGRAM_SURVEY'
// DELETE
export const DELETE_PROGRAM_SURVEY_SUCCESS = 'DELETE_PROGRAM_SURVEY_SUCCESS'
export const DELETE_PROGRAM_SURVEY_MEDIA_SUCCESS = 'DELETE_PROGRAM_SURVEY_MEDIA_SUCCESS'
export const DELETE_PROGRAM_SURVEY_FAIL = 'DELETE_PROGRAM_SURVEY_FAIL'
export const DELETE_PROGRAM_SURVEY_MEDIA_FAIL = 'DELETE_PROGRAM_SURVEY_MEDIA_FAIL'
export const DELETE_PROGRAM_SURVEY = 'DELETE_PROGRAM_SURVEY'
// UPDATE
export const UPDATE_PROGRAM_SURVEY_SUCCESS = 'UPDATE_PROGRAM_SURVEY_SUCCESS'
export const UPDATE_PROGRAM_SURVEY_MEDIA_SUCCESS = 'UPDATE_PROGRAM_SURVEY_MEDIA_SUCCESS'
export const UPDATE_PROGRAM_SURVEY_FAIL = 'UPDATE_PROGRAM_SURVEY_FAIL'
export const UPDATE_PROGRAM_SURVEY_MEDIA_FAIL = 'UPDATE_PROGRAM_SURVEY_MEDIA_FAIL'
export const UPDATE_PROGRAM_SURVEY = 'UPDATE_PROGRAM_SURVEY'
// GET
export const GET_PROGRAM_SURVEY_SUCCESS = 'GET_PROGRAM_SURVEY_SUCCESS'
export const GET_PROGRAM_SURVEY_FAIL = 'GET_PROGRAM_SURVEY_FAIL'
export const GET_PROGRAM_SURVEY = 'GET_PROGRAM_SURVEY'
// GET ALL
export const GET_PROGRAM_SURVEYS_SUCCESS = 'GET_PROGRAM_SURVEYS_SUCCESS'
export const GET_PROGRAM_SURVEYS_SUCCESS_COMPLETE = 'GET_PROGRAM_SURVEYS_SUCCESS_COMPLETE'
export const GET_PROGRAM_SURVEYS_FAIL = 'GET_PROGRAM_SURVEYS_FAIL'
export const GET_PROGRAM_SURVEYS = 'GET_PROGRAM_SURVEYS'

// FINISH ?
export const FINISH_PROGRAM_SURVEY_SUCCESS = 'FINISH_PROGRAM_SURVEY_SUCCESS'
export const FINISH_PROGRAM_SURVEY_FAIL = 'FINISH_PROGRAM_SURVEY_FAIL'
export const FINISH_PROGRAM_SURVEY = 'FINISH_PROGRAM_SURVEY'

// ENRICH
export const ENRICH_PROGRAM_SURVEYS_SUCCESS = 'ENRICH_PROGRAM_SURVEYS_SUCCESS'
export const ENRICH_PROGRAM_SURVEYS_FAIL = 'ENRICH_PROGRAM_SURVEYS_FAIL'
export const ENRICH_PROGRAM_SURVEYS = 'ENRICH_PROGRAM_SURVEYS'

/**
 * Current Types
 */
// CURRENT
export const CURRENT_PROGRAM_SURVEY_SET = 'CURRENT_PROGRAM_SURVEY_SET'
export const CURRENT_PROGRAM_SURVEY_CLEAR = 'CURRENT_PROGRAM_SURVEY_CLEAR'

export const RESET_PROGRAM_SURVEY_LOADERS = 'RESET_PROGRAM_SURVEY_LOADERS'

export const PROGRAM_SURVEY_CONTEXT_RESET = 'PROGRAM_SURVEY_CONTEXT_RESET'
