/**
 * CRUD Actions for QUESTION
 */
// ADD
export const ADD_QUESTION_SUCCESS = 'ADD_QUESTION_SUCCESS'
export const ADD_QUESTION_FAIL = 'ADD_QUESTION_FAIL'
export const ADD_QUESTION = 'ADD_QUESTION'
// UPDATE
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS'
export const UPDATE_QUESTION_FAIL = 'UPDATE_QUESTION_FAIL'
export const UPDATE_QUESTION = 'UPDATE_QUESTION'
// DELETE
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS'
export const DELETE_QUESTION_FAIL = 'DELETE_QUESTION_FAIL'
export const DELETE_QUESTION = 'DELETE_QUESTION'
// GET ALL
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS'
export const GET_QUESTIONS_FAIL = 'GET_QUESTIONS_FAIL'
export const GET_QUESTIONS = 'GET_QUESTIONS'

/**
 * Current Types
 */

export const MEDIA_QUESTION_SET = 'MEDIA_QUESTION_SET'

export const RESET_QUESTION_LOADERS = 'RESET_QUESTION_LOADERS'

export const QUESTION_CONTEXT_RESET = 'QUESTION_CONTEXT_RESET'
