import React, { useRef, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import {
	ComposedChart,
	LineChart,
	Area,
	ReferenceArea,
	Line,
	XAxis,
	YAxis,
	Cell,
	CartesianGrid,
	Tooltip,
	Scatter,
	Bar,
	Legend,
	ResponsiveContainer,
} from 'recharts'
import moment from 'moment'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrinBeam, faMeh, faTired, faRefresh } from '@fortawesome/free-solid-svg-icons'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/Refresh'
import Excellent from 'images/emotions/surprised.png'
import Great from 'images/emotions/grin.png'
import Decent from 'images/emotions/smile.png'
import Okay from 'images/emotions/moderate.png'
import Mild from 'images/emotions/sad.png'
import Bad from 'images/emotions/worried.png'
import Terrible from 'images/emotions/terrible.png'
import Typography from 'typography'
import regression from 'regression'
import { useTheme } from '@mui/material/styles'

const SymptomScoreColor = '#f3b786'
const NonComplianceColor = '#bf565f'
const ComplianceColor = '#7db586'

const enumerateDaysBetweenDates = (startDate, endDate) => {
	const now = moment(startDate).clone()
	const dates = []

	while (now.isSameOrBefore(moment(endDate))) {
		dates.push(now.format('YYYY-MM-DD'))
		now.add(1, 'days')
	}
	return dates.reduce((acc, day) => {
		acc[day] = null
		return acc
	}, {})
}

const PREFIX = 'SymptomScores'
const classes = {
	frame: `${PREFIX}-frame`,
	floatLeftPane: `${PREFIX}-floatLeftPane`,
	floatRightPane: `${PREFIX}-floatRightPane`,
	block: `${PREFIX}-block`,
	tooltipContainer: `${PREFIX}-tooltipContainer`,
	complianceBar: `${PREFIX}-complianceBar`,
	responsiveChartContainer: `${PREFIX}-responsiveChartContainer`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.frame}`]: {
		position: 'relative',
		borderWidth: 5,
		borderColor: theme.palette.primary[500],
		borderStyle: 'solid',
		borderRadius: 5,
	},
	[`& .${classes.floatLeftPane}`]: {
		position: 'absolute',
		left: -22.5,
		width: 40,
		height: 40,
		borderWidth: 1,
		borderColor: theme.palette.primary[500],
		borderStyle: 'solid',
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#444',
		display: 'flex',
	},
	[`& .${classes.floatRightPane}`]: {
		position: 'absolute',
		right: -22.5,
		width: 40,
		height: 40,
		borderWidth: 1,
		borderColor: theme.palette.primary[500],
		borderStyle: 'solid',
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#444',
		display: 'flex',
	},
	[`& .${classes.block}`]: {
		display: 'block',
	},
	[`& .${classes.tooltipContainer}`]: {
		paddingTop: 0,
		paddingBottom: 10,
		paddingLeft: 15,
		paddingRight: 15,
		borderWidth: 3,
		borderColor: theme.palette.primary[500],
		borderStyle: 'solid',
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#444',
	},
	[`& .${classes.complianceBar}`]: {
		opacity: 0.4,
	},
	[`& .${classes.responsiveChartContainer}`]: {
		flexGrow: 1,
		overflowY: 'hidden',
	},
}))

const CustomizedXDot = (props) => {
	const { cx, cy, stroke, payload, value } = props
	let y = payload['Symptom Score'] === 100 ? 5 : 10
	if (payload['Non-Compliance'] === 100) {
		return (
			<svg x={cx - 5} y={y} width={10} height={10} fill="#bf565f" stroke="#bf565f" viewBox="0 0 24 24">
				<path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
			</svg>
		)
	}
	return <></>
}

const CustomizedCheckDot = (props) => {
	const { cx, cy, stroke, payload, value } = props
	let y = payload['Symptom Score'] === 100 ? 5 : 10
	if (payload['Compliance'] === 100) {
		return (
			<svg x={cx - 5} y={y} width={10} height={10} fill="#7db586" stroke="#7db586" viewBox="0 0 24 24">
				<path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
			</svg>
		)
	}
	return <></>
}

const CustomTooltip = ({ active, payload, label, classes }) => {
	if (active) {
		const item = payload && payload[0]
		if (
			item &&
			(Number.isInteger(item.payload['Symptom Score']) ||
				Number.isInteger(item.payload['Compliance']) ||
				Number.isInteger(item.payload['Non-Compliance']))
		) {
			return (
				<div className={classes.tooltipContainer}>
					<Stack>
						{Number.isInteger(item.payload['Symptom Score']) && (
							<>
								<Grid container alignItems="center" style={{ marginTop: 5 }}>
									<Grid item>{`${item.payload['date']}: `}</Grid>
									<Grid item>{GetEmoji(item.payload['Symptom Score'])}</Grid>
								</Grid>
								<Grid container alignItems="center">
									<Grid
										item
										style={{
											color: SymptomScoreColor,
										}}
									>{`Symptom Score:`}</Grid>
									<Grid
										item
										style={{
											marginLeft: 10,
										}}
									>
										{`${mapScoreToWords('Symptom Score', item.payload['Symptom Score'])}`}
									</Grid>
								</Grid>
							</>
						)}
						{Number.isInteger(item.payload['Compliance']) && (
							<Grid container alignItems="center">
								<Grid
									item
									style={{
										color: ComplianceColor,
									}}
								>{`Compliance:`}</Grid>
								<Grid
									item
									style={{
										marginLeft: 10,
									}}
								>
									{`${mapScoreToWords('Compliance', item.payload['Compliance'])}`}
								</Grid>
							</Grid>
						)}
						{Number.isInteger(item.payload['Non-Compliance']) && (
							<Grid container alignItems="center">
								<Grid
									item
									style={{
										color: NonComplianceColor,
									}}
								>{`Non-Compliance:`}</Grid>
								<Grid
									item
									style={{
										marginLeft: 10,
									}}
								>
									{`${mapScoreToWords('Non-Compliance', item.payload['Non-Compliance'])}`}
								</Grid>
							</Grid>
						)}
					</Stack>
				</div>
			)
		}
	}

	return null
}
const GetEmoji = (value) => {
	let image
	if (value === 100) {
		image = Excellent
	} else if (value === 83) {
		image = Great
	} else if (value === 67) {
		image = Decent
	} else if (value === 50) {
		image = Okay
	} else if (value === 33) {
		image = Mild
	} else if (value === 17) {
		image = Bad
	} else if (value === 0) {
		image = Terrible
	}
	return (
		<img
			src={image}
			style={{
				margin: 'auto',
				marginLeft: 10,
				padding: 2,
				height: 30,
				width: 30,
			}}
		/>
	)
}

const mapScoreToWords = (name, num) => {
	if (name === 'Symptom Score') {
		if (num === 0) {
			return 'Terrible'
		} else if (num > 0 && num <= 17) {
			return 'Bad'
		} else if (num > 17 && num <= 33) {
			return 'Mild'
		} else if (num > 33 && num <= 50) {
			return 'Okay'
		} else if (num > 50 && num <= 67) {
			return 'Decent'
		} else if (num > 67 && num <= 83) {
			return 'Great'
		} else if (num > 83 && num <= 100) {
			return 'Excellent'
		}
	}
	if (name === 'Compliance') {
		return 'Compliant'
	}
	if (name === 'Non-Compliance') {
		return 'Not Compliant'
	}
}

// zoom() {
//   let { refAreaLeft, refAreaRight, data } = this.state;

//   if (refAreaLeft === refAreaRight || refAreaRight === '') {
//     this.setState(() => ({
//       refAreaLeft: '',
//       refAreaRight: '',
//     }));
//     return;
//   }

//   // xAxis domain
//   if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

//   // yAxis domain
//   const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, 'cost', 1);
//   const [bottom2, top2] = getAxisYDomain(refAreaLeft, refAreaRight, 'impression', 50);

//   this.setState(() => ({
//     refAreaLeft: '',
//     refAreaRight: '',
//     data: data.slice(),
//     left: refAreaLeft,
//     right: refAreaRight,
//     bottom,
//     top,
//     bottom2,
//     top2,
//   }));
// }

const SymptomScoresChart = React.memo(function WrappedComponent(props) {
	const { scores, range, onRefresh, regions = {} } = props
	const theme = useTheme()
	const ref = useRef(null)
	const [containerWidth, setContainerWidth] = useState(0)
	useEffect(() => {
		setContainerWidth(ref.current ? ref.current.offsetWidth : 0)
	}, [ref.current])
	let refAreaLeft, refAreaRight
	const rangeScores = Object.keys(scores).reduce((acc, day) => {
		if (day in acc) {
			const data = scores[day][0]

			acc[day] = {
				'Symptom Score': data.score,
				dateRaw: moment(data.range_start).valueOf(),
				date: moment(data.range_start).format('MM/DD'),
			}
		}
		return acc
	}, enumerateDaysBetweenDates(range.start, range.end))

	const bestFitData = Object.keys(rangeScores).reduce((acc, day, idx) => {
		if (rangeScores[day]) {
			acc.push([idx, rangeScores[day]['Symptom Score']])
		}
		return acc
	}, [])
	const bestFit = regression.linear(bestFitData)
	const graphData = Object.keys(rangeScores)
		.reduce((acc, date, idx, arr) => {
			let dataset = rangeScores[date]

			if (regions[date]) {
				dataset = {
					...dataset,
					...regions[date],
					date: moment(regions[date].range_start).format('MM/DD'),
				}
			} else if (idx === 0) {
				dataset = {
					date: moment(Object.keys(rangeScores)[0]).format('MM/DD'),
				}
			} else if (idx === arr.length - 1) {
				dataset = {
					date: moment(Object.keys(rangeScores)[arr.length - 1]).format('MM/DD'),
				}
			}

			acc.push(dataset)
			return acc
		}, [])
		.filter((d) => d)
		.map((data, idx, source) => {
			try {
				if (bestFit.points.length > 1) {
					if (idx === 0) {
						data._bestFitSymptom = bestFit.points[0][1]
					} else if (idx === source.length - 1) {
						data._bestFitSymptom = bestFit.points[bestFit.points.length - 1][1]
					}
				}
			} catch (err) {
				console.log(err)
			}
			return data
		})
	const hasRegions = !!Object.keys(regions).length

	return (
		<Root className={classes.frame} ref={ref}>
			<div
				className={classes.floatLeftPane}
				style={{
					top: -20,
				}}
			>
				<img
					src={Excellent}
					style={{
						margin: 'auto',
						padding: 2,
						height: 30,
						width: 30,
					}}
				/>
			</div>
			<div
				className={classes.floatLeftPane}
				style={{
					bottom: -20,
				}}
			>
				<img
					src={Terrible}
					style={{
						margin: 'auto',
						padding: 2,
						height: 30,
						width: 30,
					}}
				/>
			</div>
			<div className={classes.responsiveChartContainer}>
				<ResponsiveContainer aspect={2} width="99%" minHeight={200} maxHeight={250}>
					<ComposedChart
						width={containerWidth}
						height={250}
						data={graphData}
						onMouseDown={props.onMouseDown || (() => {})}
						onMouseMove={props.onMouseMove || (() => {})}
						onMouseUp={props.onMouseUp || (() => {})}
						margin={{
							top: 15,
							right: 5,
							left: 15,
							bottom: 5,
						}}
						barCategoryGap={'0%'}
					>
						<CartesianGrid interval={5} strokeDasharray="2 2" />
						<Tooltip
							animationDuration={0}
							allowEscapeViewBox={{ x: false, y: false }}
							cursor={{ fill: 'rgba(206, 206, 206, 0.2)' }}
							content={<CustomTooltip classes={classes} />}
						/>
						<Legend margin={{ top: 20 }} />
						<YAxis
							opacity="1"
							style={{ display: 'none' }}
							width={2}
							ticks={[0, 25, 50, 75, 100]}
							tickSize={4}
							dataKey={'Symptom Score'}
						/>
						<XAxis
							angle={45}
							dy={8}
							interval="preserveStartEnd"
							dataKey="date"
							tick={{ fontSize: 9, stroke: '#bfbfbf' }}
							padding={{ top: 10 }}
						/>
						{!!hasRegions && (
							<Bar
								stackId="c"
								dataKey="Non-Compliance"
								fill={NonComplianceColor}
								opacity={0.2}
								stroke={NonComplianceColor}
								dot={<CustomizedXDot />}
							/>
						)}
						{!!hasRegions && (
							<Bar
								stackId="c"
								dataKey="Compliance"
								opacity={0.2}
								stroke={ComplianceColor}
								fill={ComplianceColor}
								dot={<CustomizedCheckDot />}
							/>
						)}
						<Line
							type="monotone"
							dataKey="Symptom Score"
							fill={SymptomScoreColor}
							stroke={SymptomScoreColor}
							strokeWidth={4}
							activeDot={{
								r: 5,
								strokeWidth: 5,
								stroke: SymptomScoreColor,
							}}
							dot={{ r: 3, strokeWidth: 2 }}
						/>
						<Line
							type="linearClosed"
							dataKey="_bestFitSymptom"
							connectNulls={true}
							fill={theme.palette.primary[500]}
							stroke={theme.palette.primary[500]}
							strokeWidth={1}
							// fill={(d) => (d > bestFit.points[0][1] ? 'green' : 'red')}
							// stroke={(d) => (d > bestFit.points[0][1] ? 'green' : 'red')}
							dot={false}
							legendType="none"
						/>

						{refAreaLeft && refAreaRight ? (
							<ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
						) : null}
					</ComposedChart>
				</ResponsiveContainer>
			</div>

			{onRefresh ? (
				<div
					className={classes.floatRightPane}
					style={{
						top: -20,
					}}
				>
					<IconButton
						color="inherit"
						onClick={() =>
							onRefresh(
								moment(range.start).subtract(1, 'days').toISOString(),
								moment(range.end).add(1, 'days').toISOString()
							)
						}
						style={{
							borderRadius: 0,
						}}
						size="large"
					>
						<RefreshIcon className={classes.block} color="inherit" />
					</IconButton>
				</div>
			) : (
				<></>
			)}
		</Root>
	)
})

export default SymptomScoresChart
