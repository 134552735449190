import axiosInstance, { AxiosAbortController } from 'src/axiosConfig'
import { paginateDefaultArgs } from 'api/api_utils'

// export const removeSurvey = surveyData => {
//   return axiosInstance.request({
//     url: `v1/surveys/${surveyData.id}`,
//     method: 'delete',
//   });
// };

export const fetchProgramSurveys = (args) => {
	const queryString = paginateDefaultArgs(args)
	return axiosInstance.request({
		url: `v1/user/programSurveys?${queryString}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const enrichProgramSurvey = (id) => {
	return axiosInstance.request({
		url: `v1/user/programSurveys/${id}`,
		source: AxiosAbortController.signal,
		method: 'get',
	})
}

export const addProgramSurvey = (args) => {
	const data = {
		user_key: args?.user_key,
		program_survey_id: args?.program_survey_id,
		occurred_at: args?.occurred_at,
	}
	return axiosInstance.request({
		url: `v1/user/programSurveys`,
		source: AxiosAbortController.signal,
		method: 'post',
		data: data,
	})
}
