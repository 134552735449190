import { ActiveBadgeAvatar, Button, Logo, PictureInPictureAvatar, ResponsiveFullscreenDialog } from '@components'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import AssignmentIcon from '@mui/icons-material/Assignment'
import BarChartIcon from '@mui/icons-material/BarChart'
import ChatIcon from '@mui/icons-material/Chat'
import DnsRoundedIcon from '@mui/icons-material/DnsRounded'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import GetApp from '@mui/icons-material/GetApp'
import LiveHelpIcon from '@mui/icons-material/LiveHelp'
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup'
import SettingsIcon from '@mui/icons-material/Settings'
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes'
import TimelineIcon from '@mui/icons-material/Timeline'
import TimerIcon from '@mui/icons-material/Timer'
import { useTheme } from '@mui/material'
import Badge from '@mui/material/Badge'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import { styled } from '@mui/material/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Typography from '@mui/material/Typography'
import * as actions from 'actions'
import { downloadExport } from 'api/analytics'
import classNames from 'classnames'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { useIntercom } from 'react-use-intercom'
import { paths } from 'routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPoo } from '@fortawesome/free-solid-svg-icons'

const PREFIX = 'Navigator'

const classes = {
	badge: `${PREFIX}-badge`,
	categoryHeader: `${PREFIX}-categoryHeader`,
	categoryHeaderPrimary: `${PREFIX}-categoryHeaderPrimary`,
	navButton: `${PREFIX}-navButton`,
	navItemText: `${PREFIX}-navItemText`,
	iconWrapper: `${PREFIX}-iconWrapper`,
	item: `${PREFIX}-item`,
	itemCategory: `${PREFIX}-itemCategory`,
	itemPersonal: `${PREFIX}-itemPersonal`,
	firebase: `${PREFIX}-firebase`,
	topNameMargin: `${PREFIX}-topNameMargin`,
	itemActionable: `${PREFIX}-itemActionable`,
	itemActiveItem: `${PREFIX}-itemActiveItem`,
	itemPrimary: `${PREFIX}-itemPrimary`,
	divider: `${PREFIX}-divider`,
	noSelect: `${PREFIX}-noSelect`,
	ellipsisName: `${PREFIX}-ellipsisName`,
	center: `${PREFIX}-center`,
	flexContainer: `${PREFIX}-flexContainer`,
	flexHeader: `${PREFIX}-flexHeader`,
	flexList: `${PREFIX}-flexList`,
	flexFooter: `${PREFIX}-flexFooter`,
	personaSection: `${PREFIX}-personaSection`,
}

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
	[`& .${classes.badge}`]: {
		right: -36,
		top: -1,
		fontSize: 10,
		border: `2px solid #fff`,
		padding: '0 5px',
	},
	[`& .${classes.categoryHeader}`]: {
		paddingTop: 16,
		paddingBottom: 16,
	},
	[`& .${classes.categoryHeaderPrimary}`]: {
		color: theme.palette.common.white,
	},
	[`& .${classes.navButton}`]: {
		width: '100%',
		background: 'transparent',
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		},
		'&.active:hover': {
			backgroundColor: theme.palette.primary[400],
		},
	},
	[`& .${classes.navItemText}`]: {
		paddingLeft: theme.spacing(1),
		textAlign: 'center',
	},
	[`& .${classes.iconWrapper}`]: {
		margin: 'auto',
		lineHeight: 1,
	},
	[`& .${classes.item}`]: {
		paddingTop: 4,
		paddingBottom: 4,
		color: 'rgba(255, 255, 255, 0.7)',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	[`& .${classes.itemCategory}`]: {
		backgroundColor: '#232f3e',
		boxShadow: '0 -1px 0 #404854 inset',
		paddingTop: 16,
		paddingBottom: 16,
	},
	[`& .${classes.itemPersonal}`]: {
		boxShadow: '0 -1px 0 #404854 inset',
		paddingTop: 26,
		paddingBottom: 13,
	},
	[`& .${classes.firebase}`]: {
		fontSize: 24,
		fontFamily: theme.typography.fontFamily,
		color: theme.palette.common.white,
	},
	[`& .${classes.topNameMargin}`]: {
		// marginTop: 15,
	},
	[`& .${classes.itemActionable}`]: {
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)',
		},
	},
	[`& .${classes.itemActiveItem}`]: {
		backgroundColor: theme.palette.primary[500],
	},
	[`& .${classes.itemPrimary}`]: {
		color: 'inherit',
		fontSize: theme.typography.fontSize,
		'&$textDense': {
			fontSize: theme.typography.fontSize,
		},
	},
	[`& .${classes.divider}`]: {
		marginTop: theme.spacing(2),
	},
	[`& .${classes.noSelect}`]: {
		userSelect: 'none',
	},
	[`& .${classes.ellipsisName}`]: {
		textOverflow: 'ellipsis',
	},
	[`& .${classes.center}`]: {
		textAlign: 'center',
		justifyContent: 'center',
		display: 'flex',
	},
	[`& .${classes.flexContainer}`]: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	[`& .${classes.flexHeader}`]: {
		flexGrow: 0,
		flexShrink: 1,
		flexBasis: 'auto',
	},
	[`& .${classes.flexList}`]: {
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 'auto',
		overflowY: 'auto',
		'&::-webkit-scrollbar': {
			backgroundColor: theme.palette.secondary[500],
			width: 8,
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: '#8a9bb2',
			borderRadius: 8,
			border: '2px solid #262f3d',
		},
	},
	[`& .${classes.flexFooter}`]: {
		flexGrow: 0,
		flexShrink: 1,
		flexBasis: 'auto',
	},
	[`& .${classes.personaSection}`]: {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
}))

const StyledBadge = styled(Badge)(({ theme }) => ({
	[`&`]: {
		right: -16,
		top: -1,
		fontSize: 10,
		border: `2px solid #fff`,
		padding: '0 5px',
	},
}))

const NewFeatureBadge = () => {
	return <StyledBadge badgeContent={'new'} color="error"></StyledBadge>
}

const categories = [
	{
		id: 'Timeline',
		children: [
			{
				id: 'Diary',
				icon: <DnsRoundedIcon />,
				page: paths.diary,
				visibility: { individual: true, clinical: true, researcher: true },
			},
			// { id: 'Authentication', icon: <PeopleIcon />, page: paths.authentication },
			{
				id: 'Stool Trends (Beta)',
				icon: (
					<FontAwesomeIcon
						style={{
							color: '#fff',
							fontSize: '1rem',
							width: 20,
							textAlign: 'center',
						}}
						icon={faPoo}
					/>
				),
				page: paths.stoolTrends,
				visibility: { individual: false, clinical: true, researcher: true },
			},
			{
				id: 'Surveys',
				icon: <AssignmentIcon />,
				page: paths.survey,
				visibility: { individual: false, clinical: true, researcher: true },
			},
			{
				id: 'History',
				icon: <TimelineIcon />,
				page: paths.history,
				visibility: { individual: true, clinical: true, researcher: true },
			},
			// { id: 'Functions', icon: <SettingsEthernetIcon />, page: paths.functions },
			// { id: 'ML Kit', icon: <SettingsInputComponentIcon />, page: paths.ml },
		],
		visibility: { individual: true, clinical: true, researcher: true },
	},
	{
		id: 'Treatment',
		children: [
			// {
			// 	id: 'Chat',
			// 	icon: <ChatIcon />,
			// 	page: paths.chat,
			// 	visibility: { individual: true, clinical: false, researcher: false },
			// },
			{
				id: 'Analytics',
				icon: <BarChartIcon />,
				page: paths.analytics,
				visibility: { individual: true, clinical: true, researcher: false },
			},
			{
				id: 'Interventions',
				icon: <TimerIcon />,
				page: paths.interventions,
				visibility: { individual: true, clinical: true, researcher: false },
			},
			{
				id: 'Notes',
				icon: <SpeakerNotesIcon />,
				page: paths.notes,
				visibility: { individual: false, clinical: true, researcher: false },
			},
			// {
			// 	id: 'Export Data',
			// 	icon: <GetApp />,
			// 	page: false,
			// 	onClick: async () => {
			// 		const data = await downloadExport()
			// 		const a = document.createElement('a')
			// 		a.href = data.url
			// 		a.setAttribute('download', 'export.zip')
			// 		a.click()
			// 		return false
			// 	},
			// 	visibility: { individual: true, clinical: true, researcher: true },
			// },
			{
				id: 'More Analysis',
				icon: <PhonelinkSetupIcon />,
				page: paths.legacy,
				visibility: { individual: true, clinical: true, researcher: false },
			},
		],
		visibility: { individual: true, clinical: true, researcher: false },
	},
	{
		id: 'Account',
		children: [
			{
				id: 'Profile',
				icon: <AccountBoxIcon />,
				page: paths.profile,
				visibility: { individual: true, clinical: false, researcher: true },
			},
			{
				id: 'Settings',
				icon: <SettingsIcon />,
				page: paths.settings,
				visibility: { individual: true, clinical: false, researcher: true },
			},
		],
		visibility: { individual: true, clinical: false, researcher: true },
	},
]

function Navigator({
	PaperProps,
	variant,
	open = true,
	onClose,
	logoutUser,
	getUserPicture,
	fetchPatientPicture,
	auth,
	patient,
	title,
	location = null,
	iOS = false,
	...rest
}) {
	const theme = useTheme()
	const [value, setValue] = React.useState(0)
	const [locked, unlock] = React.useState(false)
	const isMonitoring = patient.current?.key
	const { show, hide } = useIntercom()
	const isResearcher = auth?.app_metadata?.roles && auth?.app_metadata?.roles.includes('Researcher')
	let patientName = patient.current?.name
	const safeAuthName = (auth.name || auth.nickname).split('@')[0]

	if (patientName?.includes('@dietahealth.com')) {
		patientName = patientName.replace('@dietahealth.com', '')
		patientName = patientName.split('-').pop()
	}
	patientName = patientName && patientName.split('@')[0]
	return (
		<StyledSwipeableDrawer
			disableSwipeToOpen={iOS}
			open={open}
			onClose={onClose}
			onOpen={() => {}}
			variant={variant}
			PaperProps={PaperProps}
		>
			<div className={classes.flexContainer}>
				<div className={classes.flexHeader}>
					<Link style={{ textDecoration: 'none', color: 'inherit' }} to={paths.home} onClick={onClose}>
						<ListItem className={classNames(classes.firebase, classes.itemCategory)}>
							<Grid container alignItems="center" spacing={2} direction="row">
								<Grid item className={classes.iconWrapper}>
									<Logo scale={0.85} palettePreference={'dark'} />
								</Grid>
							</Grid>
						</ListItem>
					</Link>

					<Link
						style={{
							textDecoration: 'none',
							color: 'inherit',
							wordBreak: 'break-all',
						}}
						to={paths.profile}
						onClick={onClose}
					>
						<ListItem className={classNames(classes.item, classes.itemPersonal, classes.itemActionable)}>
							<Grid
								container
								alignItems="center"
								spacing={1}
								direction="column"
								className={classes.personaSection}
							>
								<Grid item className={classes.iconWrapper}>
									{isMonitoring ? (
										<PictureInPictureAvatar
											src={auth.picture}
											childSrc={patient.current.picture}
											scale={1.8}
											onError={() => {
												getUserPicture()
											}}
											onChildError={() => {
												fetchPatientPicture(patient.current)
											}}
										/>
									) : (
										<ActiveBadgeAvatar
											src={auth.picture}
											scale={1.8}
											onError={() => {
												getUserPicture()
											}}
										/>
									)}
								</Grid>
								<Grid item className={classes.iconWrapper}>
									<Typography
										className={classNames(classes.noSelect, classes.center, classes.topNameMargin)}
										color="inherit"
										variant="body1"
									>
										{safeAuthName}
									</Typography>
									{isMonitoring ? (
										<>
											<Typography
												className={classNames(classes.noSelect, classes.center)}
												color="inherit"
												variant="caption"
											>
												Monitoring:
											</Typography>
											<Typography
												className={classNames(classes.noSelect, classes.center)}
												color="inherit"
												variant="subtitle2"
											>
												{patientName}
											</Typography>
										</>
									) : (
										<></>
									)}
								</Grid>
							</Grid>
						</ListItem>
					</Link>
				</div>

				<div className={classes.flexList}>
					<List disablePadding>
						{categories.map(({ id, children, visibility }) => {
							if (
								(isMonitoring && !visibility.clinical) ||
								(!isMonitoring && !visibility.individual) ||
								(isResearcher && !visibility.researcher)
							) {
								return <span key={id}></span>
							}
							return (
								<React.Fragment key={id}>
									<ListItem className={classes.categoryHeader}>
										<ListItemText
											classes={{
												primary: classes.categoryHeaderPrimary,
											}}
										>
											{id}
										</ListItemText>
									</ListItem>
									{children.map(
										({ id: childId, icon, newFeature, visibility, onClick, page = null }) => {
											const shouldRender =
												((isMonitoring && visibility.clinical) ||
													(!isMonitoring && visibility.individual)) &&
												!(isResearcher && !visibility.researcher)
											return shouldRender ? (
												page ? (
													<ListItem key={childId} dense className={classes.item}>
														<Button
															to={page}
															onClick={onClose}
															key={childId}
															variant="contained"
															color="primary"
															startIcon={icon}
															className={classNames(
																(location && page ? location.includes(page) : false) &&
																	'active',
																classes.navButton,
																(location && page ? location.includes(page) : false) &&
																	classes.itemActiveItem
															)}
														>
															<ListItemText
																className={classes.navItemText}
																classes={{
																	primary: classes.itemPrimary,
																	textDense: classes.textDense,
																}}
															>
																{childId}
																{newFeature ? <NewFeatureBadge /> : <></>}
															</ListItemText>
														</Button>
													</ListItem>
												) : (
													<ListItem key={childId} dense className={classes.item}>
														<Button
															onClick={onClick}
															key={childId}
															variant="contained"
															color="primary"
															startIcon={icon}
															className={classNames(
																(location && page ? location.includes(page) : false) &&
																	'active',
																classes.navButton,
																(location && page ? location.includes(page) : false) &&
																	classes.itemActiveItem
															)}
														>
															<ListItemText
																className={classes.navItemText}
																classes={{
																	primary: classes.itemPrimary,
																	textDense: classes.textDense,
																}}
															>
																{childId}
																{newFeature ? <NewFeatureBadge /> : <></>}
															</ListItemText>
														</Button>
													</ListItem>
												)
											) : (
												<div key={childId} />
											)
										}
									)}
									<Divider className={classes.divider} />
								</React.Fragment>
							)
						})}
					</List>
				</div>
				<div className={classes.flexFooter}>
					<BottomNavigation
						value={value}
						onChange={(event, newValue) => {
							if (newValue === 'help') {
								setValue(0)
							} else {
								setValue(newValue)
							}
						}}
						showLabels
					>
						<BottomNavigationAction
							label="Help"
							value="help"
							icon={<LiveHelpIcon />}
							onClick={() => {
								console.log(value)
								if (value === 0) {
									show()
									setValue(-1)
								} else {
									hide()
								}
							}}
						/>

						{/* <BottomNavigationAction
							label="Lock"
							value="lock"
							icon={<HttpsIcon />}
							onClick={() => unlock(true)}
						/> */}
						<BottomNavigationAction
							label="Logout"
							value="logout"
							icon={<ExitToAppIcon />}
							onClick={() => logoutUser()}
						/>
					</BottomNavigation>
				</div>
			</div>

			<ResponsiveFullscreenDialog
				open={locked}
				onClose={(bool) => {
					unlock(bool)
					setValue(0)
				}}
			/>
		</StyledSwipeableDrawer>
	)
}

Navigator.propTypes = {
	title: PropTypes.string,
	location: PropTypes.string,
	auth: PropTypes.shape({
		accessToken: PropTypes.string,
		name: PropTypes.string,
		nickname: PropTypes.string,
		roles: PropTypes.array,
	}).isRequired,
	patient: PropTypes.shape({
		current: PropTypes.shape({
			id: PropTypes.number,
			picture: PropTypes.string,
		}),
	}),
	logoutUser: PropTypes.func.isRequired,
	refreshToken: PropTypes.func.isRequired,
	fetchPatientPicture: PropTypes.func.isRequired,
	getUserPicture: PropTypes.func.isRequired,
}

const mapStateToProps = ({ auth, patient }) => {
	return {
		auth,
		patient,
	}
}

export default connect(mapStateToProps, actions)(Navigator)
