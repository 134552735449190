// SYMPTOM SCORE
export const GET_SYMPTOM_SCORES_SUCCESS = 'GET_SYMPTOM_SCORES_SUCCESS'
export const GET_SYMPTOM_SCORES_FAIL = 'GET_SYMPTOM_SCORES_FAIL'
export const GET_SYMPTOM_SCORES = 'GET_SYMPTOM_SCORES'
export const ADD_SYMPTOM_SCORE_SUCCESS = 'ADD_SYMPTOM_SCORE_SUCCESS'
export const ADD_SYMPTOM_SCORE_FAIL = 'ADD_SYMPTOM_SCORE_FAIL'
export const ADD_SYMPTOM_SCORE = 'ADD_SYMPTOM_SCORE'
export const UPDATE_SYMPTOM_SCORE_SUCCESS = 'UPDATE_SYMPTOM_SCORE_SUCCESS'
export const UPDATE_SYMPTOM_SCORE_FAIL = 'UPDATE_SYMPTOM_SCORE_FAIL'
export const UPDATE_SYMPTOM_SCORE = 'UPDATE_SYMPTOM_SCORE'
export const RESET_SYMPTOM_SCORE_LOADERS = 'RESET_SYMPTOM_SCORE_LOADERS'

export const SYMPTOM_SCORE_CONTEXT_RESET = 'SYMPTOM_SCORE_CONTEXT_RESET'
