import {
	fetchSymptoms,
	addSymptom as postSymptom,
	updateSymptom as putSymptom,
	removeSymptom,
	fetchSymptomTypes,
	addSymptomType as postSymptomType,
	updateSymptomType as putSymptomType,
	removeSymptomType,
} from 'api/symptom'
import * as types from 'types'
import { handleSuccess, handleFail } from 'actions/z_action_helpers'
import { getDefaultOccurredAt } from 'utils/helpers'

const getDefaultSymptom = (attentionDate) => ({
	notes: null,
	duration: 0,
	occurred_at: getDefaultOccurredAt(attentionDate),
	types: [],
	_app: {
		uuid: null,
		type: 'symptom',
		synced: false,
	},
})

export const resetSymptomLoaders = () => {
	return (dispatch) => {
		dispatch({ type: types.RESET_SYMPTOM_LOADERS })
	}
}

export const addSymptom = (symptom) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_SYMPTOM, payload: symptom })
		try {
			const res = await postSymptom(symptom)
			const contextResponse = {
				...res.data,
				_app: {
					...symptom._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.ADD_SYMPTOM_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...symptom,
				_app: {
					err,
					...symptom._app,
				},
			}
			handleFail(dispatch, types.ADD_SYMPTOM_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateSymptom = (symptom, previousSymptom) => {
	async function thunk(dispatch, getState) {
		dispatch({
			type: types.UPDATE_SYMPTOM,
			payload: { new: symptom, old: previousSymptom },
		})
		try {
			const res = symptom.id ? await putSymptom(symptom, previousSymptom) : await postSymptom(symptom)
			const contextResponse = {
				...res.data,
				_app: {
					...symptom._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.UPDATE_SYMPTOM_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...symptom,
				_app: {
					err,
					...symptom._app,
				},
			}
			handleFail(dispatch, types.UPDATE_SYMPTOM_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteSymptom = (symptom) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.DELETE_SYMPTOM, payload: symptom })
		try {
			const res = await removeSymptom(symptom)
			const contextResponse = {
				...res.data,
				_app: {
					...symptom._app,
					...res.data._app,
				},
			}
			await handleSuccess(dispatch, types.DELETE_SYMPTOM_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...symptom,
				_app: {
					err,
					...symptom._app,
				},
			}
			handleFail(dispatch, types.DELETE_SYMPTOM_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getSymptoms = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_SYMPTOMS })
		try {
			const res = await fetchSymptoms(args, {
				dispatch,
				event: types.GET_SYMPTOMS_SUCCESS,
			})
			await handleSuccess(dispatch, types.GET_SYMPTOMS_SUCCESS_COMPLETE)
			return res
		} catch (err) {
			handleFail(dispatch, types.GET_SYMPTOMS_FAIL, err)
		}
	}
	return thunk
}

export const addSymptomType = (symptomType) => {
	async function thunk(dispatch, getState) {
		dispatch({ type: types.ADD_SYMPTOM_TYPE, payload: symptomType })
		try {
			const res = await postSymptomType(symptomType)
			const contextResponse = {
				...res.data,
				_app: {
					...symptomType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.ADD_SYMPTOM_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...symptomType,
				_app: {
					err,
					...symptomType._app,
				},
			}
			handleFail(dispatch, types.ADD_SYMPTOM_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const updateSymptomType = (symptomType) => {
	async function thunk(dispatch) {
		dispatch({ type: types.UPDATE_SYMPTOM_TYPE, payload: symptomType })
		try {
			const res = symptomType.id ? await putSymptomType(symptomType) : await postSymptomType(symptomType)
			const contextResponse = {
				...res.data,
				_app: {
					...symptomType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.UPDATE_SYMPTOM_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...symptomType,
				_app: {
					err,
					...symptomType._app,
				},
			}
			handleFail(dispatch, types.UPDATE_SYMPTOM_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const deleteSymptomType = (symptomType) => {
	async function thunk(dispatch) {
		dispatch({ type: types.DELETE_SYMPTOM_TYPE, payload: symptomType })
		try {
			const res = await removeSymptomType(symptomType)
			const contextResponse = {
				...res.data,
				_app: {
					...symptomType._app,
					...res.data._app,
				},
			}
			handleSuccess(dispatch, types.DELETE_SYMPTOM_TYPE_SUCCESS, contextResponse)
			return contextResponse
		} catch (err) {
			const contextResponse = {
				...symptomType,
				_app: {
					err,
					...symptomType._app,
				},
			}
			handleFail(dispatch, types.DELETE_SYMPTOM_TYPE_FAIL, contextResponse)
		}
	}
	return thunk
}

export const getSymptomTypes = (args) => {
	async function thunk(dispatch) {
		dispatch({ type: types.GET_SYMPTOM_TYPES })
		try {
			const res = await fetchSymptomTypes(args)
			handleSuccess(dispatch, types.GET_SYMPTOM_TYPES_SUCCESS, res.data, {
				...args,
				invoked_at: new Date().toISOString(),
			})
			return res.data
		} catch (err) {
			handleFail(dispatch, types.GET_SYMPTOM_TYPES_FAIL, err)
		}
	}
	return thunk
}

export const setCurrentSymptom = (symptom, attentionDate) => {
	if (!symptom) {
		symptom = getDefaultSymptom(attentionDate)
	}
	return (dispatch) => {
		dispatch({ type: types.CURRENT_SYMPTOM_SET, payload: symptom })
	}
}

export const clearCurrentSymptom = (symptom = getDefaultSymptom()) => {
	return (dispatch) => {
		dispatch({ type: types.CURRENT_SYMPTOM_CLEAR, payload: symptom })
	}
}

export const addCurrentSymptomType = (symptomType) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({ type: types.CURRENT_SYMPTOM_TYPE_ADD, payload: symptomType })
			res(symptomType)
		})
}

export const removeCurrentSymptomType = (symptomType) => {
	return (dispatch) =>
		new Promise((res, rej) => {
			dispatch({
				type: types.CURRENT_SYMPTOM_TYPE_REMOVE,
				payload: symptomType,
			})
			res(symptomType)
		})
}
