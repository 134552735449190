/**
 * CRUD Actions for CLINICIAN
 */
// ADD
export const ADD_CLINICIAN_SUCCESS = 'ADD_CLINICIAN_SUCCESS'
export const ADD_CLINICIAN_FAIL = 'ADD_CLINICIAN_FAIL'
export const ADD_CLINICIAN = 'ADD_CLINICIAN'
// UPDATE
export const UPDATE_CLINICIAN_SUCCESS = 'UPDATE_CLINICIAN_SUCCESS'
export const UPDATE_CLINICIAN_FAIL = 'UPDATE_CLINICIAN_FAIL'
export const UPDATE_CLINICIAN = 'UPDATE_CLINICIAN'
// DELETE
export const DELETE_CLINICIAN_SUCCESS = 'DELETE_CLINICIAN_SUCCESS'
export const DELETE_CLINICIAN_FAIL = 'DELETE_CLINICIAN_FAIL'
export const DELETE_CLINICIAN = 'DELETE_CLINICIAN'
// GET ALL
export const GET_CLINICIANS_SUCCESS = 'GET_CLINICIANS_SUCCESS'
export const GET_CLINICIANS_FAIL = 'GET_CLINICIANS_FAIL'
export const GET_CLINICIANS = 'GET_CLINICIANS'

export const GET_CLINICIANS_ENROLLING_SUCCESS = 'GET_CLINICIANS_ENROLLING_SUCCESS'
export const GET_CLINICIANS_ENROLLING_FAIL = 'GET_CLINICIANS_ENROLLING_FAIL'
export const GET_CLINICIANS_ENROLLING = 'GET_CLINICIANS_ENROLLING'
/**
 * Current Types
 */
// CURRENT CLINICIAN
export const CURRENT_CLINICIAN_SET = 'CURRENT_CLINICIAN_SET'
export const CURRENT_CLINICIAN_CLEAR = 'CURRENT_CLINICIAN_CLEAR'

export const MEDIA_CLINICIAN_SET = 'MEDIA_CLINICIAN_SET'

export const RESET_CLINICIAN_LOADERS = 'RESET_CLINICIAN_LOADERS'

export const CLINICIAN_CONTEXT_RESET = 'CLINICIAN_CONTEXT_RESET'

export const GET_CLINICIAN_ADMINISTRATION_SETTINGS = 'GET_CLINICIAN_ADMINISTRATION_SETTINGS'
export const GET_CLINICIAN_ADMINISTRATION_SETTINGS_SUCCESS = 'GET_CLINICIAN_ADMINISTRATION_SETTINGS_SUCCESS'
export const GET_CLINICIAN_ADMINISTRATION_SETTINGS_FAIL = 'GET_CLINICIAN_ADMINISTRATION_SETTINGS_FAIL'

export const UPDATE_CLINICIAN_ADMINISTRATION_SETTINGS = 'UPDATE_CLINICIAN_ADMINISTRATION_SETTINGS'
export const UPDATE_CLINICIAN_ADMINISTRATION_SETTINGS_SUCCESS = 'UPDATE_CLINICIAN_ADMINISTRATION_SETTINGS_SUCCESS'
export const UPDATE_CLINICIAN_ADMINISTRATION_SETTINGS_FAIL = 'UPDATE_CLINICIAN_ADMINISTRATION_SETTINGS_FAIL'
