const path = require('path')

const routes = {
	404: {
		path: '/404',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, '404.js'),
	},
	analytics: {
		path: '/analytics',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'analytics.js'),
	},
	annotations: {
		path: '/annotations',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'annotationsPage.js'),
	},
	authentication: {
		path: '/authentication',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'authentication.js'),
	},
	callback: {
		path: '/callback',
		context: {
			layout: false,
		},
		component: path.resolve(__dirname, 'callback.js'),
	},
	chat: {
		path: '/chat',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'chat.js'),
	},
	channel: {
		path: '/chat/:userKey',
		matchPath: '/chat/:userKey',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'chat.js'),
	},
	diary: {
		path: '/diary',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'diary.js'),
	},
	stoolTrends: {
		path: '/stool-trends',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'stoolTrends.js'),
	},
	forgotPassword: {
		path: '/forgot-password',
		context: {
			layout: false,
		},
		component: path.resolve(__dirname, 'forgotPassword.js'),
	},
	functions: {
		path: '/functions',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'functions.js'),
	},
	home: {
		path: '/',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'diary.js'),
	},
	history: {
		path: '/history',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'history.js'),
	},
	hosting: {
		path: '/hosting',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'hosting.js'),
	},
	interventions: {
		path: '/interventions',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'interventions.js'),
	},
	legacy: {
		path: '/legacy',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'legacy.js'),
	},
	adminLogin: {
		path: '/login/admin',
		context: {
			layout: false,
		},
		component: path.resolve(__dirname, 'adminLogin.js'),
	},
	jotform: {
		path: '/jotform/:programId/:formId',
		matchPath: '/jotform/:programId/:formId',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'jotform.js'),
	},
	login: {
		path: '/login',
		context: {
			layout: false,
		},
		component: path.resolve(__dirname, 'login.js'),
	},
	ml: {
		path: '/ml',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'ml.js'),
	},
	notes: {
		path: '/notes',
		matchPath: '/notes/*',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'notes.js'),
	},
	patient: {
		path: '/patient',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'patient.js'),
	},
	monitor: {
		// "sub" refers to the JWT sub property (i.e. "subject")
		path: '/monitor/:sub',
		matchPath: '/monitor/:sub',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'patient.js'),
	},
	profile: {
		path: '/profile',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'profile.js'),
	},
	program: {
		path: '/program',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'program.js'),
	},
	settings: {
		path: '/settings',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'settings.js'),
	},
	signup: {
		path: '/signup',
		context: {
			layout: false,
		},
		component: path.resolve(__dirname, 'signup.js'),
	},
	study: {
		path: '/study',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'study.js'),
	},
	survey: {
		path: '/survey',
		context: {
			layout: true,
		},
		component: path.resolve(__dirname, 'survey.js'),
	},
}

// Same keys as 'routes', but the value is only the path.
const paths = Object.keys(routes).reduce((acc, route) => {
	acc[route] = routes[route].path
	return acc
}, {})

const pathRouteMap = Object.keys(routes).reduce((acc, route) => {
	const config = routes[route]
	acc[config.path] = {
		...config,
		name: route,
	}
	return acc
}, {})

module.exports = { routes, paths, pathRouteMap }
